<div class="download-app-first-post">

  <p mipTranslate="download-app-first-post.message">
    You haven't post yet on GotChosen!
  </p>

  <button
    class="download-app-button"
    mat-raised-button
    mipBgColor="primary"
    (click)="openDownloadAppDialog()"
  >
    <span mipTranslate="download-app-first-post.action.do-post">
      Make my first post
    </span>
  </button>

</div>
