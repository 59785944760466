<div
  class="top-menu-bar"
  [class.--is-fixed]="isFixed"
  [class.--enable-shadow]="enableShadow"
>

  <div class="__bar-item">
    <ng-content select="[left-action]"></ng-content>

    <!-- LOGO -->
    <a
      class="__bar-item __logo"
      [mipGoBackReferrer]
      routerLink="/trending"
    >
      <img src="{{assetsPath}}/images/gc-logo.png" alt="">
    </a>
  </div>

  <!-- SEARCH -->
  <div class="__bar-item __search-form" *ngIf="enableSearch">
    <mip-search-form></mip-search-form>
  </div>

  <!-- LOGIN -->
  <div class="__bar-item" style="padding-right: 20px" *ngIf="!loggedInUser">
    <a
      class="mip-color--bg-primary"
      mat-button
      [mipGoBackReferrer]
      [href]="loginUrl"
    >
      <span mipTranslate="top-menu-bar.login">Login</span>
    </a>
  </div>

  <!-- PROFILE -->
  <div class="__bar-item __user-link" *ngIf="loggedInUser">

    <a
      mat-button
      [mipGoBackReferrer]
      [routerLink]="'/@' + loggedInUser.username"
      class="__user-action"
    >
      <mip-avatar
        [userModel]="loggedInUser"
        [borderWidth]="1"
        borderColor="gray"
      ></mip-avatar>

      <span class="__link-inner">
        <mip-profile-slug-name class="__link-text" [user]="loggedInUser" [showName]="true"></mip-profile-slug-name>
        <!--<span *ngIf="loggedInUser.getName()" style="white-space: nowrap">{{loggedInUser.getName()}}</span>
        <span><mip-profile-slug-name [user]="loggedInUser"></mip-profile-slug-name></span>-->
      </span>

    </a>
    <!--<button mat-icon-button>
      <mip-icon icon="ellipsis" [bold]="true"></mip-icon>
    </button>-->

    <mip-profile-options
      [profile]="loggedInUser"
    ></mip-profile-options>

  </div>

</div>
