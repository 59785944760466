<!-- section BOTTOM ACTION -->
<div
  class="floating-button"
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
  [class.--hide]="isLoading"
>

  <button
    mat-button
    mipBgColor="primary"
    [class]="buttonSize ? 'mip-button--size-' + buttonSize : ''"
    (click)="onClick()"
  >

    <mip-icon *ngIf="icon" [icon]="icon"></mip-icon>
    <span
      *ngIf="!isContentActive"
      [mipTranslate]="text"
    >
    </span>

  </button>

</div>
