import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AdvertisementService } from '@libs/gc-common/lib/features/advertisement/advertisement.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { ImaSdkFactory } from '@libs/gc-common/lib/services/ima-sdk/ima-sdk.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-ad-post-video',
  templateUrl: './ad-post-video.component.html',
  styleUrls: ['./ad-post-video.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdPostVideoComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() isIntoViewport = false;
  @Input() videoSrc: string;
  @Input() isPreRoll = false;
  @Input() disableAd;

  // loadAd() events
  @Output() onAdRequested = new EventEmitter();
  @Output() onAdFilled = new EventEmitter();
  @Output() onAdNotFilled = new EventEmitter();
  @Output() onAdLoaded = new EventEmitter();

  // playAd() events
  @Output() onAdStartPlay = new EventEmitter();
  @Output() onAdEndedPlay = new EventEmitter();

  // other events
  @Output() onDestroyed = new EventEmitter();
  @Output() onError = new EventEmitter();

  @ViewChild('adVideoPlayer') adVideoPlayer: ElementRef;
  @ViewChild('adContainer') adContainer: ElementRef;

  hasLoadedOnce = false;
  adVideoPlayerEl: HTMLVideoElement = null;
  adContainerEl = null;
  hostEl = null;
  isMuted = true;
  hasAdBeenRequested = false;
  hasAdBeenFilled = null;
  hasAdBeenDestroyed = false;
  startAdInterval = null;

  isMobile = true;
  isDesktop = false;

  imaSdk: ImaSdkFactory = null;

  autoplay = false;
  observerHelper = new ObserverHelper();

  constructor(
    private hostElement: ElementRef,
    private advertisementService: AdvertisementService,
    private mobileDetectService: MobileDetectService
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();

    if (this.disableAd === undefined) {
      this.disableAd = this.advertisementService.isAdDisabled('disablePostAd');
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges() {

    // console.log('[VIDEO-AD] ad-post-video.component->ngOnChanges(): this.videoSrc', this.videoSrc);
    // console.log('[VIDEO-AD] ad-post-video.component->ngOnChanges(): this.isPreRoll', this.isPreRoll);

    if (this.imaSdk) {
      if (this.isIntoViewport) {
        this.imaSdk.unmuteAd();
      }
      else {
        this.imaSdk.muteAd();
      }
    }
  }

  ngAfterViewInit() {

    this.adVideoPlayerEl = this.adVideoPlayer.nativeElement;
    // console.log('[VIDEO-AD] ad-post-video.component->ngAfterViewInit(): this.adVideoPlayerEl', this.adVideoPlayerEl);

    this.adContainerEl = this.adContainer.nativeElement;
    // console.log('[VIDEO-AD] ad-post-video.component->ngAfterViewInit(): this.adContainerEl', this.adContainerEl);

    this.hostEl = this.hostElement.nativeElement;
    // console.log('[VIDEO-AD] ad-post-video.component->ngAfterViewInit(): this.hostEl', this.hostEl);

    // this.imaSdk = new ImaSdkFactory(this.adVideoPlayerEl, this.adContainerEl);
    // console.log('[VIDEO-AD] ad-post-video.component->ngAfterViewInit(): this.imaSdk', this.imaSdk);

  }

  ngOnDestroy() {

    if (this.imaSdk) {
      this.imaSdk.destroy();
    }

    clearInterval(this.startAdInterval);

    this.observerHelper.unsubscribeAll();

  }

  destroyAd() {
    // console.log('[VIDEO-AD] ad-post-video.component->destroyAd(): this.imaSdk', this.imaSdk);

    try {
      // console.log('[VIDEO-AD] ad-post-video.component->destroyAd(): this.adVideoPlayerEl', this.adVideoPlayerEl);

      if (this.imaSdk) {
        this.imaSdk.destroy();
      }

      if (this.adVideoPlayerEl) {
        this.adVideoPlayerEl.muted = true;
        this.adVideoPlayerEl.pause();
        this.adVideoPlayerEl.removeAttribute('src');
        this.adVideoPlayerEl.load();
      }

      this.imaSdk = null;
      this.hasAdBeenDestroyed = true;
      // console.log('[VIDEO-AD] ad-post-video.component->destroyAd(): this.hasAdBeenDestroyed', this.hasAdBeenDestroyed);

    }
    catch (e) {
      console.error('[VIDEO-AD] ad-post-video.component->destroyAd(): ERROR', e);
    }

  }

  resetAd() {
    this.hasLoadedOnce = false;
    this.hasAdBeenDestroyed = false;
  }

  loadAd(playAd?) {
    // console.log('[VIDEO-AD] ad-post-video.component->loadAd(): this.disableAd', this.disableAd);

    if (this.hasLoadedOnce || this.disableAd === true || this.hasAdBeenDestroyed === true) {
      return false;
    }

    const advertisementServiceSubscription = this.advertisementService.registerVideoAdElement(
      (this.isPreRoll ? 'PrerollVideoAd' : 'PostrollVideoAd'),
      this.adVideoPlayerEl,
      this.adContainerEl,
      false
    ).subscribe(response => {
      // console.log('[VIDEO-AD] ad-post-video.component->loadAd(): response.status', response.status);

      if (response.status === 'AD_HAS_STARTED') {
        this.imaSdk = response.ima;
        // console.log('[VIDEO-AD] ad-post-video.component->loadAd(): this.imaSdk', this.imaSdk);
      }

      if (response.status === 'AD_REQUESTED') {
        this.hasAdBeenRequested = true;
        this.onAdRequested.emit();
      }

      if (response.status === 'AD_HAS_FILLED') {
        this.hasAdBeenFilled = true;

        if (playAd) {
          this.startAd();
        }
      }

      if (response.status === 'AD_NOT_FILLED') {
        this.hasAdBeenFilled = false;
        this.onAdNotFilled.emit();
      }

      if (response.status === 'AD_LOADED') {
        this.onAdLoaded.emit();
      }

      if (response.status === 'AD_DESTROYED') {
        this.onDestroyed.emit();
      }

      this.hasLoadedOnce = true;

    }, error => {
      this.hasAdBeenFilled = false;
      this.hasAdBeenRequested = false;
      console.error('[VIDEO-AD] ad-post-video.component->loadAd(): error', error);
      this.onError.emit(error);
      this.destroyAd();
    });

    this.observerHelper.addSubscription(
      advertisementServiceSubscription
    );

  }

  async startAd(muted = false) {
    // console.log('[VIDEO-AD] ad-post-video.component->playAd(): muted', muted);
    // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.hasLoadedOnce', this.hasLoadedOnce);
    // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.imaSdk', !!this.imaSdk);
    // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.hasAdBeenDestroyed', this.hasAdBeenDestroyed);

    try {

      if (/*this.hasLoadedOnce || */!this.imaSdk || this.hasAdBeenDestroyed === true) {
        return false;
      }

      // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.adVideoPlayerEl', this.adVideoPlayerEl);

      this.autoplay = true;
      this.adVideoPlayerEl.play();

      this.startAdInterval = setInterval(() => {
        // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.hasAdBeenRequested', this.hasAdBeenRequested);
        // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.hasAdBeenFilled', this.hasAdBeenFilled);

        if (this.hasAdBeenFilled === true) {
          clearInterval(this.startAdInterval);
          this.imaSdk.startAd().subscribe(status => {
            // console.log('[VIDEO-AD] ad-post-video.component->playAd(): status', status);

            if (status === 'AD_STARTED') {

              this.onAdFilled.emit();

              if (this.isIntoViewport && muted === false) {
                this.imaSdk.unmuteAd();
              }
              else {
                this.imaSdk.muteAd();
              }

              /*if (this.isMuted) {
               this.imaSdk.muteAd();
               }
               else {
               this.imaSdk.unmuteAd();
               }*/

              this.onAdStartPlay.emit();

              /*setTimeout(() => {
               this.playAd();
               }, 1000);*/

            }

            if (status === 'AD_COMPLETE') {
              this.destroyAd();
              this.onAdEndedPlay.emit();
            }

          }, error => {
            console.error('[VIDEO-AD] ad-post-video.component->playAd(): error', error);
            this.destroyAd();
          });
        }
        else if (this.hasAdBeenFilled === false) {
          clearInterval(this.startAdInterval);
        }

      }, 100);

    }
    catch (e) {
      throw e;
    }
  }

  playAd() {
    // console.log('[VIDEO-AD] ad-post-video.component->playAd(): this.imaSdk', this.imaSdk);
    if (this.imaSdk) {
      return this.imaSdk ? this.imaSdk.playAd() : false;
    }
  }

  pauseAd() {
    // console.log('[VIDEO-AD] ad-post-video.component->pauseAd(): this.imaSdk', this.imaSdk);
    if (this.imaSdk) {
      return this.imaSdk ? this.imaSdk.pauseAd() : false;
    }
  }

  unmuteAd() {
    // console.log('[VIDEO-AD] ad-post-video.component->unmuteAd(): this.imaSdk', this.imaSdk);
    if (this.imaSdk) {
      this.isMuted = false;
      return this.imaSdk.unmuteAd();
    }
  }

  muteAd() {
    // console.log('[VIDEO-AD] ad-post-video.component->muteAd(): this.imaSdk', this.imaSdk);
    if (this.imaSdk) {
      this.isMuted = true;
      return this.imaSdk.muteAd();
    }
  }

  toggleMuteAd() {
    if (this.isMuted) {
      this.unmuteAd();
    }
    else {
      this.muteAd();
    }
  }

}
