import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

@Component({
	selector: 'mip-scroll-fix',
	templateUrl: './scroll-fix.component.html',
	styleUrls: ['./scroll-fix.component.scss']
})
export class ScrollFixComponent implements OnInit {
	
	@Input() top = 0;
	@Input() marginTop = 0;
	@Input() fixBorder = 'top';
	@Input() hideOnUnstick = false;
	@Input() zIndex = 10;
	
	@Output() onFixed = new EventEmitter();
	@Output() onUnfixed = new EventEmitter();
	@Output() onHide = new EventEmitter();
	
	constructor() {
	}
	
	ngOnInit(): void {
	}
	
}
