<mat-toolbar
  [class]="classes"
  [class.--is-desktop]="isDesktop"
  [class.--larger-theme]="largerTheme"
  [class.--no-background]="noBackground"
  [style]="{
    backgroundColor: bgColor,
    color: textColor
  }"
>

  <!-- GO-BACK [hideParent]="true" -->
  <div
    class="__back-button-container"
    [class.--rounded]="roundedBackButton"
    *ngIf="(goBackFallback || goBackReferrer) && disableGoBackBottom === false"
  >

    <a
      class="__back-button"
      mat-button
      [class.--has-title]="goBackReferrer && goBackReferrer.title"
      [routerLink]="goBackReferrer ? goBackReferrer.path : goBackFallback"
      [queryParams]="goBackReferrer ? goBackReferrer.search : {}"
      [fragment]="goBackReferrer ? goBackReferrer.fragment : null"
    >
      <mip-icon icon="arrow-left" mipTextColor="primary"></mip-icon> <!-- size="2x" -->
      <span class="__back-button-label" *ngIf="goBackReferrer && goBackReferrer.title">
        {{goBackReferrer.title}}
      </span>
    </a>
  </div>

  <!-- TOOLBAR-CONTENT -->
  <ng-content></ng-content>

</mat-toolbar>

<div class="__toolbar-shadow" *ngIf="noDropShadow !== true && noBackground !== true"></div>
