import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { JoinHashtagButtonComponent } from './join-hashtag-button.component';

@NgModule({
  declarations: [JoinHashtagButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule
  ],
  exports: [JoinHashtagButtonComponent]
})
export class JoinHashtagButtonModule {
}
