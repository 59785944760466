import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { MipDividerComponent } from './mip-divider.component';

@NgModule({
  declarations: [MipDividerComponent],
  imports: [
    CommonModule,
    ColorModule
  ],
  exports: [MipDividerComponent]
})
export class MipDividerModule {
}
