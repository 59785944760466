import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { TranslateService } from '@ngx-translate/core';
import {
  Events,
  FileUploadWithPreview,
  ImageAddedEvent
} from 'file-upload-with-preview';

@Component({
  selector: 'mip-form-file-upload',
  templateUrl: './mip-form-file-upload.component.html',
  styleUrls: ['./mip-form-file-upload.component.scss']
})
export class MipFormFileUploadComponent implements AfterViewInit, OnDestroy {

  @Input() maxFilesLength = 6;
  @Output() onFilesChange = new EventEmitter();

  fileUpload: FileUploadWithPreview = null;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  async ngAfterViewInit() {
    try {

      if (utilsFactory.isBrowser) {
        this.translateService.get([
          'mip-form-file-upload.error.message',
          'mip-form-file-upload.form.browse',
          'mip-form-file-upload.form.chooseFile',
          'mip-form-file-upload.form.label',
          'mip-form-file-upload.form.selectedCount',
        ]).subscribe(async translation => {
          console.log('mip-form-file-upload.component->ngAfterViewInit(): translation', translation);

          await utilsFactory.waitToBeTrue('mip-form-file-upload.component', () => !!document.querySelector('[data-upload-id="form-file-upload-container"]'));
          console.log('mip-form-file-upload.component->ngAfterViewInit(): form-file-upload-container', document.querySelector('[data-upload-id="form-file-upload-container"]'));

          this.fileUpload = new FileUploadWithPreview('form-file-upload-container', {
            multiple: true,
            text: {
              browse: translation['mip-form-file-upload.form.browse'],
              chooseFile: translation['mip-form-file-upload.form.chooseFile'],
              label: translation['mip-form-file-upload.form.label'],
              selectedCount: translation['mip-form-file-upload.form.selectedCount'],
            }
          });
          console.log('mip-form-file-upload.component->ngAfterViewInit(): this.fileUpload', this.fileUpload);

          window.addEventListener(Events.IMAGE_ADDED, (e: Event) => {
            const { detail } = e as unknown as ImageAddedEvent;
            console.log('mip-form-file-upload.component->ngAfterViewInit(): IMAGE_ADDED', detail);

            if (detail.cachedFileArray.length > this.maxFilesLength) {

              setTimeout(() => {
                this.fileUpload.resetPreviewPanel();
              }, 100);

              this.snackBar.open(`${translation['mip-form-file-upload.error.message']} ${this.maxFilesLength}`, 'Ok', {
                panelClass: 'mip-color--bg-danger'
              });

            }
            else {
              this._onFilesChange(detail.cachedFileArray || []);
            }

          });

          window.addEventListener(Events.IMAGE_DELETED, (e: Event) => {
            const { detail } = e as unknown as ImageAddedEvent;
            console.log('mip-form-file-upload.component->ngAfterViewInit(): IMAGE_DELETED', detail);
            this._onFilesChange(detail.cachedFileArray || []);
          });

          window.addEventListener(Events.CLEAR_BUTTON_CLICKED, (e: Event) => {
            const { detail } = e as unknown as ImageAddedEvent;
            console.log('mip-form-file-upload.component->ngAfterViewInit(): CLEAR_BUTTON_CLICKED', detail);
            this._onFilesChange(detail.cachedFileArray || []);
          });
        });
      }

    }
    catch (e) {
      console.error('mip-form-file-upload.component->ngAfterViewInit(): ERROR', e);
    }
  }

  ngOnDestroy() {
    if (this.fileUpload) {
      this.fileUpload.resetPreviewPanel();
      this.onFilesChange.emit([]);
    }
  }

  _onFilesChange(files: File[]) {
    console.log('mip-form-file-upload.component->_onFilesChange(): files', files);
    this.onFilesChange.emit(files);
  }
}
