import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { TranslateModule } from '@ngx-translate/core';

import { DownloadAppButtonsComponent } from './download-app-buttons.component';
import { DownloadAppFirstPostComponent } from './download-app-first-post.component';
import { DownloadAppModalComponent } from './download-app-modal.component';
import { DownloadAppSeeMoreComponent } from './download-app-see-more.component';

@NgModule({
  declarations: [
    DownloadAppModalComponent,
    DownloadAppButtonsComponent,
    DownloadAppSeeMoreComponent,
    DownloadAppFirstPostComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    MatBottomSheetModule,
    ColorModule,
    TranslateModule
  ],
  exports: [
    DownloadAppModalComponent,
    DownloadAppButtonsComponent,
    DownloadAppSeeMoreComponent,
    DownloadAppFirstPostComponent
  ]
})
export class DownloadAppModule {
}
