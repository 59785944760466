import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const mipModalAnimation = [

  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('mipModalAnimation', [

    // end state styles for route container (host)
    state('*', style({
      // the view covers the whole screen with a semi transparent background
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    })),

    // route 'enter' transition
    transition(':enter', [
      group([

        style({
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }),

        // animation and styles at end of transition
        animate(
          '0.5s ease-in-out',
          style({
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          })
        ),

        query('@mipModalChildAnimation', animateChild(), {optional: true})

      ])
    ]),

    // route 'leave' transition
    transition(':leave', [

      group([
        style({
          backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }),

        // animation and styles at end of transition
        animate(
          '0.5s ease-in-out',
          style({
            backgroundColor: 'rgba(0, 0, 0, 0)'
          })
        ),

        query('@mipModalChildAnimation', animateChild(), {optional: true})

      ])

    ])

  ]),

  trigger('mipModalChildAnimation', [

    state('*', style({
      position: 'relative',
      right: 0
    })),

    // route 'enter' transition
    transition(':enter', [

      style({
        right: '-200%',
      }),

      // animation and styles at end of transition
      animate('0.5s ease-in-out', style({
        right: 0
      }))
    ]),

    // route 'leave' transition
    transition(':leave', [
      animate('0.5s ease-in-out', style({
        right: '-200%'
      }))
    ])

  ])

];
