import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OnReachingBottomDirective } from './on-reaching-bottom.directive';

@NgModule({
  declarations: [OnReachingBottomDirective],
  imports: [
    CommonModule
  ],
  exports: [
    OnReachingBottomDirective
  ]
})
export class OnReachingBottomModule {
}
