import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, OnDestroy {

  searchForm = new FormGroup({
    search: new FormControl('', [])
  });

  searchResultsPageIsOpen = false;
  showCloseButton = false;
  searchTerm = null;
  searchWaitTimeout = null;
  referralPath = null;
  isMobile = true;
  isDesktop = false;

  onRouteChangeSubscription = null;

  extraParams = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private mobileDetectService: MobileDetectService,
    private cacheService: CacheService
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
  }

  ngOnInit() {
    this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe(route => {

      // console.log('search.component->ngOnInit() route', route);
      // console.log('search.component->ngOnInit() route.url', route.url, route.url.indexOf('/search'));

      if (this.isDesktop) {
        const referralPath = this.cacheService.getCache('search', 'mip-search-referral');
        // console.log('search.component->ngOnInit() referralPath', referralPath);

        if (route.url.indexOf('/search') === -1) {
          this.referralPath = route.url;
          this.cacheService.setCache('search', 'mip-search-referral', {
            referralPath: this.referralPath
          });
        }
        else if (referralPath) {
          this.referralPath = referralPath.referralPath;
        }
      }

      // console.log('search.component->ngOnInit() this.referralPath', this.referralPath);

      if (route.url.indexOf('/results') > -1) {

        this.extraParams = route.queryParams;

        if (route.queryParams.term) {
          this.showCloseButton = true;
          this.searchTerm = route.queryParams.term;
          this.searchForm.controls.search.setValue(this.searchTerm);
        }
        else {
          this.searchForm.controls.search.reset();
          this.searchForm.controls.search.markAsPristine();
        }

        this.searchResultsPageIsOpen = true;

      }
      else {
        this.searchForm.controls.search.reset();
        this.searchForm.controls.search.markAsPristine();
      }

    });
  }

  ngOnDestroy() {
    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }
  }

  async resetSearchTerm(close?) {

    // console.log('search.component->resetSearchTerm() close', close);

    this.showCloseButton = false;
    this.searchTerm = null;
    this.searchForm.controls.search.reset();
    this.searchForm.controls.search.markAsPristine();

    /*if (close || this.isDesktop) {
     this.searchResultsPageIsOpen = false;

     // console.log('search.component->resetSearchTerm() this.referralPath', this.referralPath);
     if (this.referralPath) {
     await this.routerService.navigateTo(this.referralPath, {
     queryParams: {
     term: null
     }
     });
     }
     else {
     await this.routerService.navigateTo('/search', {
     queryParams: {
     term: null
     }
     });
     }
     }*/
  }

  async onSearchTermChange(ignoreTimeout?, event?) {
    // console.log('search.component->onSearchTermChange() event', event);

    // removing this line to allow showing the "no data"
    // message when the user clear the search form input
    /*if (!event) {
     return;
     }*/

    if (this.searchWaitTimeout) {
      clearTimeout(this.searchWaitTimeout);
    }

    // console.log('search.component->onSearchTermChange() this.searchForm.value.search', this.searchForm.value.search, this.routerService.getCurrentUrl().indexOf('/results'));

    this.searchWaitTimeout = setTimeout(async () => {
      if (this.searchForm.value.search) {

        this.showCloseButton = true;

        const extras = {
          queryParams: {
            ...this.extraParams,
            term: this.searchForm.value.search
          }
        };

        // console.log('search.component->onSearchTermChange() extras', extras);
        await this.routerService.navigateTo('/search/results', extras);

      }
      else if (this.routerService.getCurrentUrl().indexOf('/results') > -1) {
        await this.routerService.navigateTo('/search/results', {
          queryParams: { term: null }
        });
      }
    }, ignoreTimeout ? 1 : 600);
  }

  async closeSearchResultsPage() {

    this.searchResultsPageIsOpen = false;
    this.showCloseButton = false;
    this.searchTerm = null;

    clearTimeout(this.searchWaitTimeout);

    // console.log('search.component->closeSearchResultsPage()');
    await this.routerService.navigateTo('/search', {
      queryParams: {
        term: null
      }
    });
  }

}
