import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { SlidePostsPreviewModule } from '@libs/gc-common/lib/features/slide-posts-preview/slide-posts-preview.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';

import { HashtagPreviewComponent } from './hashtag-preview.component';

@NgModule({
  declarations: [HashtagPreviewComponent],
  imports: [
    CommonModule,
    SlidePostsPreviewModule,
    RouterModule,
    MatButtonModule,
    GoBackModule,
    ColorModule,
    MillifyModule,
    MipIconModule,
    MipTranslateModule
  ],
  exports: [HashtagPreviewComponent]
})
export class HashtagPreviewModule {
}
