import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { DashboardAmbassadorModel } from './dashboard-ambassador.model';

export class DashboardAmbassadorFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = DashboardAmbassadorModel;

  constructor() {
    super();
  }

  override build(data: object): DashboardAmbassadorModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<DashboardAmbassadorModel> {
    return super.buildCollection(data);
  }

}

// export const userFactory = new UserFactory();
