import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Injectable({
	providedIn: 'root'
})
export class SideMenuService implements OnDestroy {
	
	_openSidenav = false;
	_isSidenavOpened = false;
	sidenav: MatSidenav = null;
	
	observerHelper = new ObserverHelper();
	
	constructor(
		private routerService: RouterService
	) {
		this.observerHelper.addSubscription(
			this.routerService.onRouteChange().subscribe(route => {
				if (route.queryParams.menuOpen && this.sidenav) {
					this.open();
				}
				else {
					this._openSidenav = route.queryParams.menuOpen;
				}
			})
		);
	}
	
	ngOnDestroy() {
		this.observerHelper.unsubscribeAll();
	}
	
	registerSidenav(sidenav: MatSidenav) {
		this.sidenav = sidenav;
		
		this.sidenav.closedStart.subscribe(() => {
			console.log('side-menu.service->constructor(): closedStart');
			this.close();
		});
		
		if (this._openSidenav) {
			this.open();
		}
	}
	
	async open() {
		console.log('side-menu.service->open()');
		this._isSidenavOpened = true;
		/*const menuOpen = await this.routerService.getRouteQueryParam('menuOpen');
		 console.log('side-menu.service->open(): menuOpen', menuOpen);
		 
		 if (menuOpen !== 'true') {
		 await this.routerService.navigateToChild([], {menuOpen: true});
		 }*/
		
		return this.sidenav.open();
		
	}
	
	close() {
		console.log('side-menu.service->close()');
		this._isSidenavOpened = false;
		this.sidenav.close();
		// this.routerService.navigateToChild([], {menuOpen: null});
	}
	
	toggle() {
		console.log('side-menu.service->toggle()');
		if (this._isSidenavOpened) {
			this.close();
		}
		else {
			this.open();
		}
	}
}
