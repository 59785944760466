import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WithinViewportService {

  _childAddedObservable = new Subject();
  _childAddedObserver = this._childAddedObservable.asObservable();

  constructor() {
  }

  addChild(el: HTMLElement, data: any) {
    this._childAddedObservable.next({el, data});
  }

  onChildAdded() {
    return this._childAddedObserver;
  }

}
