import packageFile from '../../../../package.json';
import { EnvironmentInterface } from './environment.interface';

const settings: EnvironmentInterface = {
  appVersion: packageFile.version,
  production: true,
  defaultLanguage: 'en',
  defaultCountry: 'BR',
  supportedLanguages: ['en', 'pt', 'es', 'hi'],
  globalLanguages: ['en', 'pt', 'es', 'hi'],
  indiaLanguages: ['en', 'hi'],
  environmentName: 'production',
  cookieBaseHref: 'gotchosen.com',
  networkUrl: 'https://www.gotchosen.com',
  loginUrl: 'https://login.gotchosen.com',
  collabUrl: 'https://collab.gotchosen.com',
  appBaseHrefUseValue: '//www.gotchosen.com',
  assetsPath: 'https://cdn.gotchosen.com/mip/ssr/assets',
  ssrBasePath: 'https://cdn.gotchosen.com/mip/ssr',
  enableSocialLogin: true,
  facebookAppId: '230975433983403',
  googleClientId: '579174145361-f9t91bfjb6im652rmflq7r7v4iv62q7s.apps.googleusercontent.com',
  googleRecaptchaV3Key: '6LdGw9UmAAAAAPRjdpLL2H7k4mnh6ZGGtCHV3ZE6',
  appleClientId: 'com.gotchosen.www',
  gcjsUrl: 'https://cdn.gotchosen.com/gcjs',
  replaceMediaUrl: {
    replace: null,
    with: null
  },
  passwordPattern: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
  // eslint-disable-next-line no-control-regex
  emailPattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  urlPattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  phonePattern: {
    pt: /\([0-9]{2}\)[0-9]{4,5}-[0-9]{4}/
  },
  userReferral: {
    code: 'PEAU3YJ',
    username: 'gotchosen'
  },
  enableTestVideoAd: false,
  disableCache: false,
  forceCache: false,
  originalLogs: false,
  disableLogs: true,
  disableLogsServer: true,
  newRelicAgentID: '1103130873',
  registerReservedWords: [
    'gotchosen',
    'gotcoin',
    'chosenplus',
    'got-chosen',
    'got-coin',
    'chosen-plus'
  ],
  enableWidget: false,
  disableAds: false,
  adsPlacement: {
    disableWidgetAds: false,
    disableMobileTopAd: false,
    disableDesktopSideMenuAd: false,
    disablePostAd: false,
    disableFeedDetailsAd: false
  },
  globalQueryParams: {
    isWithinWebView: false
  },
  activecampaignToken: 'fcf9f491453e4bc27a02441be4c5b6d4376f1fdba346f2aba7ad7cef03e9e83dc7f55cf5',
  apis: {
    api: 'https://king-kong-prod.gotchosen.com/api/v1.5',
    scraperApi: 'https://10ip94x8sh.execute-api.us-east-1.amazonaws.com/prod',
    impressionsApi: 'https://influencers-api.gotchosen.com/api',
    impressionsApi2: 'https://events-centralizer-prod.gotchosen.com/v1',
    eventsApi: 'https://events-centralizer-prod.gotchosen.com/v1',
    cdn: 'https://cdn.gotchosen.com',
    mipWebApi: 'https://6s2yh7fs7d.execute-api.us-east-1.amazonaws.com/prod',
    searchApi: 'https://king-kong-prod.gotchosen.com/api',
    backOfficeApi: 'https://king-kong-prod.gotchosen.com/backoffice/api',
    collabApi: 'https://analytics-api-kong-gateway.prod.gotchosen.net/collab',
    websocket: 'wss://prod-influencer-notifications.gotchosen.com/api'
  }
};

export const environment: EnvironmentInterface = settings;
