import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	ViewChild
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { DownloadAppSeeMoreComponent } from '@libs/gc-common/lib/features/download-app/download-app-see-more.component';
import { FeedPostSectionInterface } from '@libs/gc-common/lib/features/feed-posts/feed-post-section.interface';
import { FeedPostsService } from '@libs/gc-common/lib/features/feed-posts/feed-posts.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ResizeObserver } from 'resize-observer';
import { SwiperComponent } from 'swiper/angular';

@Component({
	selector: 'mip-feed-posts-mobile',
	templateUrl: './feed-posts-mobile.component.html',
	styleUrls: ['./feed-posts-mobile.component.scss']
})
export class FeedPostsMobileComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

	@Input() sections: Array<FeedPostSectionInterface> = [];

	@ViewChild('swiper', { static: true }) swiper: SwiperComponent;
	@ViewChild('feedPosts') feedPostsEl: ElementRef;

	@Input() postCount = 0;
	@Input() reachedBottomCountLimit = 3;
	@Input() isModal = false;
	@Input() loading = false;

	@Output() onReachedTop = new EventEmitter<PostModel>();
	@Output() onReachedBottom = new EventEmitter<PostModel>();
	@Output() onSlideChange = new EventEmitter<object>();

	@Output() onPostHeaderToggle = new EventEmitter();
	@Output() onToggleComments = new EventEmitter();
	@Output() onToggleShare = new EventEmitter();

	@Output() onPostAdFill = new EventEmitter();
	@Output() onPostAdHide = new EventEmitter();
	@Output() onPostAdPlayed = new EventEmitter();
	@Output() onPostAdEnded = new EventEmitter();

	@Input() postsLimitAuthenticated = 60;
	@Input() postsLimitAnonymous = 40;
	@Input() disableListLimit = false;

	loggedInUser: UserModel = null;

	postsListLimit = 30;

	hasDestroyed = false;

	lastPostsCount = 0;

	isMobile = false;
	isDesktop = false;

	swiperRef = null;

	firstNativeAdOffset = environment.environmentName === 'production' ? utilsFactory.randomBetweenMinMax(1, 2) : 0;
	postsAttachedAllowed = 2;
	isFirstLoad = true;

	index = 0;
	lastIndex = 0;
	initialUrlPostId = null;
	isRedirectingTo404 = false;
	idsPostTypeVideos = {};
	allowSlideScroll = true;

	hasCalledReachedBottom = false;

	config = null;
	hasMorePosts = false;

	onRouteChangeSubscriber = null;

	containerResizeObserver: ResizeObserver;

	// onSlideChangeTimeout = null;

	constructor(
		private bottomSheet: MatBottomSheet,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private routerService: RouterService,
		private mobileDetectService: MobileDetectService,
		private feedPostsService: FeedPostsService,
		private userService: UserService,
		@Optional() @Inject(REQUEST) private request: any
	) {

		this.feedPostsService.registerComponentScope(this);
		
		this.isMobile = this.mobileDetectService.isMobile();
		console.log('feed-posts-mobile.component->constructor(): this.isMobile', this.isMobile);

		this.isDesktop = !this.mobileDetectService.isMobile();
		console.log('feed-posts-mobile.component->constructor(): this.isDesktop', this.isDesktop);

		const config = {
			direction: 'vertical',
			loop: false,
			navigation: false,
			slidesPerView: 'auto',
			pagination: false,
			autoHeight: this.isDesktop,
			keyboard: true,
			userAgent: null,
			url: null,
			mousewheel: false,
			speed: 500,
			allowTouchMove: this.isMobile,
			on: {
				touchEnd: this.swiperTouchEnd.bind(this),
				touchStart: this.swiperTouchStart.bind(this)
			}
		};

		if (this.request) {
			config.userAgent = this.request.get('User-Agent');
		}

		this.config = config;

	}

	async ngOnInit() {
		console.log('feed-posts-mobile.component->ngOnInit(): this.routerService.getRouteObject', await this.routerService.getRouteObject());

		this.makePageFixed();

		this.loggedInUser = await this.userService.getLoginUser();

		if (this.disableListLimit) {
			this.postsListLimit = 1000000;
		}
		else if (this.loggedInUser) {
			if (this.loggedInUser.isModerator) {
				this.postsListLimit = 5000;
			}
			else {
				this.postsListLimit = this.postsLimitAuthenticated;
			}
		}
		else {
			this.postsListLimit = this.postsLimitAnonymous;
		}

	}

	ngAfterViewInit() {
		try {
			console.log('feed-posts-mobile.component->ngAfterViewInit(): this.swiper', this.swiper);
			this.swiperRef = this.swiper['swiperRef'];
			console.log('feed-posts-mobile.component->ngAfterViewInit(): this.swiperRef', this.swiperRef);

			const { nativeElement } = this.swiper['elementRef'];
			console.log('feed-posts-mobile.component->ngAfterViewInit(): nativeElement', nativeElement);

			const sectionContainer = nativeElement.querySelector('.swiper-wrapper');
			console.log('feed-posts-mobile.component->ngAfterViewInit(): sectionContainer', sectionContainer);

			this.config.height = sectionContainer.clientHeight;
			console.log('feed-posts-mobile.component->ngAfterViewInit(): this.config.height', this.config.height);

			if (utilsFactory.isBrowser) {

				this.containerResizeObserver = new ResizeObserver(() => {
					this.config.height = nativeElement.clientHeight;
				});

				this.containerResizeObserver.observe(this.feedPostsEl.nativeElement);

				/*const swiperNavigationEl = this.swiperNavigation.nativeElement;
				 const buttonPrevEl = swiperNavigationEl.querySelector('.__swiper-button-prev');
				 const buttonNextEl = swiperNavigationEl.querySelector('.__swiper-button-next');
				 
				 this.config.navigation = {
				 prevEl: buttonPrevEl,
				 nextEl: buttonNextEl,
				 disabledClass: '--disabled'
				 };*/

				window.addEventListener('resize', () => {
					if (this.hasDestroyed) {
						return false;
					}

					this.makePageFixed();
					this.changeSwiperHeight();
				});

				document.addEventListener('smartbanner.view', () => {
					if (this.hasDestroyed) {
						return false;
					}
					console.log('feed-posts-mobile.component->ngAfterViewInit(): SMARTBANNER.VIEW');

					this.makePageFixed();
					this.changeSwiperHeight();

				});

				document.addEventListener('smartbanner.exit', () => {
					if (this.hasDestroyed) {
						return false;
					}
					console.log('feed-posts-mobile.component->ngAfterViewInit(): SMARTBANNER.EXIT');

					this.makePageFixed();
					this.changeSwiperHeight();

				});

			}
		}
		catch (e) {
			console.error('feed-posts-mobile.component->ngAfterViewInit(): ERROR', e);
		}
	}

	ngOnDestroy() {

		this.makePageUnfixed();

		if (this.onRouteChangeSubscriber) {
			this.onRouteChangeSubscriber.unsubscribe();
		}

		if (this.containerResizeObserver) {
			this.containerResizeObserver.disconnect();
		}

		this.hasDestroyed = true;

	}

	/**
	 * Angular Lifecycle hooks: OnChanges
	 */
	async ngOnChanges() {
		
		if (this.isFirstLoad) {
			this.isFirstLoad = false;

			const params = await this.routerService.getRouteParam();
			console.log('feed-posts-mobile.component->ngOnChanges(): params', params);

			const postId = params.postId;
			console.log('feed-posts-mobile.component->ngOnChanges(): postId', postId);

			// tslint:disable-next-line:radix
			this.initialUrlPostId = parseInt(postId, 10);
			console.log('feed-posts-mobile.component->ngOnChanges(): this.initialUrlPostId', this.isFirstLoad, this.initialUrlPostId);

			console.log('feed-posts-mobile.component->ngOnChanges(): this.sections', this.sections);

			if (this.initialUrlPostId) {

				const routeSection = this.getSectionByPostId(this.initialUrlPostId);
				console.log('feed-posts-mobile.component->ngOnChanges() this.initialUrlPostId, routeSection', this.initialUrlPostId, routeSection);

				if (routeSection) {
					this.index = routeSection.index;
					this.lastIndex = this.index;

					console.log('feed-posts-mobile.component->ngOnChanges() this.index', this.index);
				}
				else {
					console.log('feed-posts-mobile.component->ngOnChanges() routeSection', routeSection);
					// this.isRedirectingTo404 = true;
					// this.router.navigate(['/trending']);
				}

			}

		}
		
		if (this.sections.length > this.lastPostsCount) {
			this.hasCalledReachedBottom = false;
		}

		this.slideChange();

		this.lastPostsCount = this.sections.length;

	}

	makePageUnfixed() {
		if (utilsFactory.isBrowser) {
			console.log('feed-posts-mobile.component->makePageUnfixed()');

			const mipPages: HTMLDivElement = document.querySelector('mip-pages');

			if (mipPages) {
				mipPages.style.position = 'relative';
				mipPages.style['width'] = `100%`;
				mipPages.style['height'] = `auto`;
			}
		}
	}

	makePageFixed() {
		if (utilsFactory.isBrowser) {
			console.log('feed-posts-mobile.component->makePageFixed()');

			const mipPages: HTMLDivElement = document.querySelector('mip-pages');

			if (mipPages) {

				this.makePageUnfixed();

				const pageWidth = mipPages.offsetWidth;
				console.log('feed-posts-mobile.component->makePageFixed(): pageWidth', pageWidth);

				const pageHeight = mipPages.offsetHeight;
				console.log('feed-posts-mobile.component->makePageFixed(): pageHeight', pageHeight);

				mipPages.style['width'] = `${pageWidth}px`;
				mipPages.style['height'] = `${pageHeight}px`;

				mipPages.style.position = 'fixed';

			}
		}
	}

	changeSwiperHeight() {
		try {
			const swiper = this.swiperRef.swiper();
			console.log('feed-posts-mobile.component->changeSwiperHeight(): swiper', swiper);

			swiper.update();

			// workaround to force swiper to change the height of .swiper-wrapper container
			swiper.$wrapperEl[0].style.height = swiper.height + 'px';
			swiper.update();
		}
		catch (error) {
			throw error;
		}
	}

	detachSections(section, recursiveIdentifier) {
		console.log('feed-posts-mobile.component->detachSections(): section', section, recursiveIdentifier);

		let recursiveSection = section;
		let countRecursiveSection = 0;

		// selecting the last section allowed to be attached to the viewport
		while (recursiveSection && countRecursiveSection < this.postsAttachedAllowed) {
			console.log('feed-posts-mobile.component->detachSections(): countRecursiveSection', recursiveSection.id, countRecursiveSection, recursiveSection);
			recursiveSection = recursiveSection[recursiveIdentifier];
			countRecursiveSection++;
		}

		console.log('feed-posts-mobile.component->detachSections(): recursiveSection', recursiveSection);

		if (recursiveSection) {

			console.log('feed-posts-mobile.component->detachSections(): recursiveSection', recursiveSection.id, countRecursiveSection, recursiveSection);

			let recursiveRemoveSection = recursiveSection;
			let countRemoveRecursiveSection = 0;

			// removing the viewport over border (we are removing 2 sections just to make
			// sure no over border items are left attached)
			while (recursiveRemoveSection && countRemoveRecursiveSection < 2) {
				console.log('feed-posts-mobile.component->detachSections(): countRemoveRecursiveSection', recursiveRemoveSection.id, countRemoveRecursiveSection, recursiveRemoveSection);

				// in case of advertisement, we wait 10 seconds before removing it
				if (recursiveRemoveSection.type === 'advertisement') {

					// tslint:disable-next-line:no-shadowed-variable
					(recursiveSection => {
						recursiveSection.removeTimeout = setTimeout(() => {
							console.log('feed-posts-mobile.component->detachSections(): recursiveSection.id REMOVING', recursiveSection);
							console.log('feed-posts-mobile.component->detachSections(): recursiveSection.id REMOVING', document.querySelector(`#${recursiveSection.id}`));
							recursiveSection.isAttached = false;

							// setting it as NOT into viewport just in case some section was left behind
						}, 10000);
					})(recursiveRemoveSection);

				}

				// in case of post, we remove it immediately
				else {
					recursiveRemoveSection.isAttached = false;

					// setting it as NOT into viewport just in case some section was left behind
				}

				recursiveRemoveSection = recursiveRemoveSection[recursiveIdentifier];
				countRemoveRecursiveSection++;
			}

		}
	}

	slideChange() {
		console.log('feed-posts-mobile.component->slideChange()');

		/*if (this.onSlideChangeTimeout) {
		 clearTimeout(this.onSlideChangeTimeout);
		 }*/

		this.index = this.swiperRef['activeIndex'];
		console.log('feed-posts-mobile.component->slideChange(): direction', this.index);

		const direction = (this.index < this.lastIndex) ? 'up' : 'down';
		console.log('feed-posts-mobile.component->slideChange(): direction', this.index, direction);

		console.log('feed-posts-mobile.component->slideChange(): direction', direction, this.index, this.lastIndex, this.index < this.lastIndex);

		this.lastIndex = this.index;
		console.log('feed-posts-mobile.component->slideChange(): direction', this.index, direction);

		setTimeout(() => {
			const section = this.getSectionByIndex(this.index);
			console.log('feed-posts-mobile.component->slideChange(): section', section);

			const prevSection = section.prevSection;
			console.log('feed-posts-mobile.component->slideChange(): prevSection', prevSection);

			const nextSection = section.nextSection;
			console.log('feed-posts-mobile.component->slideChange(): nextSection', nextSection);

			if (section.type === 'post') {
				console.log('feed-posts-mobile.component->slideChange(): section.type', section.type);
				console.log('feed-posts-mobile.component->slideChange(): this.initialUrlPostId', this.initialUrlPostId, section.model.id);

				if (!this.initialUrlPostId) {
					console.log('feed-posts-mobile.component->slideChange(): IF');

					this.routerService.navigateTo([section.model.id], {
						relativeTo: this.activatedRoute
					});
				}
				else if (this.initialUrlPostId !== section.model.id) {
					console.log('feed-posts-mobile.component->slideChange(): ELSE IF');
					this.routerService.navigateTo([section.model.id], {
						relativeTo: this.activatedRoute
					});
				}
			}

			this.initialUrlPostId = section.model.id;

			section.isAttached = true;
			section.isIntoViewport = true;
			section.isIntoTopBounceLimit = true;

			console.log('feed-posts-mobile.component->slideChange(): section', section);
			console.log('feed-posts-mobile.component->slideChange(): direction', direction, this.index, this.lastIndex);

			if (section.removeTimeout) {
				clearTimeout(section.removeTimeout);
			}

			if (prevSection) {
				prevSection.isAttached = true;
				prevSection.isIntoViewport = false;
				prevSection.isIntoTopBounceLimit = false;
			}

			if (nextSection) {
				nextSection.isAttached = true;
				nextSection.isIntoViewport = false;
				nextSection.isIntoTopBounceLimit = false;
			}

			if (direction === 'up') {

				if (prevSection) {

					// cancel the timeout removal if any. This is necessary in case a
					// AD leaves the viewport and comes back to soon, before 10 seconds
					if (prevSection.removeTimeout) {
						clearTimeout(prevSection.removeTimeout);
					}
				}

				if (nextSection) {
					this.detachSections(nextSection, 'nextSection');
				}

			}
			else {

				if (nextSection) {

					// cancel the timeout removal if any. This is necessary in case a
					// AD leaves the viewport and comes back to soon, before 10 seconds
					if (nextSection.removeTimeout) {
						clearTimeout(nextSection.removeTimeout);
					}
				}

				if (prevSection) {
					this.detachSections(prevSection, 'prevSection');
				}

			}

			this.onSlideChange.emit(section);

			console.log('feed-posts-mobile.component->slideChange(): nextSection', nextSection);

			if (nextSection && this.hasCalledReachedBottom === false) {

				let nextSectionRecursive = nextSection;
				let countNextSections = 0;

				while (nextSectionRecursive) {
					countNextSections += 1;
					nextSectionRecursive = nextSectionRecursive.nextSection;
				}

				console.log('feed-posts-mobile.component->slideChange(): this.sections.length', this.sections.length, this.postsListLimit, this.sections.length < this.postsListLimit);
				console.log('feed-posts-mobile.component->slideChange(): countNextSections', countNextSections, this.reachedBottomCountLimit);

				if (countNextSections <= this.reachedBottomCountLimit) {
					if (this.sections.length < this.postsListLimit) {
						console.log('feed-posts-mobile.component->slideChange(): onReachedBottom()');
						this.hasCalledReachedBottom = true;
						this.onReachedBottom.emit(section.model);
					}
					else if (countNextSections <= 1) {
						this.bottomSheet.open(DownloadAppSeeMoreComponent);
					}
				}

			}

		}, 10);
	}

	reachBeginning() {
		const section = this.getSectionByIndex(this.index);
		const prevSection = section.prevSection;
		console.log('feed-posts-mobile.component->reachBeginning(): prevSection', prevSection);
		if (prevSection) {
			this.onReachedTop.emit(prevSection.model);
		}
	}

	swiperTouchStart() {
		console.log('feed-posts-mobile.component->swiperTouchStart()');
		const section = this.getSectionByIndex(this.index);
		section.isMoving = true;
	}

	swiperTouchEnd() {
		console.log('feed-posts-mobile.component->swiperTouchEnd()');
		const section = this.getSectionByIndex(this.index);
		section.isMoving = false;
	}

	swiperBeforeDestroy() {
		/*const transform = this.swiper['swiperSlides'].nativeElement.style.transform;
		 
		 setTimeout(() => {
		 this.swiper['swiperSlides'].nativeElement.style.transform = transform;
		 }, 10);*/
	}

	/**
	 * Method to return a section model by its ID
	 */
	getSectionById(sectionId: string) {
		console.log('feed-posts-mobile.component->getSectionById()');
		for (const section of this.sections) {
			if (section.id === sectionId) {
				return section;
			}
		}
	}

	/**
	 * Method to return a section model by its ID
	 */
	getSectionByPostId(postId: string) {
		console.log('feed-posts-mobile.component->getSectionByPostId(): postId', postId);
		for (const section of this.sections) {
			console.log('feed-posts-mobile.component->getSectionByPostId(): section.model.id', section.model.id);
			// tslint:disable-next-line:radix
			if (section.model.id === parseInt(postId)) {
				return section;
			}
		}
	}

	/**
	 * Method to return a section model by its ID
	 */
	getSectionByIndex(sectionIndex: number) {
		console.log('feed-posts-mobile.component->getSectionByIndex()');
		return this.sections[sectionIndex];
	}

	_onPostAdFill(type) {
		this.onPostAdFill.emit(type);
	}

	_onPostAdHide(type) {
		this.onPostAdHide.emit(type);
	}

	_onPostHeaderToggle(isOpen) {

		if (this.isMobile) {
			this.config.allowTouchMove = !isOpen;
		}

		this.allowSlideScroll = !isOpen;
		console.log('feed-posts-mobile.component->_onPostHeaderToggle(): this.allowSlideScroll', this.allowSlideScroll);

		this.onPostHeaderToggle.emit(isOpen);

	}

	_onToggleComments() {
		this.onToggleComments.emit();
	}

	_onToggleShare() {
		this.onToggleShare.emit();
	}

	_onPostAdPlayed() {
		this.onPostAdPlayed.emit();
	}

	_onPostAdEnded() {
		this.onPostAdEnded.emit();
	}

}
