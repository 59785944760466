import { environment } from '@libs/gc-common/environments/environment';
import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { CommentModel } from '@libs/gc-common/lib/api/models/comment';
import { MediaModel } from '@libs/gc-common/lib/api/models/media';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';
import Axios from 'axios';

import { PostChallengeModel } from './post-challenge.model';
import {
	PostInterface,
	PostMediaInterface,
	PostStatsInterface
} from './post.interface';

export class PostModel extends BaseModel implements PostInterface {
	
	id = null; // api
	draft = false; // api
	hidden = false; // api
	views = 0; // api
	plays = 0; // api
	theme = null; // api
	updatedAt = null; // api
	createdAt = null; // api
	sample = false; // api
	contest_role = null; // api
	title = ''; // api
	description = ''; // api
	
	allowChallenge = false; // api
	allowComments = false; // api
	allowDownload = false; // api
	allowDuo = false; // api
	
	backlink = null; // api
	backlink_status = null; // api
	
	stats: PostStatsInterface = {
		views: 0, // api
		comments: 0, // api
		shares: 0, // api
		engagement: 0, // api
		reactions: {
			happy: 0, // api
			loved: 0, // api
			laughing: 0, // api
			surprised: 0, // api
			sleeping: 0, // api
			crying: 0, // api
			angry: 0 // api
		}
	};
	
	resume: {
		engagement?: number;
		views?: number;
		interactions?: number;
		views_qualified?: number;
		reach?: number;
	} = {
		engagement: 0,
		views: 0,
		interactions: 0,
		views_qualified: 0,
		reach: 0
	};
	
	totalReaction = 0;
	
	userStats = null;
	
	userActions = {
		delete: false, // api
		edit: false, // api
		share: false, // api
		flag: false // api
	};
	
	// model dependencies
	duetPost: PostModel = null; // api
	author: UserModel = null; // api
	challenge: PostChallengeModel = null; // api
	medias: PostMediaInterface = null; // api
	
	lastSeen = false; // local
	isAnimated = false; // local
	
	hashtags = []; // local
	mentions = []; // local
	duration = 0; // local
	currentTime = 0; // local
	isPlaying = false; // local
	isVideo = false; // local
	mediaType = null; // local
	hasSetAsViewed = false; // local
	setPercentViewed = -1; // local
	
	// profileShareUrl = null; // local setting
	// trendingShareUrl = null; // local setting
	
	constructor(model?: PostInterface) {
		super(model);
		
		this.fill(model);
	}
	
	override beforeFill(model) {
		// console.log('post.model->beforeFill() model', model);
		// console.log('post.model->beforeFill() model.stats', model.stats);
		
		// to ensure we dont overwrite the entire "resume" object, lets merge it...
		model.resume = { ...this.resume, ...model.resume };
		
		if (model.userStats) {
			// console.log('post.model->beforeFill() model.userStats.reaction', model.userStats.reaction);
			
			if (model.userStats.reaction && model.userStats.reaction.id === null) {
				delete model.userStats.reaction;
				// console.log('post.model->beforeFill() model.userStats', model.userStats);
			}
		}
		
		if (model.stats) {
			
			for (let i in model.stats) {
				if (model.stats[i] === null) {
					model.stats[i] = 0;
				}
			}
			
			for (let i in model.stats.reactions) {
				if (model.stats.reactions[i] === null) {
					model.stats.reactions[i] = 0;
				}
			}
			
		}
		
		// this implementation is necessary because we have deleted all cover and preview images from S3
		const isMediaDateBefore2022_06_02 = utilsFactory.hasDateExpired(model.createdAt, '2022-06-02');
		// console.log('post.model->beforeFill() diffDates', model.author.username, model.createdAt, isMediaDateBefore2022_06_02);
		
		if (isMediaDateBefore2022_06_02) {
			
			if (model.medias.cover) {
				model.medias.cover.source = model.author.avatarUrl || 'https://cdn.gotchosen.com/mip/ssr/assets/images/gc-post-default.jpg';
				// console.log('post.model->beforeFill() model.medias.cover.source', model.author.username, model.medias.cover.source);
			}
			
			if (model.medias.preview) {
				model.medias.preview.source = model.author.avatarUrl || 'https://cdn.gotchosen.com/mip/ssr/assets/images/gc-post-default.jpg';
				// console.log('post.model->beforeFill() model.medias.preview.source', model.author.username, model.medias.preview.source);
			}
			
		}
		
	}
	
	/**
	 * Method to be trigger every time AFTER the model is filled with Model.fill()
	 */
	override afterFill(model?: PostInterface) {
		try {
			
			if (model.author) {
				this.author = ModelFactoryService.instance.userFactory.build(model.author);
			}
			
			if (model.duetPost) {
				this.duetPost = ModelFactoryService.instance.postFactory.build(model.duetPost);
			}
			
			// console.log('post.model->afterFill() model.medias', model.medias);
			
			if (model.medias) {
				
				const media: PostMediaInterface = {};
				
				if (model.medias.high_quality) {
					
					model.medias.high_quality.mediaType = 'high_quality';
					
					// console.log('post.model->afterFill() this.mediaType model.medias.high_quality', model.medias.high_quality);
					media.high_quality = ModelFactoryService.instance.mediaFactory.build(model.medias.high_quality);
					
					this.mediaType = media.high_quality.mimeType.split('/')[0];
					// console.log('post.model->afterFill() this.mediaType', this.mediaType);
					
				}
				
				if (model.medias.preview) {
					model.medias.preview.mediaType = 'preview';
					media.preview = ModelFactoryService.instance.mediaFactory.build(model.medias.preview);
				}
				
				if (model.medias.cover) {
					model.medias.cover.mediaType = 'cover';
					media.cover = ModelFactoryService.instance.mediaFactory.build(model.medias.cover);
				}
				
				if (model.medias.audio) {
					model.medias.audio.mediaType = 'audio';
					media.audio = ModelFactoryService.instance.mediaFactory.build(model.medias.audio);
				}
				
				if (model.medias.hls) {
					model.medias.hls.mediaType = 'hls';
					media.hls = ModelFactoryService.instance.mediaFactory.build(model.medias.hls);
				}
				
				if (model.medias.song) {
					model.medias.song.mediaType = 'song';
					media.song = ModelFactoryService.instance.mediaFactory.build(model.medias.song);
				}
				
				this.isVideo = this.mediaType === 'video';
				
				this.medias = media;
				// console.log('post.model->afterFill() this.medias', this.medias);
				
			}
			
			/*if (model.theme === 'animated-photo') {
			 this.theme = 'photo';
			 this.isAnimated = true;
			 }*/
			
			/*if (model.theme.indexOf('article-') > -1) {
			 this.theme = 'article';
			 }*/
			
			// console.log('post.model->beforeFill() model.theme', model.theme, this.theme);
			
			this.totalReaction = 0;
			
			// tslint:disable-next-line:forin
			for (const i in this.stats.reactions) {
				if (i.indexOf('_') === -1) {
					this.totalReaction += this.stats.reactions[i];
				}
			}
			
			// console.log('post.model->afterFill() model.theme', model.theme, this.theme);
			
			if (model.challenge) {
				this.challenge = new PostChallengeModel(model.challenge);
				// console.log('post.model->afterFill() this.challenge', this.theme, this.challenge);
			}
			
			if (model.description) {
				
				model.description += ''; // making sure this field is an string
				// console.log('post.model->afterFill() model.description', model.description);
				
				const hashtags = model.description.match(/(#[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\_\.\+]+)/g);
				const mentions = model.description.match(/(@[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\_\.\+]+)/g);
				
				if (hashtags) {
					this.hashtags = hashtags;
				}
				
				if (mentions) {
					this.mentions = mentions;
				}
				
				// console.log('post.model->afterFill() model.description', model.description, this.mentions, this.hashtags);
				
			}
			
		}
		catch (e) {
			console.error('post.model->afterFill() ERROR', e);
		}
	}
	
	/**
	 * Method to get a Media to media collection
	 */
	getMedia(typeOrTypes: string | Array<string>): MediaModel {
		try {
			
			if (!Array.isArray(typeOrTypes) && typeof typeOrTypes !== 'string') {
				throw new Error(`'typeOrTypes' must be an ARRAY or a STRING`);
			}
			
			if (Array.isArray(typeOrTypes) && typeOrTypes.length === 0) {
				throw new Error(`Since 'typeOrTypes' is an ARRAY, it must contain at least one media type`);
			}
			
			if (typeof typeOrTypes === 'string') {
				if (this.medias[typeOrTypes]) {
					return this.medias[typeOrTypes];
				}
			}
			else if (Array.isArray(typeOrTypes)) {
				for (const type of typeOrTypes) {
					if (this.medias[type]) {
						return this.medias[type];
					}
				}
			}
			
			return null;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	/**
	 * Method to get the video media by priority
	 */
	getVideoMedia() {
		return this.getMedia(['hls', 'high_quality']);
	}
	
	/**
	 * Method to get the media cover source
	 */
	getCover(): string {
		
		const media = this.getMedia('cover');
		// console.log('post.model->getCover(): media', this.id, media);
		
		if (media) {
			return media.source;
		}
		
		throw new Error(`This post has no cover`);
		
	}
	
	/**
	 * Method to get the media preview source
	 */
	getPreview(): string {
		
		let media = this.getMedia('preview');
		// console.log('post.model->getPreview(): media.mimeType', this.id, media.mimeType);
		
		if (!media || media.mimeType.indexOf('jpeg') === -1 && media.mimeType.indexOf('png') === -1) {
			media = this.getMedia('cover');
		}
		
		// console.log('post.model->getPreview(): media', this.id, media.mimeType, media.mediaType);
		
		if (media) {
			return media.source;
		}
		
		throw new Error(`This post has no preview`);
		
	}
	
	/**
	 * Method to get the video media source
	 */
	getVideoSource(dimension = 'medium') {
		
		const media = this.getVideoMedia();
		// console.log('post.model->getSource() media', dimension, media);
		
		if (media) {
			if (dimension && media.mediaType === 'hls') {
				if (media.dimensions && dimension in media.dimensions) {
					return media.dimensions[dimension].source;
				}
				else {
					// throw new Error(`This media HLS has no dimension ${dimension}`);
					return media.source;
				}
			}
			else {
				return media.source;
			}
		}
		
		throw new Error(`This post has no media`);
		
	}
	
	/**
	 * Method to get user logged in user reation to a post
	 */
	getUserReaction() {
		try {
			
			let userReaction = null;
			
			if (this.userStats && this.userStats.reaction && this.userStats.reaction.id) {
				userReaction = this.userStats.reaction || null;
			}
			
			return userReaction;
		}
		catch (e) {
			throw e;
		}
	}
	
	/**
	 * Method to set the post as views by the user
	 */
	async setAsViews(percentage?: number) {
		try {
			// console.log('post.model->setAsViews(): percentage', percentage);
			
			const setPercentViewed = Math.floor(percentage || 0);
			// console.log('post.model->setAsViews(): setPercentViewed', this.setPercentViewed, setPercentViewed);
			
			if (
				this.author &&
				// this.hasSetAsViewed === false &&
				setPercentViewed > this.setPercentViewed &&
				utilsFactory.isBrowser &&
				UserService.instance.isAuthenticated()
			) {
				
				const loggedInUser = await UserService.instance.getLoginUser();
				// console.log('post.model->setAsViews(): loggedInUser', loggedInUser);
				
				this.setPercentViewed = Math.floor(percentage || 0);
				
				const payload = {
					event: 'user.view-content',
					content: {
						user: loggedInUser.id,
						post: this.id,
						author: this.author.id,
						author_post: this.author.id,
						timestamp: Math.round(new Date().getTime() / 1000),
						age: loggedInUser.getAge(),
						percent: Math.floor(percentage || 0),
						is_follow: this.author.isFollowing,
						video_duration: this.duration,
						contentLanguage: loggedInUser.getLanguages('content_language'),
						gender: loggedInUser.getGender()
					}
				};
				// console.log('post.model->setAsViews(): payload', payload);
				
				const response = await Axios.post(`${environment.apis.eventsApi}/event`, [payload]);
				// console.log('post.model->setAsViews(): response', response);
				
				this.hasSetAsViewed = true;
				this.setPercentViewed = setPercentViewed;
				
			}
			
		}
		catch (e) {
			throw e;
		}
	}
	
	/**
	 * Method to save the users reaction
	 */
	async doReaction(reaction) {
		try {
			// console.log('post.model->doReaction(): reaction', reaction);
			
			let response = null;
			const params = {
				reactionType: reaction
			};
			
			const userReaction = this.getUserReaction();
			// console.log('post.model->doReaction(): userReaction', userReaction);
			
			if (userReaction && userReaction.reactionType === reaction) {
				// console.log('post.model->doReaction(): userReaction DELETE', userReaction);
				
				response = await ResourceService.instance.delete('api', {
					resource: 'posts',
					path: `/post/${this.id}/reaction`
				});
				
			}
			else {
				// console.log('post.model->doReaction(): userReaction CREATE', userReaction);
				
				response = await ResourceService.instance.post('api', {
					resource: 'posts',
					path: `/post/${this.id}/reaction`,
					data: params
				});
			}
			
			// console.log('post.model->doReaction(): response', response);
			
			return this.fill(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	/**
	 * Method to return the user posts
	 */
	async getComment(commentId): Promise<CommentModel> {
		// console.log('post.model->getComment(): commentId', commentId);
		
		try {
			
			const commentResponse = await ResourceService.instance.get('api', {
				resource: 'comments',
				path: `/post/${this.id}/comment/${commentId}`
			});
			
			// console.log('post.model->getComment(): commentResponse', commentResponse);
			
			return ModelFactoryService.instance.commentFactory.build(commentResponse);
			
		}
		catch (e) {
			throw e;
		}
		
	}
	
	/**
	 * Method to return the user posts
	 */
	async getComments(params?): Promise<{ lastId: number, totalCount: number, list: Array<CommentModel> }> {
		// console.log('post.model->getComments(): params', params);
		
		try {
			
			const commentsResponse = await ResourceService.instance.get('api', {
				resource: 'comments',
				path: `/post/${this.id}/comment`,
				params
			});
			
			if (params.order === 'asc') {
				commentsResponse.items.reverse();
			}
			
			// console.log('post.model->getComments(): commentsResponse', commentsResponse);
			
			this.stats.comments = commentsResponse.totalCount;
			this.fill(this);
			
			return {
				lastId: commentsResponse.lastId,
				totalCount: commentsResponse.totalCount,
				list: ModelFactoryService.instance.commentFactory.buildCollection(commentsResponse.items)
			};
			
		}
		catch (e) {
			throw e;
		}
		
	}
	
	/**
	 * Method to return the user posts
	 */
	async addComment(data): Promise<CommentModel> {
		// console.log('post.model->saveComment(): data', data);
		
		try {
			
			if (!data) {
				throw new Error(`Data must bu provided.`);
			}
			
			if (!data.message) {
				throw new Error(`Message must be provided.`);
			}
			
			const commentData = await ResourceService.instance.post('api', {
				resource: 'comments',
				path: `/post/${this.id}/comment`,
				data
			});
			
			const commentModel = ModelFactoryService.instance.commentFactory.build(commentData);
			// console.log('post.model->saveComment(): commentModel', commentModel);
			
			this.stats.comments += 1;
			this.fill(this);
			
			CacheService.instance.clearCache('posts');
			
			return commentModel;
			
		}
		catch (e) {
			throw e;
		}
		
	}
	
	/**
	 * Method to return the user posts
	 */
	async deleteComment(commentId): Promise<any> {
		// console.log('post.model->deleteComment(): commentId', commentId);
		
		try {
			
			const response = await ResourceService.instance.delete('api', {
				resource: 'comments',
				path: `/post/${this.id}/comment/${commentId}`
			});
			// console.log('post.model->deleteComment(): response', response);
			
			ModelFactoryService.instance.commentFactory.removeModel(commentId);
			
			this.stats.comments -= 1;
			this.fill(this);
			
			CacheService.instance.clearCache('posts');
			
			return response;
			
		}
		catch (e) {
			throw e;
		}
		
	}
	
}
