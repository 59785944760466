import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit, OnDestroy {

  @Input() isLoading = false;
  @Input() buttonSize = '';
  @Input() text = '';
  @Input() icon = '';
  @Input() marginBottom = 0;

  @Output() onclick = new EventEmitter();

  isDesktop = false;
  isMobile = false;

  isContentActive = false;

  scrollInterval = null;

  positionRelative = false;

  constructor(
    private mobileDetect: MobileDetectService,
    private bottomSheet: MatBottomSheet,
    private hostEl: ElementRef
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  async ngOnInit() {
    if (utilsFactory.isBrowser) {
      await utilsFactory.waitToBeTrue('floating-button.component', () => !!this.hostEl.nativeElement.querySelector('.floating-button'));

      const nativeElement = this.hostEl.nativeElement;

      this.scrollInterval = setInterval(() => {

        const boundingClientRect = this.hostEl.nativeElement.getBoundingClientRect();
        const { top, bottom, x } = boundingClientRect;

        if (((bottom - window.innerHeight) + 36) <= 0) {
          this.positionRelative = true;
          utilsFactory.addClass(nativeElement, '--position-relative');
        }
        else if (this.positionRelative) {
          this.positionRelative = false;
          utilsFactory.removeClass(nativeElement, '--position-relative');
        }

      }, 100);
    }
  }

  ngOnDestroy() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }

  onClick() {
    console.log(1111);

    this.onclick.emit();
  }
}
