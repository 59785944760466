<div
  class="ad-slot-container"
  [class.--ads-disabled]="disableAd"
  [class.--collapse-empty-divs]="collapseEmptyDivs"
>

  <div
    class="__advertisement-label"
    *ngIf="showAdvertisementLabel"
  >
    Advertisement
  </div>

  <div
    class="__advertisement-container"
    [class.--has-filled-once]="hasFilledOnce"
  >

    <!-- SLOT 1 -->
    <div
      class="__slot-A"
      [id]="slotA.id"
      [class.has-filled]="slotA.hasFilled"
    ></div>

    <!-- SLOT 2 -->
    <div
      class="__slot-B"
      [id]="slotB.id"
      [class.has-filled]="slotB.hasFilled"
    ></div>

    <div class="__ads-disabled-label" *ngIf="disableAd">ADS DISABLED (300x250)</div>

  </div>

</div>
