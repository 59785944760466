import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { MipAlertModule } from '@libs/gc-common/lib/components/mip-alert/mip-alert.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { MipShapeModule } from '@libs/gc-common/lib/components/mip-shape/mip-shape.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { OnReachingBottomModule } from '@libs/gc-common/lib/directives/on-reaching-bottom/on-reaching-bottom.module';
import { ScrollFixModule } from '@libs/gc-common/lib/directives/scroll-fix/scroll-fix.module';
import { ScrollIntoViewModule } from '@libs/gc-common/lib/directives/scroll-into-view/scroll-into-view.module';
import { ScrollTopModule } from '@libs/gc-common/lib/directives/scroll-top/scroll-top.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app/download-app.module';
import { FeedItemModule } from '@libs/gc-common/lib/features/feed-item/feed-item.module';
import { FloatingButtonModule } from '@libs/gc-common/lib/features/floating-button/floating-button.module';
import { JoinHashtagButtonModule } from '@libs/gc-common/lib/features/join-hashtag-button/join-hashtag-button.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';

import { FeedGridComponent } from './feed-grid.component';

@NgModule({
  declarations: [FeedGridComponent],
  exports: [
    FeedGridComponent
  ],
  imports: [
    CommonModule,
    MipShapeModule,
    RouterModule,
    MatGridListModule,
    MipAlertModule,
    MipIconModule,
    MipLoadingModule,
    OnReachingBottomModule,
    GoBackModule,
    MillifyModule,
    FeedItemModule,
    MipTranslateModule,
    ScrollIntoViewModule,
    MatButtonModule,
    ColorModule,
    ScrollFixModule,
    ScrollTopModule,
    DownloadAppModule,
    JoinHashtagButtonModule,
    FloatingButtonModule
  ]
})
export class FeedGridModule {
}
