import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { BottomSheetModule } from '@libs/gc-common/lib/directives/bottom-sheet/bottom-sheet.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { ProfileOptionsComponent } from './profile-options.component';

@NgModule({
  declarations: [ProfileOptionsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    BottomSheetModule,
    MipIconModule,
    MatMenuModule,
    RouterModule,
    MatIconModule,
    ColorModule
  ],
  exports: [ProfileOptionsComponent]
})
export class ProfileOptionsModule {
}
