<div
  *ngIf="user"
  #headerElement
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
  [class.--is-opening-text]="isOpeningText"
  [class.--open-text]="openText"
  [class]="'--theme-' + textColorTheme"
  [style.backgroundColor]="openText ? bgColor : ''"
  [style.boxShadow]="isMobile && openText ? '0 0 100px 120px ' + bgColor : ''"
  [style.color]="textColor"
  [style.height]="isOpeningText || openText ? (headerElHeight ? headerElHeight + 'px' : 'auto') : 'auto'"
  [style.max-height]="headerElHeight ? headerElHeight + 'px' : 'none'"
  [style.padding-bottom]="headerElPadding + 'px'"
  [style.padding-top]="headerElPadding + 'px'"
  class="header"
>

  <a
    *ngIf="showAvatar"
    [mipGoBackReferrer]="true"
    [routerLink]="'/@' + user.username"
  >
    <mip-avatar
      [roundProgressCurrent]="roundProgressCurrent"
      [roundProgressMax]="roundProgressMax"
      [roundProgressRadius]="roundProgressRadius"
      [roundProgressStroke]="roundProgressStroke"
      [roundProgress]="showAvatarRoundProgress"
      [size]="avatarSize"
      [userModel]="user"
      class="__header-avatar"
    ></mip-avatar>
  </a>

  <div class="__header-info">

    <div
      #slugNameElement
      class="__info-slug-name"
    >
      <a
        [mipGoBackReferrer]="true"
        [routerLink]="'/@' + user.username"
        [style.backgroundColor]="usernameBgColor"
        [style.border]="usernameBdColor ? '1px solid ' + usernameBdColor : ''"
        [style.color]="usernameTextColor || textColor"
      >

        <mip-avatar
          *ngIf="showUsernameAvatar"
          [roundProgressCurrent]="roundProgressCurrent"
          [roundProgressMax]="roundProgressMax"
          [roundProgressRadius]="roundProgressRadius"
          [roundProgressStroke]="roundProgressStroke"
          [roundProgress]="showAvatarRoundProgress"
          [size]="avatarSize"
          [userModel]="user"
        ></mip-avatar>

        <mip-profile-slug-name
          [profileSlugName]="user.username"
          [user]="user"
        ></mip-profile-slug-name>
      </a>

      <!--<div class="__header-timestamp" *ngIf="loggedInUser && loggedInUser.isModerator">
        <small style="white-space: nowrap;">{{timestamp | amTimeAgo}}</small>
      </div>-->

      <mip-profile-following-button
        *ngIf="showFollowBtn"
        [bgColor]="null"
        [profile]="user"
        borderFollowingColor="transparent"
        buttonSize="sm"
        textColor="primary"
      ></mip-profile-following-button>

      <div class="__header-actions">
        <ng-content select="[header-actions]"></ng-content>
      </div>

    </div>

    <div
      #textContainerElement
      [class.--is-desktop]="isDesktop"
      [class.--no-text-shadow]="!textShadow"
      [style.backgroundColor]="isDesktop && (isOpeningText || openText) ? bgColor : ''"
      class="__text-container"
    >

      <!-- [style.height]="innerHeight + 'px'" -->
      <div
        #innerElement
        class="__inner"
      >

        <div
          #wrapTextElement
          class="__wrap-text"
        >

          <!-- TITLE -->
          <div
            #titleElement
            [innerHTML]="titleText"
            [mipLinkify]="{linkifyUrls: linkifyUrls, baseUrl: {hashtag: '/hashtag'}}"
            class="__text-title"
          ></div>

          <!-- BODY -->
          <div
            #bodyElement
            class="__text-body"
          >

            <div
              *ngIf="openTextBtn"
              [innerHTML]="bodyText"
              [linkifyText]="bodyText"
              [mipLinkify]="{linkifyUrls: linkifyUrls, baseUrl: {hashtag: '/hashtag'}}"
              class="__body-text"
            ></div>
            <div
              *ngIf="!openTextBtn"
              [innerHTML]="bodyTextNowrap"
              [linkifyText]="bodyTextNowrap"
              [mipLinkify]="{linkifyUrls: linkifyUrls, baseUrl: {hashtag: '/hashtag'}}"
              class="__body-text-nowrap"
            ></div>

          </div>

        </div>

      </div>

      <button
        #seeMoreElement
        (click)="toggleSeeMore($event)"
        (clickOutside)="close()"
        [attachOutsideOnClick]="true"
        [class.--is-desktop]="isDesktop"
        [class.--is-mobile]="isMobile"
        [class.--show]="seeMore && enableSeeMore"
        [style.margin-top]="seeMoreElMarginTop + 'px'"
        class="__see-more"
      >
        <span *ngIf="!openTextBtn" mipTranslate="post-header.see-more">
          See more...
        </span>
        <mip-icon *ngIf="openTextBtn" icon="times" size="2x"></mip-icon>
        <span *ngIf="openTextBtn" mipTranslate="post-header.see-less">
          Close
        </span>
        <!--<mip-icon icon="triangle-down" [solid]="true" size="1x"></mip-icon>-->
      </button>

    </div>

  </div>

</div>
