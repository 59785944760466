import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PostViewService } from '@libs/gc-common/lib/features/post-view/post-view.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-static-theme',
  templateUrl: './static-theme.component.html',
  styleUrls: ['./static-theme.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticThemeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() postModel: PostModel = null;
  @Input() isIntoViewport = false;
  @Input() postBasePath = null;

  // mediaModel: MediaModel = null;
  isMobile = true;
  isDesktop = false;

  constructor(
    private postViewService: PostViewService,
    private mobileDetect: MobileDetectService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  /**
   * Method to be triggered within component creation
   */
  ngOnInit() {
  }

  /**
   * Method to be triggered after component view has been created
   */
  ngAfterViewInit() {
  }

  /**
   * Method to be triggered every outside changes
   */
  ngOnChanges() {
    // console.log('photo-theme.component->ngOnChanges()');
    // this.mediaModel = this.postModel.getMedia();
  }

  /**
   * Detaching the video element from the view when the view is destroyed,
   * preventing video to continue playing after the component is gone
   */
  ngOnDestroy() {
  }

}
