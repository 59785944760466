import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MetaTagsService,
  MobileDetectService,
  ObserverHelper,
  RouterService,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnDestroy {
  docHtml = null;
  currentPath = null;
  loadingDoc = false;
  isDesktop = false;
  assetsPath = environment.assetsPath;
  pathsURL = {
    'community-guidelines': {
      url: `${this.assetsPath}/static/{{lang}}/community-guidelines.html`,
      title: 'terms.page.community-guidelines.title',
    },
    'monetization-terms': {
      url: `${this.assetsPath}/static/{{lang}}/monetization-terms.html`,
      // title: 'terms.page.monetization-terms.title'
    },
    privacy: {
      url: `${this.assetsPath}/static/{{lang}}/privacy.html`,
      title: 'terms.page.privacy-policy.title',
    },
    'terms-of-service': {
      url: `${this.assetsPath}/static/{{lang}}/terms-of-service.html`,
      title: 'terms.page.terms-of-service.title',
    },
    'list-countries': {
      url: `${this.assetsPath}/static/{{lang}}/list-countries.html`,
      title: 'terms.page.list-countries.title',
    },
    'about-us': {
      url: `${this.assetsPath}/static/{{lang}}/about-us.html`,
    },
  };

  observerHelper = new ObserverHelper();

  constructor(
    private routerService: RouterService,
    private translateService: TranslateService,
    private metaTagsService: MetaTagsService,
    private http: HttpClient,
    private mobileDetectService: MobileDetectService,
  ) {
    this.isDesktop = !this.mobileDetectService.isMobile();
  }

  async ngOnInit() {
    try {
      this.currentPath = this.routerService.getCurrentPathName().split('/')[1];
      // console.log('terms.component->ngOnInit(): this.currentPath', this.currentPath);

      const docEndpoint = this.pathsURL[this.currentPath];
      // console.log('terms.component->ngOnInit(): docEndpoint', docEndpoint);

      this.observerHelper.addSubscription(
        this.translateService.onLangChange.subscribe(async (event) => {
          // console.log('terms.component->ngOnInit(): onLangChange', event);
          await this.getDoc(docEndpoint, event.lang);
        }),
      );

      await this.getDoc(docEndpoint, this.translateService.currentLang);
    } catch (e) {
      this.loadingDoc = false;
      throw e;
    }
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  getDoc(docEndpoint, lang = 'en') {
    const url = docEndpoint.url.replace(/{{lang}}/, lang);
    // console.log('terms.component->getDoc(): lang', lang, url);

    if (docEndpoint.title) {
      this.translateService.get(docEndpoint.title).subscribe((translation) => {
        // console.log('terms.component->ngOnInit(): translation', translation);
        this.metaTagsService.setMetaTags({
          title: translation,
        });
      });
    }

    this.loadingDoc = true;

    setTimeout(() => {
      this.http
        .get(url, {
          headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8'),
          responseType: 'text',
        })
        .subscribe((content) => {
          this.loadingDoc = false;
          // console.log('terms.component->ngOnInit(): content', content);

          const regex = new RegExp('https://www.gotchosen.com', 'g');
          // console.log('terms.component->ngOnInit(): regex', regex);

          this.docHtml = content.toString().replace(regex, `https:${environment.appBaseHrefUseValue}`);
        });
    }, 500);
  }
}
