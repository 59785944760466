<!-- section BOTTOM ACTION -->
<div
  class="join-hashtag-button"
  [class.--floating-button]="floatingButton"
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
  [class.--hide]="isLoading"
  *ngIf="hashtagModel"
>

  <button
    mat-button
    mipBgColor="primary"
    [class]="buttonSize ? 'mip-button--size-' + buttonSize : ''"
    (click)="onJoinHashtag()"
  >

    <span
      *ngIf="isContentActive"
      mipTranslate="hashtag.action.participate-contest"
    >
      Join the Contest
    </span>
    <span
      *ngIf="!isContentActive"
      mipTranslate="hashtag.action.participate-hashtag"
    >
        Join the Hashtag
    </span>

  </button>

</div>
