import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { ProfileOptionsModule } from '@libs/gc-common/lib/features/profile-options/profile-options.module';
import { SearchFormModule } from '@libs/gc-common/lib/features/search-form/search-form.module';

import { TopMenuBarComponent } from './top-menu-bar.component';

@NgModule({
  declarations: [TopMenuBarComponent],
  exports: [
    TopMenuBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SearchFormModule,
    MatButtonModule,
    MipAvatarModule,
    MipIconModule,
    MipProfileSlugNameModule,
    ProfileOptionsModule,
    GoBackModule,
    MipTranslateModule
  ]
})
export class TopMenuBarModule {
}
