import {
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { I18nService } from '@libs/gc-common/lib/services/i18n/i18n.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';

import { FormConfigInterface } from './contact-form.component';

@Component({
  selector: 'mip-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {

  formsIdByLang = {
    pt: {
      'help': '5HuVErEbZCX',
      'learn-more': 'LwJqEEuAdhe'
    },
    en: {
      'help': 'b5ht4_y8Jjz',
      'learn-more': 'rwU6BSeMMfz'
    }
  };

  forms: {
    [key: string]: FormConfigInterface
  } = {
      'help': {
        formId: null,
        formName: 'I need help',
        mipTranslate: 'features.contact-form.name.i-need-help',
        fields: [
          'name',
          'email',
          'gotChosenProfileLink',
          'country',
          'howCanWeHelp',
          'fileUpload'
        ],
        requiredFields: {
          gotChosenProfileLink: false,
          fileUpload: false
        }
      },
      'learn-more': {
        formId: null,
        formName: 'I would like to learn more',
        mipTranslate: 'features.contact-form.name.learn-more',
        fields: [
          'name',
          'email',
          [
            'dialCode',
            'phoneNumber',
          ],
          'country',
          'website',
          'howCanWeHelp',
        ],
        requiredFields: {
          website: false
        }
      }
    };

  currentForm: FormConfigInterface = null;
  translateServiceOnLangChange = null;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: FormConfigInterface,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private routerService: RouterService,
    private userService: UserService
  ) {

  }

  async ngOnInit() {
    try {

      if (this.userService.isAuthenticated()) {

        const loggedInUser = await this.userService.getLoginUser();
        console.log('contact-page.component->ngOnInit(): loggedInUser', loggedInUser);

        const country = loggedInUser.getSettingByKey('country') ? loggedInUser.getSettingByKey('country').value : null;

        const fieldsInitialValue = {
          name: loggedInUser.getName(),
          email: loggedInUser.email,
          gotChosenProfileLink: loggedInUser.username,
          dialCode: country,
          country: country,
        };
        console.log('contact-page.component->ngOnInit(): fieldsInitialValue', fieldsInitialValue);

        this.forms['help'].fieldsInitialValue = fieldsInitialValue;
        this.forms['learn-more'].fieldsInitialValue = fieldsInitialValue;

      }

      let formName = '';

      if (this.data && this.data.formName) {
        formName = this.data.formName;
      }
      else {

        const { params } = await this.routerService.getRouteObject();

        if (params.formName) {
          formName = params.formName;
        }

      }

      this.translateServiceOnLangChange = this.translateService.onLangChange.subscribe(lang => {
        console.log('contact-page.component->ngOnInit() onLangChange: lang', lang);

        if (formName && this.forms[formName]) {
          this.showFormByName(formName);
        }

      });

      if (formName && this.forms[formName]) {
        this.showFormByName(formName);
      }
      else {
        this.routerService.navigateTo('404');
      }

    }
    catch (e) {
      console.error('contact-page.component->ngOnInit(): ERROR', e);
    }
  }

  ngOnDestroy() {
    if (this.translateServiceOnLangChange) {
      this.translateServiceOnLangChange.unsubscribe();
    }
  }

  showFormByName(formName: string) {

    const currentLang = this.translateService.currentLang || this.i18nService.getCurrentLanguage();
    console.log('contact-page.component->showFormByName(): currentLang', currentLang);

    const currentFormId = this.formsIdByLang[currentLang] || this.formsIdByLang['en'];
    console.log('contact-page.component->showFormByName(): currentFormId', currentFormId);

    this.forms['help'].formId = currentFormId['help'];
    this.forms['learn-more'].formId = currentFormId['learn-more'];
    this.currentForm = this.forms[formName];
    console.log('contact-page.component->showFormByName(): this.currentForm', this.currentForm);

  }

}
