import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MipProgressBarComponent } from './mip-progress-bar.component';

@NgModule({
  declarations: [MipProgressBarComponent],
  exports: [
    MipProgressBarComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MipProgressBarModule {
}
