import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { FormHelper } from '@libs/gc-common/lib/services/form-helper/form-helper.service';
import { I18nService } from '@libs/gc-common/lib/services/i18n/i18n.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

export interface FormConfigInterface {
  formId: string;
  formName: string;
  mipTranslate?: string;
  fields?: (string | string[])[];
  requiredFields?: { [key: string]: boolean };
  fieldsInitialValue?: { [key: string]: any };
}

export interface FormFieldInterface {
  id: string,
  label: string;
  type?: 'text' | 'number' | 'url' | 'tel' | 'email' | 'select' | 'textarea' | 'autocomplete' | 'file';
  allOptions?: Array<{ label: string, value: any, extra?: any }>
  options?: Array<{ label: string, value: any, extra?: any }>
  icon?: string;
  placeholder?: string;
  svgIcon?: string;
  hasLoading?: boolean;
  maxWidth?: string;
  readOnly?: boolean;
  image?: string;
  loading?: boolean;
  value?: any;
  pattern?: RegExp;
  disabled?: boolean;
  formControl: FormControl;
  dependencyControlName?: string;
  dependencyControl?: FormControl;
  onInit?: (field: FormFieldInterface) => void;
  onKeyUp?: (any) => void;
  onChange?: (field: FormFieldInterface) => void;
}

@Component({
  selector: 'mip-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnChanges {

  @ViewChild('autoDropdown') autoDropdown: ElementRef;
  @Input() formConfig: FormConfigInterface = null;

  formData = new FormData();

  emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
  urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
  gotchosenUrlProfile = /https?\:\/\/www\.gotchosen.com\/@([a-zA-Z0-9._\-+]*)\??/;

  storageFieldsByName: { [key: string]: FormFieldInterface } = {
    name: {
      id: 'name',
      label: 'First Name',
      icon: 'person',
      formControl: new FormControl()
    },
    firstName: {
      id: 'firstName',
      label: 'First Name',
      icon: 'person',
      formControl: new FormControl()
    },
    lastName: {
      id: 'lastName',
      label: 'Last Name',
      formControl: new FormControl()
    },
    email: {
      id: 'email',
      label: 'Email',
      type: 'email',
      icon: 'mail',
      pattern: this.emailPattern,
      formControl: new FormControl()
    },
    dialCode: {
      id: 'dialCode',
      label: 'Dial Code',
      icon: 'call',
      type: 'autocomplete',
      maxWidth: '268px',
      pattern: /^\+([0-9]+)$/g,
      options: [],
      formControl: new FormControl(),
      loading: true,
      hasLoading: true,
      onInit: this.getDialCode.bind(this),
      /*onChange: (field) => {
       console.log('contact-form.component->dialCode->onChange(): field.formControl.value', field);

       if (this.viewFields.indexOf(this.storageFieldsByName['country']) > -1) {
       console.log('contact-form.component->dialCode->onChange(): this.storageFieldsByName[country]', this.storageFieldsByName['country']);

       }
       },*/
      onKeyUp: this.filterAutocomplete.bind(this)
    },
    phoneNumber: {
      id: 'phoneNumber',
      label: 'Phone Number',
      type: 'tel',
      formControl: new FormControl(),
      dependencyControlName: 'dialCode'
    },
    country: {
      id: 'country',
      label: 'Country',
      icon: 'pin_drop',
      type: 'autocomplete',
      readOnly: true,
      options: [],
      formControl: new FormControl(),
      loading: true,
      hasLoading: true,
      onInit: this.getCountries.bind(this),
      onKeyUp: this.filterAutocomplete.bind(this),
      onChange: this.onCountryChange.bind(this)
    },
    state: {
      id: 'state',
      label: 'State/Province',
      type: 'select',
      options: [],
      hasLoading: true,
      formControl: new FormControl(),
      // onKeyUp: this.filterAutocomplete.bind(this),
      dependencyControlName: 'country'
    },
    gotChosenProfileLink: {
      id: 'gotChosenProfileLink',
      label: 'GotChosen Profile',
      hasLoading: true,
      pattern: /(^[A-Za-z0-9-_+]+)$/g,
      svgIcon: 'gc-gotchosen',
      formControl: new FormControl(),
      onChange: this.getGotChosenProfile.bind(this)
    },
    instagramProfileLink: {
      id: 'instagramProfileLink',
      label: 'Instagram Profile',
      hasLoading: true,
      pattern: /(^[A-Za-z0-9-_+]+)$/g,
      svgIcon: 'gc-instagram',
      formControl: new FormControl(),
      onChange: this.getInstagramProfile.bind(this)
    },
    numberOfInstagramFollowers: {
      id: 'numberOfInstagramFollowers',
      label: '# Followers',
      // icon: 'groups',
      maxWidth: '200px',
      disabled: true,
      formControl: new FormControl()
    },
    primaryContentCategory: {
      id: 'primaryContentCategory',
      label: 'Primary Content Category',
      icon: 'smart_display',
      type: 'select',
      options: [],
      loading: true,
      hasLoading: true,
      onInit: this.getCategories.bind(this),
      // onKeyUp: this.filterAutocomplete.bind(this),
      formControl: new FormControl()
    },
    howDidYouHearAboutUs: {
      id: 'howDidYouHearAboutUs',
      label: 'How did you hear about us?',
      type: 'textarea',
      icon: 'contact_support',
      formControl: new FormControl()
    },
    agencyName: {
      id: 'agencyName',
      label: 'Agency name',
      icon: 'campaign',
      formControl: new FormControl()
    },
    jobTitle: {
      id: 'jobTitle',
      label: 'Title',
      icon: 'work',
      formControl: new FormControl()
    },
    website: {
      id: 'website',
      label: 'Website',
      pattern: this.urlPattern,
      // type: 'url',
      icon: 'link',
      formControl: new FormControl()
    },
    companyName: {
      id: 'companyName',
      label: 'Company Name',
      icon: 'store',
      formControl: new FormControl()
    },
    industry: {
      id: 'industry',
      label: 'Industry',
      icon: 'category',
      formControl: new FormControl()
    },
    aboutYourProject: {
      id: 'aboutYourProject',
      label: 'Tell us about your project!',
      type: 'textarea',
      icon: 'contact_support',
      formControl: new FormControl()
    },
    fileUpload: {
      id: 'fileUpload',
      label: 'Select files to be attached',
      type: 'file',
      icon: 'cloud_upload',
      onChange: this.onFilesChange.bind(this),
      formControl: new FormControl()
    },
    howCanWeHelp: {
      id: 'howCanWeHelp',
      label: 'Please tell us how can we help you?',
      type: 'textarea',
      icon: 'contact_support',
      formControl: new FormControl()
    },
  };

  viewFields: FormFieldInterface[] = [];

  form = new FormHelper({});
  formConfigData: FormConfigInterface = null;

  formFields: Array<{
    group?: FormFieldInterface[],
    field?: FormFieldInterface
  }> = [];

  lang = 'en';

  allCountriesByCode3 = {};

  sending = false;
  success = false;
  errorTitle = null;
  errorMessage = null;
  resetFiles = false;
  hasFormChanged = false;
  isMobile = false;

  isWithinWebView = false;

  constructor(
    private dialog: MatBottomSheetRef,
    private routerService: RouterService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private mobileDetect: MobileDetectService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: FormConfigInterface
  ) {

    this.isMobile = this.mobileDetect.isMobile();

    this.formConfigData = data;
    console.log('contact-form.component->constructor(): this.formConfig', this.formConfigData);

    this.setFormFields();

  }

  async ngOnInit() {
    try {

      const { queryParams } = await this.routerService.getRouteObject();
      console.log('contact-form.component->ngOnInit(): queryParams', queryParams);

      this.isWithinWebView = queryParams.isWithinWebView === 'true';
      console.log('contact-form.component->ngOnInit(): this.isWithinWebView', this.isWithinWebView);

      this.lang = this.translateService.currentLang || this.i18nService.getCurrentLanguage();
      console.log('contact-form.component->ngOnInit(): this.lang', this.lang);

      this.form.disable();
      await utilsFactory.loadScript(`https://www.google.com/recaptcha/api.js?render=${environment.googleRecaptchaV3Key}&lang=${this.lang}`, () => {
        return window['grecaptcha'] && window['grecaptcha'].ready;
      });
      this.form.enable();

    }
    catch (e) {
      console.error('contact-form.component->ngOnInit(): ERROR', e);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('formConfig' in changes && this.formConfig) {

      this.formConfigData = this.formConfig;
      console.log('contact-form.component->ngOnChanges(): this.formConfigData', this.formConfigData);

      this.setFormFields();

    }
  }

  setFormFields() {
    try {
      for (const i in this.formConfigData.fields) {
        const fieldName = this.formConfigData.fields[i];
        console.log('contact-form.component->setFormFields(): fieldName', fieldName);

        if (Array.isArray(fieldName)) {

          const group = [];

          for (const x in fieldName) {

            const fieldConfig = this.storageFieldsByName[fieldName[x]];
            console.log('contact-form.component->setFormFields(): fieldConfig', fieldConfig);

            this.viewFields.push(fieldConfig);

            if (fieldConfig) {

              if (fieldConfig.onInit) {
                fieldConfig.onInit(fieldConfig);
              }

              if (fieldConfig.dependencyControlName) {
                fieldConfig.dependencyControl = this.storageFieldsByName[fieldConfig.dependencyControlName].formControl;
              }

              if (this.formConfigData.fieldsInitialValue && this.formConfigData.fieldsInitialValue[fieldConfig.id]) {

                const fieldInitialValue = this.formConfigData.fieldsInitialValue[fieldConfig.id];
                console.log('contact-form.component->setFormFields(): fieldInitialValue', fieldInitialValue);

                if (fieldInitialValue) {
                  (async (field, fieldValue) => {
                    console.log('contact-form.component->setFormFields(): field.type', field.id, field.type, ['select', 'autocomplete'].indexOf(field.type));

                    if (['select', 'autocomplete'].indexOf(field.type) > -1) {
                      await utilsFactory.waitToBeTrue('contact-form.component', () => !!(field.allOptions && field.allOptions.length));

                      console.log('contact-form.component->setFormFields(): fieldValue allOptions', field.allOptions);

                      if (field.allOptions && field.allOptions.length) {
                        const currentOption = field.allOptions.filter(({ label, value, extra }) => {
                          console.log('contact-form.component->setFormFields(): { label, value, extra }', { label, value, extra });

                          return !!(
                            label === fieldValue ||
                            value === fieldValue ||
                            extra === fieldValue
                          );
                        });

                        console.log('contact-form.component->setFormFields(): currentOption', currentOption);

                        if (currentOption.length) {
                          fieldValue = currentOption[0];
                        }

                      }

                    }

                    console.log('contact-form.component->setFormFields(): fieldValue', fieldValue);

                    fieldConfig.formControl.setValue(fieldValue);
                    fieldConfig.formControl.markAsTouched();
                    fieldConfig.formControl.markAsDirty();
                    this.form.markAsTouched();
                    this.form.markAsDirty();

                  })(fieldConfig, fieldInitialValue);
                }

              }

              /*if (this.formConfigData.fieldsInitialValue && this.formConfigData.fieldsInitialValue[fieldConfig.id]) {
               fieldConfig.formControl.setValue(this.formConfigData.fieldsInitialValue[fieldConfig.id]);
               fieldConfig.formControl.markAsTouched();
               fieldConfig.formControl.markAsDirty();
               this.form.markAsTouched();
               this.form.markAsDirty();
               }*/

              this.form.addControl(fieldName[x], fieldConfig.formControl);

              group.push(fieldConfig);
            }
          }

          this.formFields.push({
            group
          });

        }
        else {
          const fieldConfig = this.storageFieldsByName[fieldName];
          console.log('contact-form.component->setFormFields(): fieldConfig', fieldConfig);

          this.viewFields.push(fieldConfig);

          if (fieldConfig) {

            if (fieldConfig.onInit) {
              fieldConfig.onInit(fieldConfig);
            }

            if (fieldConfig.dependencyControlName) {
              fieldConfig.dependencyControl = this.storageFieldsByName[fieldConfig.dependencyControlName].formControl;
            }

            if (this.formConfigData.fieldsInitialValue && this.formConfigData.fieldsInitialValue[fieldConfig.id]) {

              const fieldInitialValue = this.formConfigData.fieldsInitialValue[fieldConfig.id];
              console.log('contact-form.component->setFormFields(): fieldInitialValue', fieldInitialValue);

              if (fieldInitialValue) {
                (async (field, fieldValue) => {
                  console.log('contact-form.component->setFormFields(): field.type', field.id, field.type, ['select', 'autocomplete'].indexOf(field.type));

                  if (['select', 'autocomplete'].indexOf(field.type) > -1) {
                    await utilsFactory.waitToBeTrue('contact-form.component', () => !!(field.allOptions && field.allOptions.length));

                    console.log('contact-form.component->setFormFields(): fieldValue allOptions', field.allOptions);

                    if (field.allOptions && field.allOptions.length) {
                      const currentOption = field.allOptions.filter(({ label, value, extra }) => {
                        console.log('contact-form.component->setFormFields(): { label, value, extra }', { label, value, extra });

                        return !!(
                          label === fieldValue ||
                          value === fieldValue ||
                          extra === fieldValue
                        );
                      });

                      console.log('contact-form.component->setFormFields(): currentOption', currentOption);

                      if (currentOption.length) {
                        fieldValue = currentOption[0];
                      }

                    }

                  }

                  console.log('contact-form.component->setFormFields(): fieldValue', fieldValue);

                  fieldConfig.formControl.setValue(fieldValue);
                  fieldConfig.formControl.markAsTouched();
                  fieldConfig.formControl.markAsDirty();
                  this.form.markAsTouched();
                  this.form.markAsDirty();

                })(fieldConfig, fieldInitialValue);
              }

            }

            this.form.addControl(fieldName, fieldConfig.formControl);
            this.formFields.push({
              field: fieldConfig
            });
          }
        }
        console.log('contact-form.component->setFormFields(): fieldName', fieldName);
        console.log('contact-form.component->setFormFields(): ------------------------------------------------------------');

      }

      console.log('contact-form.component->setFormFields(): this.viewFields', this.viewFields);

      console.log('contact-form.component->setFormFields(): this.formFields', this.formFields);
    }
    catch (e) {
      console.error('contact-form.component->setFormFields(): ERROR', e);
    }
  }

  async getCategories(field: FormFieldInterface) {
    try {
      console.log('contact-form.component->getCategories(): field', field);

      field.loading = true;

      const { data } = await axios.get('https://analytics-api-kong-gateway.qa.gotchosen.net/collab/categories');
      console.log('contact-form.component->getCategories(): data', data);

      const categories = data.categories.map(item => {
        return { label: item.name, value: item.id };
      });
      console.log('contact-form.component->getCategories(): categories', categories);

      field.loading = false;
      field.allOptions = categories;
      field.options = categories;
      console.log('contact-form.component->getCategories(): field.options', field.options);

    }
    catch (e) {
      console.error('contact-form.component->getCategories(): ERROR', e);
      throw e;
    }
  }

  async getCountries(field: FormFieldInterface) {
    try {
      console.log('contact-form.component->getCountries(): field', field);

      field.loading = true;

      const { data } = await axios.get('https://cdn.gotchosen.com/mip/ssr/assets/countries/countries.json');
      console.log('contact-form.component->getCountries(): data', this.lang, data[this.lang]);

      for (const country of data[this.lang]) {
        this.allCountriesByCode3[country['code3']] = country;
      }

      const countries = data[this.lang].map(item => {
        return { label: item.name, value: item.code3, extra: item.code2 };
      });
      console.log('contact-form.component->getCountries(): countries', countries);

      countries.sort((a, b) => a.label - b.label);

      field.loading = false;
      field.allOptions = countries;
      field.options = countries;
      console.log('contact-form.component->getCountries(): field.options', field.options);

    }
    catch (e) {
      console.error('contact-form.component->getCountries(): ERROR', e);
      throw e;
    }
  }

  async onCountryChange(field: FormFieldInterface) {
    try {
      console.log('contact-form.component->onCountryChange(): field', field);

      const stateField = this.storageFieldsByName['state'];
      console.log('contact-form.component->onCountryChange(): stateField', stateField);

      if (this.viewFields.indexOf(stateField) === -1) {
        return false;
      }

      const dialCodeField = this.storageFieldsByName['dialCode'];
      console.log('contact-form.component->onCountryChange(): stateField', stateField);

      stateField.loading = true;

      const { value } = field.formControl.value;
      console.log('contact-form.component->onCountryChange(): value', value);

      const { data } = await axios.get(`https://cdn.gotchosen.com/mip/ssr/assets/countries/${value.toLowerCase()}.json`);
      console.log('contact-form.component->onCountryChange(): data', data);

      const states = data.map(item => {
        return { label: item.name, value: item.code };
      });
      console.log('contact-form.component->onCountryChange(): states', states);

      states.sort((a, b) => a.label - b.label);

      stateField.loading = false;
      stateField.allOptions = states;
      stateField.options = states;
      console.log('contact-form.component->onCountryChange(): stateField.options', stateField.options);

      const currentCountry = this.allCountriesByCode3[value];
      console.log('contact-form.component->onCountryChange(): currentCountry', currentCountry);

      const countryDialCode = dialCodeField.allOptions.filter(item => item.extra === currentCountry.code2)[0];
      console.log('contact-form.component->onCountryChange(): countryDialCode', countryDialCode);

      if (countryDialCode) {
        dialCodeField.formControl.setValue(countryDialCode);
      }
      // dialCodeField.formControl.value = dialCodeField.allOptions.filter(item => item.extra === field.)

    }
    catch (e) {
      console.error('contact-form.component->getCountries(): ERROR', e);
      throw e;
    }
  }

  async getDialCode(field: FormFieldInterface) {

    console.log('contact-form.component->getDialCode(): field', field);

    field.loading = true;

    const { data } = await axios.get('https://cdn.gotchosen.com/mip/ssr/assets/countries/dial-code.json');
    console.log('contact-form.component->getDialCode(): data', data);

    const codes = data.map(item => {
      return { label: item.dial_code, value: item.dial_code, extra: item.code };
    });
    console.log('contact-form.component->getDialCode(): codes', codes);

    codes.sort((a, b) => a.label - b.label);

    field.loading = false;
    field.allOptions = codes;
    field.options = codes;
    console.log('contact-form.component->getDialCode(): field.options', field.options);

  }

  async getGotChosenProfile(field: FormFieldInterface) {

    this.form.markAsDirty();
    this.form.markAsTouched();

    let value = field.formControl.value;
    console.log('contact-form.component->getGotChosenProfile(): value', value);

    if (field.formControl.value && field.formControl.value.indexOf('http') > -1) {

      const pattern = field.formControl.value.match(this.gotchosenUrlProfile);
      console.log('contact-form.component->getGotChosenProfile(): pattern', pattern);

      if (!pattern) {
        field.formControl.setErrors({ invalid: true });
        return;
      }

      value = pattern[1];

    }
    else if (field.pattern && field.formControl.value && typeof field.formControl.value !== 'object') {
      console.log('contact-form.component->getGotChosenProfile(): pattern(field)', field);

      const pattern = field.formControl.value.match(field.pattern);
      console.log('contact-form.component->getGotChosenProfile(): pattern', pattern);

      if (!pattern) {
        field.formControl.setErrors({ pattern: true });
        return;
      }

    }

    field.loading = true;

    try {
      const { data } = await axios.get(`https://king-kong-prod.gotchosen.com/api/v1.4/user/${value}`);
      console.log('contact-form.component->getInstagramProfile(): data', data);

      field.image = data.avatarUrl;
      // field.svgIcon = null;

    }
    catch (e) {

      field.image = null;
      // field.svgIcon = 'gc-gotchosen';

      field.formControl.setErrors({ 'not-found': true });
      field.formControl.markAsTouched();
      field.formControl.markAsDirty();
    }

    field.loading = false;

  }

  async getInstagramProfile(field: FormFieldInterface) {

    console.log('contact-form.component->getInstagramProfile(): field', field);

    const followersField = this.storageFieldsByName['numberOfInstagramFollowers'];
    console.log('contact-form.component->getInstagramProfile(): followersField', followersField);

    const value = field.formControl.value;
    console.log('contact-form.component->getInstagramProfile(): value', value);

    if (field.pattern && field.formControl.value && typeof field.formControl.value !== 'object') {
      console.log('contact-form.component->getInstagramProfile(): pattern(field)', field);

      const pattern = field.formControl.value.match(field.pattern);
      console.log('contact-form.component->getInstagramProfile(): pattern', pattern);

      if (!pattern) {
        field.formControl.setErrors({ pattern: true });
        return;
      }

    }

    field.loading = true;

    try {

      const { data } = await axios.get(`https://10ip94x8sh.execute-api.us-east-1.amazonaws.com/prod/private/graph-instagram/user/${value}`);
      console.log('contact-form.component->getInstagramProfile(): data', data);

      field.image = data.profile_picture_url;
      // field.svgIcon = null;

      followersField.disabled = true;
      followersField.type = 'text';
      followersField.formControl.setValue(data.followers_count); // Millify(data.followers_count, {precision: 1}));

    }
    catch (e) {
      console.error('contact-form.component->getInstagramProfile(): ERROR', e);

      field.image = null;
      // field.svgIcon = 'gc-instagram';

      followersField.formControl.reset();
      followersField.formControl.setErrors({ required: true });
      followersField.formControl.markAsTouched();
      followersField.formControl.markAsDirty();
      followersField.disabled = false;
      followersField.type = 'number';
    }

    field.loading = false;

  }

  filterAutocomplete({ field, $event }: { field: FormFieldInterface, $event: any }) {
    console.log('contact-form.component->filterAutocomplete(): field', field);
    console.log('contact-form.component->filterAutocomplete(): $event', $event);

    const controlValue = field.formControl.value;
    console.log('contact-form.component->filterAutocomplete(): controlValue', controlValue);

    const allOptions = field.allOptions;
    console.log('contact-form.component->filterAutocomplete(): allOptions', allOptions);

    if (typeof controlValue === 'string') {
      const options = allOptions.filter(({ label, value, extra }) => {
        console.log('contact-form.component->filterAutocomplete(): { label, value, extra }', controlValue, { label, value, extra });

        return !!(
          (label && (label + '').toLowerCase().includes(controlValue.toLowerCase())) ||
          (value && (value + '').toLowerCase().includes(controlValue.toLowerCase())) ||
          (extra && (extra + '').toLowerCase().includes(controlValue.toLowerCase()))
        );
      });
      console.log('contact-form.component->filterAutocomplete(): options', options);

      field.options = options;

      const { keyCode } = $event;
      console.log('contact-form.component->filterAutocomplete(): keyCode', keyCode, [8, 46].indexOf(keyCode));

      if (options.length === 1 && typeof field.formControl.value !== 'object' && [8, 46].indexOf(keyCode) === -1) {
        field.formControl.setValue(field.options[0]);
      }
    }
  }

  getOptionText(option: { label: string, value: any }) {
    console.log('contact-form.component->getOptionText(): option', option);
    return option ? option.label : '';
  }

  onFilesChange(files) {
    console.log('contact-form.component->onFilesChange(): files', files);
    // this.storageFieldsByName['fileUpload'].formControl.setValue(files);
    for (const i in files) {
      this.formData.append('file-' + i, files[i]);
    }
  }

  autoDropdownOpened() {
    console.log('contact-form.component->autoDropdownOpened(): this.autoDropdown', this.autoDropdown);
  }

  async onSubmit() {
    try {

      this.form.markAsDirty();
      this.form.markAsTouched();

      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].markAsTouched();
      }

      const data = { ...this.form.value };
      console.log('contact-form.component->onSubmit(): data', data);

      let hasErrors = false;

      for (const i in data) {
        const field = this.storageFieldsByName[i];
        console.log('contact-form.component->onSubmit(): field', field.id, field);

        if (field.type === 'autocomplete') {
          console.log('contact-form.component->onSubmit(): field', field);

          const value = field.formControl.value;
          console.log('contact-form.component->onSubmit(): value', value);

          if (typeof value !== 'object') {
            field.formControl.setErrors({ invalid: true });
            hasErrors = true;
          }

        }

        if (field.pattern && field.formControl.value && typeof field.formControl.value !== 'object') {
          console.log('contact-form.component->onSubmit(): pattern(field)', field);

          const pattern = field.formControl.value.match(field.pattern);
          console.log('contact-form.component->onSubmit(): pattern', pattern);

          if (!pattern) {
            console.log('contact-form.component->onSubmit(): pattern ERROR', field);
            field.formControl.setErrors({ pattern: true });
            hasErrors = true;
          }

        }
      }

      console.log('contact-form.component->onSubmit(): this.form.valid', this.form.valid);

      if (hasErrors === false && this.form.valid) {

        if (data.gotChosenProfileLink && data.gotChosenProfileLink.indexOf('http') === -1) {
          data.gotChosenProfileLink = `https://gotchosen.com/@${data.gotChosenProfileLink}`;
        }

        if (data.instagramProfileLink && data.instagramProfileLink.indexOf('http') === -1) {
          data.instagramProfileLink = `https://instagram.com/${data.instagramProfileLink}`;
        }

        const { formId } = this.formConfigData;

        for (const i in data) {
          console.log('contact-form.component->onSubmit(): i', i, data[i]);

          if (data[i] && i !== 'fileUpload' && typeof data[i] === 'object') {
            data[i] = `${data[i].label} (${data[i].value})`;
          }
        }

        this.form.disable();
        this.sending = true;
        this.success = false;
        this.errorMessage = null;
        this.errorTitle = null;

        data['g-recaptcha-response'] = await window['grecaptcha'].execute(environment.googleRecaptchaV3Key, { action: 'contact' });
        console.log('contact-form.component->onSubmit(): data', data);

        for (const i in data) {
          if (i !== 'fileUpload') {
            this.formData.append(i, data[i]);
          }
        }

        console.log('contact-form.component->onSubmit(): this.formData', this.formData);

        // return;

        const response = await axios.post(`https://formcarry.com/s/${formId}`, this.formData);
        console.log('contact-form.component->onSubmit(): response', response);

        this.success = true;
        this.sending = false;
        this.resetFiles = true;

        for (const i in this.storageFieldsByName) {
          this.storageFieldsByName[i].image = null;
        }

        this.form.enable();
        this.form.reset();
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.formData = new FormData();

        setTimeout(() => {
          this.resetFiles = false;
        }, 100);

        setTimeout(() => {
          this.success = false;
        }, 5000);

      }

    }
    catch (e) {

      console.error('contact-form.component->onSubmit(): ERROR', e);
      console.error('contact-form.component->onSubmit(): HAS response?', !!e['response']);

      this.sending = false;
      this.form.enable();

      if (e['response']) {

        const { data } = e['response'];
        this.errorTitle = data.title || null;
        let message = e['message'];

        if (data.message) {
          message = Array.isArray(data.message) ? data.message.join(' ') : data.message;
        }

        this.errorMessage = message;

      }
      else {
        this.errorMessage = e['message'];
      }

    }
  }

  close() {

    if (window['gcApp_onRouteChange']) {
      // window['gcApp_onContactClosed'].postMessage();
      this.routerService.navigateTo('/trending', {
        queryParams: { contact: null }
      });
    }
    else {
      this.routerService.navigateTo('./', {
        queryParams: { contact: null },
        queryParamsHandling: 'merge'
      });
      this.dialog.dismiss();
    }

  }

}
