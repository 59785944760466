<div
  class="post-video"
  [class.--full-size]="fullSize"
  [class.--no-background]="noBackground"
  [style.maxWidth]="canvasMaxWidth"
>

  <!-- LOGS: z-index: 10; -->
  <div
    class="__log"
    [class.--hide]="disableLogs"
  >
    {{mediaModel.mimeType}}
  </div>

  <!-- ADVERTISEMENT: z-index: 7; -->
  <mip-ad-post-video
    #adVideoContainer
    class="__ad-container"
    [class.--has-ad-fill]="hasAdFill"
    *ngIf="videoSource && isAdEnable && hasAdEnded === false"
    [videoSrc]="videoSource"
    [isIntoViewport]="isIntoTopBounceLimit"
    [isPreRoll]="true"
    [class.--push-ad-player]="isAdPlaying"
    (onAdStartPlay)="_onAdStartPlay()"
    (onAdEndedPlay)="_onAdEndedPlay()"
    (onError)="_onAdError($event)"
    (onAdFilled)="_onAdFilled()"
  ></mip-ad-post-video>

  <!--&lt;!&ndash; MUTE BUTTON: z-index: 6; &ndash;&gt;
  <button
    *ngIf="isDesktop && enableVideoControls === false"
    mat-icon-button
    class="__mute-button"
    (click)="toggleMute()"
  >
    <mip-icon
      size="6x"
      [icon]="isVideoMuted ? 'volume-off' : 'volume-on'"
      [solid]="true"
    ></mip-icon>
  </button>-->

  <!-- VIDEO-CONTROLLERS z-index: 6; -->
  <div
    class="__video-controls"
    [class]="'--position-' + videoControlsPosition"
    *ngIf="enableVideoControls"
  >
    <mip-video-controls
      *ngIf="hasVideoLoaded"
      [currentTime]="currentTime"
      [duration]="duration"
      [isPlaying]="isVideoPlaying"
      [isMuted]="isVideoMuted"
      [controlsActions]="videoControlsActions"
      (onTimeChanged)="_onControlsTimeChanged($event)"
      (onPlayChange)="togglePlay()"
      (onMuteChange)="toggleMute()"
    ></mip-video-controls>
  </div>

  <!-- OPEN-DETAILS z-index: 5; -->
  <a
    *ngIf="enableDetailsLink"
    class="__open-details"
    [routerLink]="['.', postModel.id]"
    [queryParams]="{details: true}"
    (click)="unmuteVideo()"
  ></a>

  <!-- PLAY BUTTON: z-index: 4; -->
  <button
    *ngIf="!enableDetailsLink"
    class="__play-button"
    [class.--is-controls-enabled]="enableVideoControls"
    (click)="togglePlay()"
  >
    <mip-icon
      [size]="isVideoLoading ? '8x' : '10x'"
      [icon]="isVideoLoading ? 'refresh' : 'play'"
      [solid]="!isVideoPlaying && !isVideoLoading"
      [spin]="isVideoLoading"
      *ngIf="!isVideoPlaying || isVideoLoading"
    ></mip-icon>
  </button>

  <!-- BOTTOM-SHADOW: z-index: 3; -->
  <div
    class="__bottom-shadow"
    *ngIf="bottomShadow && (forceBottomShadow || enableVideoControls === false)"
    [style.height]="bottomShadowHeight"
  ></div>

  <!-- VIDEO COVER: z-index: 2|1; -->
  <div
    class="__video-cover"
    [class.--is-video-loaded]="hasVideoLoaded"
    [class]="'--object-fit-' + videoObjectFit"
    [style.background-image]="'url(' + videoCover + ')'"
  ></div>

  <!-- VIDEO CONTAINER: z-index: 1|2; -->
  <div
    #videoContainer
    class="__video-container"
    [class.--is-video-loaded]="hasVideoLoaded"
    [class]="'--object-fit-' + videoObjectFit"
  ></div>

</div>

<!-- CANVAS SHAPER: z-index: 0;-->
<img
  class="__canvas"
  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTkwIiBoZWlnaHQ9IjE3NjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3QgZmlsbD0iIzBmZmZmZiIgaGVpZ2h0PSIxNzYwIiB3aWR0aD0iOTkwIiBmaWxsLW9wYWNpdHk9IjAiLz4KPC9zdmc+Cg=="
  [style.width]="canvasMaxWidth"
  [style.height]="canvasHeight"
  [style.maxWidth]="canvasMaxWidth"
  [style.maxHeight]="canvasMaxHeight"
  *ngIf="enableCanvas"
>
