import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { DashboardUserInterface } from './dashboard-user.interface';

export class DashboardUserModel extends BaseModel implements DashboardUserInterface {

  createdAt = null;
  email = null;
  id = null;
  influencerUserId = null;
  name = null;
  roles = null;
  token = null;
  updatedAt = null;

  constructor(model?: DashboardUserInterface) {
    super(model);
    this.fill(model);
  }

}
