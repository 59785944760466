import { Component, OnChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { DownloadAppModalComponent } from './download-app-modal.component';

@Component({
  selector: 'mip-download-app-first-post',
  templateUrl: './download-app-first-post.component.html',
  styleUrls: ['./download-app-first-post.component.scss']
})
export class DownloadAppFirstPostComponent implements OnChanges {

  constructor(
    private bottomSheet: MatBottomSheet
  ) {
  }

  ngOnChanges() {
  }

  openDownloadAppDialog() {
    this.bottomSheet.open(DownloadAppModalComponent);
  }

}
