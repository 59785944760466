import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import MobileDetect from 'mobile-detect';
import {
  Observable,
  Subject
} from 'rxjs';

export class ImaSdkFactory {
  _videoPlayer = null;
  _videoContainer = null;
  
  _isPlaying = null;
  _originalSource = null;
  _currentTime = null;
  _duration = null;
  _isLooping = null;
  
  _googleIma = null;
  _isMobile = false;
  
  _adDisplayContainer = null;
  _vastAdContainer = null;
  _adsLoader = null;
  _adsManager = null;
  
  _hasLoadAdOnce = false;
  _hasAdFilled = null;
  _hasStartedPlayingAd = false;
  _hasRestoredPLayer = false;
  _allowRestorePLayer = true;
  
  _adLoadedObserver = new Subject();
  _adLoadedObservable = this._adLoadedObserver.asObservable();
  
  _adPlayerObserver = new Subject();
  _adPlayerObservable = this._adPlayerObserver.asObservable();
  
  constructor(
    videoPlayer: HTMLVideoElement,
    videoContainer: HTMLDivElement = null,
    allowRestorePLayer = true
  ) {
    // console.log('ima-sdk.factory->constructor(): videoPlayer', videoPlayer);
    
    const isAdsEnabled = environment.disableAds !== true && (
      environment.adsPlacement.disableWidgetAds !== true ||
      environment.adsPlacement.disableMobileTopAd !== true ||
      environment.adsPlacement.disableDesktopSideMenuAd !== true ||
      environment.adsPlacement.disablePostAd !== true);
    
    if (utilsFactory.isBrowser && isAdsEnabled) {
      if (!videoPlayer) {
        throw new Error(`'videoPlayer' must be a HTMLVideoElement typo`);
      }
      
      this._allowRestorePLayer = allowRestorePLayer;
      
      // set the video element and some of its properties
      this._videoPlayer = videoPlayer;
      this._originalSource = this._videoPlayer.src;
      
      // set the video container
      this._videoContainer = videoContainer || this._videoPlayer.parentNode;
      
      // check if is mobile
      const mobileDetect = new MobileDetect(window.navigator.userAgent);
      this._isMobile = !!mobileDetect.mobile();
    }
  }
  
  _init() {
    // console.log('ima-sdk.factory->_init()');
    
    try {
      // console.log('ima-sdk.factory->_init(): window.google.ima', window['google']['ima']);
      
      // creating a local variable for google ima
      this._googleIma = window['google']['ima'];
      
      this._isLooping = this._videoPlayer.loop;
      // console.log('ima-sdk.factory->_init(): this._isLooping', this._isLooping);
      
      this._duration = this._videoPlayer.duration;
      // console.log('ima-sdk.factory->_init(): this._duration', this._duration);
      
      // creating and registering the ad container
      this._adDisplayContainer = this._createAdContainer();
      // console.log('ima-sdk.factory->_init(): this._adDisplayContainer', this._adDisplayContainer);
      
      // starting the ads loader
      this._adsLoader = this._createAdsLoader();
      // console.log('ima-sdk.factory->_init(): this._adsLoader', this._adsLoader);
    }
    catch (e) {
      console.error('ima-sdk.factory->_init(): ERROR', e);
      this.destroy(e);
    }
  }
  
  /**
   * Method to create and register the ad container to google ad display
   */
  _createAdContainer() {
    try {
      // console.log('ima-sdk.factory->_createAdContainer()');
      
      // creating AD container element
      this._vastAdContainer = document.createElement('div');
      this._vastAdContainer.id = `vast-ad-${utilsFactory.generateGUID()}`;
      
      utilsFactory.addClass(this._vastAdContainer, 'vast-ad-container');
      
      if (this._isMobile) {
        utilsFactory.addClass(this._vastAdContainer, '--is-mobile');
      }
      
      // append element into video container element
      this._videoContainer.appendChild(this._vastAdContainer);
      
      // We assume the adContainer is the DOM id of the element that will house
      // the ads.
      return new this._googleIma.AdDisplayContainer(
        this._vastAdContainer,
        this._videoPlayer
      );
    }
    catch (e) {
      this.destroy(e);
    }
  }
  
  /**
   * Method to start the ad loader and deal with ads event callback
   */
  _createAdsLoader() {
    // console.log('ima-sdk.factory->_createAdsLoader()');
    
    try {
      const adsLoader = new this._googleIma.AdsLoader(this._adDisplayContainer);
      // console.log('ima-sdk.factory->_createAdsLoader(): adsLoader', adsLoader);
      
      // ADS_MANAGER_LOADED
      adsLoader.addEventListener(
        this._googleIma.AdError.Type.AD_PLAY,
        this._createAdsLoader_onAdsLoaderAdPlayError.bind(this)
      );
      
      // ADS_MANAGER_LOADED
      adsLoader.addEventListener(
        this._googleIma.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        this._onAdsManagerLoaded.bind(this)
      );
      
      // AD_ERROR
      adsLoader.addEventListener(
        this._googleIma.AdErrorEvent.Type.AD_ERROR,
        this._createAdsLoader_onAdsLoaderAdErrorError.bind(this)
      );
      
      return adsLoader;
    }
    catch (e) {
      this.destroy(e);
    }
  }
  
  _createAdsLoader_onAdsLoaderAdPlayError(adErrorEvent) {
    try {
      // console.log('ima-sdk.factory->_createAdsLoader_onAdsLoaderAdPlayError(): AD_PLAY', adErrorEvent);
      this.destroy(new Error('AD got a error while playing'));
    }
    catch {
    }
  }
  
  _createAdsLoader_onAdsLoaderAdErrorError(adErrorEvent) {
    try {
      console.error(
        'ima-sdk.factory->_createAdsLoader_onAdsLoaderAdErrorError(): AD_ERROR',
        adErrorEvent
      );
      this._hasAdFilled = false;
      this.destroy(new Error('adsLoader got a error'));
    }
    catch {
    }
  }
  
  /**
   * Method to load the AdsManager and deal with its events
   */
  _onAdsManagerLoaded(adsManagerLoadedEvent) {
    // console.log('ima-sdk.factory->_onAdsManagerLoaded(): adsManagerLoadedEvent', adsManagerLoadedEvent);
    try {
      this._hasAdFilled = true;
      this._adLoadedObserver.next('AD_HAS_FILLED');
      
      const adsRenderingSettings = new this._googleIma.AdsRenderingSettings();
      adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
      
      this._adsManager = adsManagerLoadedEvent.getAdsManager(
        this._videoPlayer,
        adsRenderingSettings
      );
      
      window['_adsManager'] = this._adsManager;
      
      // Add listeners to the required events.
      this._adsManager.addEventListener(
        this._googleIma.AdErrorEvent.Type.AD_ERROR,
        this._onAdsManagerLoaded_onAdsManagerAdError.bind(this)
      );
      
      // Listen to any additional events, if necessary.
      this._adsManager.addEventListener(
        this._googleIma.AdEvent.Type.LOADED,
        this._onAdsManagerLoaded_onAdsManagerAdLoaded.bind(this)
      );
      
      this._adsManager.addEventListener(
        this._googleIma.AdEvent.Type.STARTED,
        this._onAdsManagerLoaded_onAdsManagerAdStarted.bind(this)
      );
      
      this._adsManager.addEventListener(
        this._googleIma.AdEvent.Type.ALL_ADS_COMPLETED,
        this._onAdsManagerLoaded_onAdsManagerAllAdsCompleted.bind(this)
      );
    }
    catch (e) {
      this.destroy(e);
    }
  }
  
  _onAdsManagerLoaded_onAdsManagerAdError(event) {
    try {
      console.error(
        'ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdError()',
        event
      );
      this._hasAdFilled = false;
      this.destroy(new Error('AD got a error'));
    }
    catch (e) {
      throw e;
    }
  }
  
  _onAdsManagerLoaded_onAdsManagerAdLoaded(event) {
    try {
      // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded()', event);
      this._hasStartedPlayingAd = true;
      this._adLoadedObserver.next('AD_LOADED');
      this._videoPlayer.loop = false;
      
      let intervalLimiter = 0;
      
      const watchVideoTimeInterval = setInterval(() => {
        try {
          if (this._videoPlayer.src === this._originalSource) {
            // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded() AD_LOADED currentTime', intervalLimiter, this._currentTime, this._videoPlayer.currentTime);
            this._currentTime = this._videoPlayer.currentTime;
            
            if (intervalLimiter > 3000) {
              clearInterval(watchVideoTimeInterval);
            }
          }
          else {
            clearInterval(watchVideoTimeInterval);
          }
          
          intervalLimiter += 100;
        }
        catch (e) {
          clearInterval(watchVideoTimeInterval);
        }
      }, 100);
      
      const onCanplay = () => {
        // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded() AD_LOADED src', this._videoPlayer.src);
        // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded() AD_LOADED currentTime', this._videoPlayer.currentTime);
        
        if (this._videoPlayer.src !== this._originalSource) {
          if (this._videoPlayer.videoWidth >= this._videoPlayer.videoHeight) {
            utilsFactory.addClass(
              this._videoPlayer,
              '--video-object-fit-contain'
            );
          }
          else {
            utilsFactory.addClass(
              this._videoPlayer,
              '--video-object-fit-cover'
            );
          }
        }
        
        // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded(): onCanplay->src', this._videoPlayer.src);
        // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded(): onCanplay->canplay->videoWidth', this._videoPlayer.videoWidth);
        // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdLoaded(): onCanplay->canplay->videoHeight', this._videoPlayer.videoHeight);
        
        this._videoPlayer.removeEventListener('canplay', onCanplay);
      };
      
      this._videoPlayer.addEventListener('canplay', onCanplay);
    }
    catch (e) {
      throw e;
    }
  }
  
  _onAdsManagerLoaded_onAdsManagerAdStarted(event) {
    // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAdStarted()', event);
    try {
      utilsFactory.addClass(this._vastAdContainer, '--show-ad');
      
      this._hasStartedPlayingAd = true;
      this._adPlayerObserver.next('AD_STARTED');
      
      if (!this._isMobile) {
        this._videoPlayer.pause();
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  _onAdsManagerLoaded_onAdsManagerAllAdsCompleted(event) {
    // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAllAdsCompleted()', event);
    try {
      utilsFactory.removeClass(this._vastAdContainer, '--show-ad');
      utilsFactory.removeClass(this._videoPlayer, [
        '--video-object-fit-contain',
        '--video-object-fit-cover',
      ]);
      // console.log('ima-sdk.factory->_onAdsManagerLoaded_onAdsManagerAllAdsCompleted(): ALL_ADS_COMPLETED', event);
      this._adPlayerObserver.next('AD_COMPLETE');
      
      this._hasLoadAdOnce = false;
      this._hasAdFilled = null;
      
      this._restorePLayer();
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to do the ad request
   */
  _createAdRequest(vastUrl): any | void {
    // console.log('ima-sdk.factory->_createAdRequest(): vastUrl', vastUrl);
    
    try {
      const adRequest = new this._googleIma.AdsRequest();
      
      if (environment.enableTestVideoAd) {
        vastUrl =
          'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=';
      }
      
      /*const adRequestUrl = 'https://securepubads.g.doubleclick.net/gampad/ads';
       const adRequestParams = {
       correlator: '',
       description_url: encodeURIComponent(window.location.href),
       env: 'vp',
       gdfp_req: 1,
       iu: '/21669242017/gotchosen.com/PostrollVideoAd',
       output: 'vast',
       sz: '640x480',
       unviewed_position_start: 1,
       url: window.location.href,
       wta: 1
       };
       const adRequestVastUrl = `${adRequestUrl}?${utilsFactory.serializeObject(adRequestParams)}`;
       .log('ima-sdk.factory->_createAdRequest(): adRequestVastUrl', adRequestVastUrl);*/
      
      // defining adTagUrl
      adRequest.adTagUrl = vastUrl; // adRequestVastUrl;
      
      // Specify the linear and nonlinear slot sizes. This helps the SDK to
      // select the correct creative if multiple are returned.
      adRequest.linearAdSlotWidth = 640;
      adRequest.linearAdSlotHeight = 400;
      adRequest.nonLinearAdSlotWidth = 640;
      adRequest.nonLinearAdSlotHeight = 150;
      
      this._adLoadedObserver.next('AD_REQUESTED');
      
      return adRequest;
    }
    catch (e) {
      this.destroy(e);
    }
  }
  
  _restorePLayer() {
    try {
      if (this._hasRestoredPLayer || this._allowRestorePLayer === false) {
        return false;
      }
      
      this._hasRestoredPLayer = true;
      
      // console.log('ima-sdk.factory->_restorePLayer(): this._originalSource', this._originalSource);
      // console.log('ima-sdk.factory->_restorePLayer(): this._currentTime', this._currentTime);
      // console.log('ima-sdk.factory->_restorePLayer(): this._isLooping', this._isLooping);
      // console.log('ima-sdk.factory->_restorePLayer(): this._isPlaying', this._isPlaying);
      
      this._videoPlayer.src = this._originalSource;
      
      const onCanplay = () => {
        // console.log('ima-sdk.factory->onCanplay(): this._currentTime', this._currentTime);
        
        this._videoPlayer.currentTime = this._currentTime;
        this._videoPlayer.loop = this._isLooping;
        
        if (this._isPlaying) {
          this._videoPlayer.play();
        }
        else {
          // this._videoPlayer.load();
        }
        
        // console.log('ima-sdk.factory->_restorePLayer(): onCanplay->src', this._videoPlayer.src);
        // console.log('ima-sdk.factory->_restorePLayer(): onCanplay->canplay->videoWidth', this._videoPlayer.videoWidth);
        // console.log('ima-sdk.factory->_restorePLayer(): onCanplay->canplay->videoHeight', this._videoPlayer.videoHeight);
        
        this._videoPlayer.removeEventListener('canplay', onCanplay);
        this._adPlayerObserver.next('PLAY_RESTORED');
      };
      
      this._videoPlayer.addEventListener('canplay', onCanplay);
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to destroy the ad and its containers
   */
  destroy(error?, restorePLayer = true) {
    // console.log('ima-sdk.factory->destroy()');
    
    try {
      if (utilsFactory.isSSR) {
        return false;
      }
      
      if (this._hasStartedPlayingAd) {
        // Removing the ad container element from DOM
        if (this._videoContainer.contains(this._vastAdContainer)) {
          this._videoContainer.removeChild(this._vastAdContainer);
          // console.log('ima-sdk.factory->destroy(): this._vastAdContainer REMOVED');
        }
        
        // Remove the video orientation class from the video player
        utilsFactory.removeClass(this._videoPlayer, [
          '--video-object-fit-contain',
          '--video-object-fit-cover',
        ]);
        // console.log('ima-sdk.factory->destroy(): video orientation class REMOVED');
        
        if (restorePLayer) {
          this._restorePLayer();
        }
      }
      
      let areAllAdsLoaderEventsRemoved = false;
      let areAllAdsManagerEventsRemoved = false;
      
      // Destroying the AdsManager instance, among with its all event listening
      utilsFactory.waitToBeTrue('ima-sdk.factory->destroy(): this._adsLoader', () => !!(this._adsLoader)).then(() => {
        
        this._adsLoader.removeEventListener(
          this._googleIma.AdError.Type.AD_PLAY,
          this._createAdsLoader_onAdsLoaderAdPlayError.bind(this)
        );
        
        // ADS_MANAGER_LOADED
        this._adsLoader.removeEventListener(
          this._googleIma.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
          this._onAdsManagerLoaded.bind(this)
        );
        
        // AD_ERROR
        this._adsLoader.removeEventListener(
          this._googleIma.AdErrorEvent.Type.AD_ERROR,
          this._createAdsLoader_onAdsLoaderAdErrorError.bind(this)
        );
        
        this._adsLoader.destroy();
        // console.log('ima-sdk.factory->destroy(): this._adsLoader DESTROYED');
        
        areAllAdsLoaderEventsRemoved = true;
      });
      
      // Destroying the AdsManager instance, among with its all event listening
      utilsFactory.waitToBeTrue('ima-sdk.factory->destroy(): this._adsManager', () => !!(this._adsManager)).then(() => {
        
        this._adsManager.removeEventListener(
          this._googleIma.AdErrorEvent.Type.AD_ERROR,
          this._onAdsManagerLoaded_onAdsManagerAdError.bind(this)
        );
        
        // Listen to any additional events, if necessary.
        this._adsManager.removeEventListener(
          this._googleIma.AdEvent.Type.LOADED,
          this._onAdsManagerLoaded_onAdsManagerAdLoaded.bind(this)
        );
        
        this._adsManager.removeEventListener(
          this._googleIma.AdEvent.Type.STARTED,
          this._onAdsManagerLoaded_onAdsManagerAdStarted.bind(this)
        );
        
        this._adsManager.removeEventListener(
          this._googleIma.AdEvent.Type.ALL_ADS_COMPLETED,
          this._onAdsManagerLoaded_onAdsManagerAllAdsCompleted.bind(this)
        );
        
        this._adsManager.destroy();
        // console.log('ima-sdk.factory->destroy(): this._adsManager DESTROYED');
        
        areAllAdsManagerEventsRemoved = true;
      });
      
      utilsFactory.waitToBeTrue(
        'ima-sdk.factory->destroy(): areAllAdsLoaderEventsRemoved && areAllAdsManagerEventsRemoved',
        () =>
          !!(areAllAdsLoaderEventsRemoved && areAllAdsManagerEventsRemoved)
      ).then(() => {
        if (error) {
          this._adLoadedObserver.error(error);
        }
        else {
          this._adLoadedObserver.next('AD_DESTROYED');
        }
        
        if (this._hasAdFilled) {
          if (error) {
            this._adPlayerObserver.error(error);
          }
          else {
            this._adPlayerObserver.next('AD_DESTROYED');
          }
        }
        
        this._hasLoadAdOnce = false;
        this._hasAdFilled = null;
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to do the ad request
   */
  loadAd(vastUrl: string): Observable<any> {
    try {
      if (utilsFactory.isBrowser) {
        // console.log('ima-sdk.factory->loadAd(): this._hasLoadAdOnce', this._hasLoadAdOnce);
        
        if (!this._hasLoadAdOnce) {
          // init process
          this._init();
          
          this._hasLoadAdOnce = true;
          
          // creating ad request
          const adRequest = this._createAdRequest(vastUrl);
          
          // fixing "Video audibility misdeclaration" from GAM.
          adRequest.setAdWillAutoPlay(true);
          adRequest.setAdWillPlayMuted(true);
          
          utilsFactory.waitToBeTrue('ima-sdk.factory->loadAd()', () => !!(this._adsLoader)).then(() => {
            
            // making ad request
            this._adsLoader.requestAds(adRequest);
          });
        }
      }
      
      return this._adLoadedObservable;
    }
    catch (e) {
      console.error('ima-sdk.factory->loadAd(): ERROR', e);
      
      this.destroy(e);
    }
  }
  
  /**
   * Method to inject and play the ad (if filled)
   */
  startAd(): Observable<any> {
    // console.log('ima-sdk.factory->startAd(): this._hasAdFilled', this._hasAdFilled);
    
    try {
      if (utilsFactory.isBrowser) {
        // if (this._hasAdFilled) {
        
        this._isPlaying = !this._videoPlayer.paused;
        // console.log('ima-sdk.factory->startAd(): this._isPlaying', this._isPlaying);
        
        utilsFactory.waitToBeTrue('ima-sdk.factory->startAd()', () => !!(this._adsLoader)).then(() => {
          
          // tell the SDK that our content video
          // is completed so the SDK can play any post-roll ads.
          this._adsLoader.contentComplete();
          
          // Initialize the container. Must be done via a user action on mobile devices.
          // videoContent.load();
          this._adDisplayContainer.initialize();
          
          // Initialize the ads manager. Ad rules playlist will start at this time.
          this._adsManager.init(640, 360, this._googleIma.ViewMode.NORMAL);
          // Call play to start showing the ad. Single video and overlay ads will
          // start at this time; the call will be ignored for ad rules.
          this._adsManager.start();
        });
        
        /*}
         else {
         this._adLoadedObserver.next('AD_NOT_FILLED');
         }*/
      }
      
      return this._adPlayerObservable;
    }
    catch (e) {
      this.destroy(e);
      return null;
    }
  }
  
  muteAd() {
    try {
      if (this._adsManager) {
        this._adsManager.setVolume(0);
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  unmuteAd() {
    try {
      if (this._adsManager) {
        this._adsManager.setVolume(1);
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  pauseAd() {
    try {
      if (this._adsManager) {
        this._adsManager.pause();
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  playAd() {
    try {
      // console.log('ima-sdk.factory->playAd(): this._adsManager', this._adsManager);
      if (this._adsManager) {
        this._adsManager.play();
      }
    }
    catch (e) {
      throw e;
    }
  }
}
