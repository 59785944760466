import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { MipModalModule } from '@libs/gc-common/lib/components/mip-modal/mip-modal.module';
import { MipShapeModule } from '@libs/gc-common/lib/components/mip-shape/mip-shape.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { OnReachingBottomModule } from '@libs/gc-common/lib/directives/on-reaching-bottom/on-reaching-bottom.module';
import { ScrollIntoViewModule } from '@libs/gc-common/lib/directives/scroll-into-view/scroll-into-view.module';
import { ScrollTopModule } from '@libs/gc-common/lib/directives/scroll-top/scroll-top.module';
import { WithinViewportModule } from '@libs/gc-common/lib/directives/within-viewport/within-viewport.module';
import { AdvertisementModule } from '@libs/gc-common/lib/features/advertisement/advertisement.module';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app/download-app.module';
import { PostViewModule } from '@libs/gc-common/lib/features/post-view/post-view.module';
import { SwiperModule } from 'swiper/angular';

import { FeedPostsDesktopComponent } from './feed-posts-desktop/feed-posts-desktop.component';
import { FeedPostsDetailsComponent } from './feed-posts-details/feed-posts-details.component';
import { FeedPostsMobileComponent } from './feed-posts-mobile/feed-posts-mobile.component';
import { FeedPostsSkeletonComponent } from './feed-posts-skeleton/feed-posts-skeleton.component';
import { FeedPostsComponent } from './feed-posts.component';

@NgModule({
  declarations: [
    FeedPostsComponent,
    FeedPostsMobileComponent,
    FeedPostsDesktopComponent,
    FeedPostsSkeletonComponent,
    FeedPostsDetailsComponent
  ],
  imports: [
    CommonModule,
    PostViewModule,
    SwiperModule,
    MipShapeModule,
    AdvertisementModule,
    MipLoadingModule,
    MipIconModule,
    MatButtonModule,
    ColorModule,
    OnReachingBottomModule,
    WithinViewportModule,
    ScrollTopModule,
    ScrollIntoViewModule,
    DownloadAppModule,
    MipModalModule,
    RouterModule
  ],
  exports: [
    FeedPostsComponent
  ]
})
export class FeedPostsModule {
}
