import { Component } from '@angular/core';

@Component({
  selector: 'mip-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent {

}
