import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';

import { MipTranslateService } from './mip-translate.service';

@Component({
  selector: 'mip-translate',
  templateUrl: './mip-translate.component.html',
  styleUrls: ['./mip-translate.component.scss']
})
export class MipTranslateComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('container') containerEl: ElementRef;

  @Input() key: string = null;
  @Input() values: object = {};

  containerElement = null;
  observerHelper = new ObserverHelper();

  constructor(
		private translate: TranslateService,
    // private routerService: RouterService,
    private mipTranslateService: MipTranslateService,
    private hostEl: ElementRef
  ) {
  }

  ngAfterViewInit() {

    this.containerElement = this.hostEl.nativeElement; // this.containerEl.nativeElement;
    console.log('mip-translate.component->ngAfterViewInit(): this.containerElement', this.containerElement);

    this.mipTranslateService.doTranslation(this.key, this.values, this.containerElement);

		this.observerHelper.addSubscription(
      this.translate.onLangChange.subscribe(() => {
        this.mipTranslateService.doTranslation(this.key, this.values, this.containerElement);
      })
		);

  }

  async ngOnChanges() {

    if (!this.containerElement) {
      await utilsFactory.waitToBeTrue('mip-translate.component', () => this.containerElement);
    }

    this.mipTranslateService.doTranslation(this.key, this.values, this.containerElement);
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

}
