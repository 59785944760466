import { Injectable } from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

export interface GoogleDeserializeUrlInterface {
  access_token?: string;
  code?: string;
  authuser?: number;
  expires_in?: number;
  prompt?: string;
  scope?: string;
  token_type?: string;
}

export interface GoogleAccountsInterface {
  id: {
    PromptMomentNotification: (a?) => void;
    cancel: () => void;
    disableAutoSelect: () => void;
    initialize: (a?) => void;
    intermediate: {
      notifyParentClose: () => any;
      notifyParentDone: () => any;
      notifyParentResize: (a?) => any;
      notifyParentTapOutsideMode: (a?) => any;
      verifyParentOrigin: (a?, b?, c?) => any;
    }
    prompt: (a?, b?, c?) => void;
    renderButton: (a?, b?, c?) => void;
    revoke: (a?, b?) => void;
    setLogLevel: (a?) => void;
    storeCredential: (a?, b?) => void;
  };
}

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  _clientId = environment.googleClientId;
  _scope = [
    'https://www.googleapis.com/auth/user.birthday.read',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ];

  _redirectUri = environment.loginUrl; // `https:${environment.appBaseHrefUseValue}/login`;

  constructor(
    private resourceService: ResourceService
  ) {
  }

  /**
   * Login using redirect URI to get the code
   */
  async authWithGoogle(redirectUri?: string) {
    console.log('google.service->authWithGoogle(): redirectUri', redirectUri);

    const params = {
      redirect_uri: encodeURIComponent(redirectUri || this._redirectUri),
      prompt: 'consent',
      response_type: 'code',
      client_id: this._clientId,
      scope: encodeURIComponent(this._scope.join(' ')),
      access_type: 'offline'
    };
    console.log('google.service->authWithGoogle(): params', params);

    const serializedParams = utilsFactory.serializeObject(params);
    console.log('google.service->authWithGoogle(): serializedParams', serializedParams);

    const url = `https://accounts.google.com/o/oauth2/v2/auth?${serializedParams}`;
    console.log('google.service->authWithGoogle(): url', `${url}`);

    window.location.href = url;

  }

  async exchangeCodeToAccessToken(code: string, redirectUri?: string) {
    try {
      console.log('google.service->exchangeCodeToAccessToken(): code', { code, redirectUri });

      if (!code) {
        throw new Error(`the Google oauth2 'code' must be provided`);
      }

      const response = await this.resourceService.post('api', {
        path: '/user/exchange-authorization',
        data: {
          code,
          redirect_uri: redirectUri || this._redirectUri
        }
      });
      console.log('google.service->exchangeCodeToAccessToken(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }
}
