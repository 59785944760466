import {
  AbstractControl,
  FormGroup
} from '@angular/forms';

export class FormHelper extends FormGroup {

  override controls: { [key: string]: AbstractControl<any, any>; };

  constructor(controls, validatorOrOpts?, asyncValidator?) {
    super(controls, validatorOrOpts, asyncValidator);


    this.controls = controls;
  }

  validateField(errorLabel) {
    console.log('form-helper.service->validateField(): errorLabel', errorLabel);

    const parsedErrorLabel = errorLabel.split('.');
    const fieldName = parsedErrorLabel[0];
    const validation = parsedErrorLabel[1];
    const validationError = {};

    if (!fieldName || !validation) {
      throw new Error(`The 'errorLabel' provided is not valid. The pattern should be '{fieldName}.{validationLabel}'`);
    }

    console.log('form-helper.service->validateField(): fieldName', fieldName);
    console.log('form-helper.service->validateField(): validation', validation);

    const fieldControl = this.controls[fieldName];
    console.log('form-helper.service->validateField(): fieldControl', fieldControl);

    if (!fieldControl) {
      throw new Error(`The field ${fieldName} does not exist`);
    }

    fieldControl.markAsDirty();
    fieldControl.markAsTouched();

    validationError[validation] = true;
    console.log('form-helper.service->validateField(): validationError', validationError);

    fieldControl.setErrors(validationError);
  }

  validate(errorLabel: string | object) {
    try {
      console.log('form-helper.service->validate(): errorLabel', errorLabel);
      console.log('form-helper.service->validate(): this.controls', this.controls);

      if (typeof errorLabel === 'object') {

        // tslint:disable-next-line:forin
        for (const i in errorLabel) {
          console.log('form-helper.service->validate(): errorLabel[i]', errorLabel[i]);

          // tslint:disable-next-line:forin
          for (const x in errorLabel[i].errors) {
            this.validateField(`${i}.${errorLabel[i].errors[x]}`);
          }
        }
      }
      else if (typeof errorLabel === 'string') {
        console.log('form-helper.service->validate(): errorLabel', errorLabel);
        this.validateField(errorLabel);
      }
      else {
        throw new Error(`'errorLabel' must be a STRING or OBJECT`);
      }

    }
    catch (e) {
      console.error('form-helper.service->validate(): ERROR', e);
      throw e;
    }
  }
}
