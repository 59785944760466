<div class="ad-slot-container" [class.--ads-disabled]="disableAd">

  <!-- SLOT 1 -->
  <div
    class="__slot-A"
    [id]="slotA.id"
    [class.has-filled]="slotA.hasFilled"
    style="width: 320px; height: 50px"
  ></div>

  <!-- SLOT 2 -->
  <div
    class="__slot-B"
    [id]="slotB.id"
    [class.has-filled]="slotB.hasFilled"
    style="width: 320px; height: 50px"
  ></div>

  <span *ngIf="disableAd">ADS DISABLED (320x50)</span>

</div>

<div class="ad-unit-info" *ngIf="logs">
  <small>ADs: {{totalAdRequest}}</small>
  <small>FILL: {{totalAdFilled}}</small>
  <small>T: {{sectionTimer}}</small>
</div>
