import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { CollabCampaignModel } from './collab-campaign.model';

export class CollabCampaignFactory extends BaseModelFactory implements BaseModelFactoryInterface {
  override identifier = 'id';
  override model = CollabCampaignModel;

  constructor() {
    super();
  }

  override build(data: object): CollabCampaignModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<CollabCampaignModel> {
    return super.buildCollection(data);
  }

}

// export const userFactory = new UserFactory();
