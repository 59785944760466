import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mip-label-picker',
  templateUrl: './mip-label-picker.component.html',
  styleUrls: ['./mip-label-picker.component.scss']
})
export class MipLabelPickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
