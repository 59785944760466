import {
  Component,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import { WithinViewportService } from '../within-viewport.service';

@Component({
  selector: 'mip-within-viewport-child',
  templateUrl: './within-viewport-child.component.html',
  styleUrls: ['./within-viewport-child.component.scss']
})
export class WithinViewportChildComponent implements OnInit {

  @Input() childData: any;

  hostChild: HTMLElement = null;

  constructor(
    private viewportService: WithinViewportService,
    { nativeElement }: ElementRef
  ) {
    this.hostChild = nativeElement;
  }

  ngOnInit() {
    // console.log('within-viewport-child.component->ngOnInit(): this.hostChild', this.hostChild);
    // console.log('within-viewport-child.component->ngOnInit(): this.childData', this.childData);
    this.viewportService.addChild(this.hostChild, this.childData);
  }

}
