<button
  mat-flat-button
  class="__profile-actions-item --follow-btn --full"
  [mipBgColor]="bgColor"
  [mipTextColor]="textColor"
  [class]="'mip-button--size-' + buttonSize"
  (click)="follow($event)"
  *ngIf="!profile.isAuthenticated && (!profile.isFollowing && !profile.hasJustFollowed) || profile.hasJustUnfollowed"
>
  <span mipTranslate="profile-following-button.follow">Follow</span>
</button>

<!--<button
  mat-flat-button
  class="__profile-actions-item &#45;&#45;full mip-color&#45;&#45;bg-primary"
  *ngIf="!isAuthenticated"
  mipGoBackReferrer
  [routerLink]="['/login']"
>
  <span mipTranslate="profile-following-button.follow">Follow</span>
</button>-->

<button
  mat-flat-button
  class="__profile-actions-item --unfollow-btn --full"
  mipBgColor="transparent"
  [mipBdColor]="borderFollowingColor"
  [class]="['mip-button--size-' + buttonSize, 'mat-elevation-z' + elevation]"
  (click)="unfollow($event)"
  *ngIf="!profile.isAuthenticated && (profile.isFollowing || profile.hasJustFollowed) && !profile.hasJustUnfollowed"
>
  <mip-icon icon="check" size="1x"></mip-icon>
  <span mipTranslate="profile-following-button.following">Following</span>
</button>
