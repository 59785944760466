import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

export class ReferralSectionFactory {

  _cacheKey = 'mip-referral-section';

  userId = '';
  createdAt = null;
  updatedAt = null;
  isActive = false;
  hasRefresh = false;

  constructor(userId) {

    if (utilsFactory.isBrowser) {

      const sectionCache = JSON.parse(window.localStorage.getItem(this._cacheKey));
      console.log('section.factory->constructor(): sectionCache', sectionCache);

      if (sectionCache) {
        const { inSeconds } = utilsFactory.diffDates(new Date(), new Date(sectionCache.updatedAt));
        console.log('section.factory->constructor(): inSeconds', inSeconds);

        this.hasRefresh = true;

        if (userId === sectionCache.userId && inSeconds <= 120) {
          this.userId = sectionCache.userId;
          this.createdAt = sectionCache.createdAt;
          this.updatedAt = sectionCache.updatedAt;
        }
      }

      console.log('section.factory->constructor(): this', this);
      console.log('section.factory->constructor(): document.referrer', document.referrer);

      if (!this.userId && document.referrer && document.referrer.indexOf('gotchosen.com') === -1) {
        this.userId = userId;
        this.createdAt = new Date().toJSON();
        this.updatedAt = this.createdAt;
        this._setSectionCache();
      }

      console.log('section.factory->constructor(): this', this);

      if (this.userId) {

        this.isActive = true;

        setInterval(() => {
          this.updatedAt = new Date().toJSON();
          this._setSectionCache();
        }, 5000);
      }
      else {
        this._removeSectionCache();
      }

    }

  }

  _setSectionCache() {
    window.localStorage.setItem(this._cacheKey, JSON.stringify({
      userId: this.userId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    }));
  }

  _removeSectionCache() {
    window.localStorage.removeItem(this._cacheKey);
  }

}
