import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { OnReachingBottomModule } from '@libs/gc-common/lib/directives/on-reaching-bottom/on-reaching-bottom.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app/download-app.module';
import { ProfileFollowingButtonModule } from '@libs/gc-common/lib/features/profile-following-button/profile-following-button.module';
import { TranslateModule } from '@ngx-translate/core';

import { UsersListComponent } from './users-list.component';

@NgModule({
  declarations: [UsersListComponent],
  imports: [
    CommonModule,
    MatListModule,
    MipLoadingModule,
    MipAvatarModule,
    MipProfileSlugNameModule,
    RouterModule,
    MatRippleModule,
    OnReachingBottomModule,
    DownloadAppModule,
    MipTranslateModule,
    ProfileFollowingButtonModule,
    GoBackModule,
    TranslateModule,
    MipIconModule
  ],
  exports: [UsersListComponent]
})
export class UsersListModule {
}
