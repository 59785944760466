import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { AvatarModel } from './avatar.model';

export class AvatarFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = AvatarModel;

  override build(data: object): AvatarModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<AvatarModel> {
    return super.buildCollection(data);
  }

}

// export const avatarFactory = new AvatarFactory();
