<div
  class="__post-media"
  [class.--is-desktop]="isDesktop"
>

  <!--<div class="__logs" *ngIf="!disableLogs">
    <p>index: {{postIndex}}</p>
    <p>ad: {{isAdEnable}}</p>
    <p [style]="{background: postModel.theme === 'single' ? 'red' : ''}">theme: {{postModel.theme}}</p>
    <p>video: {{postModel.isVideo}}</p>
    <p>autoplay?: {{isAutoplayMode}}</p>
    <p [style]="{background: isIntoViewport ? 'blue' : ''}">viewport: {{isIntoViewport}}</p>
    <p [style]="{background: isIntoTopBounceLimit ? 'purple' : ''}">top-bounce: {{isIntoTopBounceLimit}}</p>
  </div>-->

  <!-- DUET -->
  <!--<mip-duet-theme
    *ngIf="forceTheme ? forceTheme === 'duet' : postModel.theme === 'duet'"
    [postModel]="postModel"
    [isModal]="isModal"
    [isIntoViewport]="isIntoViewport"
    [postVideoAdType]="postVideoAdType"
    [isPostRollAdEnable]="isPostRollAdEnable"
    [isPreRollAdEnable]="isPreRollAdEnable"
    [isAdEnable]="isAdEnable"
    [swiperNavigation]="swiperNavigation"
    [isFirstSlide]="isFirstSlide"
    [isLastSlide]="isLastSlide"
    (onPostAdFill)="onPostAdFillFunc($event)"
    (onPostAdHide)="onPostAdHideFunc($event)"
    (toggleAllowTouchMove)="toggleAllowTouchMoveFunc($event)"
  ></mip-duet-theme>-->

  <!-- CHALLENGE -->
  <!--<mip-challenge-theme
    *ngIf="forceTheme ? forceTheme === 'challenge' : postModel.theme === 'challenge'"
    [postModel]="postModel"
    [isModal]="isModal"
    [isIntoViewport]="isIntoViewport"
    [postVideoAdType]="postVideoAdType"
    [isPostRollAdEnable]="isPostRollAdEnable"
    [isPreRollAdEnable]="isPreRollAdEnable"
    [isAdEnable]="isAdEnable"
    [swiperNavigation]="swiperNavigation"
    [isFirstSlide]="isFirstSlide"
    [isLastSlide]="isLastSlide"
    (onPostAdFill)="onPostAdFillFunc($event)"
    (onPostAdHide)="onPostAdHideFunc($event)"
    (toggleAllowTouchMove)="toggleAllowTouchMoveFunc($event)"
  ></mip-challenge-theme>-->

  <!-- SINGLE -->
  <mip-single-theme
    *ngIf="forceTheme ? forceTheme === 'single' : postModel.theme === 'single'"
    [postModel]="postModel"
    [isModal]="isModal"
    [isIntoViewport]="isIntoViewport"
    [isIntoTopBounceLimit]="isIntoTopBounceLimit"
    [postVideoAdType]="postVideoAdType"
    [isPostRollAdEnable]="isPostRollAdEnable"
    [isPreRollAdEnable]="isPreRollAdEnable"
    [isAdEnable]="isAdEnable"
    [swiperNavigation]="swiperNavigation"
    [isFirstSlide]="isFirstSlide"
    [isLastSlide]="isLastSlide"
    [isMobile]="isMobile"
    [isDesktop]="isDesktop"
    (onPostHeaderToggle)="_onPostHeaderToggle($event)"
    (onToggleComments)="_onToggleComments()"
    (onToggleShare)="_onToggleShare()"
    (onPostAdFill)="_onPostAdFill($event)"
    (onPostAdHide)="_onPostAdHide($event)"
    (onPostAdPlayed)="_onPostAdPlayed()"
    (onPostAdEnded)="_onPostAdEnded()"
  ></mip-single-theme>

  <!-- STATIC -->
  <mip-static-theme
    *ngIf="forceTheme ? forceTheme === 'static' : postModel.theme === 'static'"
    [postModel]="postModel"
    [isIntoViewport]="isIntoViewport"
    [postBasePath]="postBasePath"
  ></mip-static-theme>

  <video
    #videoPreload
    class="__video-preload-element"
    preload="auto"
  ></video>

</div>

