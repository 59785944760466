import { Pipe, PipeTransform } from '@angular/core';
import slugify from 'slugify';

interface SlugifyInterfaces {
  replacement?: string; // replace spaces with replacement character, defaults to `-`
  remove?: RegExp; // remove characters that match regex, defaults to `undefined`
  lower?: boolean; // convert to lower case, defaults to `false`
  strict?: boolean; // strip special characters except replacement, defaults to `false`
  locale?: string; // language code of the locale to use
  trim?: boolean; // trim leading and trailing replacement chars, defaults to `true`
}

@Pipe({
  name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {

  transform(value: string, options: SlugifyInterfaces = {}): unknown {
    options = {lower: true, ...options};
    return slugify(value, options);
  }

}
