import moment from 'moment';
import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { TopTenRankingInterface } from './top-ten-ranking.interface';

export class TopTenRankingModel extends BaseModel implements TopTenRankingInterface {

  theme = null; // api
  value = 0; // api
  position = 0; // api
  reference = null; // api
  prize = null; // api

  user = null; // api

  referenceDate = null; // local
  isAfterJanuary = false; // local

  constructor(model?: TopTenRankingInterface) {
    super(model);
    this.fill(model);
  }

  override afterFill(model) {
    if (model.user) {
      this.user = ModelFactoryService.instance.userFactory.build(model.user);
    }

    if (this.reference) {
      this.referenceDate = moment(this.reference);
      this.isAfterJanuary = this.referenceDate.isAfter(new Date('2021/12/21'));
    }

    if (!this.isAfterJanuary) {
      this.value = Math.floor(this.value);
    }
  }

  getDuration(showSeconds = false) {

    if (this.value) {
      const duration = moment.duration(this.value, 'seconds');
      console.log('top-ten-ranking.model->getDuration(): duration', duration);

      let hours = ((duration.get('days') * 24) + duration.get('hours')).toString();
      console.log('top-ten-ranking.model->getDuration(): hours', hours);

      let minutes = duration.get('minutes').toString();
      console.log('top-ten-ranking.model->getDuration(): minutes', minutes);

      let seconds = duration.get('seconds').toString();
      console.log('top-ten-ranking.model->getDuration(): seconds', seconds);

      hours = hours.length === 1 ? `0${hours}` : hours;
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;

      if (showSeconds) {
        seconds = seconds.length === 1 ? `0${seconds}` : seconds;
      }

      return `${hours}h${minutes}m` + (showSeconds ? `${seconds}s` : '');
    }

    return '0s';

  }

}
