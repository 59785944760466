<div
  class="see-more"
  [class.--hide-text]="hideText"
>
  <!--
  <div
    #textContainer
    class="__text"
    [style.line-height]="lineHeight + 'px'"
    [style.font-size]="fontSize + 'px'"
    [style.-webkit-line-clamp]="hideText ? maxTextLines : currentTextLines"
  >
    {{text}}
  </div>
  -->

  <div
    #textContainer
    class="__text"
    [innerHTML]="text"
    [linkifyText]="text"
    linkTarget="_blank"
    [mipLinkify]="linkifySettings"
    [style.line-height]="lineHeight + 'px'"
    [style.font-size]="fontSize + 'px'"
    [style.-webkit-line-clamp]="hideText ? maxTextLines : currentTextLines"
  >
  </div>

  <button
    *ngIf="showSeeMoreButton && hideSeeMoreButton === false"
    class="__see-more mip-button--size-xs"
    [class.--is-uppercase]="actionTextUpperCase"
    mat-button
    [mipTextColor]="actionTextColor"
    (click)="toggleText()"
  >
    <span *ngIf="!isOpened" mipTranslate="mip-see-more.see-more">See more</span>
    <span *ngIf="isOpened" mipTranslate="mip-see-more.see-less">See less</span>
  </button>

</div>
