<div
  #feedPosts
  class="feed-posts-mobile"
  [class.--is-modal]="isModal"
  [class.--is-mobile]="isMobile"
>
  <swiper
    class="__swiper-container"
    #swiper
    [config]="config"
    (slideChange)="slideChange()"
    (reachBeginning)="reachBeginning()"
    (beforeDestroy)="swiperBeforeDestroy()"
  >

    <ng-template
      class="__section-container"
      *ngFor="let section of sections; let $index = index"
      swiperSlide
    >
      <!-- [class]="[section.model.theme || section.type]" -->

      <!-- POST MAIN COMPONENT -->
      <mip-post-view
        class="__post-view-container"
        *ngIf="section.isAttached && section.type === 'post'"
        [postModel]="section.model"
        [isModal]="isModal"
        [isIntoViewport]="section.isIntoViewport"
        [isIntoTopBounceLimit]="section.isIntoViewport"
        [isMoving]="section.isMoving"
        [postVideoAdType]="section.postVideoAdType"
        [isAdEnable]="section.isAdEnable"
        [postIndex]="section.index"
        [enableLastSeen]="true"
        [isFirstSlide]="$index === 0"
        [isLastSlide]="$index === (sections.length - 1)"
        (onPostAdFill)="_onPostAdFill($event)"
        (onPostAdHide)="_onPostAdHide($event)"
        (onPostHeaderToggle)="_onPostHeaderToggle($event)"
        (onToggleComments)="_onToggleComments()"
        (onToggleShare)="_onToggleShare()"
        (onPostAdPlayed)="_onPostAdPlayed()"
        (onPostAdEnded)="_onPostAdEnded()"
      ></mip-post-view>
    </ng-template>
  </swiper>

  <mip-loading
    class="__main-loading"
    *ngIf="loading"
    [color]="isMobile ? 'white' : 'primary'"
    size="5x"
  ></mip-loading>

</div>
