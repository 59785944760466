import {
  CommonModule,
  DOCUMENT
} from '@angular/common';
import {
  Inject,
  NgModule
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

import { MipModalComponent } from './mip-modal.component';

@NgModule({
  declarations: [MipModalComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [MipModalComponent]
})
export class MipModalModule {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    const classNames = this.document.body.className.split(' ');
    // console.log('mip-modal.module->closeModal() classNames', classNames);

    // checking if there is another modal opened. I yes, stop the process to
    // avoid remove the .--modal-opened from the <body> tag
    for (const i in classNames) {
      if (classNames[i].indexOf('mip-modal-') > -1 || classNames[i].indexOf('--modal-opened') > -1) {
        // console.log('mip-modal.module->closeModal() classNames', classNames[i]);
        utilsFactory.removeClass(this.document.body, classNames[i]);
      }
    }
  }
}
