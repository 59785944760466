import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { DownloadAppModalComponent } from '@libs/gc-common/lib/features/download-app/download-app-modal.component';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-join-hashtag-button',
  templateUrl: './join-hashtag-button.component.html',
  styleUrls: ['./join-hashtag-button.component.scss']
})
export class JoinHashtagButtonComponent implements OnInit, OnChanges, OnDestroy {

  @Input() hashtagModel: HashtagModel = null;
  @Input() isLoading = false;
  @Input() floatingButton = false;
  @Input() buttonSize = '';
  @Input() marginBottom = 0;

  isDesktop = false;
  isMobile = false;

  isContentActive = false;

  scrollInterval = null;

  positionRelative = false;

  constructor(
    private mobileDetect: MobileDetectService,
    private bottomSheet: MatBottomSheet,
    private hostEl: ElementRef
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  async ngOnInit() {
    if (utilsFactory.isBrowser && this.floatingButton && this.hashtagModel) {

      await utilsFactory.waitToBeTrue('join-hashtag-button.component', () => !!this.hostEl.nativeElement.querySelector('.join-hashtag-button'));

      const nativeElement = this.hostEl.nativeElement;
      console.log('join-hashtag-button.component->ngOnInit(): nativeElement', nativeElement);

      const joinButton = nativeElement.querySelector('.join-hashtag-button');
      console.log('join-hashtag-button.component->ngOnInit(): joinButton', joinButton);

      const { parentNode } = nativeElement;
      console.log('join-hashtag-button.component->ngOnInit(): parentNode', parentNode);

      this.scrollInterval = setInterval(() => {

        const boundingClientRect = this.hostEl.nativeElement.getBoundingClientRect();
        const { top, bottom, x } = boundingClientRect;
        console.log('join-hashtag-button.component->ngOnInit(): boundingClientRect', (bottom - window.innerHeight) + 36);

        if (((bottom - window.innerHeight) + 36) <= 0) {
          this.positionRelative = true;
          utilsFactory.addClass(nativeElement, '--position-relative');
        }
        else if (this.positionRelative) {
          this.positionRelative = false;
          utilsFactory.removeClass(nativeElement, '--position-relative');
        }

      }, 100);

    }
  }

  ngOnChanges() {

    console.log('join-hashtag-button.component->ngOnChanges(): this.hashtagModel', this.hashtagModel);

    if (this.hashtagModel && this.hashtagModel.contest) {
      this.isContentActive = this.hashtagModel.contest.isActive();
      console.log('join-hashtag-button.component->ngOnChanges(): this.isContentActive', this.isContentActive);
    }

  }

  ngOnDestroy() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }

  onJoinHashtag() {
    console.log('join-hashtag-button.component->onJoinHashtag(): this.hashtagModel', this.hashtagModel);

    if (window['gcApp_onJoinHashtag']) {
      window['gcApp_onJoinHashtag'].postMessage(JSON.stringify({
        hashtag: this.hashtagModel.hashtag,
        hashtag_id: this.hashtagModel.id
      }));
    }
    else {
      this.bottomSheet.open(DownloadAppModalComponent);
    }
  }

}
