import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

import moment from 'moment';
import {
  Observable,
  Subject
} from 'rxjs';

export class BaseModel {

  _parent = null;
  _cache_created_at = null;
  _updated_at = null;

  _modelObservable = utilsFactory.isBrowser ? new Subject() : null;
  _modelObserver = utilsFactory.isBrowser ? this._modelObservable.asObservable() : null;

  _triggersRepository = {};

  constructor(model?) {
    // this._cache_created_at = moment();
  }

  async fill(model, _parent?: BaseModel, logs = false) {

    // if model IS this already (same instance), there is nothing to update
    if (model === this) {
      return this;
    }

    // console.log('base.model->fill(): model', model, model === this);

    try {

      if (model && model._cache_created_at) {

        // console.log('base.model->fill(): _cache_created_at model.username', model.username, model);
        // console.log('base.model->fill(): _cache_created_at model._cache_created_at', model._cache_created_at);

        if (model._cache_created_at && this._cache_created_at) {
          // console.log('base.model->fill(): _cache_created_at this._cache_created_at', this._cache_created_at);
          // console.log('base.model->fill(): _cache_created_at moment', moment(model._cache_created_at).isBefore(this._cache_created_at));

          if (moment(model._cache_created_at).isBefore(this._cache_created_at)) {
            // console.log('base.model->fill(): _cache_created_at ###################### ');
            return this;
          }
        }

        this._cache_created_at = moment(model._cache_created_at);

        // console.log('base.model->fill(): _cache_created_at --------------------- ');
      }

      if (_parent) {
        this._parent = _parent;

        if (logs) {
          // console.log('base.model->fill(): this._parent', this._parent);
        }
      }

      if (logs) {
        // console.log('base.model->fill(): model', model);
      }

      if (model && typeof model === 'object') {

        this.beforeFill(model);

        this._updated_at = new Date();

        for (const key in this) {

          if (key in model) {

            const value = model[key];
            const prop = this[key];

            if (logs) {
              // console.log('base.model->fill(): prop', key, prop, prop instanceof BaseModel);
            }

            // console.log('base.model->fill(): model', model);
            // console.log('base.model->fill(): model', model);
            if (value !== null) {

              if (prop instanceof BaseModel && !(value instanceof BaseModel)) {

                if (logs) {
                  // console.log('base.model->fill(): prop', prop);
                  // console.log('base.model->fill(): value', value);
                  // console.log('base.model->fill(): fill model', prop instanceof BaseModel && !(value instanceof BaseModel));
                  // console.log('base.model->fill(): --------------------- ');
                }

                await prop.fill(value);
                // prop._parent = this;
              }
              else {

                if (logs) {
                  // console.log('base.model->fill(): VALUE', key, value, this);
                }

                this[key] = value;

              }

            }

          }
        }

        this.afterFill(model);

        if (logs) {
          // console.log('base.model->fill(): model', model);
          // console.log('base.model->fill(): this', this);
          // console.log('base.model->fill(): ------------------------------------------------');
        }

        if (this._modelObservable && this._modelObservable.next) {
          // console.log('base.model->fill(): this._modelObservable.next(this)');
          this._modelObservable.next(this);
        }

      }

      return this;

    }
    catch (e) {
      console.error('base.model->fill(): ERROR', e);
      throw e;
    }

  }

  beforeFill(model) {
    // console.log('BaseModel->beforeFill');
  }

  afterFill(model) {
    // console.log('BaseModel->afterFill');
  }

  onChange(namespace?: string) {
    // console.log('base.model->onChange(): namespace 1', namespace, this._triggersRepository);

    if (namespace) {
      if (!this._triggersRepository[namespace]) {

        const observable = new Subject();

        this._triggersRepository[namespace] = {
          observer: observable,
          observable: observable.asObservable()
        };

      }

      // console.log('base.model->onChange(): namespace 2', namespace, this._triggersRepository[namespace]);

      // NOTE: The following implementation CANNOT be uncommented because the Observable is the same for all
      // callers, so, if one caller is passing "firstExecution=true", it does NOT matter, it will trigger all
      // callers anyway, leading in mistaken implementations
      /*if (firstExecution) {
       // console.log('base.model->onChange(): namespace 3', namespace, this._triggersRepository[namespace]);

       setTimeout(() => {
       this._triggersRepository[namespace].observer.next(this);
       }, 50);
       }*/

      return this._triggersRepository[namespace].observable;

    }
    else if (this._modelObserver) {
      // console.log('base.model->onChange(): ELSE');

      setTimeout(() => {
        this._modelObservable.next(this);
      }, 50);

      return this._modelObserver;
    }
    else {
      return new Observable();
    }
  }

  triggerOnChange(namespace?: string, excludeAfterRun = false) {
    // console.log('base.model->triggerOnChange(): namespace', {namespace, excludeAfterRun}, this._triggersRepository);

    if (namespace) {
      if (this._triggersRepository[namespace]) {
        this._triggersRepository[namespace].observer.next(this);

        if (excludeAfterRun) {
          delete this._triggersRepository[namespace];
        }
      }
    }
    else {
      this._modelObservable.next(this);
    }
  }

}
