import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

import { AudioInterface } from './audio.interface';

export interface AudiosPaginationInterface {
  totalItems: number;
  page: number;
  limit: number;
  lastPage: number;
}

export class AudioModel extends BaseModel implements AudioInterface {

  id = null; // api

  // post media audio object
  description = null;
  isOriginal = null;
  owner: UserModel = null;

  // audio object
  defaultDescription = null;
  originalPost: PostModel = null;
  userStats = {
    saved: false
  };

  constructor(model?: AudioInterface) {
    super(model);

    this.fill(model);
  }

  override beforeFill(model?: AudioInterface): void {

    // console.log('audio.model->afterFill() model.owner', model.owner);

    if (model.owner) {
      model.owner = ModelFactoryService.instance.userFactory.build(model.owner);
      // console.log('audio.model->afterFill() model.owner', model.owner);
    }

    if (model.originalPost) {
      model.originalPost = ModelFactoryService.instance.postFactory.build(model.originalPost);
    }
  }

  async favorite() {
    try {

      this.fill({
        userStats: {
          saved: true
        }
      });

      const response = await ResourceService.instance.post('api', {
        path: `/user/saved_content`,
        data: {
          contentType: 'audio',
          contentIdentifier: this.id
        }
      });

      CacheService.instance.clearCache('audios');

      return response;
    }
    catch (e) {

      this.fill({
        userStats: {
          saved: false
        }
      });

      throw e;
    }
  }

  async unfavorite() {
    try {
      this.fill({
        userStats: {
          saved: false
        }
      });

      const response = await ResourceService.instance.delete('api', {
        path: `/user/saved_content/type/audio/${this.id}`
      });

      CacheService.instance.clearCache('audios');

      return response;
    }
    catch (e) {
      this.fill({
        userStats: {
          saved: true
        }
      });

      throw e;
    }
  }

  async toggleFavorite() {
    try {
      if (this.userStats.saved) {
        await this.unfavorite();
      }
      else {
        await this.favorite();
      }
    }
    catch (error) {
      throw error;
    }
  }

  async getPosts(params?: { limit?}): Promise<{ pagination: AudiosPaginationInterface, list: Array<PostModel> }> {
    try {

      params = params || {};

      params.limit = params.limit || 10;

      const response = await ResourceService.instance.get('api', {
        resource: 'audios',
        path: `/audio/${this.id}/posts`,
        params
      });

      return {
        pagination: response._pagination,
        list: ModelFactoryService.instance.postFactory.buildCollection(response.items)
      };
    }
    catch (e) {
      throw e;
    }
  }
}
