import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { IsAuthenticatedGuard } from '@libs/gc-common/lib/guards/is-authenticated.guard';
import { RedirectAtProfileGuard } from '@libs/gc-common/lib/guards/redirect-at-profile.guard';
import { RedirectCollabUrlGuard } from '@libs/gc-common/lib/guards/redirect-collab-url.guard';
import { RedirectLoginUrlGuard } from '@libs/gc-common/lib/guards/redirect-login-url.guard';

import { TermsComponent } from './modules/terms/terms.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'trending',
  },
  {
    path: 'community-guidelines',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.community-guidelines.title',
      },
    },
  },
  {
    path: 'monetization-terms',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.monetization-terms.title',
      },
    },
  },
  {
    path: 'privacy',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.privacy.title',
      },
    },
  },
  {
    path: 'terms-of-service',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.terms-of-service.title',
      },
    },
  },
  {
    path: 'list-countries',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.list-countries.title',
      },
    },
  },
  {
    path: 'about-us',
    component: TermsComponent,
    data: {
      metatags: {
        title: 'pages.metatag.about-us.title',
      },
    },
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./modules/tutorials/tutorials.module').then((m) => m.TutorialsModule),
  },
  {
    path: 'emotional-health',
    loadChildren: () =>
      import('./modules/emotional-health/emotional-health.module').then((m) => m.EmotionalHealthModule),
  },
  /*{
   path: 'influencers',
   loadChildren: () => import('./modules/for-influencers/for-influencers.module').then(m => m.ForInfluencersModule)
   },*/
  /*{
   path: 'contact',
   loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
   // outlet: 'modal'
   },*/
  /*{
   path: 'drawing',
   redirectTo: 'sweepstakes'
   },
   {
   path: 'sweepstake',
   redirectTo: 'sweepstakes'
   },
   {
   path: 'sweepstakes',
   loadChildren: () => import('./modules/drawing/drawing.module').then(m => m.DrawingModule)
   },*/
  {
    path: 'hashtag-contest',
    loadChildren: () => import('./modules/hashtag-contest/hashtag-contest.module').then((m) => m.HashtagContestModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'top-ranking',
    loadChildren: () => import('./modules/top-ten-ranking/top-ten-ranking.module').then((m) => m.TopTenRankingModule),
  },
  {
    path: 'top-ten-ranking',
    redirectTo: 'top-ranking',
  },
  {
    path: 'hashtag',
    loadChildren: () => import('./modules/hashtag/hashtag.module').then((m) => m.HashtagModule),
  },
  {
    path: 'audios',
    loadChildren: () => import('./modules/audios/audios.module').then((m) => m.AudiosModule),
  },
  {
    path: 'trending',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dashboard',
    canActivate: [RedirectCollabUrlGuard],
    children: [{
      path: '**',
      loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
    }]
  },
  {
    path: 'login',
    canActivate: [RedirectLoginUrlGuard],
    children: [{
      path: '**',
      loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
    }]
  },
  {
    path: 'following',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: ':userId',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [RedirectAtProfileGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
