<div
  nameSpace="mip-scroll-fix"
  [mipScrollFix]="top"
  [marginTop]="marginTop"
  [hideOnUnstick]="hideOnUnstick"
  [fixBorder]="fixBorder"
  [zIndex]="zIndex"
  (onFixed)="onFixed.emit()"
  (onUnfixed)="onUnfixed.emit()"
  (onHide)="onHide.emit()"
>
  <div class="mip-scroll-fix-element">
    <ng-content></ng-content>
  </div>
</div>
