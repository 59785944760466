import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { HashtagInterface } from './hashtag.interface';
import { HashtagModel } from './hashtag.model';

export class HashtagFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = HashtagModel;

  constructor() {
    super();
    // console.log(HashtagModel, HashtagModel);
  }

  override build(data: HashtagInterface): HashtagModel {
    return super.build(data);
  }

  override buildCollection(data: Array<HashtagInterface>): Array<HashtagModel> {
    return super.buildCollection(data);
  }

}

// export const hashtagFactory = new HashtagFactory();
