import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';

import { GoBackReferrerDirective } from './go-back-referrer.directive';
import { GoBackDirective } from './go-back.directive';

@NgModule({
  declarations: [GoBackDirective, GoBackReferrerDirective],
  imports: [
    CommonModule,
    MatButtonModule,
    MipIconModule
  ],
  exports: [
    GoBackDirective,
    GoBackReferrerDirective
  ]
})
export class GoBackModule {
}
