import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { MipFormFileUploadComponent } from './mip-form-file-upload.component';

@NgModule({
  declarations: [
    MipFormFileUploadComponent
  ],
  imports: [
    CommonModule,
    MipTranslateModule
  ],
  exports: [
    MipFormFileUploadComponent
  ]
})
export class MipFormFileUploadModule {
}
