import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { TranslateModule } from '@ngx-translate/core';

import { SearchFormComponent } from './search-form.component';

@NgModule({
  declarations: [SearchFormComponent],
  exports: [
    SearchFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    TranslateModule
  ]
})
export class SearchFormModule {
}
