import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnChanges {

  @Input() usersList: Array<UserModel> = [];
  @Input() isLoading = false;
  @Input() title: string = null;
  @Input() bottomBounce = null;

  @Input() topDivider = false;
  @Input() bottomDivider = false;
  @Input() showFollowBottom = false;
  @Input() disableListLimit = false;
  @Input() avatarSize = 40;

  @Input() showDownloadApp = true;

  // @Input() enableOnSelect = false;

  @Output() onReachingBottom = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  enableOnSelect = false;

  loggedInUser: UserModel = null;

  listLimit = 30;
  lastItemsCount = 0;
  // hasMoreItems = true;

  isMobile = false;
  isDesktop = false;

  constructor(
    private mobileDetect: MobileDetectService,
    private userService: UserService,
    private routerService: RouterService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();

    console.log('users-list.component->constructor(): isAuthenticated()', this.userService.isAuthenticated());

  }

  async ngOnInit() {

    console.log('users-list.component->ngOnInit(): this.usersList', this.usersList);
    console.log('users-list.component->ngOnInit(): this.onSelect', this.onSelect.observed);

    this.enableOnSelect = this.onSelect.observed;

    this.loggedInUser = await this.userService.getLoginUser();

    if (this.disableListLimit) {
      this.listLimit = 1000000;
    }
    else if (this.loggedInUser) {
      if (this.loggedInUser.isModerator) {
        this.listLimit = 1000000;
      }
      else {
        this.listLimit = 60;
      }
    }

    console.log('users-list.component->ngOnInit(): this.listLimit', this.listLimit);

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('users-list.component->ngOnChanges(): changes', changes);

    console.log('users-list.component->ngOnChanges(): changes.usersList', changes['usersList']);
    console.log('users-list.component->ngOnChanges(): this.usersList', this.usersList);

    if ('usersList' in changes) {
      console.log('users-list.component->ngOnChanges(): changes.usersList.currentValue', changes['usersList'].currentValue);
    }

    if (/*'usersList' in changes || */this.isLoading === false) {
      console.log('users-list.component->ngOnChanges(): this.usersList.length', this.usersList.length, this.lastItemsCount, this.isLoading);

      this.lastItemsCount = this.usersList.length;
      console.log('users-list.component->ngOnChanges(): this.lastItemsCount', this.lastItemsCount);
    }
  }

  _onReachingBottom() {
    console.log('users-list.component->_onReachingBottom()', this.usersList.length, this.listLimit);

    if (this.usersList.length < this.listLimit) {
      this.onReachingBottom.emit();
    }
  }

  onClick(user: UserModel, $event) {
    console.log('users-list.component->onClick(): user', this.enableOnSelect, user);

    if (this.enableOnSelect) {
      $event.preventDefault();
      $event.stopPropagation();
      this.onSelect.emit(user);
    }

  }

}
