import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { FeedPostSectionInterface } from '@libs/gc-common/lib/features/feed-posts/feed-post-section.interface';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-feed-posts-desktop',
  templateUrl: './feed-posts-desktop.component.html',
  styleUrls: ['./feed-posts-desktop.component.scss']
})
export class FeedPostsDesktopComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() sections: Array<FeedPostSectionInterface> = [];

  @Input() postCount = 0;
  @Input() reachedBottomCountLimit = 3;
  @Input() isModal = false;
  @Input() loading = false;

  @Output() onReachedBottom = new EventEmitter<any>();
  @Output() onSlideChange = new EventEmitter<any>();

  @Output() onPostHeaderToggle = new EventEmitter();
  @Output() onToggleComments = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();

  @Output() onPostAdFill = new EventEmitter();
  @Output() onPostAdHide = new EventEmitter();
  @Output() onPostAdPlayed = new EventEmitter();
  @Output() onPostAdEnded = new EventEmitter();

  @Input() postsLimitAuthenticated = 60;
  @Input() postsLimitAnonymous = 40;
  @Input() disableListLimit = false;

  loggedInUser: UserModel = null;

  disableLogs = environment.disableLogs;

  viewportsSettings = [];

  isMobile = true;
  isDesktop = false;

  initialUrlPostId = null;
  smoothBehavior = true;

  onEnterTopBounceLimitTimeout = null;

  hasBeenIncited = false;

  postsListLimit = 30;

  constructor(
    private mobileDetectService: MobileDetectService,
    private routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private bottomSheet: MatBottomSheet
  ) {
    // console.log('feed-posts-desktop.component->constructor()');

    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
  }

  async ngOnInit() {

    // console.log('feed-posts-desktop.component->ngOnInit()');

    const params = await this.routerService.getRouteParam();
    // console.log('feed-posts-desktop.component->ngOnInit(): params', params);

    const postId = params.postId;
    // console.log('feed-posts-desktop.component->ngOnInit(): postId', postId);

    // tslint:disable-next-line:radix
    this.initialUrlPostId = postId ? parseInt(postId, 10) : null;
    // console.log('feed-posts-desktop.component->ngOnInit(): this.initialUrlPostId', this.initialUrlPostId);

    if (this.initialUrlPostId) {
      this.smoothBehavior = true;
    }

    this.loggedInUser = await this.userService.getLoginUser();

    if (this.disableListLimit) {
      this.postsListLimit = 1000000;
    }
    else if (this.loggedInUser) {
      if (this.loggedInUser.isModerator) {
        this.postsListLimit = 1000000;
      }
      else {
        this.postsListLimit = this.postsLimitAuthenticated;
      }
    }
    else {
      this.postsListLimit = this.postsLimitAnonymous;
    }

    this.hasBeenIncited = true;

    if (utilsFactory.isBrowser) {

      const marginTop = this.isModal ? 140 : 75;

      this.viewportsSettings = [{
        marginTop: window.innerHeight * 2,
        marginBottom: window.innerHeight * 4,
        // showLayer: true,
        onEnter: this.onEnterVirtualViewport.bind(this),
        onLeave: this.onLeaveVirtualViewport.bind(this)
      }, {
        // marginTop: 500,
        // marginBottom: 500,
        // showLayer: true,
        onEnter: this.onEnterViewport.bind(this),
        onLeave: this.onLeaveViewport.bind(this)
      }, {
        marginTop: marginTop * (-1),
        marginBottom: (window.innerHeight - (marginTop + 640)) * (-1),
        threshold: 0.5,
        // showLayer: true,
        onEnter: this.onEnterTopBounceLimit.bind(this),
        onLeave: this.onLeaveTopBounceLimit.bind(this)
      }];

    }

  }

  async ngAfterViewInit() {

  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log('feed-posts-desktop.component->ngOnChanges(): changes', changes);
  }

  /**
   * view methods
   */

  reachEnd() {

    // console.log(`feed-posts-desktop.component->reachEnd(): this.sections.length`, this.sections.length, this.postsListLimit);

    if (this.sections.length < this.postsListLimit) {
      this.onReachedBottom.emit();
    }

  }

  /**
   * [mipWithinViewport] methods
   */

  onEnterVirtualViewport(section: FeedPostSectionInterface) {
    // console.log(`feed-posts-desktop.component->onEnterVirtualViewport() ${section.id}`);
    section.isAttached = true;
  }

  onLeaveVirtualViewport(section: FeedPostSectionInterface) {
    // console.log(`feed-posts-desktop.component->onLeaveVirtualViewport() ${section.id}`);
    section.isAttached = false;
  }

  onEnterViewport(section: FeedPostSectionInterface) {
    // console.log(`feed-posts-desktop.component->onEnterViewport() ${section.id}`);
    section.isIntoViewport = true;
  }

  onLeaveViewport(section: FeedPostSectionInterface) {
    // console.log(`feed-posts-desktop.component->onLeaveViewport() ${section.id}`);
    section.isIntoViewport = false;
  }

  async onEnterTopBounceLimit(section: FeedPostSectionInterface) {

    clearTimeout(this.onEnterTopBounceLimitTimeout);

    this.onEnterTopBounceLimitTimeout = setTimeout(async () => {

      if (!this.hasBeenIncited) {
        await utilsFactory.waitToBeTrue('feed-posts-desktop.component', () => this.hasBeenIncited === true);
      }

      // console.log(`feed-posts-desktop.component->onEnterTopBounceLimit() 1: this.initialUrlPostId`, this.initialUrlPostId, section.model.id);

      // if there is an initial post id, we need to wait the [mipScrollIntoView] to
      // scroll the section into view to prevent another post ID change the URL
      if (this.initialUrlPostId === section.model.id) {
        this.initialUrlPostId = null;
      }

      // console.log(`feed-posts-desktop.component->onEnterTopBounceLimit() 2: this.initialUrlPostId`, this.initialUrlPostId);

      // if there is no initial post ID or it has been scrolled already, allow to
      // change the URL and the section.isIntoTopBounceLimit
      if (!this.initialUrlPostId) {

        // console.log(`feed-posts-desktop.component->onEnterTopBounceLimit() 3: this.initialUrlPostId`, this.initialUrlPostId);

        section.isIntoTopBounceLimit = true;

        this.routerService.navigateTo([section.model.id], {
          relativeTo: this.activatedRoute
        });
      }

    }, 100);

  }

  onLeaveTopBounceLimit(section: FeedPostSectionInterface) {
    // console.log(`feed-posts-desktop.component->onLeaveTopBounceLimit() ${section.id}`);
    section.isIntoTopBounceLimit = false;
  }

  /**
   * Outputs
   */

  _onPostAdFill(type) {
    this.onPostAdFill.emit(type);
  }

  _onPostAdHide(type) {
    this.onPostAdHide.emit(type);
  }

  _onPostHeaderToggle(isOpen) {
    this.onPostHeaderToggle.emit(isOpen);
  }

  _onToggleComments() {
    this.onToggleComments.emit();
  }

  _onToggleShare() {
    this.onToggleShare.emit();
  }

  _onPostAdPlayed() {
    this.onPostAdPlayed.emit();
  }

  _onPostAdEnded() {
    this.onPostAdEnded.emit();
  }

}
