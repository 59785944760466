import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { LazyImgModule } from '@libs/gc-common/lib/directives/lazy-img/lazy-img.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';
import { MomentModule } from 'ngx-moment';

import { FeedItemComponent } from './feed-item.component';

@NgModule({
  declarations: [FeedItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    GoBackModule,
    MipIconModule,
    MillifyModule,
    MipAvatarModule,
    MipTranslateModule,
    ColorModule,
    MomentModule,
    LazyImgModule,
    MipLoadingModule
  ],
  exports: [FeedItemComponent]
})
export class FeedItemModule {
}
