<mip-modal size="full" *ngIf="currentSection">

  <div class="__header">
    <a
      [routerLink]="['.', currentSection.model.id]"
      [queryParams]="{details: null}"
      class="__close-btn"
      mat-icon-button
    >
      <mip-icon
        icon="chevron-left"
        [bold]="true"
      ></mip-icon>
    </a>

    <a
      class="__gc-icon"
      routerLink="/trending"
      mipTextColor="primary"
    >
      <mip-icon
        icon="gc-hand"
        size="9x"
        [circle]="true"
      ></mip-icon>
    </a>

  </div>

  <div class="feed-posts-details">

    <div
      class="__column --left"
      [class.--show]="showLeftColumn"
    >

      <!-- POST-COMMENTS -->
      <div class="__posts-comments">

        <!-- section COMMENTS -->
        <mip-post-comments
          *ngIf="loadingNextPost === false"
          [postModel]="currentSection.model"
          [avatarSize]="40"
          (onCommentsClose)="onCommentsClose()"
        >

          <mip-icon
            *ngIf="showLeftColumn"
            comments-close-icon
            icon="chevron-down"
            [bold]="true"
          ></mip-icon>

          <mip-icon
            *ngIf="!showLeftColumn"
            comments-close-icon
            icon="chevron-up"
            [bold]="true"
          ></mip-icon>

        </mip-post-comments>

      </div>

    </div>

    <div
      class="__column --center"
    >

      <div
        class="__background"
        [style.backgroundImage]="'url(' + currentSection.model.getCover() + ')'"
      ></div>

      <!-- VIDEO
        [autoplay]="true"
        [startAsMuted]="true"-->
      <mip-post-video
        *ngIf="loadingNextPost === false"
        class="__video-player"
        videoObjectFit="contain"
        videoQuality="high"
        [noBackground]="true"
        [postModel]="currentSection.model"
        [isIntoViewport]="true"
        [isIntoTopBounceLimit]="true"
        [isAdEnable]="currentSection.isAdEnable"
        [enableCanvas]="false"
        [fullSize]="true"
        [bottomShadow]="false"
        [enableVideoControls]="true"
        [startAsAttachVideo]="true"
        [autoplay]="true"
        (onVideoPlay)="_onVideoPlay()"
        (onVideoPause)="_onVideoPause()"
        (onVideoDetach)="_onVideoDetach()"
        (onPostAdFill)="_onPostAdFill()"
        (onPostAdHide)="_onPostAdHide()"
        (onPostAdPlayed)="_onPostAdPlayed()"
        (onPostAdEnded)="_onPostAdEnded()"
      ></mip-post-video>

      <div class="__navigation">

        <button
          mat-fab
          (click)="goPrevPost()"
          *ngIf="currentSection.prevSection"
          [disabled]="loadingMorePosts"
        >
          <mip-icon
            icon="arrow-up"
            [bold]="true"
          ></mip-icon>
        </button>

        <button
          mat-fab
          (click)="goNextPost()"
          *ngIf="currentSection.nextSection || loadingMorePosts"
          [disabled]="loadingMorePosts"
        >
          <mip-icon
            icon="arrow-down"
            [bold]="true"
            *ngIf="!loadingMorePosts"
          ></mip-icon>

          <mip-loading
            color="white"
            size="6x"
            *ngIf="loadingMorePosts"
          ></mip-loading>

        </button>

      </div>

    </div>

    <div class="__column --right">

      <!-- POST-HEADER -->
      <div class="__post-header" [class.--ad-has-fill]="nativeAdHasFill">

        <div class="__header-container">

          <!-- section HEADER -->
          <mip-post-header
            [user]="currentSection.model.author"
            [bodyText]="currentSection.model.description"
            [maxHeightClosed]="10000"
            [textShadow]="false"
            [showFollowBtn]="true"
            [linkifyUrls]="true"
            [showUsernameAvatar]="true"
            [avatarSize]="40"
            [showAvatarRoundProgress]="true"
            [roundProgressCurrent]="currentSection.model.currentTime"
            [roundProgressMax]="currentSection.model.duration"
            textColorTheme="dark"
            bgColor="#FAFAFA"
          ></mip-post-header>

        </div>

        <!-- TIMESTAMP (only for moderator users) -->
        <div
          class="__timestamp"
          *ngIf="showTimestamp || currentSection.model.author.isAuthenticated"
          title="New York - Eastern Time Zone"
        >
          {{createdAt}} <small>(EST)</small>
        </div>

        <!-- section AUDIO-TEXT -->
        <mip-post-audio-text
          class="__audio-text"
          *ngIf="currentSection.model.medias.audio || currentSection.model.medias.song"
          [audio]="currentSection.model.medias.audio?.audio"
          [song]="currentSection.model.medias.song?.song"
          theme="dark"
        ></mip-post-audio-text>

        <!-- section INTERACTION -->
        <div
          class="__interaction"
        >
          <mip-post-interaction
            [postModel]="currentSection.model"
            [disableCommentsAction]="true"
            [disableAudioAction]="true"
            [inline]="true"
          ></mip-post-interaction>
        </div>

      </div>

      <!-- AD-MEDIUM-RECTANGLE -->
      <div
        class="__ad"
        [class.--ad-has-fill]="nativeAdHasFill"
      >

        <!-- section AD-NATIVE -->
        <mip-ad-native
          #nativeAdvertisement
          class="__ad-native"
          adSlotId="feed-posts-details"
          bgColor="#FFF"
          adUnitName="White-NativeAd"
          [enableHeader]="false"
          [disableAd]="disableFeedDetailsAd"
          (onAdFill)="onNativeAdFill()"
        ></mip-ad-native>

      </div>

    </div>

  </div>

</mip-modal>
