import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';

import { BottomSheetComponent } from './bottom-sheet.component';
import { BottomSheetDirective } from './bottom-sheet.directive';

@NgModule({
  declarations: [BottomSheetDirective, BottomSheetComponent],
  imports: [
    CommonModule,
    MatListModule,
    MipIconModule,
    MipAvatarModule,
    MatBottomSheetModule,
    MatButtonModule
  ],
  exports: [BottomSheetDirective]
})
export class BottomSheetModule {
}
