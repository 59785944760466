import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { BottomSheetComponent, BottomSheetInterface } from './bottom-sheet.component';

/*export interface MipBottomSheetInterface {
 text: string;
 class?: string;
 path?: string;
 divider?: boolean;
 icon?: string;
 onSelect?: (any) => void;
 }*/

@Directive({
  selector: '[mipBottomSheet]'
})
export class BottomSheetDirective implements OnInit {

  @Input() mipBottomSheet: Array<BottomSheetInterface>;
  @Input() title: string = null;

  @HostListener('click', ['$event']) onClick() {
    if (this.mipBottomSheet && this.mipBottomSheet.length) {
      this.matBottomSheet.open(BottomSheetComponent, {
        data: {
          optionsList: this.mipBottomSheet,
          title: this.title
        }
      });
    }
  }

  constructor(private matBottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {

  }
  
}
