import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { UserDeviceUUIDModel } from './user-device-uuid.model';

export class UserDeviceUUIDFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = UserDeviceUUIDModel;

  constructor() {
    super();
  }

  override build(data: object): UserDeviceUUIDModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<UserDeviceUUIDModel> {
    return super.buildCollection(data);
  }

}

// export const userFactory = new UserFactory();
