import { ContestModel } from '@libs/gc-common/lib/api/models/contest';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { CollabBrandInterface } from './collab-brand.interface';

export class CollabBrandModel extends UserModel implements CollabBrandInterface {

  contests: ContestModel[] = null;

  constructor(model?: CollabBrandInterface) {
    super(model);
    this.fill(model);
  }

  override afterFill(model: CollabBrandInterface) {
    // console.log('collab-brand.model->afterFill(): model', model);

    if (model.contests && model.contests.length > 0) {
      model.contests = ModelFactoryService.instance.contestFactory.buildCollection(model.contests);
    }

    super.afterFill(model);

    // console.log('collab-brand.model->afterFill(): this', this);

  }

}
