import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { MediaDimensionInterface } from './media-dimension.interface';

export class MediaDimensionModel extends BaseModel implements MediaDimensionInterface {

  height = null; // api
  resolution = null; // api
  source = null; // api
  width = null; // api

  constructor(model?: MediaDimensionInterface) {
    super(model);
    this.fill(model);
  }

  override beforeFill(model?: MediaDimensionInterface): void {
    // console.log('media-dimension.model->beforeFill() model', model);
  }
}
