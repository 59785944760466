import { Injectable } from '@angular/core';
import { AudioFactory } from '@libs/gc-common/lib/api/models/audio';
import { AvatarFactory } from '@libs/gc-common/lib/api/models/avatar';
import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import {
  CollabAmbassadorFactory,
  CollabBrandFactory,
  CollabCampaignFactory
} from '@libs/gc-common/lib/api/models/collab';
import { CommentFactory } from '@libs/gc-common/lib/api/models/comment';
import { ContestFactory } from '@libs/gc-common/lib/api/models/contest';
import { ContestWinnerFactory } from '@libs/gc-common/lib/api/models/contest-winner';
import {
  DashboardAgencyFactory,
  DashboardAmbassadorFactory,
  DashboardCampaignFactory,
  DashboardUserFactory
} from '@libs/gc-common/lib/api/models/dashboard';
import { HashtagFactory } from '@libs/gc-common/lib/api/models/hashtag';
import { MediaFactory } from '@libs/gc-common/lib/api/models/media';
import { PostFactory } from '@libs/gc-common/lib/api/models/post';
import { TopTenRankingFactory } from '@libs/gc-common/lib/api/models/top-ten-ranking';
import { UserFactory } from '@libs/gc-common/lib/api/models/user';
import { UserDeviceUUIDFactory } from '@libs/gc-common/lib/api/models/user-device-uuid';
import { UserSettingsFactory } from '@libs/gc-common/lib/api/models/user-settings';

@Injectable({
  providedIn: 'root'
})
export class ModelFactoryService {

  static instance: ModelFactoryService;

  avatarFactory: BaseModelFactory = new AvatarFactory();
  commentFactory: BaseModelFactory = new CommentFactory();
  contestFactory: BaseModelFactory = new ContestFactory();
  contestWinnerFactory: BaseModelFactory = new ContestWinnerFactory();
  hashtagFactory: BaseModelFactory = new HashtagFactory();
  mediaFactory: BaseModelFactory = new MediaFactory();
  postFactory: BaseModelFactory = new PostFactory();
  topTenRankingFactory: BaseModelFactory = new TopTenRankingFactory();
  userFactory: BaseModelFactory = new UserFactory();
  userSettingsFactory: BaseModelFactory = new UserSettingsFactory();
  audioFactory: BaseModelFactory = new AudioFactory();
  userDeviceUUIDFactory: BaseModelFactory = new UserDeviceUUIDFactory();

  // DASHBOARD FACTORIES
  dashboardUserFactory: BaseModelFactory = new DashboardUserFactory();
  dashboardCampaignFactory: BaseModelFactory = new DashboardCampaignFactory();
  dashboardAgencyFactory: BaseModelFactory = new DashboardAgencyFactory();
  dashboardAmbassadorFactory: BaseModelFactory = new DashboardAmbassadorFactory();

  // COLLAB FACTORIES
  collabBrandFactory: BaseModelFactory = new CollabBrandFactory();
  collabCampaignFactory: BaseModelFactory = new CollabCampaignFactory();
  collabAmbassadorFactory: BaseModelFactory = new CollabAmbassadorFactory();

  constructor() {
    ModelFactoryService.instance = this;
  }

}
