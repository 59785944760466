import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { UserSettingsInterface } from './user-settings.interface';

export class UserSettingsModel extends BaseModel implements UserSettingsInterface {

  id = null;
  key = null;
  value = null;
  visibility = null;

  constructor(model?: UserSettingsInterface) {

    // console.log('user-settings.model->constructor(): model', [model.key, model.value], model)

    super(model);

    this.fill(model);

  }

}
