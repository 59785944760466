<div #singleTheme class="single-theme" *ngIf="postModel">

  <!-- [DESKTOP] POST-HEADER -->
  <mip-post-header
    *ngIf="isDesktop"
    class="__post-info-header"
    [class.--is-description-opened]="isDescriptionOpened"
    [user]="postModel.author"
    [bodyText]="postModel.description"
    [maxHeightClosed]="44"
    [textShadow]="false"
    [showFollowBtn]="true"
    [linkifyUrls]="true"
    [showAvatar]="true"
    [showAvatarRoundProgress]="true"
    [roundProgressCurrent]="postModel.currentTime"
    [roundProgressMax]="postModel.duration"
    textColorTheme="dark"
    bgColor="#FAFAFA"
    (onToggle)="_onPostHeaderToggle($event)"
  ></mip-post-header>

  <!-- MEDIA-CONTAINER -->
  <div
    class="__media-container"
    [class.--is-desktop]="isDesktop"
    [class.--is-mobile]="isMobile"
  >

    <!-- VIDEO-CONTAINER -->
    <div class="__video-container" [class.--is-desktop]="isDesktop">

      <!-- POST-INFO -->
      <div
        class="__post-info"
        [class.--is-mobile]="isMobile"
        [class.--is-modal]="isModal"
        [class.--is-description-opened]="isDescriptionOpened"
        *ngIf="isAdPlaying === false"
      >

        <!-- [MOBILE] POST-DESCRIPTION -->
        <mip-post-header
          *ngIf="isMobile"
          [user]="postModel.author"
          [bodyText]="postModel.description"
          [maxHeightClosed]="44"
          [parentContainer]="singleTheme"
          [linkifyUrls]="true"
          bgColor="rgba(0, 0, 0, 0.8)"
          position="bottom"
          (onToggle)="_onPostHeaderToggle($event)"
        ></mip-post-header>

        <!-- AUDIO-TEXT -->
        <mip-post-audio-text
          class="__audio-text"
          *ngIf="postModel.medias.audio || postModel.medias.song"
          [audio]="postModel.medias.audio?.audio"
          [song]="postModel.medias.song?.song"
        ></mip-post-audio-text>

        <!-- TIMESTAMP (only for moderator users) -->
        <div
          class="__timestamp"
          *ngIf="showTimestamp || postModel.author.isAuthenticated"
          title="New York - Eastern Time Zone"
        >
          {{createdAt}} <small>(EST)</small>
        </div>

      </div>

      <!-- VIDEO -->
      <mip-post-video
        #videoPlayer
        class="__video-player"
        [canvasMaxWidth]="isDesktop ? '360px' : '100%'"
        [videoQuality]="isDesktop ? 'high' : null"
        [class.--is-desktop]="isDesktop"
        [class.--is-mobile]="isMobile"
        [fullSize]="isMobile"
        [postModel]="postModel"
        [enableCanvas]="isDesktop"
        [isIntoViewport]="isIntoViewport"
        [isIntoTopBounceLimit]="isIntoTopBounceLimit"
        [bottomShadow]="!!(postModel.medias.audio || showTimestamp || isMobile)"
        [autoplay]="isDesktop"
        [isAdEnable]="isAdEnable"
        [enableDetailsLink]="isDesktop"
        [enableVideoControls]="isDesktop"
        [videoControlsActions]="['mute']"
        (onVideoPlay)="_onVideoPlay()"
        (onVideoPause)="_onVideoPause()"
        (onVideoEnded)="_onVideoEnded()"
        (onVideoDetach)="_onVideoDetach()"
        (onPostAdFill)="_onPostAdFill()"
        (onPostAdHide)="_onPostAdHide()"
        (onPostAdPlayed)="_onPostAdPlayed()"
        (onPostAdEnded)="_onPostAdEnded()"
      ></mip-post-video>

    </div>

    <!-- INTERACTION
    [isModal]="isModal"
    [isVideoPlaying]="isVideoPlaying"
    [isIntoViewport]="isIntoViewport"
    [isFirstSlide]="isFirstSlide"
    [isLastSlide]="isLastSlide"
    -->
    <mip-post-interaction
      class="__interaction"
      [class.--is-desktop]="isDesktop"
      [class.--is-mobile]="isMobile"
      [class.--is-modal]="isModal"
      *ngIf="postInteraction && (isDesktop || (isMobile && isAdPlaying === false))"
      [postModel]="postModel"
      [showAvatar]="isMobile"
      [isVideoPlaying]="isVideoPlaying"
      (onToggleComments)="_onToggleComments()"
      (onToggleShare)="_onToggleShare()"
    ></mip-post-interaction>

  </div>

</div>
