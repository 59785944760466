import {
	Component,
	Input,
	OnInit
} from '@angular/core';

@Component({
	selector: 'mip-feed-posts-skeleton',
	templateUrl: './feed-posts-skeleton.component.html',
	styleUrls: ['./feed-posts-skeleton.component.scss']
})
export class FeedPostsSkeletonComponent implements OnInit {
	
	@Input() animated = false;
	
	constructor() {
	}
	
	ngOnInit(): void {
	}
	
}
