interface SSPsAdaptersOptionsInterface {
  code?: string;
  host?: string;
  zoneId?: string;
  id?: string;
  adUnitName?: string;
  dmxid?: string | number;
  placements?: Array<{ id, units }>;
  siteId?: string;
  size?: Array<number | string>;
  placementId?: string;
  units?: Array<string>;
  network?: string;

  video?: {
    mimes?: Array<string>;
    skippable?: boolean;
    playback_method?: Array<string>;
  };

}

interface SSPsAdaptersParamsInterface {
  ad_unit?: string;
  floor?: number;
  sizes?: Array<number | string>;
  publisherId?: string;
  placementId?: string;
  adSlot?: string;
  kadpageurl?: string;
  accountId?: string;
  siteId?: string | number;
  dmxid?: string | number;
  memberid?: string | number;
  tagId?: string | number;
  tagid?: string | number;
  zoneId?: string;
  network?: string;
  placement?: string;
  dcn?: string;
  pos?: string;
  size?: Array<number | string>;
  video?: {
    context?: string;
    mimes?: Array<string>;
    skippable?: boolean;
    playback_method?: Array<string>;
    playerSize?: Array<Array<number>> | Array<number>;
    language?: string;
    minduration?: number;
    maxduration?: number;
    protocols?: Array<number>
  };
}

class SSPsAdaptersFactory {

  /**
   * Method to return the SSP params settings
   */
  sonobi(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    if (isVideo) {
      return null;
    }

    return {
      ad_unit: options.code,
      floor: 0.20,
      sizes: options.size
    };

  }

  /**
   * Method to return the SSP params settings
   */
  pubmatic(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    const params: SSPsAdaptersParamsInterface = {
      publisherId: '157193',
      adSlot: `${options.code}@${options.size.join('x')}`,
      kadpageurl: window.location.href
    };

    if (isVideo) {
      // params.adSlot = `${options.code}@1x1`;
      params.video = {
        mimes: ['video/mp4', 'video/x-flv'] // required
      };
    }

    return params;

  }

  /**
   * Method to return the SSP params settings
   */
  rubicon(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let siteId = null;
    let zoneId = null;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          siteId = item.siteId;
          zoneId = item.zoneId;
        }
      });
    }
    else {
      siteId = options.siteId;
      zoneId = options.zoneId;
    }

    if (siteId) {

      const params: SSPsAdaptersParamsInterface = {
        accountId: '19976',
        siteId,
        zoneId
      };

      if (isVideo) {

        let language = null;

        try {
          language = window.navigator.language;
          language = language.split('-');
          language = language[0].toLowerCase();
        }
        catch (e) {
          language = 'en';
        }

        params.video = {
          language
        };
      }

      return params;

    }
    else {
      return null;
    }

  }

  /**
   * Method to return the SSP params settings
   */
  onemobile(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    if (isVideo) {
      return null;
    }

    let params: SSPsAdaptersParamsInterface = null;

    options.placements.forEach(placement => {
      if (placement.units.indexOf(options.adUnitName) > -1) {
        params = {
          dcn: options.siteId,
          pos: placement.id
        };
      }
    });

    return params;

  }

  /**
   * Method to return the SSP params settings
   */
  ix(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let siteId = null;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          siteId = item.siteId;
        }
      });
    }
    else {
      siteId = options.siteId;
    }

    const params: SSPsAdaptersParamsInterface = {
      siteId,
      size: options.size
    };

    if (isVideo) {
      params.video = {
        mimes: [
          'video/mp4',
          'video/webm'
        ],
        minduration: 0,
        maxduration: 60,
        protocols: [6]
      };
    }

    if (siteId) {
      return params;
    }
    else {
      return null;
    }

  }

  /**
   * Method to return the SSP params settings
   */
  appnexus(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let placementId = null;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          placementId = item.placementId;
        }
      });
    }
    else {
      placementId = options.placementId;
    }

    if (placementId) {

      const params: SSPsAdaptersParamsInterface = {
        placementId
      };

      if (isVideo) {
        params.video = {
          skippable: true,
          playback_method: ['auto_play_sound_off']
        };
      }

      return params;

    }
    else {
      return null;
    }
  }

  /**
   * Method to return the SSP params settings
   */
  districtmDMX(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let dmxid = null;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          dmxid = item.dmxid;
        }
      });
    }
    else {
      dmxid = options.dmxid;
    }

    if (dmxid) {

      const params: SSPsAdaptersParamsInterface = {
        dmxid,
        memberid: 102117
      };

      if (isVideo) {
        params.video = {
          playerSize: [[640, 480]],
          skippable: true,
          minduration: 5,
          maxduration: 45,
          playback_method: ['auto_play_sound_off', 'viewport_sound_off'],
          mimes: ['application/javascript', 'video/mp4']
        };
      }

      return params;

    }
    else {
      return null;
    }
  }

  /**
   * Method to return the SSP params settings
   */
  unruly(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let enableAdapter = false;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          enableAdapter = true;
        }
      });
    }
    else {
      enableAdapter = true;
    }

    if (enableAdapter) {

      const params: SSPsAdaptersParamsInterface = {
        siteId: isVideo ? 234409 : 234073
      };

      if (isVideo) {
        params.video = {
          context: 'instream',
          mimes: ['video/mp4'],
          playerSize: [[640, 480]]
        };
      }

      return params;

    }
    else {
      return null;
    }
  }

  /**
   * Method to return the SSP params settings
   */
  amx(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    let enableAdapter = false;

    if (Array.isArray(options)) {
      options.forEach(item => {
        if (item.units.indexOf(options.adUnitName) > -1) {
          enableAdapter = true;
        }
      });
    }
    else {
      enableAdapter = true;
    }

    if (enableAdapter) {

      const params: SSPsAdaptersParamsInterface = {
        tagId: 'Z290Y2hvc2VuLmNvbQ'
      };

      if (isVideo) {
        // params.video = {};
      }

      return params;

    }
    else {
      return null;
    }
  }

  /**
   * Method to return the SSP params settings
   */
  sovrn(options: SSPsAdaptersOptionsInterface, isVideo = false): SSPsAdaptersParamsInterface {

    try {
      // console.log('ssps-adapters.factory->sovrn(): options', options);

      if (isVideo) {
        return null;
      }

      let tagid = null;

      if (Array.isArray(options)) {
        options.forEach((item) => {

          // console.log('ssps-adapters.factory->sovrn(): options.item', item);

          if (item.units.indexOf(options.adUnitName) > -1) {
            tagid = item.id;
          }
        });
      }
      else {
        tagid = options.id;
      }

      if (tagid) {
        return {
          tagid
        };
      }
      else {
        return null;
      }
    }
    catch (e) {
      return null;
    }

  }

}

export default new SSPsAdaptersFactory();
