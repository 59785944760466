import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Directive({
  selector: '[mipGoBackReferrer]'
})
export class GoBackReferrerDirective implements OnInit {
  
  @Input() routerLink = null;
  @Input() href = null;
  @Input() target = 'self';
  @Input() fragment = null;
  @Input() redirectPath = null;
  @Input() redirectPaths = [];
  @Input() mipGoBackReferrer = true;
  @Input() pageTitle: string = null;
  
  routerLinkString = '';
  
  constructor(
    private routerService: RouterService,
    private hostElement: ElementRef,
    protected host: ElementRef,
  ) {
    // console.log('router-link-referrer.directive->constructor() this.routerLink', this.routerLink);
    // console.log('router-link-referrer.directive->constructor() this.hostElement', this.hostElement);
  }
  
  ngOnInit() {
    // console.log('router-link-referrer.directive->ngOnInit() this.routerLink', this.routerLink);
    // console.log('router-link-referrer.directive->ngOnInit() this.href', this.href);
    
    if (this.mipGoBackReferrer === false) {
      return false;
    }
    
    if (!this.routerLink && !this.href) {
      throw new Error(`The [mipGoBackReferrer] directive can only be used together with 'routerLink' directive`);
    }
    
    if (this.routerLink) {
      let routerLinkString = this.routerLink;
      // console.log('router-link-referrer.directive->ngOnInit() routerLinkString', routerLinkString);
      
      if (Array.isArray(this.routerLink)) {
        routerLinkString = this.routerLink.join('/');
      }
      
      routerLinkString = routerLinkString.replace('//', '/');
      
      if (utilsFactory.isBrowser && routerLinkString[0] !== '/') {
        routerLinkString = `${this.routerService.getCurrentPathName()}/${routerLinkString}`;
      }
      
      this.routerLinkString = routerLinkString;
      // console.log('router-link-referrer.directive->ngOnInit() this.routerLinkString', this.routerLinkString);
      
      // console.log('router-link-referrer.directive->ngOnInit() this.fragment', this.fragment);
    }
    else if (this.href) {
      this.routerLinkString = this.href;
      // console.log('router-link-referrer.directive->ngOnInit() this.host.nativeElement', this.host.nativeElement);
      this.host.nativeElement.href = this.href;
    }
  }
  
  @HostListener('click', ['$event'])
  onClick(event) {
    
    event.preventDefault();
    
    if (this.mipGoBackReferrer === false) {
      return false;
    }
    
    const params = {
      path: this.routerLinkString,
      redirectUrl: this.redirectPath,
      title: this.pageTitle,
      fragment: this.fragment,
      allowRemoveGoBack: true
    };
    
    // console.log('router-link-referrer.directive->onClick() this.routerLinkString', this.routerLinkString, params);
    
    this.routerService.registerGoBackReferer(params);
    
    this.routerService.removeGoBackByReferer(this.routerLinkString);
    
    // tslint:disable-next-line:forin
    for (const path in this.redirectPaths) {
      this.routerService.registerGoBackReferer({
        path: this.redirectPaths[path],
        redirectUrl: this.redirectPath,
        allowRemoveGoBack: true
      });
    }
    
    if (this.href) {
      if (this.target !== 'self') {
        window.open(this.href);
      }
      else {
        document.location = this.href;
      }
    }
    
  }
  
}
