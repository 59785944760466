<div
  (click)="onClick.emit($event); $event.preventDefault(); $event.stopPropagation()"
  (mouseenter)="showVideoPreview()"
  (mouseleave)="hideVideoPreview()"
  *ngIf="postModel"
  class="feed-item"
>

  <a
    (click)="onClick.emit($event); $event.preventDefault(); $event.stopPropagation()"
    (mouseenter)="showVideoPreview()"
    (mouseleave)="hideVideoPreview()"
    *ngIf="disableLink !== true"
    [mipGoBackReferrer]="true"
    [queryParams]="customParams"
    [routerLink]="postUrl"
    [target]="linkTarget"
    class="__link"
    queryParamsHandling="merge"
  ></a>

  <div *ngIf="showPlayIcon" class="__play-icon">
    <mip-icon
      [matIconOutlined]="true"
      matIcon="play_arrow"
      size="7x"
    ></mip-icon>
  </div>

  <!-- section CANVAS-SHAPE -->
  <div
    [mipLazyImgBackground]="bgImage"
    [ngSwitch]="orientation"
    [style.max-height]="height ? height + 'px' : null"
    [style.min-height]="height ? height + 'px' : null"
    class="__canvas"
  >
    <img *ngSwitchCase="'square'" src="{{assetsPath}}/images/canvas/square-canvas.svg">
    <img *ngSwitchCase="'landscape'" src="{{assetsPath}}/images/canvas/landscape-canvas.svg">
    <img *ngSwitchCase="'380-340'" src="{{assetsPath}}/images/canvas/380-340-canvas.svg">
    <img *ngSwitchCase="'576-1024'" src="{{assetsPath}}/images/canvas/576-1024-canvas.svg">
    <img *ngSwitchCase="'720-1280'" src="{{assetsPath}}/images/canvas/720-1280-canvas.svg">
    <img *ngSwitchCase="'720-1100'" src="{{assetsPath}}/images/canvas/720-1100-canvas.svg">
    <img *ngSwitchCase="'990-1760'" src="{{assetsPath}}/images/canvas/990-1760-canvas.svg">
    <img *ngSwitchDefault src="{{assetsPath}}/images/canvas/portrait-canvas.svg">
  </div>

  <div
    #videoContainer
    *ngIf="enableVideoPreview"
    class="__video-container"
  >
    <mip-loading *ngIf="loadingVideoPreview" color="white"></mip-loading>
  </div>

  <div class="__content">

    <div *ngIf="enableLastSee && postModel.lastSeen" [class.--show]="postModel.lastSeen" class="__last-seen">
      <div mipTranslate="feed-item.label.last-seen">Last seen</div>
    </div>

    <!-- section AVATAR -->
    <mip-avatar
      *ngIf="showAvatar"
      [borderColor]="avatarBorderColor"
      [borderWidth]="avatarBorderWidth"
      [border]="!!avatarBorderColor"
      [crown]="avatarCrown"
      [size]="avatarSize"
      [userModel]="postModel.author"
      class="__avatar"
    ></mip-avatar>

    <div class="__post-header">

      <!-- section TIMESTAMP (is moderator) -->
      <div
        *ngIf="enableTimestamp && (showTimestamp || postModel.author.isAuthenticated)"
        [class.--has-title]="postModel.theme === 'article'"
        class="__post-timestamp"
        title="New York - Eastern Time Zone"
      >
        {{ createdAt }} <small>(EST)</small>
      </div>

      <div
        *ngIf="isOriginalPost"
        class="__original"
        mipTextColor="white"
      >
        <span mipTranslate="audio.original-video">Original Video</span>
      </div>

    </div>

    <!-- section SAMPLE-LABEL -->
    <div
      *ngIf="showSampleLabel && postModel.sample"
      [class.--statis-enable]="showThemeIcon || (showViews && !infoOnTop)"
      class="__sample-label"
      mipTextColor="primary"
    >
      <span mipTranslate="feed-item.label.sample">Sample</span>
    </div>

    <!-- section ARTICLE-TILE -->
    <div
      *ngIf="postModel.theme === 'article' && postModel.title"
      class="__post-title"
    >
      <div class="__text">
        {{ postModel.title }}
      </div>
    </div>

    <!-- section SHADOW-TOP -->
    <div
      *ngIf="(infoOnTop && (showViews || showThemeIcon)) || (enableTimestamp && (showTimestamp || postModel.author.isAuthenticated))"
      [class.--has-title]="postModel.theme === 'article'"
      class="__shadow-top"
    ></div>

    <!-- section SHADOW-BOTTOM -->
    <div
      *ngIf="(!infoOnTop && (showViews || (showThemeIcon && !postModel.isVideo))) || showAvatar"
      class="__shadow-bottom"
    ></div>

    <!-- section FOOTER -->
    <div
      *ngIf="showFooter"
      [class.--top]="infoOnTop"
      [style.justify-content]="showThemeIcon && (showViews || showPlays) ? 'space-between' : 'flex-end'"
      class="__footer"
    >
      <div *ngIf="showViews || showPlays" class="__post-stats">
        <div
          *ngIf="showViews"
          class="__post-views"
        >
          <span>{{ postModel.stats.views | millify: 2 : 10000 }}</span>
          <mip-icon [solid]="true" icon="eye" size="1x"></mip-icon>
        </div>
        <div
          *ngIf="showPlays"
          class="__post-views"
        >
          <span>{{ postModel.plays | millify: 2 : 10000 }}</span>
          <mip-icon icon="play" size="1x"></mip-icon>
        </div>
      </div>

      <mip-icon
        *ngIf="postModel.theme === 'photo' && showThemeIcon && !showAvatar"
        [solid]="true"
        class="__theme-icon"
        icon="photo-camera"
        size="4x"
      ></mip-icon>

      <ng-content select="[feed-item-footer]"></ng-content>

    </div>

  </div>

</div>
