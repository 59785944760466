import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';

@Component({
  selector: 'mip-hashtags-list',
  templateUrl: './hashtags-list.component.html',
  styleUrls: ['./hashtags-list.component.scss']
})
export class HashtagsListComponent implements OnInit, OnChanges {

  @Input() hashtagsList: Array<HashtagModel> = [];
  @Input() isLoading = false;
  @Input() title: string = null;

  @Input() topDivider = false;
  @Input() bottomDivider = false;

  @Input() disableListLimit = false;

  @Input() showDownloadApp = true;

  @Output() onReachingBottom = new EventEmitter();

  loggedInUser: UserModel = null;

  listLimit = 30;
  lastItemsCount = 0;
  hasMoreItems = true;

  constructor(
    private userService: UserService
  ) {
  }

  async ngOnInit() {

    this.loggedInUser = await this.userService.getLoginUser();

    if (this.disableListLimit) {
      this.listLimit = 1000000;
    }
    else if (this.loggedInUser) {
      if (this.loggedInUser.isModerator) {
        this.listLimit = 5000;
      }
      else {
        this.listLimit = 60;
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    this.hasMoreItems = this.hashtagsList.length === this.lastItemsCount;
    this.lastItemsCount = this.hashtagsList.length;

    console.log('hashtags-list.component->ngOnChanges(): this.hasMoreItems', this.hasMoreItems);

  }

  _onReachingBottom() {
    if (this.hashtagsList.length < this.listLimit) {
      this.onReachingBottom.emit();
    }
  }

}
