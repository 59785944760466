import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MipLottieComponent } from './mip-lottie.component';

@NgModule({
  declarations: [MipLottieComponent],
  imports: [
    CommonModule
  ],
  exports: [MipLottieComponent]
})
export class MipLottieModule {
}
