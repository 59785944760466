import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  I18nModule,
  MipLoadingModule,
  SideMenuModule,
  TopMenuBarModule,
} from '@lib/gc-common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { TermsComponent } from './modules/terms/terms.component';

@NgModule({
  declarations: [AppComponent, TermsComponent],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'serverApp',
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    I18nModule,
    MatSnackBarModule,
    TopMenuBarModule,
    MatSidenavModule,
    SideMenuModule,
    MipLoadingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
