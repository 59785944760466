import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mip-divider',
  templateUrl: './mip-divider.component.html',
  styleUrls: ['./mip-divider.component.scss']
})
export class MipDividerComponent implements OnInit {

  @Input() mipColor: string = null;
  @Input() labelColor: string = null;
  @Input() lineColor: string = this.labelColor || null;
  @Input() bdHeight = 1;
  @Input() textColor: string = null;
  // @Input() mipBgColor: string = null;
  // @Input() mipTextColor: string = null;

  labelClasses = [];
  lineClasses = [];

  constructor() {
  }

  ngOnInit(): void {

    /*if (this.mipBgColor) {
     this.labelClasses.push(`mip-color--bg-${this.mipBgColor}`);
     }

     if (this.mipTextColor) {
     this.labelClasses.push(`mip-color--text-${this.mipTextColor}`);
     }

     if (this.mipBgColor) {
     this.lineClasses.push(`mip-color--bg-${this.mipBgColor}`);
     }*/

  }

}
