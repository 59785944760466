import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';

import { MipTranslateService } from './mip-translate.service';

@Directive({
  selector: '[mipTranslate]'
})
export class MipTranslateDirective implements OnInit, OnChanges, OnDestroy {
  
  @Input() mipTranslate: string;
  @Input() translateValues: object = {};
  
  observerHelper = new ObserverHelper();
  
  constructor(
    private translate: TranslateService,
    private elRef: ElementRef,
    // private routerService: RouterService,
    private mipTranslateService: MipTranslateService
  ) {
  }
  
  ngOnInit() {
    
    this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);
    
    this.observerHelper.addSubscription(
      this.translate.onLangChange.subscribe(() => {
        this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);
      })
    );
    
  }
  
  ngOnChanges() {
    this.mipTranslateService.doTranslation(this.mipTranslate, this.translateValues, this.elRef.nativeElement);
  }
  
  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

}
