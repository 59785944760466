<!-- POSTS LIST -->
<mip-within-viewport
  class="feed-posts-desktop"
  *ngIf="sections.length"
  [mipOnReachingBottom]="sections.length"
  [bottomBounce]="1400"
  (onReachingBottom)="reachEnd()"
  [viewports]="viewportsSettings"
>

  <mip-within-viewport-child
    [id]="section.id"
    class="__section-container"
    [class.--is-attached]="section.isAttached"
    [class.--is-into-viewport]="section.isIntoViewport"
    [class.--into-top-bounce]="section.isIntoTopBounceLimit"
    scrollBehavior="auto"
    [mipScrollIntoView]="initialUrlPostId === section.model.id"
    [childData]="section"
    *ngFor="let section of sections; let $index = index"
  >

    <!--<div class="__logs" *ngIf="!disableLogs">
      <p>section: {{section.id}}</p>
      <p [style.color]="section.isAttached ? 'green' : 'red'">isAttached: {{section.isAttached}}</p>
      <p [style.color]="section.isIntoViewport ? 'green' : 'red'">isIntoViewport: {{section.isIntoViewport}}</p>
      <p [style.color]="section.isIntoTopBounceLimit ? 'green' : 'red'">isIntoTopBounceLimit: {{section.isIntoTopBounceLimit}}</p>
    </div>-->

    <mip-feed-posts-skeleton
      class="__post-skeleton"
      [class.--hide]="section.isAttached"
    ></mip-feed-posts-skeleton>

    <!-- POST MAIN COMPONENT -->
    <mip-post-view
      class="__post-view-container"
      *ngIf="section.isAttached"
      [postModel]="section.model"
      [isModal]="isModal"
      [isIntoViewport]="section.isIntoViewport"
      [isIntoTopBounceLimit]="section.isIntoTopBounceLimit"
      [isMoving]="section.isMoving"
      [postVideoAdType]="section.postVideoAdType"
      [isAdEnable]="section.isAdEnable"
      [postIndex]="section.index"
      [enableLastSeen]="true"
      [swiperNavigation]="isDesktop"
      [isFirstSlide]="$index === 0"
      [isLastSlide]="$index === (sections.length - 1)"
      (onPostAdFill)="_onPostAdFill($event)"
      (onPostAdHide)="_onPostAdHide($event)"
      (onPostHeaderToggle)="_onPostHeaderToggle($event)"
      (onToggleComments)="_onToggleComments()"
      (onToggleShare)="_onToggleShare()"
      (onPostAdPlayed)="_onPostAdPlayed()"
      (onPostAdEnded)="_onPostAdEnded()"
    ></mip-post-view>

  </mip-within-viewport-child>

</mip-within-viewport>

<!-- LOADING -->
<!--
<div
  class="__main-loading"
  *ngIf="loading"
>
  <mip-loading
    color="primary"
    size="5x"
  ></mip-loading>
</div>
-->

<mip-feed-posts-skeleton
  class="__skeleton-loading"
  *ngIf="loading"
  [animated]="true"
></mip-feed-posts-skeleton>

<!-- DOWNLOAD-APP message -->
<mip-download-app-see-more
  *ngIf="sections.length >= postsListLimit"
></mip-download-app-see-more>
