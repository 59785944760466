import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

import { CommentInterface } from './comment.interface';

export class CommentModel extends BaseModel implements CommentInterface {

  id = null; // api
  message = null; // api
  createdAt = null; // api
  userStats = null; // api
  stats = null; // api
  author = null; // api

  constructor(model) {
    super(model);
    this.fill(model);
  }

  override afterFill(model) {
    this.author = ModelFactoryService.instance.userFactory.build(model.user);
  }

  /**
   * Method to return the user posts
   */
  async doReaction(reactionType): Promise<any> {
    console.log('comment.model->doReaction(): reactionType', reactionType);

    try {

      const userReaction = {
        id: null,
        commentReactionType: reactionType
      };

      this.userStats = {
        reaction: userReaction
      };

      const response = await ResourceService.instance.post('api', {
        resource: 'comments',
        path: `/comment/${this.id}/reactions`,
        data: {
          commentReactionType: reactionType
        }
      });

      userReaction.id = response.id;
      console.log('comment.model->doReaction(): response', response);

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method to return the user posts
   */
  async undoReaction(reactionType): Promise<any> {
    console.log('comment.model->undoReaction(): reactionType', reactionType);

    try {

      this.userStats = null;

      await ResourceService.instance.delete('api', {
        resource: 'comments',
        path: `/comment/${this.id}/reactions`
      });

    }
    catch (e) {
      throw e;
    }

  }

  hasReacted(reactionType) {

    if (this.userStats && this.userStats.reaction) {
      return this.userStats.reaction.commentReactionType === reactionType;
    }

    return false;

  }

}
