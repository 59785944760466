import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LinkifyDirective } from './linkify.directive';
import { LinkifyPipe } from './linkify.pipe';

@NgModule({
  declarations: [LinkifyDirective, LinkifyPipe],
  imports: [
    CommonModule
  ],
  exports: [LinkifyDirective, LinkifyPipe]
})
export class LinkifyModule {
}
