import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Directive({
  selector: '[mipLinkify]'
})
export class LinkifyDirective implements OnInit, AfterViewInit, OnChanges {

  @Input() linkifyUrls = false;
  @Input() hashtagBaseUrl: string;
  @Input() profileBaseUrl: string;
  @Input() linkifyText: string;
  @Input() linkTarget = 'self';

  @Input() mipLinkify?: {
    linkifyUrls?: boolean,
    baseUrl?: { hashtag?: string | boolean, profile?: string | boolean }
  } = null;

  hostEl = null;

  constructor(
    private hostElement: ElementRef,
    private routerService: RouterService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.hostEl = this.hostElement.nativeElement;
    // console.log('linkify.directive->constructor(): this.hostEl', this.hostEl);
  }

  ngOnInit() {

    if (this.mipLinkify === null) {
      this.mipLinkify = {};
    }

    if (this.hashtagBaseUrl) {
      this.mipLinkify.baseUrl.hashtag = this.hashtagBaseUrl;
    }

    if (this.profileBaseUrl) {
      this.mipLinkify.baseUrl.profile = this.profileBaseUrl;
    }

    // console.log('linkify.directive->ngOnInit(): this.mipLinkify', this.mipLinkify);
  }

  ngAfterViewInit() {
    // console.log('linkify.directive->ngAfterViewInit(): this.hostEl.innerHTML', this.hostEl.innerHTML);
    // this.linkify(this.hostEl.innerHTML);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('linkify.directive->ngOnChanges(): changes', changes);

    if (changes['linkifyText'] && this.hostEl) {
      this.linkify(this.hostEl.innerHTML);
    }

  }

  linkify(plainText) {
    // console.log('linkify.directive->linkify(): plainText', plainText);

    if (!plainText) {
      return false;
    }

    try {
      const tmpContainer = this.document.createElement('div');

      const linksText = plainText.match(/((@|#)[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\_\.\+]+)/g); // (^|\s)
      // console.log('linkify.directive->linkify(): linksText', linksText);

      const linksReplaced = {};

      // tslint:disable-next-line:forin
      for (const i in linksText) {

        // console.log('linkify.directive->linkify(): linksText[i] BEFORE', i, linksText[i]);
        const normalizeLinkText = linksText[i].trim(); // .normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // console.log('linkify.directive->linkify(): normalizeLinkText AFTER', i, normalizeLinkText);

        let baseSettings = '';
        let path = `/${normalizeLinkText}`;

        if (normalizeLinkText.indexOf('@') === 0) {

          if (
            this.mipLinkify &&
            this.mipLinkify.baseUrl &&
            typeof this.mipLinkify.baseUrl.profile === 'string'
          ) {
            baseSettings = this.mipLinkify.baseUrl.profile;
          }

          if (baseSettings) {

            path = `${baseSettings}/${normalizeLinkText}`;

            if (baseSettings.indexOf('http') > -1) {
              path = path.replace('@', '');
            }
          }
          else {
            path = null;
          }

        }
        else if (normalizeLinkText.indexOf('#') === 0 && this.mipLinkify.baseUrl) {
          // console.log('linkify.directive->linkify(): normalizeLinkText', normalizeLinkText);

          if (
            this.mipLinkify &&
            this.mipLinkify.baseUrl &&
            typeof this.mipLinkify.baseUrl.hashtag === 'string'
          ) {
            baseSettings = this.mipLinkify.baseUrl.hashtag;
          }

          if (baseSettings) {

            let tmpPath = normalizeLinkText.split('');
            // console.log('linkify.directive->linkify(): tmpPath', tmpPath);

            tmpPath.splice(0, 1);
            // console.log('linkify.directive->linkify(): tmpPath', tmpPath);

            tmpPath = tmpPath.join('');
            // console.log('linkify.directive->linkify(): tmpPath', tmpPath);

            if (baseSettings.indexOf('http') > -1) {
              path = `${baseSettings}${tmpPath}`;
            }
            else {
              path = `${baseSettings}/${tmpPath}`;
            }

            // console.log('linkify.directive->linkify(): path: ', path);

            // console.log('linkify.directive->linkify(): path: AFTER', path);

          }
          else {
            path = null;
          }
        }

        // console.log('linkify.directive->linkify(): linksReplaced', linksReplaced);

        if (path && !linksReplaced[path]) {
          // console.log('linkify.directive->linkify(): linksReplaced[path]: ', linksReplaced[path]);

          const rgx = new RegExp(`${linksText[i]}`, 'g'); // (${linksText[i]}\\s|${linksText[i]}$)
          // console.log('linkify.directive->linkify(): rgx', rgx, linksText[i]);

          plainText = plainText.replace(rgx, ` <a class="__linkify --internal-link" href="${path}" target="${this.linkTarget}">${normalizeLinkText}</a> `);
        }

        linksReplaced[path] = true;

      }
      // console.log('linkify.directive->linkify(): plainText', plainText);

      if (this.mipLinkify && this.mipLinkify.linkifyUrls) {

        const externalLinksText = plainText.match(/(^|\s)((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g);
        // console.log('linkify.directive->linkify(): externalLinksText', plainText, externalLinksText);

        const externalLinksTextFinal = [];

        for (const i in externalLinksText) {
          if (externalLinksTextFinal.indexOf(externalLinksText[i].trim()) === -1) {
            externalLinksTextFinal.push(externalLinksText[i].trim());
          }
        }
        // console.log('linkify.directive->linkify(): externalLinksText', externalLinksText, externalLinksTextFinal);

        const externalLinksReplaced = {};

        // tslint:disable-next-line:forin
        for (const i in externalLinksTextFinal) {

          const normalizeLinkText = externalLinksTextFinal[i].indexOf('http') > -1 ? externalLinksTextFinal[i] : 'http://' + externalLinksTextFinal[i];

          if (!externalLinksReplaced[normalizeLinkText]) {

            const rgx = new RegExp(`(^|\\s)(${externalLinksTextFinal[i]})`, 'g');
            // console.log('linkify.directive->linkify(): rgx', rgx, externalLinksTextFinal[i]);

            plainText = plainText.replace(rgx, `$1 <a class="__linkify --external-link" href="${normalizeLinkText}" target="_blank">${externalLinksTextFinal[i]}</a>`);
            // console.log('linkify.directive->linkify(): plainText', plainText);

          }

          externalLinksReplaced[normalizeLinkText] = true;
        }
      }

      tmpContainer.innerHTML = plainText;
      // console.log('linkify.directive->linkify(): tmpContainer', tmpContainer);

      const links = tmpContainer.querySelectorAll('.__linkify.--internal-link');
      // console.log('linkify.directive->linkify(): links', links);

      links.forEach((link) => {
        link['onclick'] = async (event) => {
          event.preventDefault();
          const href = link.getAttribute('href');
          const target = link.getAttribute('target');
          // console.log('linkify.directive->linkify(): href', target, href);

          if (target !== 'self') {
            window.open(href);
          }
          else {
            await this.routerService.navigateTo(href, { redirectPath: true });
          }

        };
      });

      this.hostEl.innerHTML = '';
      this.hostEl.appendChild(tmpContainer);

    }
    catch (e) {
      console.error('linkify.directive->linkify(): ERROR', e);
      throw e;
    }

  }

}
