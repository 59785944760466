import { Injectable } from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { PostsService } from '@libs/gc-common/lib/api/services/posts/posts.service';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import DebugFactory from '@libs/gc-common/lib/factories/debug.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { AdBusterService } from '@libs/gc-common/lib/services/ad-buster/ad-buster.service';
import { AltchaService } from '@libs/gc-common/lib/services/altcha/altcha.service';
import { ImaSdkFactory } from '@libs/gc-common/lib/services/ima-sdk/ima-sdk.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import Axios from 'axios';
import moment from 'moment';
import {
  Observable,
  Subject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {

  sectionStartedAt = Date.now();
  counting = {};

  _userReferral = null;
  _userReferralPromise = null;
  _sectionInterval = null;

  _clockObservable = new Subject();
  _clockObserver: Observable<any> = this._clockObservable.asObservable();

  constructor(
    private adBusterService: AdBusterService,
    private routerService: RouterService,
    private postService: PostsService,
    private userService: UserService,
    private mobileDetectService: MobileDetectService,
    private altchaService: AltchaService
  ) {

    // console.log('advertisement.component->constructor(): environmentName', environment.environmentName);

    if (utilsFactory.isBrowser && environment.disableAds !== true) {

      this.startUserSection();

      setInterval(() => {
        if (environment.environmentName !== 'production' && environment.disableAds !== true) {
          this._clockObservable.next({
            clock: this.getSectionTimerLabel(),
            adsRequestCount: this.getAllAdUnitAdRequestCount(),
            adsFilledCount: this.getAllAdUnitAdFilledCount()
          });
        }
      }, 1000);

    }

  }

  isAdDisabled(adUnitPlacement?) {
    return this.adBusterService.isAdDisabled(adUnitPlacement);
  }

  /**
   * Method to register that an adUnit has been requested (dev environment only)
   */
  registerAdUnitAdRequestCount(AdUnit) {

    // console.log('advertisement.component->registerAdUnitAdRequestCount()');

    if (!this.counting[AdUnit]) {
      this.counting[AdUnit] = {};
    }

    if (!this.counting[AdUnit].adRequest) {
      this.counting[AdUnit].adRequest = 0;
    }

    this.counting[AdUnit].adRequest += 1;
  }

  /**
   * Method to register that an adUnit has been filled (dev environment only)
   */
  registerAdUnitAdFilledCount(adUnit) {

    // console.log('advertisement.component->registerAdUnitAdFilledCount()');

    if (!this.counting[adUnit]) {
      this.counting[adUnit] = 0;
    }

    if (!this.counting[adUnit].adFilled) {
      this.counting[adUnit].adFilled = 0;
    }

    if (adUnit) {
      this.counting[adUnit].adFilled += 1;
    }

    // console.log('advertisement.service->countAds(): this.counting', adUnit, this.counting);
  }

  /**
   * Method to return the requested counting for a given adUnits name (dev environment only)
   */
  getAdUnitAdRequestCount(adUnit) {

    // console.log('advertisement.component->getAdUnitAdRequestCount()');

    try {
      if (!this.counting[adUnit]) {
        this.counting[adUnit] = {};
      }

      return this.counting[adUnit].adRequest || 0;
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return an adUnit counting that was filled (dev environment only)
   */
  getAdUnitFilledCount(adUnit) {

    // console.log('advertisement.component->getAdUnitFilledCount()');

    try {

      if (!this.counting[adUnit]) {
        this.counting[adUnit] = {};
      }

      return this.counting[adUnit].adFilled || 0;
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return all counting for all ad units requested (dev environment only)
   */
  getAllAdUnitAdRequestCount() {

    // console.log('advertisement.component->getAllAdUnitAdRequestCount()');

    let countAds = 0;

    // tslint:disable-next-line:forin
    for (const i in this.counting) {
      countAds += this.counting[i].adRequest || 0;
    }

    return countAds;

  }

  /**
   * Method to return all counting for all ad units that was filled (dev environment only)
   */
  getAllAdUnitAdFilledCount() {

    // console.log('advertisement.component->getAllAdUnitAdFilledCount()');

    let countAds = 0;

    // tslint:disable-next-line:forin
    for (const i in this.counting) {
      countAds += this.counting[i].adFilled || 0;
    }

    return countAds;

  }

  /**
   * Method to returns the current ad section timer in milliseconds (dev environment only)
   */
  getSectionTimer() {
    // console.log('advertisement.component->getSectionTimer()');
    return Math.floor((Date.now() - this.sectionStartedAt) / 1000);
  }

  /**
   * Method to returns the current ad section string timer "00:00" (dev environment only)
   */
  getSectionTimerLabel() {

    // console.log('advertisement.component->getSectionTimerLabel()');

    const timer = this.getSectionTimer();
    const minutes = Math.floor(timer / 60);
    const seconds = timer - (minutes * 60);

    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

  }

  /**
   * Method to returns the clock observer (dev environment only)
   */
  startSectionClock(): Observable<{ clock, adsRequestCount, adsFilledCount }> {
    // console.log('advertisement.component->startSectionClock()');
    return this._clockObserver;
  }

  /**
   * Method to register the impression (ad-filled) on BE
   */
  async registerAdImpression(adUnitName: string, postId?: string) {
    // console.log('advertisement.service->registerAdImpression(): environment.disableAds', environment.disableAds);

    if (environment.disableAds) {
      return false;
    }

    // console.log('advertisement.service->registerAdImpression(): adUnitName', adUnitName);

    try {

      // getting the path name
      const pathname = window.location.pathname;
      // console.log('advertisement.service->registerAdImpression(): pathname', pathname);

      // getting the route params
      let routeParams = await this.routerService.getRouteParam();
      postId = postId || routeParams.postId;
      // console.log('advertisement.service->registerAdImpression(): routeParams', routeParams);

      if (!postId) {
        try {
          await new Promise((resolve, reject) => {
            let countInterval = 0;
            const interval = setInterval(async () => {

              routeParams = await this.routerService.getRouteParam();
              postId = routeParams.postId;

              // console.log('advertisement.service->registerAdImpression(): setTimeout', countInterval, postId);

              if (postId) {
                clearInterval(interval);
                resolve(postId);
              }
              else if (countInterval > 5000) {
                clearInterval(interval);
                reject(`There is no 'postId'.`);
              }

              countInterval += 50;

            }, 50);
          });
        }
        catch (e) {
          // console.log('advertisement.service->registerAdImpression(): Promise', e);
        }
      }

      // getting the postId
      postId = postId || routeParams.postId;
      // console.log('advertisement.service->registerAdImpression(): postId', postId);

      let authorId = null;

      // was the postId provided?
      if (postId) {
        const postModel = await this.postService.getPost(postId);
        // console.log('advertisement.service->registerAdImpression(): postModel', postModel);
        authorId = postModel.author.id;
      }
      else {

        postId = '0000';

        if (routeParams.userId) {
          const userModel = await this.userService.getUser(routeParams.userId);
          // console.log('advertisement.service->registerAdImpression(): userModel', userModel);
          authorId = userModel.id;
        }
        else {
          authorId = 665;
        }

      }

      const headers = {
        // 'x-path-name': pathname,
        // 'x-post-id': postId,
        // 'x-ad-unit-name': adUnitName,
        // 'x-author-id': authorId,
        // 'X-Signature': payload
      };

      try {
        const payload = await this.altchaService.getPayload();
        // console.log('advertisement.service->registerAdImpression(): payload', payload);
        headers['X-Signature'] = payload;
      }
      catch (e) {
        console.warn('advertisement.service->registerAdImpression(): WARN: Challenge not available!');
      }

      let referralId = null;

      if (this._userReferral) {
        // console.log('advertisement.service->registerAdImpression(): this._userReferral', this._userReferral);
        referralId = this._userReferral;
      }
      else if (this._userReferralPromise) {
        // console.log('advertisement.service->registerAdImpression(): this._userReferralPromise', this._userReferralPromise);
        referralId = await this._userReferralPromise;
      }

      if (referralId) {
        headers['x-user-referral'] = referralId;
      }

      // console.log('advertisement.service->registerAdImpression(): headers', headers);

      DebugFactory.log(`BidRequested ${adUnitName}:`, postId, ['Sending impression']);

      Axios.post(`${environment.apis.impressionsApi2}/event/user.impressions`, [{
        entity: postId ? 'post' : 'profile',
        entity_id: postId || authorId,
        entity_owner_id: authorId,
        impression_owner_id: referralId || authorId,
        author: authorId,
        ad_unit: adUnitName,
        platform: 'web',
        duration: 3000,
        timestamp: new Date().toISOString()
      }], {
        headers
      });

      /*// registering the impression on BE
       await Axios.get(`${environment.apis.impressionsApi}/ad/${postId}`, {headers});
       DebugFactory.log(`BidRequested ${adUnitName}:`, postId, ['BE has responded']);*/

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to deal with somethings before making the ad request
   */
  async registerAdSlot(adUnitName: string, slotId: string, postId?: string) {

    // console.log('advertisement.service->registerAdSlot(): adUnitName', adUnitName);

    try {

      if (environment.disableAds) {
        return false;
      }

      // counting the adRequest
      this.registerAdUnitAdRequestCount(adUnitName);

      // starting the BID-REQUEST/AD-REQUEST process
      await this.adBusterService.registerAdSlot(adUnitName, slotId);
      this.adBusterService.onAdSlotViewable(slotId).then(async () => {

        // console.log('advertisement.service->registerAdSlot(): onAdSlotViewable', adUnitName, slotId);

        try {
          // counting the AD_FILL
          await this.registerAdImpression(adUnitName, postId);
        }
        catch (e) {
          console.error('advertisement.service->registerAdSlot(): ERROR', e);
        }

      });

      // registering the AD-FILL
      this.registerAdUnitAdFilledCount(adUnitName);

    }
    catch (e) {
      // NO-FILL/ERROR
      throw e;
    }
  }

  /**
   * Method to deal with somethings before making the video ad request
   */
  registerVideoAdElement(
    adUnitName: string,
    videoEl: HTMLVideoElement,
    adContainer?: HTMLDivElement,
    allowRestorePLayer = true
  ): Observable<{ status, ima: ImaSdkFactory }> {
    try {

      // console.log('advertisement.service->registerVideoAdElement(): adUnitName', adUnitName);

      return new Observable(observer => {

        if (environment.disableAds) {
          observer.error(`ADs is disabled`);
        }

        // counting the adRequest
        this.registerAdUnitAdRequestCount(adUnitName);

        // starting the video BID-REQUEST/AD-REQUEST process
        this.adBusterService.registerVideoAdElement(
          adUnitName,
          videoEl,
          adContainer,
          allowRestorePLayer
        ).subscribe(async response => {

          // was it filled?
          if (response.status === 'AD_HAS_FILLED') {

            // registering the AD-FILL
            this.registerAdUnitAdFilledCount(adUnitName);

            try {
              // counting the AD_FILL
              await this.registerAdImpression(adUnitName);
            }
            catch (e) {
              console.error('advertisement.service->registerAdSlot(): ERROR', e);
            }

          }

          observer.next(response);

        }, error => {
          observer.error(error);
        });

      });
    }
    catch (e) {
      // NO-FILL/ERROR
      throw e;
    }
  }

  /**
   * Method to return the user section (if valid)
   */
  getUserSection(): { created_at: string, data: string } {
    try {

      const storageUserReferral = window.localStorage.getItem('mip-advertisement-user-referral');
      // console.log('advertisement.component->getUserSection(): storageUserReferral', storageUserReferral);

      if (storageUserReferral) {

        const userReferral: { created_at: string, data: string } = JSON.parse(storageUserReferral);
        const createdAt = moment(userReferral.created_at);
        const now = moment();
        const diffDates = now.diff(createdAt, 'seconds');

        // console.log('advertisement.component->getUserSection(): diffDates', diffDates);

        if (diffDates < 60) {
          // console.log('advertisement.component->getUserSection(): RETURN section');
          return userReferral;
        }
        else {
          // console.log('advertisement.component->getUserSection(): DELETE section');
          window.localStorage.removeItem('mip-advertisement-user-referral');
          return null;
        }

      }

    }
    catch (e) {

    }
  }

  /**
   * Method to start the user section
   */
  startUserSection(userData?: { created_at: string, data: string }) {
    try {
      // console.log('advertisement.service->startUserSection(): userData 1', userData);

      userData = userData || this.getUserSection();

      if (!userData) {
        return false;
      }

      // console.log('advertisement.service->startUserSection(): userData 2', userData);

      if (this._sectionInterval) {
        clearInterval(this._sectionInterval);
      }

      this._userReferral = userData.data;

      window.localStorage.setItem('mip-advertisement-user-referral', JSON.stringify(userData));

      // starting a interval to refresh the section every 5 seconds
      this._sectionInterval = setInterval(() => {

        // console.log('advertisement.service->startUserSection(): --------------------------------');
        const userDataLocalStorage = this.getUserSection();
        // console.log('advertisement.service->startUserSection(): userDataLocalStorage', userDataLocalStorage);
        // console.log('advertisement.service->startUserSection(): utilsFactory.isTabActive', utilsFactory.isTabActive);

        if (!userDataLocalStorage) {
          clearInterval(this._sectionInterval);
        }
        else {
          if (utilsFactory.isTabActive) {
            userDataLocalStorage.created_at = new Date().toISOString();
            // console.log('advertisement.service->startUserSection(): UPDATED', userDataLocalStorage);
            window.localStorage.setItem('mip-advertisement-user-referral', JSON.stringify(userDataLocalStorage));
          }
        }

        // console.log('advertisement.service->startUserSection(): --------------------------------');

      }, 5000);
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to save the user identifier. Can be the id or the username
   */
  async registerUserSection(userIdentifier: string, postId?: string) {
    // console.log('advertisement.service->registerUserSection(): userIdentifier', userIdentifier);
    try {

      if (utilsFactory.isBrowser) {

        if (!userIdentifier) {
          throw new Error(`The user id/username must be provided`);
        }

        // console.log('advertisement.service->registerUserSection(): new Promise()');
        this._userReferralPromise = new Promise(async resolve => {

          let userModel = null;
          let userId = null;

          if (postId) {
            const postModel = await this.postService.getPost(postId);
            // console.log('advertisement.service->registerUserSection(): userModel', userModel);

            if (postModel.author.id.toString() === userIdentifier.toString() || postModel.author.username === userIdentifier) {
              userModel = postModel.author;
            }
          }

          if (!userModel) {
            userModel = await this.userService.getUser(userIdentifier);
          }

          if (!userModel) {
            throw new Error(`The userModel is not valid`);
          }

          userId = userModel.id;

          // console.log('advertisement.service->registerUserSection(): userModel', userModel);

          const userSection = this.getUserSection();
          // console.log('advertisement.service->registerUserSection(): userSection', userSection);

          const referrer = document.referrer;
          // console.log('advertisement.service->registerUserSection(): referrer', referrer);

          if (userSection && userSection.data !== userId || referrer.indexOf('gotchosen.com') > -1) {

            if (userSection) {
              // console.log('advertisement.service->registerUserSection(): THERE IS AN ACTIVE SECTION ALREADY');
            }
            else {
              // console.log('advertisement.service->registerUserSection(): WIL NOT STARTING A NEW SECTION');
            }

            return resolve(null);

          }

          // console.log('advertisement.service->registerUserSection(): STARTING NEW SECTION');

          this.startUserSection({
            created_at: new Date().toISOString(),
            data: userId
          });

          resolve(userId);

          setTimeout(() => {
            this._userReferralPromise = null;
          }, 1000);

        });

      }

    }
    catch (e) {
      console.error('advertisement.service->startUserSection(): ERROR', e);
      throw e;
    }
  }

}
