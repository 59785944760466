<div class="mip-alert" [class]="getClasses()" [style.border-radius]="borderRadios">
  <div class="__mip-alert-container" [style.align-items]="alignItems" [style.justify-content]="justifyContent">
    <div class="__mip-alert-icon">
      <ng-content select="mip-icon,mat-icon,mip-loading,mip-avatar,round-progress"></ng-content>
    </div>
    <div class="__mip-alert-content" [class]="'--text-align--' + this.textAlign" [class.--no-width]="justifyContent">
      <ng-content select="p,span,div,small"></ng-content>
    </div>
  </div>
  <div class="__mip-alert-actions" [class]="'--text-align--' + this.textAlign">
    <ng-content select="a,button"></ng-content>
  </div>
  <div class="__mip-alert-footer">
    <ng-content select="mip-alert-footer,[mip-alert-footer]"></ng-content>
  </div>
</div>
