import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

import { FeedPostSectionInterface } from './feed-post-section.interface';

@Component({
  selector: 'mip-feed-posts',
  templateUrl: './feed-posts.component.html',
  styleUrls: ['./feed-posts.component.scss']
})
export class FeedPostsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() postsList: Array<PostModel>;
  @Input() postCount = 0;
  @Input() reachedBottomCountLimit = 3;
  @Input() isModal = false;
  @Input() loading = false;
  @Input() postsLimitAuthenticated = 60;
  @Input() postsLimitAnonymous = 40;
  @Input() disableListLimit = false;
  @Input() isDetailsView = false;

  @Output() onReachedTop = new EventEmitter<PostModel>();
  @Output() onReachedBottom = new EventEmitter<PostModel>();
  @Output() onSlideChange = new EventEmitter<object>();

  @Output() onPostHeaderToggle = new EventEmitter();
  @Output() onToggleComments = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();

  @Output() onPostAdFill = new EventEmitter();
  @Output() onPostAdHide = new EventEmitter();
  @Output() onPostAdPlayed = new EventEmitter();
  @Output() onPostAdEnded = new EventEmitter();

  postsListLimit = 30;
  lastPostsCount = 0;

  isMobile = true;
  isDesktop = false;

  sections: Array<FeedPostSectionInterface> = [];
  sectionsControl = {};

  firstNativeAdOffset = environment.environmentName === 'production' ? utilsFactory.randomBetweenMinMax(1, 2) : 0;
  postsAttachedAllowed = 2;

  observerHelper = new ObserverHelper();

  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private mobileDetectService: MobileDetectService,
    private userService: UserService
  ) {

    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();

    this.observerHelper.addSubscription(
      this.routerService.onRouteChange().subscribe(({ queryParams }) => {
        // console.log('feed-posts.component->constructor(): queryParams', queryParams);

        this.isDetailsView = /*this.isDetailsView || */queryParams.details === 'true';
        // console.log('feed-posts.component->constructor(): this.isDetailsView', this.isDetailsView);
      })
    );

  }

  async ngOnInit() {

    const loggedInUser = await this.userService.getLoginUser();

    if (this.disableListLimit) {
      this.postsListLimit = 1000000;
    }
    else if (loggedInUser) {
      if (loggedInUser.isModerator) {
        this.postsListLimit = 5000;
      }
      else {
        this.postsListLimit = this.postsLimitAuthenticated;
      }
    }
    else {
      this.postsListLimit = this.postsLimitAnonymous;
    }

  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['postCount']) {
      // console.log('feed-posts.component->ngOnChanges(): changes.postCount', changes['postCount']);

      this.postsList.forEach((model, index) => {
        this.addPostSection(model, index, 'post');
      });

      if (
        changes['postCount'].firstChange &&
        this.sections.length &&
        utilsFactory.isSSR
      ) {
        // console.log('feed-posts.component->ngOnChanges(): this.sections', this.sections);
        this.sections[0].isAttached = true;
        this.sections[0].isIntoViewport = true;
      }
    }
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  addPostSection(model, index, type) {
    try {
      // console.log('feed-posts.component->addPostSection(): ', ['id', model.id], ['type', type]);

      if (this.sectionsControl[model.id] !== true) {

        const randomNativeAdOffset = utilsFactory.randomBetweenMinMax(6, 8);
        // console.log('feed-posts.component->addPostSection(): randomNativeAdOffset', randomNativeAdOffset);

        this.sectionsControl[model.id] = true;

        const currentSection: FeedPostSectionInterface = {
          id: 'section-' + model.id,
          isAdEnable: false,
          type,
          model,
          isAttached: false,
          isIntoViewport: false,
          nextSection: null,
          prevSection: null,
          isMoving: false,
          index
        };
        // console.log('feed-posts.component->addPostSection(): currentSection', currentSection);

        // console.log('feed-posts.component->addPostSection(): this.sections.length', this.sections.length, this.firstNativeAdOffset);
        if (this.sections.length === this.firstNativeAdOffset) {
          currentSection.isAdEnable = true;
        }

        this.sections.push(currentSection);

        const currentSectionIndex = this.sections.indexOf(currentSection);
        const prevSection = this.sections[currentSectionIndex - 1] || null;

        if (prevSection) {
          prevSection.nextSection = currentSection;
          currentSection.prevSection = prevSection;
        }

        this.sections.forEach((item, indexItem) => {
          item.index = indexItem;
        });

        let countPrevPosts = 0;
        for (let i = (this.sections.length - 1); i >= 0; i--) {

          // console.log('feed-posts.component->addPostSection(): this.sections[i]', i, countPrevPosts, this.sections[i].isAdEnable);

          if (this.sections[i].isAdEnable) {
            break;
          }

          countPrevPosts += 1;
        }

        // console.log('feed-posts.component->addPostSection(): countPrevPosts', countPrevPosts, randomNativeAdOffset);

        if (countPrevPosts >= randomNativeAdOffset) {
          currentSection.isAdEnable = true;
        }

        // console.log('feed-posts.component->addPostSection(): this.sections', this.sections);
        // console.log('feed-posts.component->addPostSection(): this.sections ------------------------------- ');

      }
    }
    catch (e) {
      console.error('feed-posts.component->addPostSection(): type, model', type, model);
      console.error('feed-posts.component->addPostSection(): ERROR', e);
      throw e;
    }
  }

  _onReachedTop(model) {
    this.onReachedTop.emit(model);
  }

  _onReachedBottom(model) {
    this.onReachedBottom.emit(model);
  }

  _onSlideChange(section) {
    this.onSlideChange.emit(section);
  }

  _onPostAdFill(type) {
    this.onPostAdFill.emit(type);
  }

  _onPostAdHide(type) {
    this.onPostAdHide.emit(type);
  }

  _onPostHeaderToggle(isOpen) {
    this.onPostHeaderToggle.emit(isOpen);
  }

  _onToggleComments() {
    this.onToggleComments.emit();
  }

  _onToggleShare() {
    this.onToggleShare.emit();
  }

  _onPostAdPlayed() {
    this.onPostAdPlayed.emit();
  }

  _onPostAdEnded() {
    this.onPostAdEnded.emit();
  }

}
