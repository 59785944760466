import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ContestWinnerModel } from '@libs/gc-common/lib/api/models/contest-winner';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

interface FeedGridInterface {
  cols: number;
  rows: number;
  model: PostModel;
  color?: string;
}

@Component({
  selector: 'mip-feed-grid',
  templateUrl: './feed-grid.component.html',
  styleUrls: ['./feed-grid.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedGridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  
  @ViewChild('containerElement') containerElement: ElementRef;
  
  @Input() backTopMarginTop: number = null;
  @Input() hashtagModel: HashtagModel = null;
  @Input() downloadAppSeeMoreLabel = null;
  @Input() enableVideoPreview = null;
  @Input() columns = 4;
  @Input() padding = 2;
  @Input() orientation = '';
  
  @Input() page = 1;
  @Input() loading = true;
  @Input() listLayout: Array<number> = null;
  @Input() postsList: Array<PostModel> = [];
  @Input() postsWinners: {
    winner: ContestWinnerModel,
    second: ContestWinnerModel,
    joker: ContestWinnerModel
  } = null;
  @Input() postsCount = 0;
  @Input() baseRoute: Array<string> | string = null;
  @Input() customParams: object = null;
  @Input() bounce = 300;
  @Input() enableAvatarCrowns = false;
  @Input() enableSampleLabel = this.enableAvatarCrowns;
  
  // post-view component
  @Input() showViews = true;
  @Input() showPlays = false;
  @Input() showThemeIcon = true;
  @Input() avatarCrowns = null;
  @Input() showSampleLabel = false;
  @Input() showAvatar = false;
  @Input() avatarBorderColor: string = null;
  @Input() avatarBorderWidth = null;
  @Input() avatarSize = 40;
  @Input() avatarCrown: string = null;
  
  @Input() tilesLimitAuthenticated = 60;
  @Input() tilesLimitAnonymous = 30;
  @Input() lastResponseCount = 0;
  
  @Input() audioId = 0;
  
  // output callback
  @Output() onReachedBottom = new EventEmitter();
  
  loggedInUser: UserModel = null;
  
  lastPostsListInstance = null;
  
  tilesLimit = 30;
  tiles: Array<FeedGridInterface> = [];
  containerEl = null;
  scrollTimeout = null;
  scrollInterval = null;
  
  isMobile = true;
  isDesktop = false;
  
  hasSamplePost = false;
  lastSeenTimeout = null;
  scrollTop = false;
  
  constructor(
    private mobileDetectService: MobileDetectService,
    private userService: UserService
  ) {
    
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
    
    if (utilsFactory.isBrowser) {
      this.bounce = window.innerHeight;
    }
    
  }
  
  async ngOnInit() {
    // console.log('feed-grid.component->ngOnInit(): this.postsWinners', this.postsWinners);
    
    this.loggedInUser = await this.userService.getLoginUser();
    
    if (this.loggedInUser) {
      if (this.loggedInUser.isModerator) {
        this.tilesLimit = 50000;
      }
      else {
        this.tilesLimit = this.tilesLimitAuthenticated;
      }
    }
    else {
      this.tilesLimit = this.tilesLimitAnonymous;
    }
    
  }
  
  ngOnChanges(changes: SimpleChanges) {
    
    // console.log('feed-grid.component->ngOnChanges(): this.loading', this.loading);
    // console.log('feed-grid.component->ngOnChanges(): this.postsList.length', this.postsList.length);
    // console.log('feed-grid.component->ngOnChanges(): this.lastResponseCount', this.lastResponseCount);
    // console.log('feed-grid.component->ngOnChanges(): this.lastPostsListInstance', this.lastPostsListInstance === this.postsList);
    
    if (this.postsList.length) {
      
      if (this.page === 1 || this.lastPostsListInstance !== this.postsList) {
        this.tiles = [];
      }
      
      if (this.enableAvatarCrowns && this.postsWinners && this.lastPostsListInstance === null) {
        
        const titles = [];
        
        if (this.postsWinners.winner) {
          titles.push(this.postsWinners.winner.post);
        }
        if (this.postsWinners.second) {
          titles.push(this.postsWinners.second.post);
        }
        if (this.postsWinners.joker) {
          titles.push(this.postsWinners.joker.post);
        }
        
        this.addTiles(titles);
      }
      
      this.lastPostsListInstance = this.postsList;
      
      this.addTiles(this.postsList);
      
      // console.log('feed-grid.component->ngOnChanges(): this.tiles', this.tiles);
      
      this.lastSeenTimeout = setTimeout(() => {
        // console.log('feed-grid.component->ngOnChanges(): this.postModel.lastSeen->setTimeout()');
        
        for (const post of this.postsList) {
          post.lastSeen = false;
          // console.log('feed-grid.component->ngOnChanges(): this.postModel.lastSeen', post.id, post.lastSeen);
        }
      }, 3000);
      
    }
    else {
      this.tiles = [];
    }
    
    // console.log('feed-grid.component->ngOnChanges(): this.tiles', this.postsList.length, this.tiles.length);
    
  }
  
  ngAfterViewInit() {
    this.containerEl = this.containerElement.nativeElement;
    // console.log('feed-grid.component->ngAfterViewInit(): this.containerEl', this.containerEl);
  }
  
  ngOnDestroy() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
    if (this.lastSeenTimeout) {
      clearTimeout(this.lastSeenTimeout);
    }
    if (this.postsList.length) {
      for (const post of this.postsList) {
        post.lastSeen = false;
        // console.log('feed-grid.component->ngOnDestroy(): this.postModel.lastSeen', post.id, post.lastSeen);
      }
    }
  }
  
  onReachingBottom() {
    
    // console.log('feed-grid.component->onReachingBottom(): this.tilesLimit', this.tilesLimit, this.tiles.length);
    
    if (this.tiles.length < this.tilesLimit) {
      this.onReachedBottom.emit();
    }
  }
  
  addTiles(postsList: PostModel[]) {
    
    let countBigger = 0;
    let indexBigger = this.listLayout ? this.listLayout[0] : null;
    let indexPosition = 0;
    
    postsList.forEach((post) => {
      
      const item = this.tiles.find(tile => tile.model === post);
      
      if (item) {
        return;
      }
      
      let isBigger = false;
      console.log('isBigger', countBigger, indexBigger);
      
      if (indexBigger !== null) {
        if (countBigger === indexBigger) {
          countBigger = 1;
          isBigger = true;
          
          if (indexPosition === 1) {
            indexPosition = 2;
            indexBigger = this.listLayout[2];
          }
          else {
            indexPosition = 1;
            indexBigger = this.listLayout[1];
          }
        }
        else {
          console.log('count', countBigger);
          countBigger++;
        }
      }
      // console.log('feed-grid.component->ngOnChanges() isBigger', isBigger);
      
      // console.log('feed-grid.component->ngOnChanges(): this.tiles.push', post);
      this.tiles.push({
        cols: isBigger ? 4 : 2,
        rows: isBigger ? 4 : 2,
        model: post
      });
      
      console.log('-----------------------------------');
      
    });
  }
  
  getAvatarCrown(postIndex, postModel) {
    
    if (this.hasSamplePost === false) {
      this.hasSamplePost = postModel.sample;
    }
    
    if (this.enableAvatarCrowns) {
      
      switch (postModel.contest_role) {
        case 'winner':
          return 'king';
        case 'second':
          return 'prince';
        case 'joker':
          return 'joker';
        default:
          return null;
      }
    }
    
    return null;
    
  }
  
  scrollToTop() {
    this.scrollTop = true;
    
    setTimeout(() => {
      this.scrollTop = false;
    }, 50);
  }
  
}
