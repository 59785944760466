<button
  mat-icon-button
  class="follow-user"
  [class.--just-followed]="userModel.hasJustFollowed"
  [class.--hide]="userModel.isFollowing || userModel.isAuthenticated"
  *ngIf="!userModel.isAuthenticated && isAuthenticated"
  (click)="userModel.follow()"
>
  <mip-icon icon="plus" class="__follow-icon" size="1x"></mip-icon>
  <mip-icon icon="check" class="__follow-icon --has-follow" size="1x"></mip-icon>
</button>
