import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipToolbarModule } from '@libs/gc-common/lib/components/mip-toolbar/mip-toolbar.module';

import { MipCardContentComponent } from './mip-card-content/mip-card-content.component';
import { MipCardFooterComponent } from './mip-card-footer/mip-card-footer.component';
import { MipCardHeaderComponent } from './mip-card-header/mip-card-header.component';
import { MipCardComponent } from './mip-card.component';

@NgModule({
  declarations: [
    MipCardComponent,
    MipCardHeaderComponent,
    MipCardContentComponent,
    MipCardFooterComponent
  ],
  imports: [
    CommonModule,
    MipToolbarModule
  ],
  exports: [
    MipCardComponent,
    MipCardHeaderComponent,
    MipCardContentComponent,
    MipCardFooterComponent
  ]
})
export class MipCardModule {
}
