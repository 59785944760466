import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { TranslateModule } from '@ngx-translate/core';

import { FloatingButtonComponent } from './floating-button.component';

@NgModule({
  declarations: [FloatingButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule,
    MipIconModule,
    TranslateModule
  ],
  exports: [FloatingButtonComponent]
})
export class FloatingButtonModule {
}
