import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { LinkifyModule } from '@libs/gc-common/lib/directives/linkify/linkify.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { MipSeeMoreComponent } from './mip-see-more.component';

@NgModule({
  declarations: [MipSeeMoreComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ColorModule,
    MipTranslateModule,
    LinkifyModule
  ],
  exports: [MipSeeMoreComponent]
})
export class MipSeeMoreModule {
}
