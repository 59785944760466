import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import DebugFactory from '@libs/gc-common/lib/factories/debug.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { AdvertisementService } from '@libs/gc-common/lib/features/advertisement/advertisement.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { AdBusterService } from '@libs/gc-common/lib/services/ad-buster/ad-buster.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

interface AdNativeSlotInterface {
  label: string;
  id: string;
  hasFilled: boolean;
  adSlotId: string;
}

@Component({
  selector: 'mip-ad-native',
  templateUrl: './ad-native.component.html',
  styleUrls: ['./ad-native.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdNativeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() logs = false;
  @Input() postIndex;
  @Input() disableAd;
  @Input() adUnitName = 'Post-NativeAd';
  @Input() adSlotId: string;
  @Input() enableHeader = true;
  @Input() bgColor = '#000';
  @Input() enableCanvas = false;

  @Output() onAdFill = new EventEmitter<object>();
  @Output() onClose = new EventEmitter<object>();

  sectionTimer = '00:00';
  totalAdRequest = 0;
  totalAdFilled = 0;

  refreshTimeout = 15000;

  assetsPath = environment.assetsPath;

  slotA: AdNativeSlotInterface = {
    label: 'Slot-A',
    id: utilsFactory.generateGUID(),
    hasFilled: false,
    adSlotId: 'ad-native-slot-a'
  };

  slotB: AdNativeSlotInterface = {
    label: 'Slot-B',
    id: utilsFactory.generateGUID(),
    hasFilled: false,
    adSlotId: 'ad-native-slot-b'
  };

  isBrowser = utilsFactory.isBrowser;

  isRequestingAnAd = false;
  adUnitCodesAvailable = null;
  lastAdRequestTime = null;
  nextAdRequestTimeout = null;
  pauseAdRequest = false;
  hasFilledOnce = false;

  isMobile = true;
  isDesktop = false;

  observerHelper = new ObserverHelper();

  constructor(
    private adBusterService: AdBusterService,
    private advertisementService: AdvertisementService,
    private mobileDetectService: MobileDetectService
  ) {
    this.adUnitCodesAvailable = this.adBusterService.getAdUnitsBySize(['fluid']);
    console.log('ad-native.component->constructor(): this.adUnitCodesAvailable', this.adUnitCodesAvailable);

    if (this.disableAd === undefined) {
      this.disableAd = this.advertisementService.isAdDisabled('disablePostAd');
    }

    console.log('ad-native.component->constructor(): this.disableAd', this.disableAd);

    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();

    console.log('ad-native.component->constructor(): this.slotA', this.postIndex, this.slotA);

  }

  ngOnInit() {

    this.observerHelper.addSubscription(
      utilsFactory.onTabChangingActiveState().subscribe(isTagFocused => {
        console.log('ad-native.component->ngOnInit(): isTagFocused', isTagFocused);
        this.pauseAdRequest = !isTagFocused;
      })
    );

    if (this.isBrowser && this.logs) {
      this.observerHelper.addSubscription(
        this.advertisementService.startSectionClock().subscribe(section => {
          this.sectionTimer = section.clock;
          this.totalAdRequest = section.adsRequestCount;
          this.totalAdFilled = section.adsFilledCount;
        })
      );
    }

    if (this.adSlotId) {
      this.slotA.adSlotId = `${this.adSlotId}-slot-a`;
      console.log('ad-native.component->ngOnInit(): this.slotA.adSlotId', this.slotA.adSlotId);
    }

  }

  ngAfterViewInit() {
    console.log('ad-native.component->ngAfterViewInit(): adSlot1', document.querySelector(`#${this.slotA.id}`));
    console.log('ad-native.component->ngAfterViewInit(): adSlot2', document.querySelector(`#${this.slotB.id}`));

    // rendering AD on slotA

    this.renderAd(this.slotA, this.slotB, 0);
  }

  ngOnDestroy() {
    console.log('ad-native.component->ngOnDestroy()');
    clearTimeout(this.nextAdRequestTimeout);
    this.observerHelper.unsubscribeAll();
  }

  /**
   * Method to deal slots switching and AD rendering
   */
  /**
   * Method to deal slots switching and AD rendering
   */
  async renderAd(slot1: AdNativeSlotInterface, slot2: AdNativeSlotInterface, adUnitIndex) {
    if (utilsFactory.isBrowser && !this.disableAd) {
      try {
        console.log('ad-native.component->renderAd(): -----------------------------');
        console.log('ad-native.component->renderAd():', this.isRequestingAnAd, this.disableAd);

        if (this.isRequestingAnAd || this.disableAd === true) {
          return;
        }

        // setting a flag to prevent multiples ad request ad once
        this.isRequestingAnAd = true;

        // should we pause the ad request?
        // await this.waitTabToBeFocused();

        let nextAdUnitIndex;

        if (adUnitIndex === (this.adUnitCodesAvailable.adUnits.length - 1)) { // === 3
          nextAdUnitIndex = 0;
        }
        else {
          // tslint:disable-next-line:radix
          nextAdUnitIndex = parseInt(adUnitIndex) + 1;
        }

        console.log(`ad-native.component->renderAd(): adUnitIndex`, adUnitIndex, this.adUnitCodesAvailable.adUnits[adUnitIndex]);
        console.log(`ad-native.component->renderAd(): nextAdUnitIndex`, nextAdUnitIndex, this.adUnitCodesAvailable.adUnits[nextAdUnitIndex]);

        try {

          console.log(`ad-native.component->renderAd(): slot1.id`, slot1.id);

          await utilsFactory.waitToBeTrue('ad-native.component', () => !!document.querySelector(`#${slot1.id}`));

          const slotElement = document.querySelector(`#${slot1.id}`);
          console.log(`ad-native.component->renderAd(): slotElement`, adUnitIndex, slotElement);

          console.log(`ad-native.component->renderAd(): slot1.id`, adUnitIndex, slot1.id);
          this.lastAdRequestTime = Date.now();

          slotElement.setAttribute('data-adslotid', slot1.adSlotId);

          await this.advertisementService.registerAdSlot(this.adUnitCodesAvailable.adUnits[adUnitIndex], slot1.id);
          DebugFactory.success('styled', `ad-native.component->renderAd(): ${slot1.label} (${this.adUnitCodesAvailable.adUnits[adUnitIndex]}) has FILLED`);
          console.log(`ad-native.component->renderAd(): FILLED? has FILLED`, slot1);

          // reset flag
          this.isRequestingAnAd = false;

          this.onAdFill.emit();

          slot1.hasFilled = true;
          slot2.hasFilled = false;

          document.querySelector(`#${slot2.id}`).innerHTML = '';
          slot2.id = utilsFactory.generateGUID();

          this.nextAdRequestTimeout = setTimeout(() => {
            DebugFactory.info('styled', `ad-native.component->renderAd(): Refreshing ${slot2.label} (${this.adUnitCodesAvailable.adUnits[nextAdUnitIndex]})`);
            this.renderAd(slot2, slot1, nextAdUnitIndex);
          }, 30000);

        }
        catch (e) {

          console.log(`ad-native.component->renderAd(): NO-FILL`);

          this.isRequestingAnAd = false;

          // if returns "e.message" from the registerAdSlot(), it means we have a JS error
          if (e['message']) {
            throw e;
          }

          DebugFactory.error('styled', `ad-native.component->renderAd(): ${slot1.label} (${this.adUnitCodesAvailable.adUnits[adUnitIndex]}) has NO-FILL`);
          document.querySelector(`#${slot1.id}`).innerHTML = '';
          slot1.hasFilled = false;

          this.nextAdRequestTimeout = setTimeout(() => {
            DebugFactory.warn('styled', `ad-native.component->renderAd(): Retrying ${slot1.label} (${this.adUnitCodesAvailable.adUnits[nextAdUnitIndex]})`);
            slot1.id = utilsFactory.generateGUID();
            this.renderAd(slot1, slot2, nextAdUnitIndex);
          }, 10000);

        }
      }
      catch (e) {
        this.isRequestingAnAd = false;
        console.error('ad-native.component->renderAd(): ERROR', e);
        throw e;
      }
    }
  }

  /**
   * Method to watch the "pauseAdRequest" to decide if we need to pause the ADs or not.
   */
  async waitTabToBeFocused(): Promise<any> {
    return new Promise(resolve => {
      const pauseInterval = setInterval(() => {

        console.log('ad-native.component->waitTabToBeFocused(): this.pauseAdRequest', this.pauseAdRequest);

        if (this.pauseAdRequest === false) {
          clearInterval(pauseInterval);
          resolve(true);
        }
      }, 100);
    });
  }

  /**
   * Method to do an AdRequest externally
   */
  refreshAd(force = false) {
    try {

      console.log('ad-native.component->refreshAd(): force', force);
      console.log('ad-native.component->refreshAd(): lastFillTime', (Date.now() - this.lastAdRequestTime), this.refreshTimeout);
      console.log('ad-native.component->refreshAd(): this.isRequestingAnAd', this.isRequestingAnAd);

      if (this.isRequestingAnAd) {
        return false;
      }

      if (force || (Date.now() - this.lastAdRequestTime) >= this.refreshTimeout) {

        clearTimeout(this.nextAdRequestTimeout);

        setTimeout(() => {

          let slot1 = this.slotA;
          let slot2 = this.slotB;

          if (this.slotA.hasFilled) {
            slot1 = this.slotB;
            slot2 = this.slotA;
          }

          console.log(`ad-native.component->refreshAd(): BEFORE`);
          this.renderAd(slot1, slot2, 0);

        }, 100);
      }

    }
    catch (e) {
      throw e;
    }
  }

  closeAd() {
    this.onClose.emit();
  }

}
