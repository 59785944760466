<button
  mat-icon-button
  class="--is-mobile"
  *ngIf="isMobile && menuOptions.length"
  [mipBottomSheet]="menuOptions"
>
  <mip-icon icon="ellipsis" [bold]="true"></mip-icon>
</button>

<button
  mat-icon-button
  class="--is-desktop"
  *ngIf="isDesktop && menuOptions.length"
  [matMenuTriggerFor]="menu"
>
  <mip-icon icon="ellipsis" [bold]="true"></mip-icon>
</button>

<mat-menu
  #menu="matMenu"
  xPosition="before"
  class="__dropmenu"
>

  <div
    class="__menu-item"
    mat-menu-item
    routerLinkActive="--active"
    [class.--divider]="option.divider"
    [class.--title]="option.title"
    *ngFor="let option of menuOptions"
  >

    <div
      class="__menu-title"
      *ngIf="option.title"
    >
      <small>{{option.title}}</small>
    </div>

    <button
      mat-button
      class="__menu-action --align-left"
      (click)="option.onSelect(option)"
      *ngIf="option.onSelect"
    >

      <span class="__icon">

        <mip-icon
          *ngIf="option.icon"
          [icon]="option.icon"
          [bold]="option.iconBold"
          size="4x"
        ></mip-icon>

        <mip-icon
          *ngIf="option.matIcon"
          [matIcon]="option.matIcon"
          [bold]="option.matIconBold"
          [matIconOutlined]="option.matIconOutlined"
          size="4x"
        ></mip-icon>

        <!--<mip-icon
          *ngIf="option.matIcon"
          class="material-icons-outlined gc-icon&#45;&#45;size-4x"
        >
          {{option.matIcon}}
        </mip-icon>-->

        <mip-icon
          *ngIf="option.addonMatIcon"
          class="__icon--addon"
          [matIcon]="option.addonMatIcon"
          [bold]="option.addonMatIconBold"
          [matIconOutlined]="option.addonMatIconOutlined"
          mipColor="primary"
          size="2x"
        ></mip-icon>

        <!--<mip-icon
          *ngIf="option.addonMatIcon"
          class="__icon&#45;&#45;addon"
        >
          {{option.addonMatIcon}}
        </mip-icon>-->
      </span>

      <span class="__label">{{option.text}}</span>

    </button>

    <a
      mat-button
      class="__menu-action --align-left"
      [routerLink]="option.path"
      *ngIf="option.path"
    >
      <ng-container *ngTemplateOutlet="linkTpl; context: { option: option }"></ng-container>
    </a>

    <a
      mat-button
      class="__menu-action --align-left"
      [href]="option.href"
      [target]="option.target"
      *ngIf="option.href"
    >
      <ng-container *ngTemplateOutlet="linkTpl; context: { option: option }"></ng-container>
    </a>
  </div>

</mat-menu>

<ng-template #linkTpl let-option="option">

  <span class="__icon">

        <mip-icon
          *ngIf="option.icon"
          [icon]="option.icon"
          [bold]="option.iconBold"
          size="4x"
        ></mip-icon>

        <mip-icon
          *ngIf="option.matIcon"
          [matIcon]="option.matIcon"
          [bold]="option.matIconBold"
          [matIconOutlined]="option.matIconOutlined"
          size="4x"
        ></mip-icon>

        <!--<mip-icon
          *ngIf="option.matIcon"
          class="material-icons-outlined gc-icon&#45;&#45;size-4x"
        >
          {{option.matIcon}}
        </mip-icon>-->

        <mip-icon
          *ngIf="option.addonMatIcon"
          class="__icon--addon"
          [matIcon]="option.addonMatIcon"
          [bold]="option.addonMatIconBold"
          [matIconOutlined]="option.addonMatIconOutlined"
          mipColor="primary"
          size="2x"
        ></mip-icon>

        <!--<mip-icon
          *ngIf="option.addonMatIcon"
          class="__icon&#45;&#45;addon"
        >
          {{option.addonMatIcon}}
        </mip-icon>-->

      </span>

  <span class="__label">{{option.text}}</span>

</ng-template>
