<a
  [mipGoBackReferrer]="true"
  [routerLink]="[(postBasePath ? postBasePath : '/@' + postModel.author.username), 'p', postModel.id]"
  class="__full-click"
></a>

<!-- MEDIA HEADER -->
<div class="__post-info">

  <div class="__post-username">
    <mip-profile-slug-name [user]="postModel.author"></mip-profile-slug-name>
  </div>

  <div [innerHTML]="postModel.description | safeHtml : true" class="__post-description"></div>
</div>

<div *ngIf="postModel.stats.views !== null" class="__post-views">
  {{ postModel.stats.views | millify }}
  <mip-icon [solid]="true" icon="eye"></mip-icon>
</div>

<!-- MEDIA CONTAINER [class.--photo-landscape]="mediaModel.width >= mediaModel.height" -->
<div
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
  class="static-container"
>

  <div
    [style.backgroundImage]="'url(' + postModel.getCover() + ')'"
    class="__post-content-container"
  ></div>

  <div *ngIf="postModel.isVideo" class="__btn-play-container">
    <div class="__btn-play">
      <mip-icon [solid]="true" icon="play" size="8x"></mip-icon>
    </div>
  </div>

</div>

