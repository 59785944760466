import { Injectable } from '@angular/core';
import SspSettings from '@libs/gc-common/assets/ssp-settings.json';
import { environment } from '@libs/gc-common/environments/environment';
import DebugFactory from '@libs/gc-common/lib/factories/debug.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ImaSdkFactory } from '@libs/gc-common/lib/services/ima-sdk/ima-sdk.factory';
import { Observable } from 'rxjs';

import SSPsAdaptersFactory from './ssps-adapters.factory';

@Injectable({
  providedIn: 'root'
})
export class AdBusterService {
  
  // SSPs settings (placement IDs, site IDs and etc)
  sspSettings = SspSettings;
  
  // SSPs bidders
  nameBidders: Array<string> = [];
  
  // Host domain for ad unit names
  hostDomain = 'gotchosen.com';
  
  // AD Manager ID Account
  dfpAccountId = 21669242017;
  
  adUnitsSlots = {};
  
  // Active AD units codes
  adUnitsCodes = [
    {
      size: [320, 50],
      isVideo: false,
      adUnits: [
        'HeaderAd1',
        'HeaderAd2'
      ],
      adUnitsCode: [
        `/${this.dfpAccountId}/${this.hostDomain}/HeaderAd1`,
        `/${this.dfpAccountId}/${this.hostDomain}/HeaderAd2`
      ]
    },
    {
      size: [300, 250],
      isVideo: false,
      adUnits: [
        `DFP-WidgetAd`,
        `DFP-SlideAd`
      ],
      adUnitsCode: [
        `/${this.dfpAccountId}/${this.hostDomain}/DFP-WidgetAd`,
        `/${this.dfpAccountId}/${this.hostDomain}/DFP-SlideAd`
      ]
    },
    {
      size: [640, 480],
      isVideo: true,
      adUnits: [
        `PrerollVideoAd`,
        `PostrollVideoAd`
      ],
      adUnitsCode: [
        `/${this.dfpAccountId}/${this.hostDomain}/PrerollVideoAd`,
        `/${this.dfpAccountId}/${this.hostDomain}/PostrollVideoAd`
      ]
    },
    {
      size: ['fluid'],
      isVideo: false,
      adUnits: [
        'Post-NativeAd'
      ],
      adUnitsCode: [
        `/${this.dfpAccountId}/${this.hostDomain}/Post-NativeAd`
      ]
    }
  ];
  
  adSlotsPromisesStorage = {};
  adSlotsImpressionViewablePromisesStorage = {};
  adSlotsImpressionViewableResolveStorage = {};
  overallSSPsBids = {};
  overallSSPsBidsAndPrices = {};
  listAllVideoBiddersBidded = {};
  
  constructor() {
    
    const isAdDisabled = this.isAdDisabled();
    
    // console.log('ad-buster.service->constructor(): isAdDisabled', isAdDisabled);
    
    if (utilsFactory.isBrowser && !isAdDisabled) {
      
      // console.log('ad-buster.service->constructor(): this.sspSettings', this.sspSettings);
      
      window['googletag'] = window['googletag'] || {};
      window['googletag'].cmd = window['googletag'].cmd || [];
      
      window['pbjs'] = window['pbjs'] || {};
      window['pbjs'].que = window['pbjs'].que || [];
      
      this.start();
      
    }
  }
  
  isAdDisabled(adUnitPlacement?) {
    // console.log('ad-buster.service->isAdEnabled(): environment.disableAds', environment.disableAds, adUnitPlacement, environment.adsPlacement[adUnitPlacement]);
    
    let response = true;
    
    if (environment.disableAds !== true) {
      if (adUnitPlacement) {
        response = environment.adsPlacement[adUnitPlacement];
        // console.log('ad-buster.service->isAdEnabled(): environment.adsPlacement[adUnitPlacement]', adUnitPlacement, environment.adsPlacement[adUnitPlacement], response);
      }
      else {
        // tslint:disable-next-line:forin
        for (const i in environment.adsPlacement) {
          if (environment.adsPlacement[i] !== true) {
            response = false;
            break;
          }
        }
      }
    }
    
    // console.log('ad-buster.service->isAdEnabled(): response', adUnitPlacement, response);
    
    return response;
    
  }
  
  async start() {
    try {
      
      const libsDependencies = [];
      
      /*/!* geoedge *!/
       window['grumi'] = {
       cfg: {
       advs: {
       '4523221147': true, // Google
       '4799583524': true, // OpsCo
       '4682024547': true, // RTB House LATAM
       'exclude': true
       }
       },
       key: 'd2de39e6-2874-452c-b454-39b1a2689aac'
       };
       libsDependencies.push(utilsFactory.loadScript('https://rumcdn.geoedge.be/d2de39e6-2874-452c-b454-39b1a2689aac/grumi-ip.js'));
       // console.log('ad-buster.service->start(): geoedge HAS LOADED');*/
      
      /* GPT */
      libsDependencies.push(utilsFactory.injectTag('https://securepubads.g.doubleclick.net/tag/js/gpt.js', 'script', () => {
        // console.log('ad-buster.service->start(): gpt -----> ', window['googletag'], window['googletag']['pubads']);
        return !!(window['googletag'] && window['googletag']['pubads']);
      }));
      // console.log('ad-buster.service->start(): gpt HAS LOADED');
      
      /* PREBID */
      libsDependencies.push(utilsFactory.loadScript('https://cdn.gotchosen.com/prebid/prebid-schain-video-cpt-cmp.7.54.0.js'));
      // console.log('ad-buster.service->start(): prebid HAS LOADED');
      
      /* IMA3 */
      libsDependencies.push(utilsFactory.injectTag('https://imasdk.googleapis.com/js/sdkloader/ima3.js', 'script', () => {
        // console.log('ad-buster.service->start(): ima3 -----> ', window['google'] && window['google'].ima);
        return !!(window['google'] && window['google'].ima);
      }));
      // console.log('ad-buster.service->start(): ima3 HAS LOADED');
      
      await Promise.all(libsDependencies);
      // console.log('ad-buster.service->start(): ALL LIBS HAS LOADED');
      
      window['googletag'].pubads().collapseEmptyDivs();
      window['googletag'].pubads().disableInitialLoad();
      // window['googletag'].pubads().enableSingleRequest()
      window['googletag'].enableServices();
      
      this.startPrebid();
    }
    catch (e) {
      console.error('ad-buster.service->start(): ERROR', e);
      throw e;
    }
  }
  
  /**
   * Method to load libs dependencies
   */
  async waitLibsDependenciesToBeLoaded() {
    
    return new Promise(async (resolve, reject) => {
      
      if (utilsFactory.isBrowser) {
        
        let countInterval = 0;
        
        const interval = setInterval(async () => {
          try {
            
            /*console.log('ad-buster.service->waitLibsDependenciesToBeLoaded():',
              (window['googletag'] && 'pubads' in window['googletag']),
              (window['pbjs'] && 'onEvent' in window['pbjs'] && window['pbjs']['adUnits'].length),
              (window['google'] && window['google'].ima)
            );*/
            
            if (
              (window['googletag'] && 'pubads' in window['googletag']) &&
              (window['pbjs'] && 'onEvent' in window['pbjs'] && window['pbjs']['adUnits'].length) &&
              (window['google'] && window['google'].ima)
            ) {
              resolve(true);
              clearInterval(interval);
            }
            else if (countInterval > 20000) {
              clearInterval(interval);
              reject(new Error(`Waiting for libs has timed out.`));
            }
            
            countInterval += 100;
            
          }
          catch (e) {
            reject(e);
          }
        }, 100);
        
      }
      else {
        reject();
      }
      
    });
    
  }
  
  /**
   * Method to return all ad unit codes of a given size
   */
  getAdUnitsBySize(size: Array<number | string>) {
    try {
      
      // tslint:disable-next-line:forin
      for (const i in this.adUnitsCodes) {
        const adUnitSize = this.adUnitsCodes[i].size.join('x');
        const providedSize = size.join('x');
        
        if (adUnitSize === providedSize) {
          return this.adUnitsCodes[i];
        }
      }
      
      return null;
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to prepare and initialize the Prebid
   */
  startPrebid() {
    if (utilsFactory.isBrowser) {
      
      const computedAdUnits = [];
      const nameBidders = [];
      
      this.adUnitsCodes.forEach(adUnitCode => {
        if (adUnitCode) {
          // console.log('ad-buster.service->startPrebid(): adUnitCode', adUnitCode);
          
          const adUnitSize = adUnitCode.size;
          
          adUnitCode.adUnits.forEach(adUnitName => {
            // console.log('ad-buster.service->startPrebid(): adUnitName', adUnitName, adUnitSize);
            
            const bidders = {};
            
            for (const i in this.sspSettings) {
              if (this.sspSettings[i] !== false) {
                bidders[i] = this.sspSettings[i];
              }
            }
            
            const dfpData = {
              code: `/${this.dfpAccountId}/${this.hostDomain}/${adUnitName}`,
              sizes: adUnitSize,
              bids: [],
              mediaTypes: {}
            };
            
            if (adUnitCode.isVideo) {
              dfpData.mediaTypes = {
                video: {
                  context: 'instream',
                  playerSize: [adUnitSize],
                  mimes: ['video/mp4', 'video/x-ms-wmv'],
                  protocols: [2],
                  maxduration: 15,
                  linearity: 1,
                  api: [2]
                }
              };
            }
            else {
              dfpData.mediaTypes = {
                banner: {
                  sizes: adUnitSize
                }
              };
            }
            
            // console.log('ad-buster.service->startPrebid(): bidders', bidders);
            
            // tslint:disable-next-line:forin
            for (const bidderName in bidders) {
              let bidder = bidders[bidderName];
              
              if (typeof bidders[bidderName] === 'boolean') {
                bidder = {};
              }
              
              // console.log('ad-buster.service->startPrebid(): bidderName', bidderName, bidder);
              
              if (bidderName in SSPsAdaptersFactory) {
                bidder.adUnitName = adUnitName;
                bidder.code = dfpData.code;
                bidder.size = adUnitSize;
                bidder.host = this.hostDomain;
                
                const adapterParams = SSPsAdaptersFactory[bidderName](bidder, adUnitCode.isVideo);
                // console.log('ad-buster.service->startPrebid(): adapterParams', bidderName, adapterParams);
                
                if (adapterParams) {
                  dfpData.bids.push({
                    bidder: bidderName,
                    params: adapterParams
                  });
                }
                
                if (nameBidders.indexOf(bidderName) === -1) {
                  nameBidders.push(bidderName);
                }
                
              }
            }
            
            // console.log('ad-buster.service->startPrebid(): dfpData', dfpData);
            computedAdUnits.push(dfpData);
            
          });
        }
      });
      
      // console.log('ad-buster.service->startPrebid(): nameBidders', nameBidders);
      // console.log('ad-buster.service->startPrebid(): computedAdUnits', computedAdUnits);
      
      window['pbjs'].que.push(() => {
        
        const pbjsConfig = {
          consentManagement: {
            gdpr: {
              cmpApi: 'iab',
              timeout: 8000,
              defaultGdprScope: true
            },
            gpp: {
              cmpApi: 'iab',
              timeout: 8000
            },
            usp: {
              timeout: 150 // US Privacy timeout 100ms
            }
          },
          gptPreAuction: {
            enabled: true, // enabled by default
            customPbAdSlot: (adUnitCode, adServerAdSlot) => {
              
              try {
                
                // console.log('ad-buster.service->customPbAdSlot(): this', this);
                // console.log('ad-buster.service->customPbAdSlot(): adUnitCode, adServerAdSlot', adUnitCode, adServerAdSlot);
                
                // get adUnit object
                const adUnit = window['pbjs'].adUnits.filter(item => item.code === adUnitCode)[0];
                // console.log('ad-buster.service->customPbAdSlot(): adUnit', adUnit);
                
                const gptSlots = window['googletag'].pubads().getSlots().filter((gpt) => {
                  return gpt.getAdUnitPath() === adServerAdSlot;
                });
                // console.log('ad-buster.service->customPbAdSlot(): gptSlots', gptSlots);
                
                const gptSlot = gptSlots[gptSlots.length - 1];
                // console.log('ad-buster.service->customPbAdSlot(): gptSlot', gptSlot);
                
                if (gptSlot) {
                  
                  // console.log('ad-buster.service->customPbAdSlot(): adUnitCode->slotElementId', adUnitCode, gptSlot.getSlotElementId());
                  
                  const el = document.querySelector(`#${gptSlot.getSlotElementId()}`);
                  // console.log('ad-buster.service->customPbAdSlot(): el', el);
                  
                  return `${adUnit.code}#${el.getAttribute('data-adslotid')}`;
                  
                }
                
                return adUnitCode;
                
              }
              catch (e) {
                console.error('ad-buster.service->customPbAdSlot(): ERROR', e);
              }
              
            }
          },
          cache: {
            url: 'https://prebid.adnxs.com/pbc/v1/cache'
          },
          useBidCache: true,
          debug: false,
          enableSendAllBids: true,
          userSync: {
            iframeEnabled: true,
            enabledBidders: this.nameBidders,
            syncDelay: 6000,
            syncEnabled: true,
            syncsPerBidder: 8,
            filterSettings: {
              iframe: {
                bidders: '*',
                filter: 'include'
              },
              image: {
                bidders: '*',
                filter: 'include'
              }
            }
          },
          priceGranularity: {
            'buckets': [{
              'precision': 2,
              'min': 0.01,
              'max': 31.5,
              'increment': 0.01
            }]
          },
          schain: {
            config: {
              ver: '1.0',
              complete: 1,
              nodes: [{
                asi: 'gotchosen.com',
                sid: '1000001',
                hp: 1
              }, {
                asi: 'appnexus.com',
                sid: 11011,
                hp: 1
              }, {
                asi: 'pubmatic.com',
                sid: 157193,
                hp: 1
              }, {
                asi: 'rubiconproject.com',
                sid: 19976,
                hp: 1
              }, {
                asi: 'indexexchange.com',
                sid: 188140,
                hp: 1
              }, {
                asi: 'indirectseller.com',
                sid: '00001',
                hp: 1
              }]
            }
          }
        };
        
        // console.log('ad-buster.service->startPrebid(): computedAdUnits', computedAdUnits);
        // console.log('ad-buster.service->startPrebid(): pbjsConfig', pbjsConfig);
        
        window['pbjs'].addAdUnits(computedAdUnits);
        window['pbjs'].setConfig(pbjsConfig);
        
        // console.log('ad-buster.service->startPrebid(): DONE');
      });
      
      window['googletag'].pubads().addEventListener('slotRenderEnded', (event) => {
        // console.log('ad-buster.service->startPrebid(): addEventListener slotRenderEnded', event);
        
        const getDomId = event.slot.getSlotId().getDomId();
        const adUnitPath = event.slot.getAdUnitPath();
        let adUnitName = adUnitPath.split('/');
        adUnitName = adUnitName[adUnitName.length - 1];
        
        // console.log('ad-buster.service->startPrebid(): addEventListener slotRenderEnded => getDomId', getDomId);
        // console.log('ad-buster.service->startPrebid(): addEventListener slotRenderEnded => adUnitName', adUnitName);
        // console.log('ad-buster.service->startPrebid(): addEventListener slotRenderEnded => isEmpty', event.isEmpty);
        
        if (this.adSlotsPromisesStorage[getDomId]) {
          
          if (!event.isEmpty) {
            this.adSlotsPromisesStorage[getDomId].resolve('FILLED');
          }
          else {
            this.adSlotsPromisesStorage[getDomId].reject('NO-FILL');
          }
          
          delete this.adSlotsPromisesStorage[getDomId];
        }
      });
      
      window['googletag'].pubads().addEventListener('slotRequested', (event) => {
        // console.log(`ad-buster.service->startPrebid(): addEventListener slotRequested`);
        const adUnitPath = event.slot.getAdUnitPath();
        let adUnitName = adUnitPath.split('/');
        adUnitName = adUnitName[adUnitName.length - 1];
        
        // console.log(`ad-buster.service->startPrebid(): addEventListener slotRequested => `, adUnitName);
      });
      
      window['googletag'].pubads().addEventListener('impressionViewable', (event) => {
        // console.log(`ad-buster.service->startPrebid(): addEventListener impressionViewable: event`, event);
        // console.log(`ad-buster.service->startPrebid(): addEventListener impressionViewable: event.slot.getSlotId()`, event.slot.getSlotId().getId());
        
        const getDomId = event.slot.getSlotId().getDomId();
        const adUnitPath = event.slot.getAdUnitPath();
        let adUnitName = adUnitPath.split('/');
        adUnitName = adUnitName[adUnitName.length - 1];
        
        // console.log(`ad-buster.service->startPrebid(): addEventListener impressionViewable: adUnitName`, adUnitName);
        
        if (this.adSlotsImpressionViewableResolveStorage[getDomId]) {
          // console.log(`ad-buster.service->startPrebid(): addEventListener impressionViewable: adUnitName RESOLVING`, adUnitName);
          this.adSlotsImpressionViewableResolveStorage[getDomId]();
        }
        
      });
      
    }
  }
  
  /**
   * Method to trigger "pbjs.requestBids()"
   */
  async requestBids(code) {
    
    // console.log('ad-buster.service->requestBids(): code', code);
    
    return new Promise((resolve, reject) => {
      try {
        window['pbjs'].requestBids({
          timeout: 10000,
          adUnitCodes: [code],
          bidsBackHandler: (bidsResponse) => {
            resolve(bidsResponse);
          }
        });
      }
      catch (e) {
        // console.log(`ad-buster.service->registerAdSlot(): ERROR`, e);
        reject(e);
      }
    });
  }
  
  /**
   * Method to register a promise to be triggered within the googletag
   * event: "slotRenderEnded".
   * Once this event is triggered, we can check if the ad slot IS NOT EMPTY, this
   * means that we have a FILL, otherwise is a NO-FILL.
   */
  async registerAdSlotPromise(slotId) {
    // console.log('ad-buster.service->registerAdSlotPromise()', slotId);
    return new Promise((resolve, reject) => {
      this.adSlotsPromisesStorage[slotId] = {
        resolve,
        reject
      };
    });
  }
  
  /**
   * Method to register a promise to be triggered within the googletag event: "impressionViewable".
   * Once this event is triggered, we know that an impression becomes viewable
   */
  registerAdSlotImpressionViewablePromise(slotId) {
    // console.log('ad-buster.service->registerAdSlotImpressionViewablePromise()', slotId);
    this.adSlotsImpressionViewablePromisesStorage[slotId] = new Promise(resolve => {
      this.adSlotsImpressionViewableResolveStorage[slotId] = resolve;
    });
  }
  
  async onAdSlotViewable(slotId) {
    return this.adSlotsImpressionViewablePromisesStorage[slotId];
  }
  
  /**
   * Method to register an AD slot. This will make the AD Buster
   * Service to deliver ADs into this slot
   */
  async registerAdSlot(adUnitName, slotId): Promise<any> {
    
    if (utilsFactory.isBrowser) {
      
      // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): adUnitName`, adUnitName);
      // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): slotId`, slotId);
      
      await this.waitLibsDependenciesToBeLoaded();
      
      return await new Promise(async (resolve, reject) => {
        
        window['googletag'].cmd.push(async () => {
          
          try {
            
            const currentSSPsBidsAndPrices = {};
            
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): window['googletag']`, window['googletag']);
            
            // getting the current adUnit
            const adUnit = window['pbjs'].adUnits.filter((item) => item.code.includes(adUnitName))[0];
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): adUnit`, adUnit);
            
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): adUnit.code`, adUnit.code);
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): adUnit.sizes`, adUnit.sizes);
            
            // creating a new adSlot from googletag
            const adSlot = window['googletag'].defineSlot(adUnit.code, adUnit.sizes, slotId).addService(window['googletag'].pubads());
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): adSlot`, adSlot);
            
            // trigger the bid request
            const bidResponse = await this.requestBids(adUnit.code);
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): bidResponse`, bidResponse);
            
            if (bidResponse && bidResponse[adUnit.code]) {
              // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): bidResponse[adUnit.code]`, adUnit.code, bidResponse ? bidResponse[adUnit.code] : undefined);
            }
            
            // getting the highestBid (just for debug purpose)
            const highestBid = window['pbjs'].getHighestCpmBids(adUnit.code);
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): highestBid`, highestBid);
            
            const AdSlotPromise = this.registerAdSlotPromise(slotId);
            this.registerAdSlotImpressionViewablePromise(slotId);
            
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): setTargetingForGPTAsync`, adUnit.code);
            // setting the GPT targeting
            window['pbjs'].setTargetingForGPTAsync([adUnit.code]);
            
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): pubads().refresh()`, adSlot);
            // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): getElementById`, document.getElementById(slotId));
            // tell googletag to refresh the slot since we have the bids already
            window['googletag'].pubads().refresh([adSlot]);
            
            if (bidResponse && bidResponse[adUnit.code]) {
              
              // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): bidResponse[adUnit.code]`, adUnit.code, bidResponse[adUnit.code]);
              
              // tslint:disable-next-line:forin
              for (const i in bidResponse[adUnit.code].bids) {
                const bidder = bidResponse[adUnit.code].bids[i];
                // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): BidRequested: bidder`, bidder);
                
                // tslint:disable-next-line:radix
                currentSSPsBidsAndPrices[bidder.bidderCode] = parseFloat(bidder.adserverTargeting.hb_pb);
                
                if (!this.overallSSPsBidsAndPrices[bidder.bidderCode]) {
                  this.overallSSPsBidsAndPrices[bidder.bidderCode] = 0;
                }
                
                if (currentSSPsBidsAndPrices[bidder.bidderCode] > this.overallSSPsBidsAndPrices[bidder.bidderCode]) {
                  this.overallSSPsBidsAndPrices[bidder.bidderCode] = currentSSPsBidsAndPrices[bidder.bidderCode];
                }
                
                if (!this.overallSSPsBids[bidder.bidderCode]) {
                  this.overallSSPsBids[bidder.bidderCode] = 0;
                }
                
                this.overallSSPsBids[bidder.bidderCode] += 1;
              }
            }
            
            let hasBeenFilled;
            
            try {
              
              // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): highestBid`, highestBid);
              
              // register and wait for the ad slot to be ready
              await AdSlotPromise;
              
              // console.log(`ad-buster.service->registerAdSlot(${adUnitName}): FILLED!`);
              
              DebugFactory.success('styled', `BidRequested ${adUnitName}: FILLED! :)`);
              hasBeenFilled = true;
              
            }
            catch (e) {
              DebugFactory.warn('styled', `BidRequested ${adUnitName}: NO-FILL! :/`);
              hasBeenFilled = false;
            }
            
            if (hasBeenFilled) {
              
              if (highestBid[0]) {
                // console.log(`BidRequested ${adUnitName}:`, ['Current highest bid'], [highestBid[0].bidder, highestBid[0].adserverTargeting.hb_pb]);
              }
              else {
                // console.log(`BidRequested ${adUnitName}:`, ['Current highest bid'], ['NO WINNER']);
              }
              
              // console.log(`BidRequested ${adUnitName}:`, ['Current auction bidders and its prices'], currentSSPsBidsAndPrices);
              // console.log(`BidRequested ${adUnitName}:`, ['Highest prices by SSP'], this.overallSSPsBidsAndPrices);
              // console.log(`BidRequested ${adUnitName}:`, ['Total bids by SSP'], this.overallSSPsBids);
              
            }
            else {
              // console.log(`BidRequested ${adUnitName}: NO-FILL!`);
            }
            
            if (hasBeenFilled) {
              resolve('FILLED');
            }
            else {
              reject(`NO-FILL for '${adUnitName}' | slotId: '${slotId}'`);
            }
          }
          catch (e) {
            // console.error(`ad-buster.service->registerAdSlot(${adUnitName}): reject`, e);
            reject(e);
          }
          
        });
        
      });
      
    }
    
  }
  
  /**
   * Method to register the AD video element.
   */
  registerVideoAdElement(
    adUnitName: string,
    videoEl: HTMLVideoElement,
    adContainer?: HTMLDivElement,
    allowRestorePLayer = true
  ): Observable<{ status, ima: ImaSdkFactory }> {
    // console.log('AdBusterService->registerVideoAdElement(): adUnitName', adUnitName);
    
    return new Observable(observer => {
      try {
        
        this.waitLibsDependenciesToBeLoaded().then(() => {
          
          // console.log('AdBusterService->registerVideoAdElement(): window[pbjs].adUnits', window['pbjs'].adUnits);
          
          // getting the current adUnit
          const adUnit = window['pbjs'].adUnits.filter((item) => item.code.includes(adUnitName))[0];
          // console.log('AdBusterService->registerVideoAdElement(): adUnit', adUnit);
          
          const ima = new ImaSdkFactory(videoEl, adContainer, allowRestorePLayer);
          
          observer.next({
            status: 'AD_HAS_STARTED',
            ima
          });
          
          this.requestBids(adUnit.code).then(async bids => {
            
            if (bids && bids[adUnit.code] && bids[adUnit.code].bids) {
              
              // console.log(`[AD-REQUEST] BidRequested ---------------------------------------------------`);
              // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: bidResponses: bids`, bids[adUnit.code].bids);
              
              for (const bid of bids[adUnit.code].bids) {
                
                if (!this.listAllVideoBiddersBidded[bid.bidder]) {
                  this.listAllVideoBiddersBidded[bid.bidder] = 0;
                }
                
                this.listAllVideoBiddersBidded[bid.bidder] += 1;
                
              }
              
            }
            
            // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: adUnit`, adUnit);
            // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: Bidders`, this.listAllVideoBiddersBidded);
            // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: HighestCpmBids`, window['pbjs'].getHighestCpmBids(adUnit.code));
            // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: AdserverTargetingForAdUnitCode`, window['pbjs'].getAdserverTargetingForAdUnitCode([adUnit.code]));
            // console.log(`[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: BidResponsesForAdUnitCode`, window['pbjs'].getBidResponsesForAdUnitCode(adUnit.code));
            // console.log(`[AD-REQUEST] BidRequested ---------------------------------------------------`);
            
            try {
              // console.log('AdBusterService->registerVideoAdElement(): ######################################################## ');
              
              if (bids) {
                // console.log('AdBusterService->registerVideoAdElement(): bids', adUnit.code, bids[adUnit.code]);
              }
              
              const vastUrl = window['pbjs'].adServers.dfp.buildVideoUrl({
                adUnit,
                params: {
                  iu: adUnit.code,
                  // url: encodeURIComponent(descriptionUrl),
                  description_url: encodeURIComponent(window.location.href), // encodeURIComponent(`https://influencers-qa.gotchosen.com/${window.location.pathname}${window.location.search}`),
                  output: 'vast',
                  vpmute: 0,
                  ad_type: 'video'
                }
              });
              
              // console.log('AdBusterService->registerVideoAdElement(): vastUrl', vastUrl);
              
              const paramsString = vastUrl.split('?')[1];
              const searchParams = new URLSearchParams(paramsString);
              const vastUrlParams = {};
              
              // Iterate the search parameters.
              searchParams.forEach((value, key) => vastUrlParams[key] = value);
              
              // console.log('AdBusterService->registerVideoAdElement(): vastUrlParams', vastUrlParams);
              // console.log('AdBusterService->registerVideoAdElement(): ######################################################## ');
              
              // load video ad (video ad request)
              ima.loadAd(vastUrl).subscribe(status => {
                // console.log('AdBusterService->registerVideoAdElement(): startAd: status', status);
                observer.next({
                  status,
                  ima
                });
              }, error => {
                observer.error(error);
              });
              
              /*// play video ad
               ima.startAd().subscribe(status => {
               console.log('AdBusterService->registerVideoAdElement(): startAd: status', status);
               observer.next(status);
               }, error => {
               observer.error(error);
               });*/
              
            }
            catch (e) {
              console.error('styled', `[VIDEO-AD-REQUEST] BidRequested ${adUnitName}: NO-FILL!`);
              console.error('AdBusterService->registerVideoAdElement(): bidsBackHandler(): ERROR', e);
              observer.error(e);
            }
            
          });
          
        });
        
      }
      catch (e) {
        console.error('AdBusterService->registerVideoAdElement(): ERROR', e);
        observer.error(e);
      }
    });
    
  }
  
}
