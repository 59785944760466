import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';

import { MipToolbarComponent } from './mip-toolbar.component';

@NgModule({
  declarations: [MipToolbarComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    GoBackModule,
    MipIconModule,
    ColorModule,
    RouterModule
  ],
  exports: [MipToolbarComponent]
})
export class MipToolbarModule {
}
