import {
  Component,
  Input
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-download-app-buttons',
  templateUrl: './download-app-buttons.component.html',
  styleUrls: ['./download-app-buttons.component.scss']
})
export class DownloadAppButtonsComponent {

  @Input() buttonHeight = 40;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() align: 'center' | 'left' | 'right' = null;

  isIphone = false;
  isAndroid = false;
  isMobile = false;
  isDesktop = false;

  assetsPath = environment.assetsPath;

  constructor(
    private mobileDetect: MobileDetectService
  ) {
    this.isIphone = this.mobileDetect.isIphone();
    // console.log('download-app-buttons.component->constructor(): this.isIphone', this.isIphone);

    this.isAndroid = this.mobileDetect.isAndroid();
    // console.log('download-app-buttons.component->constructor(): this.isAndroid', this.isAndroid);

    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !(this.isIphone || this.isAndroid || this.isMobile);
  }

}
