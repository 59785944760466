import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { UsersListModule } from '@libs/gc-common/lib/features/users-list/users-list.module';
import { ClickOutsideModule } from 'ng4-click-outside';

import { MipTextareaComponent } from './mip-textarea.component';

@NgModule({
  declarations: [MipTextareaComponent],
  exports: [
    MipTextareaComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MipIconModule,
    MatButtonModule,
    FormsModule,
    MipLoadingModule,
    MipAvatarModule,
    MipTranslateModule,
    PickerModule,
    MipProfileSlugNameModule,
    ClickOutsideModule,
    UsersListModule
  ]
})
export class MipTextareaModule {
}
