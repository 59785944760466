import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'mip-video-controls',
  templateUrl: './mip-video-controls.component.html',
  styleUrls: ['./mip-video-controls.component.scss']
})
export class MipVideoControlsComponent implements AfterViewInit, OnChanges {

  @ViewChild('slider') slider: ElementRef;

  @Input() duration = 100;
  @Input() currentTime = 0;
  @Input() isPlaying = false;
  @Input() isMuted = true;

  @Input() controlsActions = ['timeline', 'play', 'mute'];

  @Output() onTimeChanged = new EventEmitter();
  @Output() onPlayChange = new EventEmitter();
  @Output() onMuteChange = new EventEmitter();

  isTouching = false;
  sliderCurrentPosition = 0.1;
  timer = '00:00';

  constructor() {
  }

  ngAfterViewInit() {

    const slider = this.slider.nativeElement;
    // console.log('mip-video-controls.component->ngAfterViewInit(): slider', slider);

    const sliderThumb = slider.querySelector('.mat-slider-thumb');
    // console.log('mip-video-controls.component->ngAfterViewInit(): sliderThumb', sliderThumb);

    slider.onmousedown = () => {
      // console.log('mip-video-controls.component->ngAfterViewInit(): onmousedown');
      this.isTouching = true;
    };

    slider.onmouseup = () => {
      this.isTouching = false;
    };

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentTime']) {
      // console.log('mip-video-controls.component->ngOnChanges(): this.currentTime', this.currentTime);

      let minutes = Math.floor(this.currentTime / 60).toString();
      minutes = minutes.length === 1 ? '0' + minutes : minutes;
      // console.log('mip-video-controls.component->ngOnChanges(): minutes.length', minutes, minutes.length);

      let seconds = Math.floor(this.currentTime).toString();
      seconds = seconds.length === 1 ? '0' + seconds : seconds;
      // console.log('mip-video-controls.component->ngOnChanges(): seconds.length', seconds, seconds.length);

      this.timer = `${minutes}:${seconds}`;
      // console.log('mip-video-controls.component->ngOnChanges(): this.timer', this.currentTime, this.timer);

      if (this.isTouching === false) {
        this.sliderCurrentPosition = this.currentTime + 0.1;
      }
    }
  }

  onSliderChange({target}) {
    // console.log('mip-video-controls.component->onSliderChange(): this.sliderCurrentPosition', this.sliderCurrentPosition, this.currentTime, this.duration, target.value);
    this.onTimeChanged.emit(target.value);
  }

}
