<a
  *ngIf="audio"
  [class.--is-desktop]="isDesktop"
  [class]="'--theme-' + theme"
  [mipGoBackReferrer]="true"
  class="audio-text"
  routerLink="/audios/{{audio.id}}"
>
  <!--<mip-icon svgIcon="music"></mip-icon>-->
  <mat-icon class="__icon">music_note</mat-icon>

  <div class="__info">
    <span [innerHTML]="audioText"></span> -
    <span *ngIf="audio.owner && audio.owner.getName()"><span [innerHTML]="audio.owner.getName()"></span></span>
    <span *ngIf="audio.owner && !audio.owner.getName()">@{{ audio.owner.username }}</span>
  </div>

</a>

<div
  *ngIf="song"
  [class.--is-desktop]="isDesktop"
  [class]="'--theme-' + theme"
  class="audio-text --not-available"
>
  <mip-icon class="__icon" matIcon="warning"></mip-icon>
  <div class="__info" mipTranslate="post-audio-text.audio-not-available">
    Audio is not available
  </div>
</div>
