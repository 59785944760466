import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { DashboardAgencyInterface } from './dashboard-agency.interface';

export class DashboardAgencyModel extends BaseModel implements DashboardAgencyInterface {

  id = null;
  email = null;
  username = null;
  name = null;

  constructor(model?: DashboardAgencyInterface) {
    super(model);
    this.fill(model);
  }

}
