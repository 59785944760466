import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { ProfileFollowingButtonComponent } from './profile-following-button.component';
import { ProfileFollowingMiniButtonComponent } from './profile-following-mini-button.component';

@NgModule({
  declarations: [
    ProfileFollowingButtonComponent,
    ProfileFollowingMiniButtonComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    MatButtonModule,
    MipTranslateModule,
    ColorModule
  ],
  exports: [
    ProfileFollowingButtonComponent,
    ProfileFollowingMiniButtonComponent
  ]
})
export class ProfileFollowingButtonModule {
}
