import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'mip-alert,[mip-alert]',
  templateUrl: './mip-alert.component.html',
  styleUrls: ['./mip-alert.component.scss']
})
export class MipAlertComponent implements OnInit {

  @Input() bgColor: string;
  @Input() bgColorHover: string;
  @Input() bdColor: string;
  @Input() borderRadios: string = '4px';
  @Input() textColor: string;
  @Input() textAlign = 'left';
  @Input() alignItems = 'center';
  @Input() justifyContent = null;
  @Input() theme = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  getClasses() {
    const classes = {};

    if (this.bgColor) {
      classes[`mip-color--bg-${this.bgColor}`] = true;
    }

    if (this.bgColorHover) {
      classes[`mip-color--bg-hover-${this.bgColorHover}`] = true;
    }

    if (this.bdColor) {
      classes[`mip-color--bd-${this.bdColor}`] = true;
    }

    if (this.textColor) {
      classes[`mip-color--text-${this.textColor}`] = true;
    }

    if (this.theme && ['success', 'warning', 'danger', 'info'].indexOf(this.theme) > -1) {
      classes[`mip-color--bg-${this.theme}`] = true;
    }

    classes[`--text-align--${this.textAlign}`] = true;

    return classes;
  }

}
