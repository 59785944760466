<div [class.--inline]="inline" class="post-interaction">

  <div *ngIf="showAvatar" class="__avatar">

    <mip-profile-following-mini-button
      [userModel]="postModel.author"
      class="__follow-user"
    ></mip-profile-following-mini-button>

    <a
      [mipGoBackReferrer]="!isProfileFeed"
      class="__avatar-link"
      routerLink="/@{{postModel.author.username}}"
    >

      <mip-avatar
        *ngIf="postModel.author"
        [border]="videoProgressBar ? 0 : 3"
        [roundProgressCurrent]="postModel.currentTime || 100"
        [roundProgressDuration]=" 500"
        [roundProgressMax]="postModel.duration || 100"
        [roundProgressRadius]="22"
        [roundProgressStroke]="4"
        [roundProgress]="true"
        [size]="46"
        [userModel]="postModel.author"
      ></mip-avatar>

    </a>

  </div>

  <!-- ACTIONS-LIST -->
  <div
    [class.--is-desktop]="isDesktop"
    [class.--is-mobile]="isMobile"
    class="__actions"
  >

    <!-- ACTION-CONTAINER -->
    <div
      (clickOutside)="closeChildrenActions(action)"
      *ngFor="let action of interactionsActions"
      [class.--is-animating]="action.isAnimating"
      [class.--is-open]="action.isOpen"
      class="__action --is-animating"
    >

      <div class="__action-container">

        <!-- ACTIVE-ACTION -->
        <button (click)="doAction(action)" class="__action-button" mat-fab>

          <div *ngIf="action.activeAction && action.activeAction.currentIcon">
            <ng-container
              *ngTemplateOutlet="icon; context: {
            icon: action.activeAction.currentIcon
          }"
            ></ng-container>
          </div>

          <div *ngIf="!action.activeAction && action.currentIcon">
            <ng-container
              *ngTemplateOutlet="icon; context: {
            icon: action.currentIcon
          }"
            ></ng-container>
          </div>

        </button>

        <!-- COUNT -->
        <small class="__action-count">
          {{ action.count | millify }}
        </small>

      </div>

      <div
        *ngIf="(action.isOpen || action.isAnimating) && action.actions && action.actions.length > 0"
        class="__children-actions"
      >

        <div
          *ngIf="isMobile"
          class="__child-action __close-btn"
        >
          <button (click)="toggleOpenAction(action)" mat-icon-button>
            <mip-icon icon="times"></mip-icon>
          </button>
        </div>

        <div *ngFor="let child of action.actions" class="__child-action">

          <button
            (click)="doReaction(child, action)"
            *ngIf="child.icons && child.icons.open" class="__action-button"
            mat-fab
          >
            <ng-container
              *ngTemplateOutlet="icon; context: {
                icon: child.icons.open,
                colored: child.activated,
                loading: child.loading
              }"
            ></ng-container>
          </button>

          <!-- COUNT -->
          <small class="__child-count __action-count">
            {{ child.count | millify }}
          </small>

        </div>

      </div>

    </div>
  </div>

  <div
    *ngIf="disableAudioAction === false && postModel && postModel.medias?.audio"
    [class.--is-mobile]="isMobile"
    class="__audio"
  >

    <mip-icon
      *ngIf="isVideoPlaying"
      [lottieIcon]="isMobile ? 'music-notes-white' : 'music-notes'"
      [reverseHorizontally]="true"
      class="__audio-icon"
      size="10x"
    ></mip-icon>

    <a
      [class.--pause-spin]="!isVideoPlaying"
      [mipGoBackReferrer]="true"
      class="__audio-link"
      mat-fab
      routerLink="/audios/{{postModel.medias?.audio?.audio.id}}"
    >
      <mip-avatar
        [size]="30"
        [userModel]="postModel.author"
      >
      </mip-avatar>
    </a>

  </div>

</div>

<!-- template: ICON -->
<ng-template #icon let-colored="colored" let-icon="icon" let-loading="loading">

  <!-- ICON -->
  <mip-icon
    *ngIf="icon.icon"
    [bold]="icon.bold"
    [class.--is-loading]="loading"
    [colored]="icon.colored || colored"
    [icon]="icon.icon"
    [solid]="icon.solid"
    size="6x"
  ></mip-icon>

  <!-- SVG -->
  <mip-icon
    *ngIf="icon.svgIcon"
    [bold]="icon.bold"
    [class.--is-loading]="loading"
    [colored]="icon.colored || colored"
    [solid]="icon.solid"
    [svgIcon]="icon.svgIcon"
    size="6x"
  ></mip-icon>

  <!-- PNG -->
  <mip-icon
    *ngIf="icon.pngIcon"
    [bold]="icon.bold"
    [class.--is-loading]="loading"
    [colored]="icon.colored || colored"
    [pngIcon]="icon.pngIcon"
    [solid]="icon.solid"
    size="7x"
  ></mip-icon>

  <!-- GIF -->
  <mip-icon
    *ngIf="icon.gifIcon"
    [bold]="icon.bold"
    [class.--is-loading]="loading"
    [colored]="icon.colored || colored"
    [gifIcon]="icon.gifIcon"
    [size]="isMobile ? '9x' : '11x'"
    [solid]="icon.solid"
  ></mip-icon>

</ng-template>


