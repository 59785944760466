<div
  *ngIf="postsList.length && swiperConfig"
>
  <swiper
    [config]="swiperConfig"
    class="__swiper-container"
  >

    <!-- WINNER -->
    <ng-template
      *ngIf="postsWinners && postsWinners.winner"
      swiperSlide
    >
      <ng-container
        [ngTemplateOutletContext]="{post: postsWinners.winner.post, $index: 0}"
        [ngTemplateOutlet]="postSlide"
      ></ng-container>
    </ng-template>

    <!-- SECOND -->
    <ng-template
      *ngIf="postsWinners && postsWinners.second"
      swiperSlide
    >
      <ng-container
        [ngTemplateOutletContext]="{post: postsWinners.second.post, $index: 0}"
        [ngTemplateOutlet]="postSlide"
      ></ng-container>
    </ng-template>

    <!-- JOKER -->
    <ng-template
      *ngIf="postsWinners && postsWinners.joker"
      swiperSlide
    >
      <ng-container
        [ngTemplateOutletContext]="{post: postsWinners.joker.post, $index: 0}"
        [ngTemplateOutlet]="postSlide"
      ></ng-container>
    </ng-template>

    <!-- OTHER POSTS -->
    <ng-template
      *ngFor="let post of postsList; let $index = index;"
      class="__item"
      swiperSlide
    >
      <ng-container
        [ngTemplateOutletContext]="{post: post, $index: $index}"
        [ngTemplateOutlet]="postSlide"
      ></ng-container>
    </ng-template>

    <!-- SEE MORE -->
    <ng-template
      *ngIf="seeMoreLink"
      swiperSlide
    >
      <a
        [mipGoBackReferrer]="true"
        [routerLink]="seeMoreLink"
        [style.margin-left]="(spacing/2) + 'px'"
        [style.margin-right]="(spacing/2) + 'px'"
        [style.max-height]="(tileHeight ? tileHeight + 'px' : null)"
        [style.max-width]="tileWidth + 'px'"
        [style.min-height]="(tileHeight ? tileHeight + 'px' : null)"
        [style.min-width]="tileWidth + 'px'"
        class="__slide-container --see-more"
        mipBgColor="primary"
      >

        <div class="__text">
          <h4 mipTranslate="slide-posts-preview.see-more">See more</h4>
          <mip-icon icon="arrow-right"></mip-icon>
        </div>

        <img src="{{assetsPath}}/images/canvas/{{orientation || 'portrait'}}-canvas.svg">
      </a>
    </ng-template>

  </swiper>
</div>

<div
  #swiperNavigation
  [class.--is-mobile]="isMobile"
  [class.--show]="postsList.length"
  class="__swiper-navigation"
>

  <button
    class="__slide-posts-preview --prev"
    mat-icon-button
    mipBgColor="primary"
  >
    <mip-icon [bold]="true" icon="chevron-left"></mip-icon>
  </button>

  <button
    class="__slide-posts-preview --next"
    mat-icon-button
    mipBgColor="primary"
  >
    <mip-icon [bold]="true" icon="chevron-right"></mip-icon>
  </button>

</div>

<ng-template
  #postSlide
  let-$index='$index'
  let-post='post'
>
  <div
    [style.max-height]="(tileHeight ? tileHeight + 'px' : null)"
    [style.max-width]="($index === 0 ? (tileWidth - (spacing/2)) : tileWidth) + 'px'"
    [style.min-height]="(tileHeight ? tileHeight + 'px' : null)"
    [style.min-width]="($index === 0 ? (tileWidth - (spacing/2)) : tileWidth) + 'px'"
    [style.padding-left]="(spacing/2) + 'px'"
    [style.padding-right]="(spacing/2) + 'px'"
    class="__slide-container"
  >

    <!-- POST MAIN COMPONENT -->
    <mip-feed-item
      [avatarCrown]="getAvatarCrown($index, post)"
      [baseRoute]="baseRoute ? baseRoute : ['/@' + post.author.username, 'p']"
      [enableTimestamp]="enableTimestamp"
      [height]="tileHeight"
      [infoOnTop]="infoOnTop"
      [orientation]="orientation"
      [postModel]="post"
      [showAvatar]="!!getAvatarCrown($index, post)"
      [showSampleLabel]="enableSampleLabel"
      [showThemeIcon]="showThemeIcon"
      [showViews]="showViews"
    ></mip-feed-item>

  </div>
</ng-template>
