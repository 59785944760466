import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { CommentModel } from './comment.model';
import { CommentInterface } from './comment.interface';

export class CommentFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = CommentModel;

  constructor() {
    super();
  }

  override build(data: CommentModel): CommentModel {
    return super.build(data);
  }

  override buildCollection(data: Array<CommentInterface>): Array<CommentModel> {
    return super.buildCollection(data);
  }

}

// export const commentFactory = new CommentFactory();
