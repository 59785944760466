<div class="__cover-image" *ngIf="imageUrl" [style.background-image]="'url(' + imageUrl + ')'">
  <img [src]="imageUrl"><!-- {{assetsPath}}/images/canvas/square-canvas.svg -->
</div>

<div class="shared-url-container">

  <div class="__left-image" *ngIf="imageLeftUrl" [style.background-image]="'url(' + imageLeftUrl + ')'">
    <img src="{{assetsPath}}/images/canvas/square-canvas.svg">
  </div>

  <div class="__share-info">
    <div class="__url">
      {{data.shareUrl}}
    </div>

    <button mat-flat-button (click)="copyUrl()">
      <mat-icon>content_copy</mat-icon>
      <span class="__button-text">Copy link</span>
    </button>
  </div>

</div>

<mat-list role="list">

  <!-- FACEBOOK -->
  <mat-list-item role="listitem">
    <a mat-button [href]="networkOptions.facebook" target="popup">
      <mip-icon icon="facebook"></mip-icon>
      <span class="__button-text">Share on Facebook</span>
    </a>
  </mat-list-item>

  <!-- TWITTER -->
  <mat-list-item role="listitem">
    <a mat-button [href]="networkOptions.twitter" target="popup">
      <mip-icon icon="twitter"></mip-icon>
      <span class="__button-text">Share on Twitter</span>
    </a>
  </mat-list-item>

  <!-- EMAIL -->
  <mat-list-item role="listitem">
    <a mat-button [href]="networkOptions.email" target="popup">
      <mat-icon>email</mat-icon>
      <span class="__button-text">Send via email</span>
    </a>
  </mat-list-item>

</mat-list>
