import { Injectable } from '@angular/core';
import Axios from 'axios';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Injectable({
  providedIn: 'root'
})
export class AltchaService {

  _container: HTMLDivElement = null;
  _altchaEl = null;
  _payload = null;

  constructor() {
    if (utilsFactory.isBrowser) {
      this._container = document.createElement('div');
      this._container.className = 'altcha-widget-container';
      document.body.prepend(this._container);
    }
  }

  async createAltcha() {
    return new Promise(async (resolve, reject) => {
      try {

        if (utilsFactory.isBrowser) {
          // console.log('app.component->createAltcha()');

          this._container.innerHTML = '';

          this._altchaEl = document.createElement('altcha-widget');
          // console.log('app.component->createAltcha(): this.altchaEl', this._altchaEl);

          this._altchaEl.setAttribute('hidelogo', '');
          this._altchaEl.setAttribute('hidefooter', '');
          // console.log('app.component->createAltcha(): this.altchaEl', this._altchaEl);

          this._container.append(this._altchaEl);

          const challenge = await this.getChallenge();
          // console.log('app.component->createAltcha(): challenge', challenge);

          this._altchaEl.configure({ challenge });

          await utilsFactory.waitToBeTrue('altcha.service', () => !!this._altchaEl.querySelector('[type="checkbox"]'));

          const checkbox = this._altchaEl.querySelector('[type="checkbox"]');
          // console.log('app.component->createAltcha(): checkbox', checkbox);

          checkbox.click();

          this._altchaEl.addEventListener('statechange', (ev) => {
            if (ev.detail.state === 'verified') {
              this._payload = ev.detail.payload;
              // console.log('app.component->createAltcha(): this._payload', this._payload);
              resolve(this._payload);
            }
          });

        }

      }
      catch (e) {
        console.error('app.component->createAltcha(): ERROR', e);
        reject(e);
      }
    });

  }

  async getChallenge() {
    try {

      const { data } = await Axios.post(`${environment.apis.eventsApi}/challenge`);
      // console.log('app.component->registerAdImpression(): data', data);

      return data;

    }
    catch (e) {
      // console.log('app.component->getChallenge(): ERROR', e);
      throw e;
    }
  }

  async getPayload() {
    try {
      // console.log('app.component->getPayload()');
      return await this.createAltcha();
    }
    catch (e) {
      console.error('app.component->getPayload(): ERROR', e);
      throw e;
    }
  }

}
