<div class="post-skeleton" [class.--animation]="animated">

  <div class="__skeleton-header">
    <div class="__skeleton-avatar"></div>
    <div class="__skeleton-info">
      <div class="__skeleton-username">
        <span></span>
        <span></span>
      </div>
      <div class="__skeleton-description">
        <p></p>
        <p></p>
      </div>
    </div>
  </div>

  <div class="__skeleton-media-container">
    <div class="__skeleton-media"></div>
    <ul class="__skeleton-interaction">
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>

</div>
