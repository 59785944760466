import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@libs/gc-common/environments/environment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { MipIconInterface } from '@libs/gc-common/lib/components/mip-icon/mip-icon.component';
import { PostCommentsComponent } from '@libs/gc-common/lib/features/post-view/post-comments/post-comments.component';
import { ShareModalService } from '@libs/gc-common/lib/features/share-modal/share-modal.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

interface PostInteractionActionInterface {
  name?: string;
  count?: number;
  activated?: boolean;
  isOpen?: boolean;
  isAnimating?: boolean;
  onClick?: () => void;
  currentIcon?: MipIconInterface;
  activeAction?: PostInteractionActionInterface;
  actions?: PostInteractionActionInterface[];
  loading?: boolean;
  icons?: {
    inactive: MipIconInterface;
    active: MipIconInterface;
    open: MipIconInterface;
  };
}

@Component({
  selector: 'mip-post-interaction',
  templateUrl: './post-interaction.component.html',
  styleUrls: ['./post-interaction.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostInteractionComponent implements OnInit, OnChanges, OnDestroy {

  @Input() postModel: PostModel = null;
  @Input() videoProgressBar = true;
  @Input() showAvatar = false;
  @Input() inline = false;

  @Input() isVideoPlaying = false;

  @Input() disableReactionsAction = false;
  @Input() disableCommentsAction = false;
  @Input() disableShareAction = false;
  @Input() disableAudioAction = false;

  @Output() onToggleComments = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();

  reactionsActions: PostInteractionActionInterface[] = [
    {
      name: 'happy',
      count: 0,
      activated: false,
      currentIcon: null,
      loading: false,
      icons: {
        inactive: {
          pngIcon: 'happy-face'
        },
        active: {
          pngIcon: 'happy-face',
          colored: true
        },
        open: {
          gifIcon: 'happy-face',
          colored: false
        }
      }
    },
    {
      name: 'loved',
      count: 0,
      activated: false,
      currentIcon: null,
      icons: {
        inactive: {
          pngIcon: 'loved-face'
        },
        active: {
          pngIcon: 'loved-face',
          colored: true
        },
        open: {
          gifIcon: 'loved-face',
          colored: false
        }
      }
    },
    {
      name: 'laughing',
      count: 0,
      activated: false,
      currentIcon: null,
      icons: {
        inactive: {
          pngIcon: 'laughing-face'
        },
        active: {
          pngIcon: 'laughing-face',
          colored: true
        },
        open: {
          gifIcon: 'laughing-face',
          colored: false
        }
      }
    },
    {
      name: 'surprised',
      count: 0,
      activated: false,
      currentIcon: null,
      icons: {
        inactive: {
          pngIcon: 'surprised-face'
        },
        active: {
          pngIcon: 'surprised-face',
          colored: true
        },
        open: {
          gifIcon: 'surprised-face',
          colored: false
        }
      }
    },
    {
      name: 'sleeping',
      count: 0,
      activated: false,
      currentIcon: null,
      icons: {
        inactive: {
          pngIcon: 'sleeping-face'
        },
        active: {
          pngIcon: 'sleeping-face',
          colored: true
        },
        open: {
          gifIcon: 'sleeping-face',
          colored: false
        }
      }
    },
    {
      name: 'crying',
      count: 0,
      activated: false,
      currentIcon: null,
      icons: {
        inactive: {
          pngIcon: 'crying-face'
        },
        active: {
          pngIcon: 'crying-face',
          colored: true
        },
        open: {
          gifIcon: 'crying-face',
          colored: false
        }
      }
    },
    {
      name: 'angry',
      count: 0,
      currentIcon: null,
      activated: false,
      icons: {
        inactive: {
          pngIcon: 'angry-face'
        },
        active: {
          pngIcon: 'angry-face',
          colored: true
        },
        open: {
          gifIcon: 'angry-face',
          colored: false
        }
      }
    }
  ];

  reactionsAction: PostInteractionActionInterface = {
    count: 0,
    isOpen: false,
    activeAction: null,
    actions: null
  };

  commentsAction: PostInteractionActionInterface = {
    count: 0,
    onClick: this.openComments.bind(this),
    currentIcon: {
      icon: 'comments',
      solid: true,
      circle: true
    }
  };

  shareAction: PostInteractionActionInterface = {
    count: 0,
    onClick: this.sharePost.bind(this),
    currentIcon: {
      icon: 'share',
      solid: true,
      circle: true
    }
  };

  interactionsActions: PostInteractionActionInterface[] = [];

  isMobile = false;
  isDesktop = false;

  commentsModalInstance = null;

  assetsPath = environment.assetsPath;
  isProfileFeed = false;
  observerHelper = new ObserverHelper();

  constructor(
    private matBottomSheet: MatBottomSheet,
    private matDialog: MatDialog,
    private routerService: RouterService,
    private userService: UserService,
    private shareModalService: ShareModalService,
    private mobileDetectService: MobileDetectService
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();

    this.reactionsAction.actions = this.reactionsActions;
    // console.log('post-interaction.component->constructor(): this.reactionsAction.actions', this.reactionsAction.actions);

    this.reactionsAction.activeAction = this.reactionsAction.actions[0];
    // console.log('post-interaction.component->constructor(): this.reactionsAction.activeAction', this.reactionsAction.activeAction);

    for (const action of this.reactionsActions) {
      action.currentIcon = action.icons.inactive;
    }
    // console.log('post-interaction.component->constructor(): this.reactionsAction', this.reactionsAction);

  }

  ngOnInit() {

    this.observerHelper.addSubscription(
      this.routerService.onRouteChange().subscribe(route => {
        // console.log('post-interaction.component->ngOnInit(): route', route);
        this.isProfileFeed = route.url.indexOf('/@') === 0;

        if (route.queryParams.comments === 'true') {
          this.openComments();
        }
      })
    );

    // REACTIONS
    if (this.disableReactionsAction === false) {
      this.interactionsActions.push(this.reactionsAction);
    }

    // COMMENTS
    if (this.postModel.allowComments && this.disableCommentsAction === false) {
      this.interactionsActions.push(this.commentsAction);
    }

    // SHARE
    if (this.disableShareAction === false) {
      this.interactionsActions.push(this.shareAction);
    }

    // console.log('post-interaction.component->ngOnInit(): this.interactionsActions', this.interactionsActions);

  }

  async ngOnChanges(changes: SimpleChanges) {

    this.onReactionChange();

    this.commentsAction.count = this.postModel.stats.comments || 0;
    this.shareAction.count = this.postModel.stats.shares || 0;

  }

  async doReaction(actionChild, action) {
    // console.log('post-interaction.component->doReaction(): actionChild', actionChild);
    // console.log('post-interaction.component->doReaction(): action', action);

    try {

      if (this.userService.isAuthenticated()) {

        actionChild.loading = true;

        await this.postModel.doReaction(actionChild.name);

        actionChild.loading = false;

        this.onReactionChange();
        this.closeChildrenActions(action);

      }
      else {
        await this.routerService.navigateTo(environment.loginUrl, {
          redirectPath: true
        });
      }

    }
    catch (e) {
      actionChild.loading = false;
      console.error('post-interaction.component->doReaction() ERROR', e);
      throw e;
    }

  }

  doAction(action) {
    if (action.actions) {
      this.toggleOpenAction(action);
    }
    else if (action.onClick) {
      action.onClick(action);
    }
  }

  onReactionChange() {
    try {
      // console.log('post-interaction.component->onReactionChange()');

      this.reactionsActions.forEach(item => {
        item.activated = false;
        item.currentIcon = item.icons.inactive;
      });

      const reactionStats = this.postModel.stats.reactions;
      // console.log('post-interaction.component->onReactionChange() reactionStats', reactionStats);

      const userReaction = this.postModel.getUserReaction();
      // console.log('post-interaction.component->onReactionChange() userReaction', userReaction);

      if (userReaction) {

        const userReactionAction = this.reactionsActions.find(({ name }) => {
          // console.log('post-interaction.component->onReactionChange() find() name', name);

          return name === userReaction.reactionType;
        });
        // console.log('post-interaction.component->onReactionChange() userReactionAction', userReactionAction);

        userReactionAction.activated = true;
        userReactionAction.currentIcon = userReactionAction.icons.active;

        this.reactionsAction.activeAction = userReactionAction;
        // console.log('post-interaction.component->onReactionChange() userReactionAction', userReactionAction);

      }
      else {
        this.reactionsAction.activeAction = this.reactionsActions[0];
      }

      this.reactionsAction.count = this.postModel.totalReaction;
      // console.log('post-interaction.component->onReactionChange() this.reactionsAction.count', this.reactionsAction.count);

      if (reactionStats) {
        // console.log('post-interaction.component->onReactionChange() this.reactionsActions', this.reactionsActions);
        for (const action of this.reactionsActions) {

          // console.log('post-interaction.component->onReactionChange() action', action);

          const reactionCount = reactionStats[action.name];
          // console.log('post-interaction.component->onReactionChange() reactionCount', reactionCount);

          action.count = reactionCount;

        }
      }

    }
    catch (e) {
      console.error('post-interaction.component->onReactionChange() ERROR', e);
      throw e;
    }
  }

  toggleOpenAction(action) {
    if (action.actions) {
      if (action.isOpen) {
        this.closeChildrenActions(action);
      }
      else {
        this.openChildrenActions(action);
      }
    }
  }

  openChildrenActions(action) {
    if (action.actions) {

      action.isAnimating = true;

      setTimeout(() => {
        action.isOpen = true;
      }, 10);

      setTimeout(() => {
        action.isAnimating = false;
      }, 300);

    }
  }

  closeChildrenActions(action) {
    if (action.actions) {

      action.isAnimating = true;

      setTimeout(() => {
        action.isOpen = false;
      }, 10);

      setTimeout(() => {
        action.isAnimating = false;
      }, 300);

    }
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  sharePost() {

    const url = `/@${this.postModel.author.username}/p/${this.postModel.id}`;
    // console.log('post-interaction.component->sharePost(): url', url);

    const text = `@${this.postModel.author.username} - ${this.postModel.description}`;
    // console.log('post-interaction.component->sharePost(): text', text);

    const imageLeft = this.postModel.getCover();
    // console.log('post-interaction.component->sharePost(): imageLeft', imageLeft);

    this.shareModalService.shareOpen({ url, text, imageLeft });

    this.onToggleShare.emit();

  }

  openComments() {
    // console.log('post-interaction.component->openComments()');

    try {

      // if (this.userService.isAuthenticated()) {

      this.onToggleComments.emit(true);
      this.commentsModalInstance = this.matBottomSheet.open(PostCommentsComponent, {
        data: {
          postModel: this.postModel,
          isModal: true
        }
      });
      this.commentsModalInstance.afterDismissed().subscribe(() => {
        this.onToggleComments.emit(false);
        // console.log('post-interaction.component->openComments()->afterDismissed()');
        this.routerService.navigateToChild('', { comments: null });
      });

      // }
      /*else {
       this.routerService.registerGoBackReferer({
       path: '/login',
       redirectUrl: `${this.routerService.getCurrentUrl()}?comments=true`
       });
       this.routerService.navigateTo('/login');
       }*/

    }
    catch (e) {
      console.error('post-interaction.component->openComments() ERROR', e);
      throw e;
    }

  }

  closeComments() {
    // console.log('post-interaction.component->closeComments()');
    if (this.commentsModalInstance) {
      // console.log('post-interaction.component->closeComments(): MatBottomSheetRef');
      this.commentsModalInstance.dismiss();
    }
  }

}
