import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Injectable({
	providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

	constructor(
		private userService: UserService,
		private router: Router,
		private routerService: RouterService
	) {
	}

	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {

		console.log('is-authenticated.guard->canActivate() state.url', state.url);
		console.log('is-authenticated.guard->canActivate() this.userService.isAuthenticated()', this.userService.isAuthenticated());

		if (this.userService.isAuthenticated()) {
			return true;
		}
		else {
			this.routerService.setActiveRoute(route);

			const routerData = await this.routerService.getRouteDataParam();
			console.log('is-authenticated.guard->canActivate() routerData', routerData);

			if (
				routerData.canActivate &&
				routerData.canActivate.IsAuthenticatedGuard &&
				routerData.canActivate.IsAuthenticatedGuard.matchRegex
			) {

				const matchRegex = routerData.canActivate.IsAuthenticatedGuard.matchRegex;
				console.log('is-authenticated.guard->canActivate() matchRegex', matchRegex);
				console.log('is-authenticated.guard->canActivate() match', state.url.match(matchRegex));

				console.log('is-authenticated.guard->canActivate() state.url', state.url, matchRegex, state.url.match(matchRegex));
				if (state.url.match(matchRegex) === null) {
					return true;
				}

			}

			this.routerService.registerGoBackReferer({
				path: environment.loginUrl,
				redirectUrl: state.url
			});

			document.location = environment.loginUrl;

			// return this.router.parseUrl(routerData.loginBasePath || '/login');

			return false;
			
		}

	}
}
