import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { DashboardAmbassadorInterface } from './dashboard-ambassador.interface';

export class DashboardAmbassadorModel extends BaseModel implements DashboardAmbassadorInterface {

  id = null;
  campaignId = null;
  start = null;
  end = null;
  classification = null;
  reason = null;
  status = null;

  social_media = null;

  createdAt = null;
  updatedAt = null;

  user: UserModel = null;
  parent: UserModel = null;

  constructor(model?: DashboardAmbassadorInterface) {
    super(model);
    this.fill(model);
  }

  override afterFill(model) {

    if (model.user) {
      this.user = ModelFactoryService.instance.userFactory.build(model.user);
    }

    if (model.parent) {
      this.parent = ModelFactoryService.instance.userFactory.build(model.parent);
    }

  }

}
