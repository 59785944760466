<div *ngIf="hashtagModel" class="__header">

  <img
    *ngIf="hashtagModel.coverUrl"
    [style.background-image]="'url(' + hashtagModel.coverUrl + ')'"
    class="__avatar"
    src="{{assetsPath}}/images/canvas/square-canvas.svg"
  >

  <div
    *ngIf="!hashtagModel.coverUrl"
    class="__avatar"
  >
    <span
      *ngIf="!icon && !iconSvg && !iconPng"
      class="__icon --bg"
    >
      #
    </span>
    <mip-icon
      *ngIf="icon || iconSvg || iconPng"
      [bold]="iconBold"
      [icon]="icon"
      [pngIcon]="iconPng"
      [size]="iconSize"
      [solid]="iconSolid"
      [svgIcon]="iconSvg"
      class="__icon"
    ></mip-icon>
    <img src="{{assetsPath}}/images/canvas/square-canvas.svg">
  </div>

  <h2 class="__name">
    <a
      [mipGoBackReferrer]="true"
      class="__hashtag-action mip-button--size-lg --full"
      mat-button
      routerLink="/hashtag/{{hashtagModel.hashtag}}"
    >
      <span *ngIf="customTitle">{{ customTitle }}</span>
      <span *ngIf="!customTitle">
        #{{ hashtagModel.hashtag }}<br>
      </span>
      <small *ngIf="contestModel && !contestModel.hasEnded()" mipTextColor="success"
             mipTranslate="hashtag-info.active-contest.title">Active contest</small>
    </a>
  </h2>

  <div class="__views" mipBgColor="gray">
    <span>{{ (contestModel ? contestModel.views : hashtagModel.views) | millify }}</span>
    <mip-icon [solid]="true" icon="play" size="2x"></mip-icon>
  </div>

</div>

<mip-slide-posts-preview
  *ngIf="hashtagModel && postsList && postsList.length"
  [baseRoute]="['/hashtag', hashtagModel.hashtag, 'p']"
  [enableAvatarCrowns]="!!contestModel"
  [enableSampleLabel]="true"
  [enableTimestamp]="enableTimestamp"
  [infoOnTop]="true"
  [orientation]="!orientation && isDesktop ? 'square' : orientation"
  [postsList]="postsList"
  [postsWinners]="postsWinners"
  [showThemeIcon]="false"
  [showViews]="true"
  [spacing]="slideSpacing"
  [tileHeight]="tileHeight"
  [tileWidth]="tileWidth ? tileWidth : isDesktop ? 200 : 95"
  seeMoreLink="/hashtag/{{hashtagModel.hashtag}}"
></mip-slide-posts-preview>
