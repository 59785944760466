import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectCollabUrlGuard implements CanActivate {
  /**
   * Main method of the guard, automatically called by Angular
   * to determine whether navigation to a route should be allowed.
   *
   * @param route Represents the active route at the time of navigation.
   * @param state Represents the URL state at the time of navigation.
   * @returns Always returns `false` because the goal is to redirect the user to another URL.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // Logs debugging information if the environment is not production
    if (!environment.production) {
      console.log('redirect-collab-url.guard->canActivate(): route', route);
      console.log('redirect-collab-url.guard->canActivate(): state', state);
    }

    // Manipulates the current URL to remove the '/dashboard' and '/collab' segments
    let newPath = state.url.replace(/^\/dashboard/, '').replace(/^\/collab/, '');
    console.log('redirect-collab-url.guard->canActivate(): newPath', newPath);

    const url = environment.collabUrl + newPath;
    console.log('redirect-collab-url.guard->canActivate(): url', url);

    // Redirects the user to the new URL using document.location.href
    document.location = url;

    return false; // Stops Angular's navigation cycle
  }
}
