import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

import { WithinViewportService } from './within-viewport.service';

interface WithinViewportOptionsInterface {
  rootMargin?: string;
  margin?: string | number;
  marginTop?: string | number;
  marginRight?: string | number;
  marginBottom?: string | number;
  marginLeft?: string | number;
  threshold?: number;
  delay?: number;
  enterDelay?: number;
  leaveDelay?: number;

  onEnter?: (any, InteractionObserverEntry) => void;
  onLeave?: (any, InteractionObserverEntry) => void;
  hasStarted?: boolean;

  showLayer?: boolean; // for debugging purpose
}

@Component({
  selector: 'mip-within-viewport',
  templateUrl: './within-viewport.component.html',
  styleUrls: ['./within-viewport.component.scss'],
  providers: [WithinViewportService]
})
export class WithinViewportComponent implements OnInit, OnChanges, OnDestroy {

  @Input() viewports: WithinViewportOptionsInterface | WithinViewportOptionsInterface[] = null;
  @Input() setChildMinHeight = true;

  @Output() onEnterViewport = new EventEmitter();
  @Output() onLeaveViewport = new EventEmitter();

  nativeElement = null;
  scrollableParent = null;

  intersectionChildren = [];
  intersectionObservers = [];

  constructor(
    private withinViewportService: WithinViewportService,
    { nativeElement }: ElementRef
  ) {

    this.nativeElement = nativeElement;
    // console.log('within-viewport.component->constructor(): this.nativeElement', this.nativeElement);

  }

  ngOnInit(): void {
    try {

      this.scrollableParent = utilsFactory.getCloserScrollableParent(this.nativeElement, false);
      // console.log(`within-viewport.component->constructor(): this.scrollableParent`, this.scrollableParent);

      // console.log('within-viewport.component->ngOnInit(): this.withinViewportService', this.withinViewportService);

      this.withinViewportService.onChildAdded().subscribe(response => {
        this.onChildAdded(response['el'], response['data']);
      });

    }
    catch (e) {
      console.error('within-viewport.component->ngOnInit(): ERROR', e);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewports'] && utilsFactory.isBrowser) {

      // console.log(`within-viewport.component->ngOnChanges(): this.viewports`, this.viewports);

      if (this.viewports) {
        const viewports = Array.isArray(this.viewports) ? this.viewports : [this.viewports];
        // console.log(`within-viewport.component->ngOnInit(): viewports`, viewports);

        for (const viewport of viewports) {
          if (!viewport.hasStarted) {
            viewport.hasStarted = true;
            this.createIntersectionObserver(viewport);
          }
        }
      }

    }

  }

  onChildAdded(childEl: HTMLElement, data: any) {

    if (utilsFactory.isBrowser) {
      // console.log('within-viewport.component->onChildAdded(): childEl', childEl);
      // console.log('within-viewport.component->onChildAdded(): this.intersectionObservers', this.intersectionObservers.length);

      childEl['within-viewport-data'] = data || {};

      this.intersectionChildren.push(childEl);

      for (const observer of this.intersectionObservers) {
        observer.observe(childEl);
      }

    }
  }

  ngOnDestroy() {

    for (const childEl of this.intersectionChildren) {
      for (const observer of this.intersectionObservers) {
        observer.unobserve(childEl);
      }
    }

    this.intersectionChildren = [];
    this.intersectionObservers = [];

    if (utilsFactory.isBrowser) {

      const container = document.body.querySelector('.within-viewport-layers');

      if (container) {
        container.remove();
      }

    }

  }

  createIntersectionObserver(viewport: WithinViewportOptionsInterface) {
    try {

      // console.log(`within-viewport.component->createIntersectionObserver(): viewport`, viewport);

      if (utilsFactory.isBrowser) {

        const margin = viewport.margin || 0;
        let marginTop = viewport.marginTop;
        let marginRight = viewport.marginRight;
        let marginBottom = viewport.marginBottom;
        let marginLeft = viewport.marginLeft;

        if (marginTop === undefined) {
          marginTop = margin;
        }

        if (marginRight === undefined) {
          marginRight = margin;
        }

        if (marginBottom === undefined) {
          marginBottom = marginTop;
        }

        if (marginLeft === undefined) {
          marginLeft = marginRight;
        }

        const marginArray = [marginTop, marginRight, marginBottom, marginLeft];
        // console.log(`within-viewport.component->createIntersectionObserver(): marginArray`, marginArray);

        const rootMargin = viewport.rootMargin || marginArray.map(i => typeof i === 'number' ? i + 'px' : i).join(' ');
        // console.log(`within-viewport.component->createIntersectionObserver(): rootMargin`, rootMargin);

        const observer = new IntersectionObserver((entries) => {
          // console.log(`within-viewport.component->createIntersectionObserver(): entries`, entries);

          for (const entry of entries) {
            // console.log(`within-viewport.component->createIntersectionObserver(): entry`, rootMargin, entry.isIntersecting, entry.target);

            const data = entry.target['within-viewport-data'];

            if (this.setChildMinHeight) {

              // setting a min-height to avoid elements to jump as soon
              // as the above children has been removed
              const minHeight = entry.target['offsetHeight'];
              // console.log(`within-viewport.component->createIntersectionObserver(): minHeight`, entry.isIntersecting, minHeight);
              entry.target['style'].minHeight = `${minHeight}px`;

            }

            if (entry.isIntersecting) {
              setTimeout(() => {
                // console.log(`within-viewport.component->createIntersectionObserver(): entry`, rootMargin, entry.isIntersecting, entry.target);

                this.onEnterViewport.emit({ data, entry });

                if (viewport.onEnter) {
                  viewport.onEnter(data, entry);
                }

              }, viewport.enterDelay || viewport.delay || 0);
            }
            else {
              setTimeout(() => {

                this.onLeaveViewport.emit({ data, entry });

                if (viewport.onLeave) {
                  viewport.onLeave(data, entry);
                }

              }, viewport.leaveDelay || viewport.delay || 0);

            }
          }

        }, {
          root: this.scrollableParent === window ? document : this.scrollableParent,
          rootMargin,
          threshold: viewport.threshold
        });

        if (viewport.showLayer) {

          let container = document.body.querySelector('.within-viewport-layers');

          if (!container) {
            container = document.createElement('ul');
            container.className = 'within-viewport-layers';
            document.body.appendChild(container);
          }

          // console.log(`within-viewport.component->createIntersectionObserver(): container`, container);

          const layer = document.createElement('li');
          container.appendChild(layer);

          const styleTop = parseInt((marginTop).toString().replace(/px/, ''), 10) * (-1);
          const styleBottom = parseInt((marginBottom).toString().replace(/px/, ''), 10) * (-1);
          // console.log(`within-viewport.component->createIntersectionObserver(): styleTop, styleBottom`, {styleTop, styleBottom});

          layer.style.top = styleTop + 'px';
          layer.style.bottom = styleBottom + 'px';

          if (viewport.threshold) {
            const thresholdTop = document.createElement('span');
            thresholdTop.style.top = (viewport.threshold * 100) + '%';
            layer.appendChild(thresholdTop);

            const thresholdBottom = document.createElement('span');
            thresholdBottom.style.bottom = (viewport.threshold * 100) + '%';
            layer.appendChild(thresholdBottom);
          }

        }

        // console.log(`within-viewport.component->createIntersectionObserver(): this.intersectionChildren.length`, this.intersectionChildren.length);
        if (this.intersectionChildren.length) {
          for (const child of this.intersectionChildren) {
            observer.observe(child);
          }
        }

        this.intersectionObservers.push(observer);
      }

    }
    catch (e) {
      console.error('within-viewport.component->createIntersectionObserver(): ERROR', e);
    }
  }

}
