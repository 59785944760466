import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Component({
  selector: 'mip-card',
  templateUrl: './mip-card.component.html',
  styleUrls: ['./mip-card.component.scss']
})
export class MipCardComponent implements OnInit, OnChanges {

  @Input() noShadow = false;
  @Input() matElevation = 1;
  @Input() mipBgColor = null;
  @Input() noBackground = !!this.mipBgColor;
  @Input() border = '';
  @Input() borderRadius: string | number = 0;
  @Input() class = '';

  containerEl = null;
  classes = [];
  matElevationClass = null;

  borderRadiusPx = '0px';

  constructor(
    private hostEl: ElementRef
  ) {
    this.containerEl = this.hostEl.nativeElement;
  }

  ngOnInit() {
    this.setShadow();
  }

  ngOnChanges(changes: SimpleChanges) {

    // on "mipBgColor" changes
    if (changes['mipBgColor']) {
      this.noBackground = !!this.mipBgColor;
    }

    // on "borderRadius" changes
    if (changes['borderRadius']) {
      if (typeof this.borderRadius === 'string') {
        this.containerEl.style.borderRadius = this.borderRadius + (this.borderRadius.indexOf('px') > -1 ? '' : 'px');
      }
      else if (typeof this.borderRadius === 'number') {
        this.containerEl.style.borderRadius = this.borderRadius + 'px';
      }
    }

    // on "noShadow" changes
    if (changes['noShadow']) {
      if (this.noShadow) {
        utilsFactory.addClass(this.containerEl, '--no-shadow');
      }
      else {
        utilsFactory.removeClass(this.containerEl, '--no-shadow');
      }
    }

    // on "matElevation" changes
    if (changes['matElevation']) {
      this.setShadow();
    }

    // on "class" changes
    if (changes['class'] && this.class) {

      this.classes = [];

      const classes = this.class.split(' ');
      // `console.log('mip-card.component->ngOnChanges(): this.class', this.class);

      this.classes = [...this.classes, ...classes];
      // console.log('mip-card.component->ngOnChanges(): this.classes', this.classes);

    }

  }

  setShadow() {
    // console.log('mip-card.component->setShadow(): this.noShadow', this.noShadow, this.containerEl);

    if (this.noShadow !== true) {
      // this.classes.push(`mat-elevation-z${this.matElevation}`);
      utilsFactory.addClass(this.containerEl, `mat-elevation-z${this.matElevation}`);
      this.matElevationClass = `mat-elevation-z${this.matElevation}`;

    }
    else if (this.matElevationClass) {
      utilsFactory.removeClass(this.containerEl, this.matElevationClass);
    }

  }

}

