import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Component({
  selector: 'mip-see-more',
  templateUrl: './mip-see-more.component.html',
  styleUrls: ['./mip-see-more.component.scss']
})
export class MipSeeMoreComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('textContainer') textContainer: ElementRef;

  @Input() disable = false;
  @Input() text = '';
  @Input() minTextLines = 3;
  @Input() maxTextLines = 1000;
  @Input() lineHeight = 20;
  @Input() fontSize = 14;
  @Input() actionTextColor = 'primary';
  @Input() actionTextUpperCase = true;
  @Input() hideSeeMoreButton = false;

  @Input() linkifySettings: {
    linkifyUrls?: boolean,
    baseUrl?: { hashtag?: string | boolean, profile?: string | boolean }
  } = { baseUrl: { hashtag: '/hashtag' } };

  textContainerEl = null;
  showSeeMoreButton = false;

  currentTextLines = this.minTextLines;
  isOpened = false;
  hideText = true;

  constructor() {
  }

  ngOnInit(): void {
    // console.log('mip-see-more.component->ngOnInit(): this.text', this.text);
  }

  async ngOnChanges() {
    if (this.disable !== true) {

      await utilsFactory.waitToBeTrue('mip-see-more.component', () => !!this.textContainer);
      // console.log('mip-see-more.component->ngAfterViewInit(): this.textContainer', this.textContainer);

      if (this.textContainer) {

        this.textContainerEl = this.textContainer.nativeElement;
        // console.log('mip-see-more.component->ngAfterViewInit(): this.textContainerEl', this.textContainerEl);

        const offsetHeight = this.textContainerEl.offsetHeight;
        // console.log('mip-see-more.component->ngAfterViewInit(): offsetHeight', offsetHeight);

        const linesNumber = Math.floor(offsetHeight / this.lineHeight);
        // console.log('mip-see-more.component->ngAfterViewInit(): linesNumber', linesNumber);

        if (linesNumber > this.minTextLines) {
          this.showSeeMoreButton = true;
        }

        this.hideText = false;

      }
    }
    else {
      // console.log('mip-see-more.component->ngAfterViewInit(): this.disable', this.disable);

      this.currentTextLines = 1000;
      // console.log('mip-see-more.component->ngAfterViewInit(): this.minTextLines', this.minTextLines);

      this.hideText = false;

    }
  }

  ngAfterViewInit() {

  }

  toggleText() {
    if (this.isOpened) {
      this.isOpened = false;
      this.currentTextLines = this.minTextLines;
    }
    else {
      this.isOpened = true;
      this.currentTextLines = this.maxTextLines;
    }
  }

}
