import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { MomentModule } from 'ngx-moment';

import { AdMediumRectangleComponent } from './ad-medium-rectangle/ad-medium-rectangle.component';
import { AdMobileBannerComponent } from './ad-mobile-banner/ad-mobile-banner.component';
import { AdNativeComponent } from './ad-native/ad-native.component';
import { AdPostVideoComponent } from './ad-post-video/ad-post-video.component';

@NgModule({
  declarations: [
    AdMediumRectangleComponent,
    AdMobileBannerComponent,
    AdNativeComponent,
    AdPostVideoComponent
  ],
  imports: [
    CommonModule,
    MomentModule,
    MipIconModule,
    MipTranslateModule,
    MatButtonModule
  ],
  exports: [
    AdMobileBannerComponent,
    AdNativeComponent,
    AdPostVideoComponent,
    AdMediumRectangleComponent
  ]
})
export class AdvertisementModule {
}
