import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { SearchService } from '@libs/gc-common/lib/api/services/search/search.service';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-textarea',
  templateUrl: './mip-textarea.component.html',
  styleUrls: ['./mip-textarea.component.scss']
})
export class MipTextareaComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('textareaElement') textareaElement: ElementRef;

  @Input() rows = 1;
  @Input() placeholder = null;
  @Input() actionIcon = null;
  @Input() actionIconBold = false;
  @Input() actionIconSolid = false;
  @Input() colorIcon = null;
  @Input() text = '';
  @Input() isSaving = false;
  @Input() enableMentions = true;
  @Input() enableEmojis = true;

  @Output() onClickSendMessage = new EventEmitter();
  @Output() onKeyboardOpens = new EventEmitter();
  @Output() onKeyboardCloses = new EventEmitter();

  loggedInUser: UserModel = null;

  textareaEl = null;

  waitTimeout = null;
  listUsernamesAdded = [];

  hasSubmit = false;
  isSearching = false;
  listUsers = [];
  lastTermSearched = null;

  classColor = '';
  hexColor = '';

  cursorSelectionStart = 0;

  isEmojisModalOpened = false;
  emojisTranslation = null;

  isMobile = true;
  isDesktop = false;
  isSafari = false;

  isFocus = false;
  hasJustFocus = false;
  hasJustCloseUsers = false;

  onKeyboardOpenInterval = null;
  lastInnerHeight = 0;
  autocompleteMaxHeight = null;

  constructor(
    private mobileDetectService: MobileDetectService,
    private searchService: SearchService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private userService: UserService
  ) {

    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
    this.isSafari = this.mobileDetectService.isSafari();

    if (this.enableEmojis && !this.isDesktop) {
      this.enableEmojis = false;
    }

    this.translateService.get([
      'mip-textarea.search',
      'mip-textarea.emojilist',
      'mip-textarea.notfound',
      'mip-textarea.clear',
      'mip-textarea.categories.search',
      'mip-textarea.categories.recent',
      'mip-textarea.categories.people',
      'mip-textarea.categories.nature',
      'mip-textarea.categories.foods',
      'mip-textarea.categories.activity',
      'mip-textarea.categories.places',
      'mip-textarea.categories.objects',
      'mip-textarea.categories.symbols',
      'mip-textarea.categories.flags',
      'mip-textarea.categories.custom',
      'mip-textarea.skintones.1',
      'mip-textarea.skintones.2',
      'mip-textarea.skintones.3',
      'mip-textarea.skintones.4',
      'mip-textarea.skintones.5',
      'mip-textarea.skintones.6'
    ]).subscribe(translation => {
      this.emojisTranslation = {
        search: translation['mip-textarea.search'],
        emojilist: translation['mip-textarea.emojilist'],
        notfound: translation['mip-textarea.notfound'],
        clear: translation['mip-textarea.clear'],
        categories: {
          search: translation['mip-textarea.categories.search'],
          recent: translation['mip-textarea.categories.recent'],
          people: translation['mip-textarea.categories.people'],
          nature: translation['mip-textarea.categories.nature'],
          foods: translation['mip-textarea.categories.foods'],
          activity: translation['mip-textarea.categories.activity'],
          places: translation['mip-textarea.categories.places'],
          objects: translation['mip-textarea.categories.objects'],
          symbols: translation['mip-textarea.categories.symbols'],
          flags: translation['mip-textarea.categories.flags'],
          custom: translation['mip-textarea.categories.custom']
        },
        skintones: {
          1: translation['mip-textarea.skintones.1'],
          2: translation['mip-textarea.skintones.2'],
          3: translation['mip-textarea.skintones.3'],
          4: translation['mip-textarea.skintones.4'],
          5: translation['mip-textarea.skintones.5'],
          6: translation['mip-textarea.skintones.6']
        }
      };
    });

  }

  async ngOnInit() {

    if (this.userService.isAuthenticated()) {
      this.loggedInUser = await this.userService.getLoginUser();
      console.log('mip-textarea.component->ngOnInit(): this.loggedInUser', this.loggedInUser);
    }

    if (this.colorIcon) {
      if (this.colorIcon.indexOf('#') > -1) {
        this.hexColor = this.colorIcon;
      }
      else {
        this.classColor = this.colorIcon;
      }
    }

    if (utilsFactory.isBrowser && this.isMobile) {

      this.lastInnerHeight = window.innerHeight;

      /*this.onKeyboardOpenInterval = setInterval(() => {
       console.log('mip-textarea.component->ngOnInit(): setInterval: this.isFocus', this.isFocus, this.lastInnerHeight);

       if (this.isFocus) {

       if (window.innerHeight < this.lastInnerHeight) {
       this.onKeyboardOpens.emit(window.innerHeight);
       }
       else if (window.innerHeight > this.lastInnerHeight) {
       this.onKeyboardCloses.emit(window.innerHeight);
       }

       this.lastInnerHeight = window.innerHeight;

       }

       }, 100);*/
    }
  }

  ngAfterViewInit() {
    this.textareaEl = this.textareaElement.nativeElement;

    if (utilsFactory.isBrowser) {
    }
  }

  ngOnDestroy() {
    if (this.onKeyboardOpenInterval) {
      clearInterval(this.onKeyboardOpenInterval);
    }
  }

  applyTextChanges() {
    console.log('mip-textarea.component->applyTextChanges()');

    if (!this.enableMentions || this.hasJustCloseUsers) {
      return true;
    }

    if (this.waitTimeout) {
      clearTimeout(this.waitTimeout);
    }

    this.waitTimeout = setTimeout(() => {

      this.hasSubmit = false;

      const linksText: string[] = this.text.match(/@([a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-\_\.\+]*)/g) || [];
      console.log('mip-textarea.component->applyTextChanges(): linksText', linksText);

      console.log('mip-textarea.component->applyTextChanges(): this.listUsernamesAdded', this.listUsernamesAdded);

      const itemsToRemove: string[] = [];
      for (const i in this.listUsernamesAdded) {
        if (linksText.indexOf(this.listUsernamesAdded[i]) === -1) {
          itemsToRemove.push(this.listUsernamesAdded[i]);
        }
      }

      if (itemsToRemove.length) {
        console.log('mip-textarea.component->applyTextChanges(): itemsToRemove', itemsToRemove);
        // tslint:disable-next-line:forin
        for (const i in itemsToRemove) {
          this.listUsernamesAdded.splice(this.listUsernamesAdded.indexOf(itemsToRemove[i]), 1);
        }
        console.log('mip-textarea.component->applyTextChanges(): this.listUsernamesAdded', this.listUsernamesAdded);
      }

      this.changeDetectorRef.markForCheck();

      if (linksText.length) {
        console.log('mip-textarea.component->applyTextChanges(): linksText', linksText);

        let term = '';

        // tslint:disable-next-line:forin
        for (const i in linksText) {
          console.log('mip-textarea.component->applyTextChanges(): linksText[i]', linksText[i], this.listUsernamesAdded);

          if (this.listUsernamesAdded.indexOf(linksText[i]) === -1) {
            term = linksText[i];
            break;
          }
        }
        console.log('mip-textarea.component->applyTextChanges(): term', term);

        this.lastTermSearched = term;
        console.log('mip-textarea.component->applyTextChanges(): this.lastTermSearched', this.lastTermSearched);

        if (term.length >= 2) {
          this.isEmojisModalOpened = false;
          this.searchUser(term);
        }
        else if (this.loggedInUser && term) {
          this.isEmojisModalOpened = false;
          this.getUserFollowing();
        }

      }
      else {
        this.listUsers = [];
        this.isSearching = false;
        this.changeDetectorRef.markForCheck();
      }
      console.log('mip-textarea.component->applyTextChanges(): ----------------');
    }, 300);

  }

  resizeTextarea() {
    console.log('mip-textarea.component->resizeTextarea()');
    setTimeout(() => {
      this.textareaEl.style.cssText = 'height:auto; padding:0';
      this.textareaEl.style.cssText = 'height:' + this.textareaEl.scrollHeight + 'px';
    }, 50);
  }

  focusTextarea() {

    if (this.hasJustFocus) {
      return;
    }

    console.log('mip-textarea.component->onBlur()');

    this.textareaEl.focus();
    this.textareaEl.setSelectionRange(this.cursorSelectionStart, this.cursorSelectionStart);

    setTimeout(() => {
      this.textareaEl.focus();
      this.textareaEl.setSelectionRange(this.cursorSelectionStart, this.cursorSelectionStart);
      this.hasJustFocus = false;
    }, 100);

    this.hasJustFocus = true;

  }

  onTextareaClick(event) {
    setTimeout(() => {
      console.log('mip-textarea.component->onTextareaClick(): event', event.target.selectionStart);
      this.cursorSelectionStart = event.target.selectionStart;
      console.log('mip-textarea.component->onTextareaClick(): this.cursorSelectionStart', this.cursorSelectionStart);
    }, 10);
  }

  onTextareaFocus(event) {
    this.isFocus = true;
    this.onTextareaClick(event);

    if (utilsFactory.isBrowser && this.isMobile) {
      setTimeout(() => {
        console.log('mip-textarea.component->onTextareaFocus(): window.innerHeight', window.innerHeight);
        this.autocompleteMaxHeight = window.innerHeight - 60;
        this.changeDetectorRef.markForCheck();

        if (window.innerHeight < this.lastInnerHeight) {
          this.onKeyboardOpens.emit(window.innerHeight);
          this.lastInnerHeight = window.innerHeight;
        }
      }, 200);
      /*if (window.innerHeight < this.lastInnerHeight) {
       this.onKeyboardOpens.emit(window.innerHeight);
       }
       else if (window.innerHeight > this.lastInnerHeight) {
       this.onKeyboardCloses.emit(window.innerHeight);
       }*/
    }

  }

  onTextareaBlur() {
    this.isFocus = false;
    this.resizeTextarea();

    if (utilsFactory.isBrowser && this.isMobile) {
      setTimeout(() => {
        if (window.innerHeight > this.lastInnerHeight) {
          this.onKeyboardCloses.emit(window.innerHeight);
          this.lastInnerHeight = window.innerHeight;
        }
      }, 200);
    }
  }

  onTextareaKeydown(event) {
    console.log('mip-textarea.component->onKeydown(): this.text', this.text);
    if (event.which === 13) {
      event.preventDefault();
      this.onSubmit();
      return false;
    }

    this.onTextareaClick(event);
    this.resizeTextarea();

    // setTimeout(() => {
    this.applyTextChanges();
    // }, 10);
  }

  closeUsersList() {

    this.listUsers = [];
    this.lastTermSearched = null;
    this.hasJustCloseUsers = true;

    const rgx = new RegExp(`(@\\s|@$)`, 'g');
    this.text = this.text.replace(rgx, '');

    setTimeout(() => {
      this.hasJustCloseUsers = false;
    }, 10);

    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 10);

  }

  onSelectUser(user) {

    console.log('mip-textarea.component->onSelectUser(): user', user, this.lastTermSearched);
    const rgx = new RegExp(`(${this.lastTermSearched}\\s|${this.lastTermSearched}$)`, 'g');
    console.log('mip-textarea.component->onSelectUser(): rgx', rgx, this.lastTermSearched);

    if (this.listUsernamesAdded.indexOf(`@${user.username}`) === -1) {
      this.listUsernamesAdded.push(`@${user.username}`);
    }

    const username = `@${user.username} `;
    console.log('mip-textarea.component->onSelectUser(): username', rgx, username);

    this.text = this.text.replace(rgx, username);
    this.cursorSelectionStart += username.length;
    console.log('mip-textarea.component->onSelectUser(): AFTER', rgx, this.lastTermSearched);
    this.focusTextarea();
    // this.changeDetectorRef.markForCheck();

    this.closeUsersList();

  }

  onEmojiSelect({ emoji }) {
    if (this.enableEmojis) {

      this.focusTextarea();

      console.log('mip-textarea.component->onEmojiSelect(): emoji', this.cursorSelectionStart, emoji.native, emoji.native.length);

      this.text = [this.text.slice(0, this.cursorSelectionStart), ` ${emoji.native} `, this.text.slice(this.cursorSelectionStart)].join('').replace(/\s\s/g, ' ');
      console.log(this.text);

      this.cursorSelectionStart += 3;

    }
  }

  resetForm() {
    console.log('mip-textarea.component->resetForm()');
    this.text = '';
  }

  onPaste(event) {
    event.preventDefault();
    this.resizeTextarea();
  }

  toggleEmojisModal(event) {

    setTimeout(() => {
      console.log('mip-textarea.component->toggleEmojisModal()');
      this.focusTextarea();

      this.isEmojisModalOpened = !this.isEmojisModalOpened;
      this.changeDetectorRef.markForCheck();

      if (this.isEmojisModalOpened) {
        this.closeUsersList();
      }
    }, 10);
  }

  closeEmojisModal() {
    if (this.isEmojisModalOpened) {
      console.log('mip-textarea.component->closeEmojisModal()');
      this.isEmojisModalOpened = false;
    }
  }

  addMentionSymbol() {
    if (this.enableMentions) {

      this.focusTextarea();
      // this.isEmojisModalOpened = false;

      console.log('mip-textarea.component->addMentionSymbol(): this.cursorSelectionStart', this.cursorSelectionStart);

      this.text = [this.text.slice(0, this.cursorSelectionStart), `@`, this.text.slice(this.cursorSelectionStart)].join('').replace(/\s\s/g, ' ');
      console.log(this.text);

      this.cursorSelectionStart += 2;

      this.applyTextChanges();

    }
  }

  async searchUser(term) {
    try {

      console.log('mip-textarea.component->searchUser(): term', term);

      if (this.hasSubmit) {
        return false;
      }

      this.listUsers = [];

      if (!term) {
        throw new Error(`Username must be provided`);
      }

      this.isSearching = true;
      this.changeDetectorRef.markForCheck();

      const { users } = await this.searchService.getAccountsSearch({ term: term.split('@')[1], limit: 20 });

      if (this.hasSubmit) {
        return false;
      }

      for (const user of users) {
        console.log('mip-textarea.component->searchUser():this.listUsernamesAdded', `@${user.username}`);
        if (this.listUsernamesAdded.indexOf(`@${user.username}`) === -1) {
          this.listUsers.push(user);
        }
      }
      console.log('mip-textarea.component->searchUser(): this.listUsers', this.listUsers);

      this.isSearching = false;
      this.changeDetectorRef.markForCheck();

    }
    catch (e) {
      this.isSearching = false;
      this.changeDetectorRef.markForCheck();
      throw e;
    }
  }

  async getUserFollowing() {
    try {

      if (this.hasSubmit) {
        return false;
      }

      this.listUsers = [];
      this.isSearching = true;
      this.changeDetectorRef.markForCheck();

      const response = await this.loggedInUser.getUserFollowing({ limit: 20 });
      console.log('mip-textarea.component->getUserFollowing(): response', response);

      if (this.hasSubmit) {
        return false;
      }

      for (const user of response.list) {
        console.log('mip-textarea.component->getUserFollowing():this.listUsernamesAdded', `@${user.username}`);
        if (this.listUsernamesAdded.indexOf(`@${user.username}`) === -1) {
          this.listUsers.push(user);
        }
      }
      console.log('mip-textarea.component->getUserFollowing(): this.listUsers', this.listUsers);

      this.isSearching = false;
      this.changeDetectorRef.markForCheck();

    }
    catch (e) {
      throw e;
    }
  }

  async onSubmit() {

    this.focusTextarea();

    this.hasSubmit = true;

    this.listUsernamesAdded = [];
    this.isSearching = false;
    this.listUsers = [];
    this.lastTermSearched = null;
    this.isEmojisModalOpened = false;
    this.cursorSelectionStart = 0;
    this.changeDetectorRef.markForCheck();

    this.onClickSendMessage.emit({
      text: this.text,
      resetForm: this.resetForm.bind(this)
    });

    setTimeout(() => {
      this.focusTextarea();
      this.closeUsersList();
    }, 500);

  }

}
