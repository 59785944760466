import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import DebugFactory from '@libs/gc-common/lib/factories/debug.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { AdvertisementService } from '@libs/gc-common/lib/features/advertisement/advertisement.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { AdBusterService } from '@libs/gc-common/lib/services/ad-buster/ad-buster.service';

interface AdMobileBannerSlotInterface {
  label: string;
  id: string;
  adSlotId: string;
  hasFilled: boolean;
}

@Component({
  selector: 'mip-ad-mobile-banner',
  templateUrl: './ad-mobile-banner.component.html',
  styleUrls: ['./ad-mobile-banner.component.scss']
})
export class AdMobileBannerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() logs = false;
  @Input() disableAd;
  @Input() adSlotId: string;

  sectionTimer = '00:00';
  totalAdRequest = 0;
  totalAdFilled = 0;

  isBrowser = utilsFactory.isBrowser;

  refreshTimeout = 15000;

  slotA: AdMobileBannerSlotInterface = {
    label: 'Slot-A',
    adSlotId: 'ad-mobile-banner-slot-a',
    id: utilsFactory.generateGUID(),
    hasFilled: false
  };

  slotB: AdMobileBannerSlotInterface = {
    label: 'Slot-B',
    adSlotId: 'ad-mobile-banner-slot-b',
    id: utilsFactory.generateGUID(),
    hasFilled: false
  };

  isRequestingAnAd = false;
  adUnitAvailable = null;
  lastAdRequestTime = null;
  nextAdRequestTimeout = null;
  pauseAdRequest = false;

  observerHelper = new ObserverHelper();

  constructor(
    private adBusterService: AdBusterService,
    private advertisementService: AdvertisementService,
    private hostRef: ElementRef
  ) {
    this.adUnitAvailable = this.adBusterService.getAdUnitsBySize([320, 50]);
    console.log('ad-mobile-banner.component->constructor(): this.adUnitAvailable', this.adUnitAvailable);

    if (this.disableAd === undefined) {
      this.disableAd = this.advertisementService.isAdDisabled('disableMobileTopAd');
    }
    console.log('ad-mobile-banner.component->constructor():', this.disableAd);
  }

  ngOnInit() {

    this.observerHelper.addSubscription(
      utilsFactory.onTabChangingActiveState().subscribe(isTagFocused => {
        console.log('ad-mobile-banner.component->ngOnInit(): isTagFocused', isTagFocused);
        this.pauseAdRequest = !isTagFocused;
      })
    );

    if (this.isBrowser && this.logs) {
      this.observerHelper.addSubscription(
        this.advertisementService.startSectionClock().subscribe(section => {
          this.sectionTimer = section.clock;
          this.totalAdRequest = section.adsRequestCount;
          this.totalAdFilled = section.adsFilledCount;
        })
      );
    }

    if (this.adSlotId) {
      this.slotA.adSlotId = `${this.adSlotId}-slot-a`;
      this.slotB.adSlotId = `${this.adSlotId}-slot-b`;
    }

  }

  ngAfterViewInit() {
    console.log('ad-mobile-banner.component->ngAfterViewInit(): adSlot1', document.querySelector(`#${this.slotA.id}`));
    console.log('ad-mobile-banner.component->ngAfterViewInit(): adSlot2', document.querySelector(`#${this.slotB.id}`));

    // rendering AD on slotA
    this.renderAd(this.slotA, this.slotB, 0);

  }

  ngOnDestroy() {
    clearTimeout(this.nextAdRequestTimeout);

    this.observerHelper.unsubscribeAll();
  }

  /**
   * Method to deal slots switching and AD rendering
   */
  async renderAd(slot1: AdMobileBannerSlotInterface, slot2: AdMobileBannerSlotInterface, adUnitIndex) {
    if (utilsFactory.isBrowser && !this.disableAd) {
      try {
        console.log('ad-mobile-banner.component->renderAd(): -----------------------------');
        console.log('ad-mobile-banner.component->renderAd():', this.isRequestingAnAd, this.disableAd);

        if (this.isRequestingAnAd || this.disableAd === true) {
          return;
        }

        // setting a flag to prevent multiples ad request ad once
        this.isRequestingAnAd = true;

        // should we pause the ad request?
        // await this.waitTabToBeFocused();

        let nextAdUnitIndex;

        if (adUnitIndex === (this.adUnitAvailable.adUnits.length - 1)) { // === 3
          nextAdUnitIndex = 0;
        }
        else {
          // tslint:disable-next-line:radix
          nextAdUnitIndex = parseInt(adUnitIndex) + 1;
        }

        try {

          this.lastAdRequestTime = Date.now();

          await utilsFactory.waitToBeTrue('ad-mobile-banner.component', () => !!document.querySelector(`#${slot1.id}`));

          const slotElement = document.querySelector(`#${slot1.id}`);
          console.log(`ad-native.component->renderAd(): slotElement`, adUnitIndex, slotElement);

          // slot1.adSlotIdCode = `${this.adUnitAvailable.adUnitsCode[adUnitIndex]}#${this.adUnitAvailable.size.join('x')}`;
          slotElement.setAttribute('data-adslotid', slot1.adSlotId);
          console.log('ad-mobile-banner.component->renderAd(): slot1.adSlotId', slot1.adSlotId, document.querySelector(`#${slot1.id}`));

          await this.advertisementService.registerAdSlot(this.adUnitAvailable.adUnits[adUnitIndex], slot1.id);
          DebugFactory.success('styled', `ad-mobile-banner.component->renderAd(): ${slot1.label} (${this.adUnitAvailable.adUnits[adUnitIndex]}) has FILLED`);
          console.log('ad-mobile-banner.component->renderAd(): FILLED? has FILLED', slot1);

          // reset flag
          this.isRequestingAnAd = false;

          // this.onAdFilled(this.adUnitCodesAvailable.adUnits[adUnitIndex]);

          slot1.hasFilled = true;
          slot2.hasFilled = false;

          document.querySelector(`#${slot2.id}`).innerHTML = '';
          slot2.id = utilsFactory.generateGUID();

          this.nextAdRequestTimeout = setTimeout(() => {
            DebugFactory.info('styled', `ad-mobile-banner.component->renderAd(): Refreshing ${slot2.label} (${this.adUnitAvailable.adUnits[nextAdUnitIndex]})`);
            this.renderAd(slot2, slot1, nextAdUnitIndex);
          }, 30000);

        }
        catch (e) {

          console.log('ad-mobile-banner.component->renderAd(): NO-FILL');

          this.isRequestingAnAd = false;

          // if returns "e.message" from the registerAdSlot(), it means we have a JS error
          if (e['message']) {
            throw e;
          }

          DebugFactory.error('styled', `ad-mobile-banner.component->renderAd(): ${slot1.label} (${this.adUnitAvailable.adUnits[adUnitIndex]}) has NO-FILL`);
          document.querySelector(`#${slot1.id}`).innerHTML = '';
          slot1.hasFilled = false;

          this.nextAdRequestTimeout = setTimeout(() => {
            DebugFactory.warn('styled', `ad-mobile-banner.component->renderAd(): Retrying ${slot1.label} (${this.adUnitAvailable.adUnits[nextAdUnitIndex]})`);
            slot1.id = utilsFactory.generateGUID();
            this.renderAd(slot1, slot2, nextAdUnitIndex);
          }, 10000);

        }
      }
      catch (e) {
        this.isRequestingAnAd = false;
        console.error('ad-mobile-banner.component->renderAd(): ERROR', e);
        throw e;
      }
    }
  }

  /**
   * Method to watch the "pauseAdRequest" to decide if we need to pause the ADs or not.
   */
  async waitTabToBeFocused() {
    return new Promise(resolve => {
      const pauseInterval = setInterval(() => {

        console.log('ad-mobile-banner.component->waitTabToBeFocused(): this.pauseAdRequest', this.pauseAdRequest);

        if (this.pauseAdRequest === false) {
          clearInterval(pauseInterval);
          resolve(true);
        }
      }, 100);
    });
  }

  /**
   * Method to be triggered every time an AD has filled
   */

  /*onAdFilled(adUnitCode: string) {
   try {
   this.advertisementService.registerAdUnitAdFilledCount(adUnitCode);
   }
   catch (e) {
   throw e;
   }
   }*/

  /**
   * Method to do an AdRequest externally
   */
  refreshAd(force = false) {
    try {

      console.log('ad-mobile-banner.component->refreshAd(): lastFillTime', force, (Date.now() - this.lastAdRequestTime), this.refreshTimeout);
      console.log('ad-mobile-banner.component->refreshAd(): this.isRequestingAnAd', this.isRequestingAnAd);

      if (this.isRequestingAnAd) {
        return false;
      }

      if (force || (Date.now() - this.lastAdRequestTime) >= this.refreshTimeout) {

        let slot1 = this.slotA;
        let slot2 = this.slotB;

        if (this.slotA.hasFilled) {
          slot1 = this.slotB;
          slot2 = this.slotA;
        }

        console.log('ad-mobile-banner.component->refreshAd()');
        clearTimeout(this.nextAdRequestTimeout);
        // slot1.id = utilsFactory.generateGUID();

        setTimeout(() => {
          this.renderAd(slot1, slot2, 0);
        }, 100);
      }

    }
    catch (e) {
      throw e;
    }
  }

}
