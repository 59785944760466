import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedPostsService {

  feedPostsComponent = null;

  constructor() {
  }

  registerComponentScope(feedPostsComponent) {
    this.feedPostsComponent = feedPostsComponent;
    console.log('feed-posts.service->constructor(): this.feedPostsComponent', this.feedPostsComponent);
  }

  goUp() {
    console.log('feed-posts.service->goUp(): this.feedPostsComponent', this.feedPostsComponent);
    this.feedPostsComponent.goUp();
  }

  goDown() {
    console.log('feed-posts.service->goDown(): this.feedPostsComponent', this.feedPostsComponent);
    this.feedPostsComponent.goDown();
  }

}
