import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MipLottieModule } from '@libs/gc-common/lib/components/mip-lottie/mip-lottie.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { MipIconComponent } from './mip-icon.component';

@NgModule({
	declarations: [MipIconComponent],
	imports: [
		CommonModule,
		MipLottieModule,
		// NgIf,
		MatIconModule,
		ColorModule,
	],
	exports: [MipIconComponent],
})
export class MipIconModule {
}
