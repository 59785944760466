<div
  class="__back-top"
  *ngIf="tiles.length"
  [mipScrollFix]="-100"
  [marginTop]="backTopMarginTop || (isDesktop ? 90 : 20)"
  [hideOnUnstick]="true"
  nameSpace="mip-feed-grid"
>
  <div class="mip-scroll-fix-element">
    <button mat-icon-button mipBgColor="primary" (click)="scrollToTop()">
      <mip-icon icon="arrow-up" [bold]="true"></mip-icon>
    </button>
  </div>
</div>

<div class="feed-grid" #containerElement [mipScrollTop]="scrollTop">

  <div *ngIf="!loading && !tiles.length">
    <mip-alert textAlign="center">
      <p mipTranslate="no-content-here">No content here :/</p>
    </mip-alert>
    <hr>
  </div>

  <div
    class="__grid-container"
    [mipOnReachingBottom]="postsList.length"
    (onReachingBottom)="onReachingBottom()"
  >

    <div
      class="__grid-item"
      [style.min-width]="100/columns + '%'"
      [style.max-width]="100/columns + '%'"
      [style.width]="100/columns + '%'"
      [style.padding]="padding + 'px'"
      *ngFor="let tile of tiles; let $index = index"
    >

      <mip-feed-item
        [postModel]="tile.model"
        [baseRoute]="baseRoute"
        [customParams]="customParams"
        [showViews]="showViews"
        [showPlays]="showPlays"
        [orientation]="orientation"
        [showThemeIcon]="showThemeIcon"
        [avatarCrowns]="avatarCrowns"
        [showSampleLabel]="enableSampleLabel"
        [avatarCrown]="getAvatarCrown($index, tile.model)"
        [showAvatar]="!!getAvatarCrown($index, tile.model)"
        [avatarBorderColor]="avatarBorderColor"
        [avatarBorderWidth]="avatarBorderWidth"
        [avatarSize]="avatarSize"
        [isOriginalPost]="tile.model.id === audioId"
        [enableVideoPreview]="enableVideoPreview !== null ? enableVideoPreview : isDesktop"
      ></mip-feed-item>

    </div>

  </div>

  <div class="__grid-loading" *ngIf="tiles.length && loading">
    <mip-loading></mip-loading>
  </div>

  <!-- section BOTTOM ACTION -->
  <mip-join-hashtag-button
    *ngIf="hashtagModel && !loading"
    [hashtagModel]="hashtagModel"
    [floatingButton]="true"
    [marginBottom]="150"
  ></mip-join-hashtag-button>

  <!-- && lastResponseCount === 0 -->
  <div
    *ngIf="tiles.length >= tilesLimit && !loading"
  >
    <mip-download-app-see-more
      [label]="downloadAppSeeMoreLabel"
    ></mip-download-app-see-more>
  </div>

  <!-- INITIAL LOADING -->
  <div class="__feed-loading" *ngIf="!tiles.length && loading">
    <mip-loading></mip-loading>
  </div>

</div>
