import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'mip-card-header',
  templateUrl: './mip-card-header.component.html',
  styleUrls: ['./mip-card-header.component.scss']
})
export class MipCardHeaderComponent {

  @Input() dropShadow = false;
  @Input() noShadow = false;

  constructor() {
    console.log('mip-card-header.component->constructor()');
  }

}
