import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { ContestWinnerModel } from '@libs/gc-common/lib/api/models/contest-winner';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'mip-slide-posts-preview',
  templateUrl: './slide-posts-preview.component.html',
  styleUrls: ['./slide-posts-preview.component.scss']
})
export class SlidePostsPreviewComponent implements OnInit {

  @ViewChild('swiperNavigation', { static: true }) swiperNavigation: ElementRef;

  @Input() postsList: Array<PostModel> = [];
  @Input() postsWinners: {
    winner: ContestWinnerModel,
    second: ContestWinnerModel,
    joker: ContestWinnerModel
  } = null;
  @Input() baseRoute = null;
  @Input() spacing = 1;
  @Input() tileWidth = 95;
  @Input() tileHeight = null;
  @Input() orientation = 'portrait';
  // @Input() config = {};
  @Input() enableAvatarCrowns = false;
  @Input() enableSampleLabel = this.enableAvatarCrowns;
  @Input() showViews = false;
  @Input() enableTimestamp = true;
  @Input() showThemeIcon = false;
  @Input() seeMoreLink: string = null;
  @Input() infoOnTop = false;

  assetsPath = environment.assetsPath;

  swiperNavigationEl = null;
  buttonPrevEl = null;
  buttonNextEl = null;

  hasSamplePost = false;

  swiperConfig: SwiperOptions = null;

  index = 0;
  isMobile = true;

  slideItems = [];

  constructor(
    private mobileDetect: MobileDetectService
  ) {

    this.isMobile = this.mobileDetect.isMobile();

  }

  ngOnInit() {

    this.swiperNavigationEl = this.swiperNavigation.nativeElement;
    // console.log('slide-posts-preview.component->ngOnInit(): this.swiperNavigationEl', this.swiperNavigationEl);

    this.buttonPrevEl = this.swiperNavigationEl.querySelector('.__slide-posts-preview.--prev');
    // console.log('slide-posts-preview.component->ngOnInit(): this.buttonPrevEl', this.buttonPrevEl);

    this.buttonNextEl = this.swiperNavigationEl.querySelector('.__slide-posts-preview.--next');
    // console.log('slide-posts-preview.component->ngOnInit(): this.buttonNextEl', this.buttonNextEl);

    this.swiperConfig = {
      slidesPerView: 'auto',
      // autoHeight: true,
      navigation: {
        prevEl: this.buttonPrevEl,
        nextEl: this.buttonNextEl,
        disabledClass: '--disabled'
      }
    };

  }

  getAvatarCrown(postIndex, postModel) {

    if (this.hasSamplePost === false) {
      this.hasSamplePost = postModel.sample;
    }

    if (this.enableAvatarCrowns) {

      // console.log('slide-posts-preview.component->getAvatarCrown(): postModel.contest_role', postModel.contest_role, postModel);

      switch (postModel.contest_role) {
        case 'winner':
          return 'king';
        case 'second':
          return 'prince';
        case 'joker':
          return 'joker';
        default:
          return null;
      }
    }

    return null;

  }

}
