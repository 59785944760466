<div
  class="ad-slot-container"
  [class.--is-mobile]="isMobile"
  [class.--is-desktop]="isDesktop"
  [class.--is-header-enabled]="enableHeader"
  [style.background-color]="bgColor"
>
  <div class="__ad-header" *ngIf="enableHeader">
    <button
      mat-button
      class="mip-button--size-sm mip-color--bg-white"
      (click)="closeAd()"
    >
      <span mipTranslate="ad-native.actions.close">Close</span>
    </button>
  </div>

  <!-- SLOT 1 -->
  <div
    class="__ad-container __slot-A"
    [id]="slotA.id"
    [class.--has-filled]="slotA.hasFilled"
  ></div>

  <!-- SLOT 2 -->
  <div
    class="__ad-container __slot-B"
    [id]="slotB.id"
    [class.--has-filled]="slotB.hasFilled"
  ></div>

  <img
    class="__canvas"
    *ngIf="enableCanvas"
    src="{{assetsPath}}/images/canvas/720-1280-canvas.svg"
  >

</div>

<div class="ad-unit-info" *ngIf="logs">
  <small>ADs: {{totalAdRequest}}</small>
  <small>FILL: {{totalAdFilled}}</small>
  <small>T: {{sectionTimer}}</small>
</div>
