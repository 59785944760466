<mat-nav-list
  #menu
  *ngIf="optionsList.length"
>
  <!--
  (selectionChange)="onSelection($event, menu.selectedOptions.selected[0])"
  -->
  <mat-list-item class="__title" *ngIf="title">
    <div class="__title-content">
      {{title}}
    </div>
  </mat-list-item>

  <mat-list-item
    *ngFor="let item of optionsList"
    [class.--is-disabled]="item.disabled"
    [class.--has-divider]="item.divider"
  >

    <button
      mat-button
      class="__option-content"
      [class]="item.class"
      (click)="onSelection($event, item)"
    >

      <span
        class="__option-text"
        [class]="item.textClass"
        [class.--nowrap]="item.nowrap"
      >
        <mip-avatar
          *ngIf="item.avatar"
          [avatar]="item.avatar"
          [size]="30"
        ></mip-avatar>

        <mip-icon
          *ngIf="item.icon"
          [icon]="item.icon"
          [bold]="item.iconBold"
        ></mip-icon>

        <span [innerHTML]="item.text"></span>

      </span>

    </button>

  </mat-list-item>
</mat-nav-list>
