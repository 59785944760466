import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { videoPlayerFactory } from '@libs/gc-common/lib/factories/video-player.factory';
import { MetaTagsService } from '@libs/gc-common/lib/services/metatags/metatags.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

import { PostViewService } from './post-view.service';

@Component({
  selector: 'mip-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('videoPreload') videoPreload: ElementRef;

  @Input() postModel: PostModel = null;
  @Input() postBasePath = null;
  @Input() isIntoViewport = false;
  @Input() isIntoTopBounceLimit = false;
  @Input() isMoving = false;
  @Input() isModal = false;
  @Input() postVideoAdType: string = null;
  @Input() isPostRollAdEnable = false;
  @Input() isPreRollAdEnable = false;
  @Input() isAdEnable = false;
  @Input() forceTheme = null;
  @Input() postInteraction = true;
  @Input() postIndex = null;
  @Input() enableLastSeen = false;
  @Input() swiperNavigation = false;
  @Input() isFirstSlide = false;
  @Input() isLastSlide = false;

  @Input() isMobile = null;
  @Input() isDesktop = null;

  @Output() onPostHeaderToggle = new EventEmitter();
  @Output() onToggleComments = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();
  @Output() onPostAdFill = new EventEmitter();
  @Output() onPostAdHide = new EventEmitter();
  @Output() onPostAdPlayed = new EventEmitter();
  @Output() onPostAdEnded = new EventEmitter();

  disableLogs = environment.disableLogs;
  isPostAdDisable = environment.adsPlacement.disablePostAd;

  isAutoplayMode = false;
  isLogsEnabled = false;

  hasEnterOnceIntoViewport = false;

  slideIndex = 0;
  playingAd = false;

  config = {
    speed: 1,
    pagination: true,
    autoHeight: false,
    allowTouchMove: false
  };

  lastSeenInterval = null;
  videoPlayerFactoryObserver = null;

  videoPreloadEl = null;

  constructor(
    private pvs: PostViewService,
    private metaTagsService: MetaTagsService,
    private routerService: RouterService,
    private mobileDetect: MobileDetectService
  ) {

    this.isLogsEnabled = !environment.disableLogs;
    // console.log('post-view.component->constructor(): this.isAdEnable', this.isAdEnable);
  }

  async ngOnInit() {
    try {
      // console.log('post-view.component->ngOnInit(): this.postModel', this.postModel);

      if (typeof this.isMobile !== 'boolean') {
        this.isMobile = this.mobileDetect.isMobile();
      }

      if (typeof this.isDesktop !== 'boolean') {
        this.isDesktop = !this.mobileDetect.isMobile();
      }

      this.isAutoplayMode = videoPlayerFactory.autoplay;
      // console.log('post-view.component->ngOnInit(): this.isAutoplayMode', this.isAutoplayMode, this.postModel.id);

      if (this.isAutoplayMode === false) {
        this.videoPlayerFactoryObserver = videoPlayerFactory.observer.subscribe(state => {

          this.isAutoplayMode = videoPlayerFactory.autoplay;
          // console.log('post-view.component->ngOnInit(): this.isAutoplayMode (subscribe)', state, this.isAutoplayMode, this.postModel.id);

          if (this.isAutoplayMode) {
            this.loadVideoPreloadSource();
          }

        });
      }

      // if post ID on URL, let the component set metatags
      if (await this.routerService.getRouteParam('postId')) {

        const shareData: { title?, description, image } = {
          image: this.postModel.getCover(),
          description: `${this.postModel.author.getName()} @${this.postModel.author.username}`,
          title: `${(this.postModel.title || this.postModel.description)} - @${this.postModel.author.username}`
        };

        // console.log('post-view.component->ngOnChanges(): shareData', shareData);
        await this.metaTagsService.setMetaTags(shareData);

      }

    }
    catch (e) {
      console.error('post-view.component->ngOnInit(): ERROR', e);
    }
  }

  async ngOnChanges() {

    if (this.isIntoViewport) {

      // console.log('post-view.component->ngOnChanges(): this.postModel.id', this.isIntoViewport, this.postModel.id);
      // console.log('post-view.component->ngOnChanges(): this.isAdEnable', this.isAdEnable);
      // console.log('post-view.component->ngOnChanges(): this.postModel', this.postModel.id);

      if (environment.disableAds) {
        this.isAdEnable = false;
      }

      this.hasEnterOnceIntoViewport = true;
      // this.mediaModel = this.postModel.getMedia();

      // console.log('post-view.component->ngOnChanges(): this.postModel.lastSeen->setInterval()', this.postModel.id, this.enableLastSeen);
      if (utilsFactory.isBrowser && this.enableLastSeen) {
        this.postModel.lastSeen = true;
      }

    }
    else {
      // this.goBackToPost();
      this.postModel.lastSeen = false;
      // console.log('post-view.component->ngOnChanges(): this.postModel.lastSeen', this.postModel.id, this.isIntoViewport, this.postModel.lastSeen);
    }
  }

  async ngAfterViewInit() {

    this.videoPreloadEl = this.videoPreload.nativeElement;

    if (this.isAutoplayMode) {
      await this.loadVideoPreloadSource();
    }
  }

  ngOnDestroy() {
    if (this.videoPlayerFactoryObserver) {
      this.videoPlayerFactoryObserver.unsubscribe();
    }
  }

  async loadVideoPreloadSource() {

    if (!this.videoPreloadEl) {
      await utilsFactory.waitToBeTrue('post-view.component', () => !!this.videoPreload.nativeElement);
    }

    if (!this.videoPreloadEl.src) {

      const mediaModel = this.postModel.getVideoMedia();

      if (mediaModel.mimeType.indexOf('mp4') > -1) {
        // console.log('post-view.component->loadVideoPreloadSource(): mediaModel.mimeType', mediaModel.mimeType);
        this.videoPreloadEl.src = mediaModel.source;
      }
    }

  }

  _onPostHeaderToggle(isOpen) {
    this.onPostHeaderToggle.emit(isOpen);
  }

  _onToggleComments() {
    this.onToggleComments.emit();
  }

  _onToggleShare() {
    this.onToggleShare.emit();
  }

  _onPostAdFill(type) {
    this.onPostAdFill.emit(type);
  }

  _onPostAdHide(type) {
    this.onPostAdHide.emit(type);
  }

  _onPostAdPlayed() {
    this.onPostAdPlayed.emit();
  }

  _onPostAdEnded() {
    this.onPostAdEnded.emit();
  }

}
