import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WithinViewportChildComponent } from './within-viewport-child/within-viewport-child.component';
import { WithinViewportComponent } from './within-viewport.component';

@NgModule({
	declarations: [WithinViewportComponent, WithinViewportChildComponent],
	imports: [
		CommonModule
	],
	exports: [WithinViewportComponent, WithinViewportChildComponent]
})
export class WithinViewportModule {
}
