import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-profile-following-button',
  templateUrl: './profile-following-button.component.html',
  styleUrls: ['./profile-following-button.component.scss']
})
export class ProfileFollowingButtonComponent implements OnInit, OnDestroy {

  @Input() profile: UserModel = null;
  @Input() autoFollow = false;
  @Input() buttonSize = 'md';
  @Input() elevation = 0;
  @Input() borderFollowingColor = 'gray';

  @Input() bgColor = 'primary';
  @Input() textColor: string = null;

  @Input() loggedInUser: UserModel = null;

  onUserAuthenticationSubscription = null;

  constructor(
    private userService: UserService,
    private routerService: RouterService
  ) {

  }

  async ngOnInit() {
    // console.log('profile-following-button.component->ngOnInit(): this.autoFollow', this.autoFollow);

    // console.log('profile-following-button.component->ngOnInit(): this.profile', this.profile);

    this.loggedInUser = await this.userService.getLoginUser();
    // console.log('profile-following-button.component->ngOnInit(): this.loggedInUser', this.loggedInUser);

    if (this.autoFollow && this.profile.isFollowing === false) {
      await this.follow();
    }
  }

  ngOnDestroy() {
    if (this.onUserAuthenticationSubscription) {
      this.onUserAuthenticationSubscription.unsubscribe();
    }
  }

  async follow(event?) {
    try {

      // console.log('profile-following-button.component->follow(): this.loggedInUser', event, this.loggedInUser);

      if (event) {
        event.stopPropagation();
      }

      if (this.loggedInUser && this.loggedInUser.isAuthenticated) {
        await this.profile.follow();
      }
      else {
        await this.routerService.navigateTo(environment.loginUrl, {
          redirectPath: this.routerService.getCurrentUrl()
        });
      }

    }
    catch (e) {
      throw e;
    }
  }

  async unfollow(event?) {
    try {

      // console.log('profile-following-button.component->unfollow(): this.loggedInUser.isAuthenticated', event, this.loggedInUser.isAuthenticated);

      if (event) {
        event.stopPropagation();
      }

      if (this.loggedInUser.isAuthenticated) {
        await this.profile.unfollow();
      }
      else {
        await this.routerService.navigateTo(environment.loginUrl, {
          redirectPath: this.routerService.getCurrentUrl()
        });
      }

    }
    catch (e) {
      throw e;
    }
  }

}
