import {
  Directive,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: '[mipScrollTop]'
})
export class ScrollTopDirective implements OnChanges {

  @Input() mipScrollTop = false;
  @Input() scrollBehavior = 'smooth';

  hostEl = null;

  constructor(
    private hostElement: ElementRef
  ) {
    // console.log('scroll-top.directive->constructor(): this.hostElement.nativeElement', this.hostElement.nativeElement);
    this.hostEl = this.hostElement.nativeElement;
  }

  ngOnChanges() {
    // console.log('scroll-top.directive->ngOnChanges(): this.mipScrollTop', this.mipScrollTop);

    if (this.mipScrollTop) {
      // console.log('scroll-top.directive->ngOnChanges(): this.hostEl', this.hostEl);

      const scrollableParent = utilsFactory.getCloserScrollableParent(this.hostEl);
      // console.log('scroll-top.directive->ngOnChanges(): scrollableParent', scrollableParent);

      if (scrollableParent) {
        // scrollableParent.style.scrollBehavior = 'smooth';
        scrollableParent.scrollTo({top: 0, behavior: this.scrollBehavior});
      }

      setTimeout(() => {
        this.mipScrollTop = false;
      }, 50);

    }
  }

}
