import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';

import { MipVideoControlsComponent } from './mip-video-controls.component';

@NgModule({
  declarations: [MipVideoControlsComponent],
  imports: [
    CommonModule,
    MatSliderModule,
    FormsModule,
    MipIconModule,
    MatButtonModule
  ],
  exports: [MipVideoControlsComponent]
})
export class MipVideoControlsModule {
}
