import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { MediaModel } from '@libs/gc-common/lib/api/models/media';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import {
  AttachVideoInterface,
  videoPlayerFactory
} from '@libs/gc-common/lib/factories/video-player.factory';
import { AdPostVideoComponent } from '@libs/gc-common/lib/features/advertisement/ad-post-video/ad-post-video.component';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-post-video',
  templateUrl: './post-video.component.html',
  styleUrls: ['./post-video.component.scss']
})
export class PostVideoComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('adVideoContainer') adVideoContainer: AdPostVideoComponent;

  @Input() postModel: PostModel = null;
  @Input() videoQuality = null;
  @Input() isIntoViewport = false;
  @Input() isIntoTopBounceLimit = false;
  @Input() bottomShadow = false;
  @Input() forceBottomShadow = false;
  @Input() bottomShadowHeight = '200px';
  @Input() fullSize = false;
  @Input() autoplay = false;

  @Input() enableCanvas = true;
  @Input() canvasWidth = '300px';
  @Input() canvasHeight = 'auto';
  @Input() canvasMaxWidth = '100%';
  @Input() canvasMaxHeight = '100%';

  @Input() enableVideoPoster = false;
  @Input() videoObjectFit = 'cover';
  @Input() startAsMuted;
  @Input() startAsAttachVideo = false;

  @Input() noBackground = false;
  @Input() enableVideoControls = false;
  @Input() videoControlsPosition: 'top' | 'bottom' = 'top';
  @Input() videoControlsActions = ['timer', 'timeline', 'play', 'mute'];
  @Input() enableDetailsLink = false;

  @Input() disableLogs = environment.disableLogs;

  // AD INPUTS
  @Input() isAdEnable = false;

  // VIDEO EVENTS EMITTERS
  @Output() onVideoPlay = new EventEmitter();
  @Output() onVideoPause = new EventEmitter();
  @Output() onVideoEnded = new EventEmitter();
  @Output() onVideoLoadStart = new EventEmitter();
  @Output() onVideoLoaded = new EventEmitter();
  @Output() onVideoMuted = new EventEmitter();
  @Output() onVideoUnmute = new EventEmitter();
  @Output() onVideoDetach = new EventEmitter();

  // AD EVENTS EMITTERS
  @Output() onPostAdFill = new EventEmitter();
  @Output() onPostAdHide = new EventEmitter();
  @Output() onPostAdPlayed = new EventEmitter();
  @Output() onPostAdEnded = new EventEmitter();

  mediaModel: MediaModel = null;

  // Environment properties
  assetsPath = environment.assetsPath;
  isProduction = environment.environmentName === 'production';

  // Native video container element
  videoContainerEl = null;
  videoSource = null;

  // VIDEO properties
  isVideoLoading = false;
  isVideoPlaying = false;
  isVideoMuted = true;
  isVideoAttach = false;
  hasVideoLoaded = false;
  wasVideoPlaying = false;
  videoCover = null;

  // AD properties
  hasAdEnded = false;
  isAdPlaying = false;
  hasAdPlayed = false;
  hasAdFill = false;
  hasCalledVideoAdLoad = false;

  // other properties
  isMobile = false;
  isDesktop = false;

  currentTime = 0;
  totalVideoPlayed = 0;
  duration = 100;

  playingTimeout = null;

  constructor(private mobileDetect: MobileDetectService) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  ngOnInit() {
    try {
      // console.log('post-video.component->ngOnInit(): this.videoQuality', this.videoQuality);

      if (this.postModel.medias.song) {
        this.videoControlsActions.splice(this.videoControlsActions.indexOf('mute'), 1);
      }

      this.videoCover = this.postModel.getCover();
      // console.log('post-video.component->ngOnInit(): this.videoCover', this.videoCover);

      this.videoSource = this.postModel.getVideoSource(this.videoQuality);
      // console.log('post-video.component->ngOnInit(): this.videoSource', this.videoSource);

      this.mediaModel = this.postModel.getVideoMedia();
      // console.log('post-video.component->ngOnInit(): this.mediaModel', this.mediaModel);
    }
    catch (e) {
      console.error('post-video.component->ngOnInit(): ERROR', e);
    }
  }

  ngAfterViewInit() {
    this.videoContainerEl = this.videoContainer.nativeElement;
    // console.log('post-video.component->ngAfterViewInit(): this.videoContainerEl', this.videoContainerEl);

    // console.log('post-video.component->ngAfterViewInit(): this.startAsAttachVideo', this.startAsAttachVideo);
    if (this.startAsAttachVideo) {
      setTimeout(() => {
        this.attachVideo();
      }, 10);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(`post-video.component->ngOnChanges(): changes`, changes);

    this.isVideoMuted = videoPlayerFactory.muted;
    // console.log(`post-video.component->ngOnChanges(): this.isVideoMuted`, this.isVideoMuted);

    this.isVideoPlaying = videoPlayerFactory.autoplay;
    // console.log(`post-video.component->ngOnChanges(): this.isVideoPlaying`, this.isVideoPlaying);

    if (environment.adsPlacement.disablePostAd) {
      this.isAdEnable = false;
    }

    // console.log(`post-video.component->ngOnChanges(): this.isAdPlaying`, this.isAdPlaying);
    // console.log(`post-video.component->ngOnChanges(): this.autoplay`, this.autoplay);

    if (changes['isIntoTopBounceLimit']) {
      if (this.isIntoTopBounceLimit) {
        if ((this.autoplay || this.isVideoPlaying) && !this.isAdPlaying) {
          setTimeout(() => {
            this.playVideo();
          }, 10);
        }
      }
      else {
        this.detachVideo();
      }
    }
  }

  /**
   * VIDEO CONTROLLERS
   */

  attachVideo() {

    try {
      // console.log(`post-video.component->attachVideo() ${this.postModel.id}: this.isVideoAttach`, this.isVideoAttach);

      if (this.adVideoContainer) {
        this.adVideoContainer.unmuteAd();
      }

      if (this.isVideoAttach) {
        return;
      }

      if (!this.postModel) {
        throw new Error(`The 'postModel' must be provided`);
      }

      if (!this.videoContainerEl) {
        throw new Error(`The video container must be created`);
      }

      // console.log(`post-video.component->attachVideo(): this.enableVideoPoster`, this.enableVideoPoster);
      // console.log(`post-video.component->attachVideo(): this.startAsMuted`, this.startAsMuted);
      // console.log(`post-video.component->attachVideo(): this.autoplay`, this.autoplay);

      const videoSettings: AttachVideoInterface = {
        preservePlayer: this.isMobile,
        videoContainer: this.videoContainerEl,
        postModel: this.postModel,
        looping: true,
        poster: this.enableVideoPoster,
        videoQuality: this.videoQuality,
        // setting the video callbacks
        // (we need to do that because we always using the same HTMLVideoElement instance)
        onPlay: this._onVideoPlay.bind(this),
        onPause: this._onVideoPause.bind(this),
        onEnded: this._onVideoEnded.bind(this),
        onLoadStart: this._onVideoLoadStart.bind(this),
        onLoaded: this._onVideoLoaded.bind(this),
        onDetach: this._onVideoDetach.bind(this)

      };

      if (this.isMobile) {
        videoSettings.muted = false;
      }

      // attaching the video element with the media model and the video container
      // (we need to do that because we always using the same HTMLVideoElement instance)
      videoPlayerFactory.attachVideo(videoSettings);

      this.isVideoAttach = true;

      if (this.adVideoContainer && this.hasAdPlayed === false && this.hasCalledVideoAdLoad === false) {
        // console.log('post-video.component->attachVideo(): this.adVideoContainer.loadAd()');
        this.adVideoContainer.resetAd();
        this.adVideoContainer.loadAd();
        this.hasCalledVideoAdLoad = true;
      }

    }
    catch (e) {
      console.error(`post-video.component->attachVideo(): ERROR`, e);
    }

  }

  detachVideo() {

    // console.log(`post-video.component->detachVideo() ${this.postModel.id}: this.isVideoAttach`, this.isVideoAttach);

    if (this.isVideoAttach) {

      if (this.adVideoContainer) {
        this.adVideoContainer.muteAd();
      }

      clearInterval(this.playingTimeout);

      // video control
      this.isVideoLoading = false;
      this.isVideoPlaying = false;
      this.isVideoAttach = false;
      this.hasVideoLoaded = false;

      // ad control
      if (!this.hasAdPlayed) {
        this.hasCalledVideoAdLoad = false;
      }

      videoPlayerFactory.detachVideo(this.isVideoPlaying);

    }

  }

  playVideo() {

    // console.log(`post-video.component->playVideo(): this.isAdPlaying`, this.isAdPlaying);
    this.attachVideo();

    // this.isVideoPlaying = true;
    videoPlayerFactory.playVideo();

  }

  pauseVideo() {
    // console.log(`post-video.component->pauseVideo()`);
    videoPlayerFactory.pauseVideo();
  }

  togglePlay() {
    // console.log(`post-video.component->togglePlay(): this.isVideoPlaying`, this.isVideoPlaying);

    if (this.isVideoPlaying) {
      this.pauseVideo();
    }
    else {
      this.playVideo();
    }
  }

  toggleMute() {

    if (this.isVideoMuted) {
      this.unmuteVideo();
    }
    else {
      this.muteVideo();
    }

    // console.log(`post-video.component->toggleMute(): this.isVideoMuted`, this.isVideoMuted);

  }

  muteVideo() {
    // console.log(`post-video.component->muteVideo(): this.isVideoMuted`, this.isVideoMuted);

    videoPlayerFactory.muteVideo();
    this.onVideoMuted.emit();
    this.isVideoMuted = true;
  }

  unmuteVideo() {
    // console.log(`post-video.component->unmuteVideo(): this.isVideoMuted`, this.isVideoMuted);

    if (!this.postModel.medias.song) {
      videoPlayerFactory.unmuteVideo();
      this.onVideoUnmute.emit();
      this.isVideoMuted = false;
    }
  }

  _onControlsTimeChanged(timer) {
    // console.log(`post-video.component->_onControlsTimeChanged()`, timer);
    videoPlayerFactory.setCurrentVideoTime(timer);
  }

  /**
   * VIDEO CALLBACKS
   */

  _onVideoDetach() {
    const watchTotalPercentage = (this.totalVideoPlayed * 100) / this.duration;
    // console.log('post-video.component->_onVideoDetach(): this.postModel.id', this.postModel.id, watchTotalPercentage);

    this.postModel.setAsViews((this.totalVideoPlayed * 100) / this.duration);
    this.onVideoDetach.emit();
  }

  _onVideoPlay() {
    // console.log(`post-video.component->_onVideoPlay()`);
    this.onVideoPlay.emit();
    this.isVideoPlaying = true;
    this.wasVideoPlaying = true;
    this.isVideoMuted = videoPlayerFactory.muted;

    let countSetAsViewed = 0;

    clearInterval(this.playingTimeout);

    this.playingTimeout = setInterval(() => {
      this.currentTime = this.postModel.currentTime;
      // console.log(`post-video.component->_onVideoPlay(): setInterval()`, this.duration, this.currentTime);

      if (this.totalVideoPlayed < this.currentTime) {
        this.totalVideoPlayed = this.currentTime;
        // console.log(`post-video.component->_onVideoPlay(): this.totalVideoPlayed`, this.totalVideoPlayed);
      }

      if (countSetAsViewed > 10000) {
        // console.log(`post-video.component->_onVideoPlay(): setInterval()`, this.duration, this.currentTime);
        this.postModel.setAsViews((this.currentTime * 100) / this.duration);
        countSetAsViewed = 0;
      }
      else {
        countSetAsViewed += 100;
      }

    }, 100);

    if (this.adVideoContainer && this.hasAdPlayed === false) {
      // console.log('post-video.component->_onVideoPlay(): this.adVideoContainer.startAd()');
      this.adVideoContainer.startAd(this.isVideoMuted);
    }

  }

  _onVideoPause() {
    // console.log(`post-video.component->_onVideoPause()`);
    this.isVideoPlaying = false;
    this.onVideoPause.emit();
    clearInterval(this.playingTimeout);
  }

  _onVideoEnded() {
    // console.log(`post-video.component->_onVideoEnded()`);
    this.onVideoEnded.emit();
  }

  _onVideoLoadStart() {
    // console.log(`post-video.component->_onVideoLoadStart()`);
    this.isVideoLoading = true;
    this.onVideoLoadStart.emit();
  }

  _onVideoLoaded() {
    // console.log(`post-video.component->_onVideoLoaded()`);

    this.currentTime = this.postModel.currentTime;
    // console.log(`post-video.component->_onVideoLoaded(): this.currentTime`, this.currentTime);

    this.duration = this.postModel.duration;
    // console.log(`post-video.component->_onVideoLoaded(): this.duration`, this.duration);

    this.isVideoLoading = false;
    this.hasVideoLoaded = true;
    this.onVideoLoaded.emit();

  }

  /**
   * AD CALLBACKS
   */

  _onAdStartPlay() {

    // console.log(`post-video.component->onAdStartPlay()`);
    this.isAdPlaying = true;
    this.hasAdPlayed = true;
    this.hasAdFill = true;

    this.onPostAdPlayed.emit();

    clearInterval(this.playingTimeout);

    if (this.isVideoAttach) {
      // console.log(`post-video.component->onAdStartPlay(): pauseVideo()`);
      videoPlayerFactory.pauseVideo(true);
    }

  }

  _onAdEndedPlay() {

    // console.log(`post-video.component->onAdEndedPlay()`);
    this.isAdPlaying = false;
    this.hasAdFill = false;

    this.onPostAdHide.emit('post-video-ad');
    this.onPostAdEnded.emit();

    // console.log(`post-video.component->onAdEndedPlay(): this.isVideoPlaying`, this.isVideoPlaying);

    if (this.isVideoAttach) {
      // console.log(`post-video.component->onAdStartPlay(): playVideo()`);
      this.playVideo();
    }

  }

  _onAdError(error) {
    console.error(`post-video.component->onAdError(): ERROR`, error);
    this.isAdPlaying = false;
    this.hasAdFill = false;

    this.onPostAdHide.emit('post-video-ad');

    if (this.isVideoAttach && this.isVideoPlaying) {
      // console.log(`post-video.component->onAdError(): playVideo()`);
      videoPlayerFactory.playVideo();
    }
  }

  _onAdFilled() {
    // console.log(`post-video.component->onAdFilled()`);
    this.onPostAdFill.emit('post-video-ad');
    this.hasAdFill = true;
  }

}
