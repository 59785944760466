import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { ContestInterface } from './contest.interface';
import { ContestModel } from './contest.model';

export class ContestFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = ContestModel;

  constructor() {
    super();
  }

  override build(data: ContestInterface): ContestModel {
    return super.build(data);
  }

  override buildCollection(data: Array<ContestInterface>): Array<ContestModel> {
    return super.buildCollection(data);
  }

}

// export const contestFactory = new ContestFactory();
