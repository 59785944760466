<div
  #sideMenu
  class="side-menu"
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
>

  <div class="__menu-header" *ngIf="isMobile">
    <img [src]="assetsPath + '/images/gc-logo.png'" height="40">
  </div>

  <!-- NAVIGATION -->
  <mat-list class="__list-container __list-item-navigation" (click)="closeSideMenu()">

    <!-- TRENDING -->
    <mat-list-item
      class="__list-item --no-horizontal-padding"
      routerLinkActive="--nav-active"
    >
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/trending">
        <mip-icon icon="home" [bold]="!isRouteEnable('trending')" [solid]="isRouteEnable('trending')"></mip-icon>
        <span mipTranslate="side-menu.trending">Trending</span>
      </a>

    </mat-list-item>

    <!-- FOLLOWING (if logged in) -->
    <mat-list-item
      class="__list-item --no-horizontal-padding"
      routerLinkActive="--nav-active"
      *ngIf="loggedInUser"
    >
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/following">
        <mip-icon icon="following" [bold]="!isRouteEnable('following')" [solid]="isRouteEnable('following')"></mip-icon>
        <span mipTranslate="side-menu.following">Following</span>
      </a>

    </mat-list-item>

    <!-- HASHTAGS -->
    <mat-list-item
      class="__list-item --no-horizontal-padding"
      routerLinkActive="--nav-active"
    >
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/search/hashtags">
        <mip-icon icon="hashtag" [bold]="!isRouteEnable(['search'])" [solid]="isRouteEnable(['search'])"></mip-icon>
        <span mipTranslate="side-menu.hashtags">Hashtags</span>
      </a>

    </mat-list-item>

    <!-- PROFILE (if logged in) -->
    <mat-list-item
      class="__list-item --no-horizontal-padding"
      routerLinkActive="--nav-active"
      *ngIf="loggedInUser"
    >
      <a mat-button class="__item-link --full --horizontal-align--left" [routerLink]="'/@' + loggedInUser.username">
        <mip-icon icon="profile" [bold]="!isRouteEnable('@' + loggedInUser.username)" [solid]="isRouteEnable('@' + loggedInUser.username)"></mip-icon>
        <span mipTranslate="side-menu.profile">Profile</span>
      </a>

    </mat-list-item>

    <!-- COMPETITION AREA -->
    <mat-list-item class="__list-item --has-children --no-horizontal-padding" routerLinkActive="--nav-active">

      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/hashtag-contest">
        <mip-icon icon="carnival" [bold]="!isRouteEnable(['hashtag-contest', 'drawing'])" [solid]="isRouteEnable(['hashtag-contest', 'drawing'])"></mip-icon>
        <span mipTranslate="side-menu.carnival">Competition Area</span>
      </a>

      <mat-list class="__list-container __list-children __list-item-navigation">

        <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
          <a mat-button class="__item-link --child --full --horizontal-align--left" routerLink="/top-ranking">
            <span mipTranslate="side-menu.top-ten-ranking">Top Ranking</span>
          </a>
        </mat-list-item>

        <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
          <a mat-button class="__item-link --child --full --horizontal-align--left" routerLink="/hashtag-contest">
            <span mipTranslate="side-menu.hashtag-contest">Hashtag Contest</span>
          </a>

        </mat-list-item>

        <!--<mat-list-item class="__list-item &#45;&#45;no-horizontal-padding" routerLinkActive="&#45;&#45;nav-active">
          <a mat-button class="__item-link &#45;&#45;child &#45;&#45;full &#45;&#45;horizontal-align&#45;&#45;left" routerLink="/sweepstakes">
            <span mipTranslate="side-menu.drawing">Sweepstakes</span>
          </a>
        </mat-list-item>-->
      </mat-list>

    </mat-list-item>

  </mat-list>

  <!-- OUR PROGRAMS -->
  <mat-list class="__list-container --secondary __list-item-our-programs" (click)="closeSideMenu()">

    <mip-divider mipColor="gray--darker"></mip-divider>

    <!-- FOR INFLUENCERS -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" target="_blank" href="https://gotchosen.io/influencers">
        <mip-icon icon="phone-play" [bold]="true"></mip-icon>
        <span mipTranslate="side-menu.for-influencers">For Influencers</span>
      </a>
    </mat-list-item>

    <!-- COLLAB MARKETPLACE -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" target="_blank" href="https://gotchosen.io/brands">
        <mip-icon icon="monitor-ad" [bold]="true"></mip-icon>
        <span mipTranslate="side-menu.for-publisher">Collab Marketplace</span>
      </a>
    </mat-list-item>

  </mat-list>

  <!-- AD -->
  <div class="__ad-container" *ngIf="isDesktop">

    <mip-ad-medium-rectangle
      [showAdvertisementLabel]="true"
      adSlotId="left-side-menu"
    ></mip-ad-medium-rectangle>

    <div class="gcwp-carousel"></div>

  </div>

  <!-- MENU-INSTITUTIONAL -->
  <mat-list class="__list-container --secondary __list-item-institutional" (click)="closeSideMenu()">

    <!-- CONTACT US -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <button mat-button class="__item-link --parent --full --horizontal-align--left" (click)="openContactModal('help')">
        <span mipTranslate="side-menu.contact-us">Contact Us</span>
      </button>

      <mat-list class="__list-container __list-children __list-item-navigation">

        <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
          <button mat-button class="__item-link --child --full --horizontal-align--left" (click)="openContactModal('help')">
            <span mipTranslate="side-menu.i-need-help">I need help!</span>
          </button>
        </mat-list-item>

        <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
          <button mat-button class="__item-link --child --full --horizontal-align--left" (click)="openContactModal('learn-more')">
            <span mipTranslate="side-menu.learn-more">I want to learn more</span>
          </button>
        </mat-list-item>

      </mat-list>

    </mat-list-item>

    <!-- ABOUT US -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" target="_blank" href="https://gotchosen.io/about-us">
        <!--<mip-icon icon="gc-hand-circle" [bold]="!isRouteEnable('about-us')" [solid]="isRouteEnable('about-us')"></mip-icon>-->
        <span mipTranslate="side-menu.about-us">About Us</span>
      </a>
    </mat-list-item>

    <!-- FAQ -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/faq">
        <!--<mip-icon icon="dash-faq" [bold]="!isRouteEnable('faq')" [solid]="isRouteEnable('faq')"></mip-icon>-->
        <span mipTranslate="side-menu.faq">FAQ</span>
      </a>
    </mat-list-item>

    <!-- TUTORIALS -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button [mipGoBackReferrer] class="__item-link --parent --full --horizontal-align--left" routerLink="/tutorials">
        <!--<mip-icon icon="tutorials" [bold]="!isRouteEnable('tutorials')" [solid]="isRouteEnable('tutorials')"></mip-icon>-->
        <span mipTranslate="side-menu.tutorials">Tutorials</span>
      </a>
    </mat-list-item>

    <!-- PRIVACY POLICY -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/privacy">
        <span mipTranslate="side-menu.privacy-policy">Privacy Policy</span>
      </a>
    </mat-list-item>

    <!-- MONETIZATION TERMS -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/monetization-terms">
        <span mipTranslate="side-menu.monetization-terms">Monetization Terms</span>
      </a>
    </mat-list-item>

    <!-- TERMS OF SERVICE -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/terms-of-service">
        <span mipTranslate="side-menu.terms-of-service">Terms of Service</span>
      </a>
    </mat-list-item>

    <!-- COMMUNITY GUIDELINES -->
    <mat-list-item class="__list-item --no-horizontal-padding" routerLinkActive="--nav-active">
      <a mat-button class="__item-link --parent --full --horizontal-align--left" routerLink="/community-guidelines">
        <span mipTranslate="side-menu.community-guidelines">Community Guidelines</span>
      </a>
    </mat-list-item>

  </mat-list>

  <!-- LOGIN/LOGOUT -->
  <mat-list class="__list-container __list-item-login" (click)="closeSideMenu()">

    <mat-divider></mat-divider>

    <mat-list-item class="__list-item --no-horizontal-padding" *ngIf="isAuthenticated">
      <button mat-button class="__item-link --full --horizontal-align--left" (click)="logout()">
        <span mipTranslate="side-menu.logout">Logout</span>
      </button>
    </mat-list-item>

    <mat-list-item class="__list-item --no-horizontal-padding" *ngIf="!isAuthenticated">
      <button
        mat-button
        class="__item-link --full --horizontal-align--left"
        routerLink="/login"
        mipTranslate="side-menu.login"
      >
        Login
      </button>
    </mat-list-item>

  </mat-list>

  <!-- BANNER: GOTCHOSEN-EXPERIENCE -->
  <!--<mip-event-banner class="__event-banner"></mip-event-banner>-->

  <!-- BANNER: GLASSHOUSE -->
  <a class="__event-banner" routerLink="/glasshouse" *ngIf="country === 'IN'">
    <img src="{{assetsPath}}/images/event/glass-house/banner-website.jpg" width="100%">
  </a>

  <!-- DOWNLOAD-APP -->
  <div class="__download-app" *ngIf="isDesktop">
    <mip-download-app-buttons></mip-download-app-buttons>
  </div>

  <!-- LANGUAGES -->
  <mat-list class="__list-container __list-item-language" *ngIf="!isAuthenticated">
    <mat-divider></mat-divider>

    <h5 class="__menu-title">
      <span mipTranslate="side-menu.language">Languages</span>
    </h5>

    <mat-list-item class="__list-item --no-horizontal-padding">
      <div class="__languages">
        <button
          mat-button
          class="--full"
          *ngFor="let lang of languages"
          [class.--is-active]="lang.isActive"
          (click)="chanceLanguage(lang)"
        >
          {{ lang.key | uppercase }}
        </button>
      </div>
    </mat-list-item>

  </mat-list>

  <!-- COPYRIGHT -->
  <mat-list class="__list-container __list-item-copyright">
    <mat-list-item class="__list-item __copyright">
      <small><span>{{ currentYear }} © GotChosen, Inc.</span> &#8226; <span title="Web application version {{deployVersion}}">v{{ deployVersion }}</span></small>
    </mat-list-item>
  </mat-list>

</div>



