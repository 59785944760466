import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@libs/gc-common/environments/environment';

export interface ShareModalInterface {
  shareUrl?: string;
  image?: string;
  imageLeft?: string;
}

@Component({
  selector: 'mip-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  shareUrl = null;
  imageUrl = null;
  imageLeftUrl = null;

  assetsPath = environment.assetsPath;

  networkOptions: {
    facebook: string,
    twitter: string,
    email: string
  };

  constructor(
    public dialogRef: MatDialogRef<ShareModalComponent>,
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ShareModalInterface,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log('share-modal.component->constructor() this.data', this.data);
    this.shareUrl = this.data.shareUrl;
    this.imageUrl = this.data.image;
    this.imageLeftUrl = this.data.imageLeft;
  }

  ngOnInit(): void {
    const shareUrl = this.shareUrl;

    const facebookShareUrl = 'https://www.facebook.com/dialog/share';
    const facebookShareParams = [
      `app_id=${environment.facebookAppId}`,
      'display=popup',
      `href=${encodeURIComponent(shareUrl)}`
    ];

    const twitterShareUrl = 'https://twitter.com/intent/tweet';
    const twitterShareParams = [
      `url=${encodeURIComponent(shareUrl)}`
    ];

    this.translate.get('share-modal.email-subject').subscribe((response) => {
      this.networkOptions = {
        facebook: `${facebookShareUrl}?${facebookShareParams.join('&')}`,
        twitter: `${twitterShareUrl}?${twitterShareParams.join('&')}`,
        email: `mailto:?subject=${response}&body=${shareUrl}`
      };
    });

  }

  copyUrl() {

    const copyText = this.document.createElement('input');

    copyText.setAttribute('value', this.shareUrl);
    this.document.body.appendChild(copyText);
    copyText.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(copyText);

    this.translate.get('share-modal.coped-successfully').subscribe((response) => {
      this.matSnackBar.open(response, null, {
        duration: 1500,
        panelClass: 'mip-color--text-success'
      });
    });

  }

}
