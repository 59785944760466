import { Injectable } from '@angular/core';

import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class AudiosService {

  static instance: AudiosService;

  constructor(
    private resourceService: ResourceService,
    private modelFactory: ModelFactoryService
  ) {
    AudiosService.instance = this;
  }

  async getAudioById(id: number): Promise<any> {
    try {

      const response = await this.resourceService.get('api', {
        resource: 'audios',
        path: `/audio/${id}`,
      });

      return this.modelFactory.audioFactory.build(response);
    }
    catch (e) {
      throw e;
    }
  }

}
