import { Injectable } from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { CacheService } from '@libs/gc-common/lib/services/cache/cache.service';

export interface AppleCredentialsInterface {
  code?: string;
  idToken?: string;
  clientId?: string;
  redirectUri?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppleService {

  _credentials: AppleCredentialsInterface;

  _redirectUri = environment.loginUrl; // `https:${environment.appBaseHrefUseValue}/login`;
  _client_id = environment.appleClientId;

  _loadScriptPromise = null;

  constructor(
    private cacheService: CacheService
  ) {
    try {

      this._credentials = this.cacheService.getCache('apple-auth-api', 'credentials');
      console.log('apple-auth.service->constructor(): this._credentials', this._credentials);

    }
    catch (e) {
      console.log(e);
    }
  }

  getCredentials() {
    if (this._credentials) {
      return this._credentials;
    }
  }

  /**
   * Method to load the APPLE JS SDK
   */
  async loadScript() {
    console.log('apple.service->loadScript()');
    try {

      await utilsFactory.loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
        console.log('apple.service->loadScript(): persistence', 'AppleID' in window);
        return 'AppleID' in window;
      });

      window['AppleID'].auth.init({
        clientId: this._client_id,
        scope: 'name email',
        redirectURI: this._redirectUri,
        usePopup: true
      });

      return window['AppleID'];

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to return the AppleID api
   */
  async AppleID(): Promise<{ auth: { signIn } }> {

    console.log('apple.service->AppleID()');

    try {

      if ('AppleID' in window) {
        console.log('apple.service->AppleID(): from window');
        // @ts-ignore
        return window['AppleID'];
      }
      else if (!this._loadScriptPromise) {
        console.log('apple.service->AppleID(): from loading');
        this._loadScriptPromise = this.loadScript();
      }

      return await this._loadScriptPromise;

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to make the login with apple
   */
  async login(redirectUri?: string): Promise<AppleCredentialsInterface> {
    console.log('apple.service->login()');

    try {

      const signInOptions: { redirectURI?: string } = {};

      if (!redirectUri) {
        redirectUri = this._redirectUri;
      }

      if (redirectUri.indexOf('https://') === -1) {
        throw new Error(`The 'redirectUri' provided must start with 'https://'`);
      }

      signInOptions.redirectURI = redirectUri;

      const AppleID = await this.AppleID();
      console.log('apple.service->login(): AppleID', AppleID);

      const { authorization } = await AppleID.auth.signIn(signInOptions);
      console.log('apple.service->login(): authorization', authorization);

      this._credentials = {
        idToken: authorization.id_token,
        code: authorization.code,
        clientId: this._client_id,
        redirectUri
      };

      this.cacheService.setCache('apple-auth-api', 'credentials', this._credentials);

      return this._credentials;

    }
    catch (e) {
      console.error('apple.service->login(): ERROR', e);
      if (e['error'] && e['error'] === 'popup_closed_by_user') {
        return null;
      }
      else {
        throw e;
      }
    }

  }

  /**
   * Method to logout from apple
   */
  async logout() {
    console.log('apple.service->logout()');
    return new Promise(async (resolve, reject) => {
      try {

        this._credentials = null;
        this.cacheService.removeCache('apple-auth-api', 'credentials');

        resolve(true);

      }
      catch (e) {
        reject(e);
      }
    });
  }

}
