import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { UserDeviceUUIDInterface } from './user-device-uuid.interface';

import * as randomMC from 'random-material-color';

export class UserDeviceUUIDModel extends BaseModel implements UserDeviceUUIDInterface {

  id = null;
  username = null;
  avatarUrl = null;
  email = null;
  pendingDelete = null;
  devicePendingDelete = null;
  userPendingDelete = null;
  bgColor = randomMC.getColor({ shades: ['400', '900'] });

  constructor(model?: UserDeviceUUIDInterface) {
    super(model);
    this.fill(model);
  }

  override beforeFill(model) {

    console.log(`user-device-uuid.model->beforeFill(): ${model.username} this.devicePendingDelete`, model.devicePendingDelete, this.devicePendingDelete);
    console.log(`user-device-uuid.model->beforeFill(): ${model.username} this.userPendingDelete`, model.userPendingDelete, this.userPendingDelete);

    if (this.devicePendingDelete === true) {
      model.devicePendingDelete = this.devicePendingDelete;
    }

    if (this.userPendingDelete === true) {
      model.userPendingDelete = this.userPendingDelete;
    }

  }

  override afterFill(model) {
    this.pendingDelete = this.userPendingDelete || this.devicePendingDelete;
  }
}
