import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { MipSectionArrowComponent } from './mip-section-arrow.component';
import { MipSectionContentComponent } from './mip-section-content/mip-section-content.component';
import { MipSectionImageComponent } from './mip-section-image/mip-section-image.component';
import { MipSectionYtVideoComponent } from './mip-section-yt-video/mip-section-yt-video.component';

@NgModule({
	declarations: [
		MipSectionArrowComponent,
		MipSectionContentComponent,
		MipSectionImageComponent,
		MipSectionYtVideoComponent
	],
	imports: [
		CommonModule,
		ColorModule
	],
	exports: [
		MipSectionArrowComponent,
		MipSectionContentComponent,
		MipSectionImageComponent,
		MipSectionYtVideoComponent
	]
})
export class MipSectionArrowModule {
}
