<div class="__container">

  <div class="__gc-icon">
    <mip-icon icon="gc-hand-circle" size="8x"></mip-icon>
  </div>

  <div class="__info">

    <div class="__description">
      <h3><strong>GotChosen For Influencers</strong></h3>
      <p mipTranslate="download-app-modal.message">Open the GotChosen app to create your content.</p>
    </div>

  </div>

</div>

<!-- IS: IPHONE or ANDROID -->
<div class="__actions --is-phone" *ngIf="isIphone || isAndroid">

  <button
    mat-stroked-button
    class="__action "
    (click)="closeDialog()"
  >
    <span mipTranslate="download-app-modal.later">Later</span>
  </button>

  <a
    mat-raised-button
    class="__action mip-color--bg-primary"
    href="itms-apps://apps.apple.com/app/gotchosen/id1536151089"
    *ngIf="isIphone"
  >
    <span mipTranslate="download-app-modal.open-app">Open GotChosen</span>
  </a>

  <a
    mat-raised-button
    class="__action mip-color--bg-primary"
    href="market://details?id=com.gotchosen.influencers"
    *ngIf="isAndroid"
  >
    <span mipTranslate="download-app-modal.open-app">Open GotChosen</span>
  </a>
</div>

<!-- IS-NOT: IPHONE or ANDROID -->
<div class="__actions --flex-row --flex-row--center" *ngIf="!isIphone && !isAndroid">
  <a
    class="__action"
    target="_blank"
    href="https://apps.apple.com/app/gotchosen/id1536151089"
  >
    <img src="{{assetsPath}}/images/apple-store-badge.svg" height="40">
  </a>

  <a
    class="__action"
    target="_blank"
    href="https://play.google.com/store/apps/details?id=com.gotchosen.influencers"
  >
    <img src="{{assetsPath}}/images/google-play-badge.svg" height="40">
  </a>
</div>
