import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipCardModule } from '@libs/gc-common/lib/components/mip-card/mip-card.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipSeeMoreModule } from '@libs/gc-common/lib/components/mip-see-more/mip-see-more.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { FeedItemModule } from '@libs/gc-common/lib/features/feed-item/feed-item.module';
import { JoinHashtagButtonModule } from '@libs/gc-common/lib/features/join-hashtag-button/join-hashtag-button.module';
import { ShareModalModule } from '@libs/gc-common/lib/features/share-modal/share-modal.module';
import { SlidePostsPreviewModule } from '@libs/gc-common/lib/features/slide-posts-preview/slide-posts-preview.module';
import { SwiperModule } from 'swiper/angular';

import { HashtagInfoComponent } from './hashtag-info.component';

@NgModule({
  declarations: [HashtagInfoComponent],
  imports: [
    CommonModule,
    ColorModule,
    MipTranslateModule,
    MipCardModule,
    FeedItemModule,
    SwiperModule,
    RouterModule,
    MatButtonModule,
    MipIconModule,
    MipSeeMoreModule,
    SlidePostsPreviewModule,
    GoBackModule,
    JoinHashtagButtonModule,
    ShareModalModule
  ],
  exports: [HashtagInfoComponent]
})
export class HashtagInfoModule {
}
