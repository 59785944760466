interface LastIdPaginationInterface {
  order?: string;
  limit?: number;
  lastId?: number;
  hashtag?: string;
}

export class LastIdPaginationFactory {

  serviceFunc = null;

  reverseLastId = null;

  lastId = null;
  totalCount = 0;
  list = null;

  lastResponseCount = 0;

  options = {
    limit: 20
  };

  caller = null;

  constructor(
    serviceFunc: (any) => Promise<any>,
    list = [],
    options: { limit?: number },
    caller = null
  ) {
    // console.log('last-id-pagination.factory->constructor(): options', options);

    if (!serviceFunc) {
      throw new Error(`The 'serviceFunc' must be provided`);
    }

    this.list = list;
    // console.log('last-id-pagination.factory->constructor(): list', list);

    this.options = { ...this.options, ...options };
    // console.log('last-id-pagination.factory->constructor(): this.options', this.options);

    this.serviceFunc = serviceFunc;
    // console.log('last-id-pagination.factory->constructor(): this.serviceFunc', this.serviceFunc);

    this.caller = caller;
  }

  destroy() {
    // console.log('last-id-pagination.factory->destroy(): this BEFORE', this);

    // tslint:disable-next-line:forin
    for (const i in this) {
      delete this[i];
    }

    // console.log('last-id-pagination.factory->destroy(): this AFTER', this);
  }

  async getItems(options: LastIdPaginationInterface = {}, reverse = false, resetList = false): Promise<any> {
    try {

      // console.log('last-id-pagination.factory->getItems(): this.list: BEFORE', this.caller, this.list.length, this.totalCount, this.list);

      // if (this.list.length <= this.totalCount || this.totalCount === 0) {

      const serviceParams: LastIdPaginationInterface = {
        ...this.options,
        ...options
      };

      if (reverse) {
        if (this.reverseLastId) {
          serviceParams.lastId = this.reverseLastId;
        }
      }
      else if (this.lastId) {
        serviceParams.lastId = this.lastId;
      }

      if (resetList) {
        this.reverseLastId = null;
        this.lastId = null;
        serviceParams.lastId = null;
      }

      // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'serviceParams', serviceParams);
      // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.serviceFunc', this.serviceFunc);

      const response: { totalCount?, list, lastId?, pagination? } = await this.serviceFunc(serviceParams);
      // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'response', response);

      if (resetList) {
        this.list.splice(0, 100000); // removing everything from the list
      }

      // this.totalCount = response.totalCount || response.pagination ? response.pagination.totalItems : 5000;
      // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.totalCount', this.totalCount);

      if (response.list) {
        this.lastResponseCount = response.list.length;

        if (reverse) {

          this.reverseLastId = response.lastId || response.pagination ? response.pagination.lastId : null;
          // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.reverseLastId', this.reverseLastId);

          response.list.reverse();

          let lastItem = null;

          for (const item of response.list) {
            if (this.list.indexOf(item) === -1) {
              lastItem = item;
              this.list.unshift(item);
            }
          }

          if (!this.reverseLastId && lastItem) {
            this.reverseLastId = lastItem.id;
          }

        }
        else {

          // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'response.lastId', response.lastId);

          if (response.lastId) {
            this.lastId = response.lastId;
          }
          else if (response.pagination && response.pagination.lastId) {
            this.lastId = response.pagination.lastId;
          }

          // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.lastId', this.lastId);

          let lastItem = null;

          for (const item of response.list) {
            if (this.list.indexOf(item) === -1) {
              lastItem = item;
              this.list.push(item);
            }
          }

          // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'lastItem', lastItem);

          if (!this.lastId && lastItem) {
            this.lastId = lastItem.id;
          }
        }

        // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.lastId', this.lastId);

        if (response.totalCount) {
          this.totalCount = response.totalCount;
        }
        else if (response.pagination) {
          if (response.pagination.totalItems) {
            this.totalCount = response.pagination.totalItems;
          }
          else if (response.pagination.hasMoreItems) {
            this.totalCount = this.list.length + 1;
          }
        }

      }

      // console.log(`last-id-pagination.factory->getItems(${this.caller}):`, 'this.list: AFTER', this.list);

      return this.list;

    }
    catch (e) {
      console.error(`last-id-pagination.factory->getItems(${this.caller}):`, 'ERROR', e);
      throw e;
    }
  }

  addPost(postModel) {
    this.list.push(postModel);
    this.totalCount = this.list.length;
  }
}
