<div
  #mainContainerElement
  [class.--is-desktop]="isDesktop"
  [class.--is-modal]="isModal"
  class="post-comments"
>

  <button
    (click)="closeComments()"
    [disabled]="isFirstLoading"
    class="__close-button"
    mat-icon-button
  >
    <ng-content select="mip-icon[comments-close-icon],mat-icon[comments-close-icon]"></ng-content>
    <mip-icon icon="times"></mip-icon>
  </button>

  <div *ngIf="!isFirstLoading" class="__inner-container">

    <h4 *ngIf="totalCount === 1" class="__header">{{ totalCount }}
      <span mipTranslate="post-comments.messages.comment-count">comment</span>
    </h4>

    <h4 *ngIf="totalCount === 0 || totalCount > 1" class="__header">
      <span>{{ totalCount }}</span>
      <span mipTranslate="post-comments.messages.comments-count">comments</span>
    </h4>

    <div *ngIf="!isFirstLoading" class="__comments-container">

      <div *ngIf="commentId && hasMorePreviousComments" class="__comment __more-previous">
        <button
          (click)="getMoreComments('get-previous')"
          [disabled]="isLoadingPrevious"
          class="--full --horizontal-align--left mip-color--text-primary"
          mat-button
        >
          <mip-icon [busy]="isLoadingPrevious" icon="chevron-up-small"></mip-icon>
          <div *ngIf="!isLoadingPrevious" mipTranslate="post-comments.actions.get-more-previous-comments">
            Load previous comments
          </div>
          <div *ngIf="isLoadingPrevious" mipTranslate="post-comments.actions.get-more-previous-loading">
            Loading...
          </div>
        </button>
      </div>

      <div
        (onReachingBottom)="getMoreComments('get-more', false, 'tlp')"
        [bottomBounce]="150"
        [mipOnReachingBottom]="comments.length"
        class="__list-comments-container"
      >

        <div
          *ngFor="let comment of comments"
          [class.--just-added]="comment['just_created']"
          [mipScrollTop]="comment['just_created'] || comment['focus']"
          class="__comment"
        >

          <!-- AVATAR -->
          <div class="__avatar">
            <a
              (click)="closeComments()"
              [mipGoBackReferrer]="true"
              [routerLink]="'/@' + comment.author.username"
            >
              <mip-avatar
                [borderWidth]="1"
                [size]="avatarSize"
                [userModel]="comment.author"
                borderColor="gray"
              ></mip-avatar>
            </a>
          </div>

          <!-- CONTENT -->
          <div class="__content">

            <a
              (click)="closeComments()"
              [mipGoBackReferrer]="true"
              [routerLink]="'/@' + comment.author.username"
              class="__username"
            >
              <mip-profile-slug-name
                [user]="comment.author"
              ></mip-profile-slug-name>
            </a>

            <div class="__message">
              <div [mipLinkify]="{baseUrl: {hashtag: '/hashtag'}}" class="__text">{{ comment.message }}</div>
              <div class="__created-at">{{ comment.createdAt | amTimeAgo }}</div>
            </div>

          </div>

          <!-- REACTIONS -->
          <div class="__actions">

            <!-- DELETE action (for user) -->
            <button
              (click)="deleteComment(comment)"
              *ngIf="comment.author.isAuthenticated"
              [disabled]="comment['deleting']"
              class="__action __delete"
              mat-icon-button
            >
              <mip-icon [solid]="true" icon="times" size="3x"></mip-icon>
            </button>

            <!-- LIKE action -->
            <button
              (click)="toggleReaction('like', comment)"
              [class.mip-color--text-primary]="comment.hasReacted('like')"
              [disabled]="comment['saving']"
              class="__action __like"
              mat-icon-button
            >
              <mip-icon
                [solid]="comment.hasReacted('like')"
                icon="heart2"
              ></mip-icon>
            </button>

          </div>

        </div>

      </div>

      <div *ngIf="comments.length > 0 && isLoadingMore" class="__comment __loading-more">
        <mip-loading size="5x"></mip-loading>
      </div>

      <div *ngIf="comments.length === 0" class="__comment " mipTranslate="post-comments.messages.no-comments">
        Be the first to comment!
      </div>

    </div>

    <div
      *ngIf="!isFirstLoading"
      class="__composer"
    >
      <a
        *ngIf="!loggedInUser"
        [mipGoBackReferrer]="true"
        class="__composer-login"
        [href]="loginUrl"
      >
        {{ 'post-comments.messages.composer.not-logged-in' | translate }}
      </a>
      <mip-textarea
        (onClickSendMessage)="onSendMessage($event)"
        (onKeyboardCloses)="onKeyboardCloses()"
        (onKeyboardOpens)="onKeyboardOpens()"
        [actionIconSolid]="true"
        [isSaving]="addingNewCommenting"
        actionIcon="send"
        colorIcon="primary"
        placeholder="{{'post-comments.messages.composer.placeholder' | translate}}"
      ></mip-textarea>
    </div>

  </div>

  <div *ngIf="isFirstLoading" class="__inner-container" style="flex-grow: 0">
    <h4 class="__header">
      <mip-loading size="3x"></mip-loading>
      <span mipTranslate="post-comments.messages.loading">Loading...</span>
    </h4>
  </div>

  <div *ngIf="isFirstLoading" class="__loading">
    <mip-loading size="8x"></mip-loading>
  </div>

</div>
