import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-toolbar',
  templateUrl: './mip-toolbar.component.html',
  styleUrls: ['./mip-toolbar.component.scss']
})
export class MipToolbarComponent implements OnInit, OnDestroy {

  @Input() bgColor: string = null;
  @Input() textColor: string = null;
  @Input() mipBgColor: string = null;
  @Input() mipTextColor: string = null;

  @Input() roundedBackButton = false;

  @Input() noBackground = false;
  @Input() noDropShadow = false;
  @Input() hideIfEmpty = false;
  @Input() disableGoBackBottom = false;
  @Input() goBackFallback = '';

  @Input() largerTheme = false;
  @Input() goBackReferrer = null;

  classes = [];

  isMobile = true;
  isDesktop = false;

  currentPathname = null;

  observerHelper = new ObserverHelper();

  constructor(
    private mobileDetectService: MobileDetectService,
    private routerService: RouterService,
    private host: ElementRef
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
  }

  ngOnInit(): void {

    // console.log('mip-toolbar.component->ngOnInit() this.disableGoBackBottom', this.host.nativeElement, this.disableGoBackBottom);

    if (this.disableGoBackBottom !== true && !this.goBackReferrer) {

      const onRouteChangeSubscriber = this.routerService.onRouteChange().subscribe(route => {
        // console.log('mip-toolbar.component->ngOnInit() route', route);

        this.currentPathname = route.url;
        // console.log('mip-toolbar.component->ngOnInit() this.currentPathname', this.currentPathname);

        const goBackReferrer = this.routerService.getGoBackReferer(this.currentPathname);
        // console.log('mip-toolbar.component->ngOnInit() goBackReferrer', goBackReferrer);

        if (goBackReferrer/* && this.currentPathname !== goBackReferrer.path*/) {
          this.goBackReferrer = goBackReferrer;
        }
        else {
          this.goBackReferrer = null;
        }

        // console.log('mip-toolbar.component->ngOnInit() this.goBackReferrer', this.goBackReferrer);

      });

      this.observerHelper.addSubscription(onRouteChangeSubscriber);

    }

    if (this.mipBgColor && this.noBackground !== true) {
      this.classes.push(`mip-color--bg-${this.mipBgColor}`);
    }

    if (this.mipTextColor) {
      this.classes.push(`mip-color--text-${this.mipTextColor}`);
    }

    if (this.noDropShadow) {
      this.classes.push(`--no-drop-shadow`);
    }

    if (this.hideIfEmpty) {
      this.classes.push(`--hide-if-empty`);
    }
  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

}
