import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mip-card-content',
  templateUrl: './mip-card-content.component.html',
  styleUrls: ['./mip-card-content.component.scss']
})
export class MipCardContentComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
