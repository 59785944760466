import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { BottomSheetInterface } from '@libs/gc-common/lib/directives/bottom-sheet/bottom-sheet.component';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-profile-options',
  templateUrl: './profile-options.component.html',
  styleUrls: ['./profile-options.component.scss']
})
export class ProfileOptionsComponent implements OnChanges {
  
  @Input() profile: UserModel = null;
  
  isMobile = true;
  isDesktop = false;
  
  menuOptions: Array<BottomSheetInterface> = [];
  
  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private mobileDetectService: MobileDetectService,
    private routerService: RouterService
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // console.log('profile-options.component->ngOnChanges() changes.profile', changes['profile']);
    
    if (changes['profile'] && this.profile) {
      // console.log('profile-options.component->ngOnChanges() this.profile', this.profile);
      
      this.translate.get(['profile.logout', 'side-menu.tutorials']).subscribe((response) => {
        
        this.menuOptions = [];
        
        this.menuOptions.push({
          text: response['side-menu.tutorials'],
          path: '/tutorials',
          icon: 'tutorials'
        });
        
        // console.log('profile-options.component->ngOnChanges() this.profile', this.profile.isCollabDashboardUser(), this.profile);
        
        if (this.profile.isCollabDashboardUser()) {
          
          this.menuOptions.push({
            title: 'Dashboard',
            divider: true
          });
          
          this.menuOptions.push({
            text: 'Collab Dashboard',
            href: environment.collabUrl,
            target: '_blank',
            matIcon: 'campaign',
            addonMatIcon: 'settings'
          });
          
        }
        
        this.menuOptions.push({
          text: response['profile.logout'],
          icon: 'logout',
          divider: !!this.menuOptions.length,
          onSelect: option => {
            this.userService.logout();
          }
        });
        
        // console.log('profile-options.component->ngOnInit() this.menuOptions', this.menuOptions);
        
      });
    }
  }
  
}

