import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MipCardModule } from '@libs/gc-common/lib/components/mip-card/mip-card.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipModalModule } from '@libs/gc-common/lib/components/mip-modal/mip-modal.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MomentModule } from 'ngx-moment';

import { MipAvatarComponent } from './mip-avatar.component';

@NgModule({
	declarations: [MipAvatarComponent],
	exports: [
		MipAvatarComponent,
	],
	imports: [
		CommonModule,
		MipIconModule,
		RoundProgressModule,
		RouterLink,
		MomentModule,
		MipTranslateModule,
		MipModalModule,
		MipCardModule
	]
})
export class MipAvatarModule {
}
