<form
  autocomplete="off"
  class="search-form"
  (ngSubmit)="onSearchTermChange(true)"
  [formGroup]="searchForm"
>

  <div class="__search-input">
    <mip-icon icon="search" size="1x"></mip-icon>
    <!--(focus)="openSearchResultsPage()"-->
    <input
      class="mip-color--bg-gray"
      name="search"
      formControlName="search"
      placeholder="{{'search.form.placeholder.search' | translate}}"
      autocomplete="off"
      (ngModelChange)="onSearchTermChange(null, $event)"
    >
    <a
      class="__bottom-reset --small-button"
      mat-button
      (click)="resetSearchTerm()"
      *ngIf="this.showCloseButton"
    >
      <mip-icon icon="times" size="1x"></mip-icon>
    </a>
  </div>

  <a
    class="__bottom-cancel"
    *ngIf="searchResultsPageIsOpen && isMobile"
    mat-button
    (click)="closeSearchResultsPage()"
  >
    <span mipTranslate="search.form.action.cancel">Cancel</span>
  </a>

</form>
