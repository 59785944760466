import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { environment } from '@libs/gc-common/environments/environment';
import { CommentModel } from '@libs/gc-common/lib/api/models/comment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { PostsService } from '@libs/gc-common/lib/api/services/posts/posts.service';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { LastIdPaginationFactory } from '@libs/gc-common/lib/factories/last-id-pagination.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-post-comments',
  templateUrl: './post-comments.component.html',
  styleUrls: ['./post-comments.component.scss']
})
export class PostCommentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mainContainerElement') mainContainerElement: ElementRef;

  @Input() postModel: PostModel = null;
  @Input() avatarSize = 30;

  @Output() onCommentsClose = new EventEmitter();

  loggedInUser: UserModel = null;
  loginUrl = environment.loginUrl;

  mainContainerEl = null;

  isModal = false;
  isFirstLoading = true;
  isLoadingPrevious = false;
  isLoadingMore = false;
  isRequestingMoreComments = false;
  requestingMoreCommentsInterval = null;
  addingNewCommenting = false;
  hasMorePreviousComments = false;

  postId = null;
  commentId = null;

  comments: Array<CommentModel> = [];

  totalCount = 0;
  totalCommentsBeforeCommentId = 2;
  totalCommentsPerPage = 20;

  isDesktop = false;

  commentsPagination: LastIdPaginationFactory = null;

  hostEl = null;

  constructor(
    @Optional() private commentsModalInstance: MatBottomSheetRef,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private hostElement: ElementRef,
    private routerService: RouterService,
    private postService: PostsService,
    private changeDetectorRef: ChangeDetectorRef,
    private mobileDetectService: MobileDetectService,
    private userService: UserService
  ) {
    this.isDesktop = !this.mobileDetectService.isMobile();

    console.log('post-comments.component->constructor(): data', data);

    this.isModal = !!(data && data.isModal);

    if (data && 'postModel' in data) {
      this.postModel = data.postModel;
      console.log('post-comments.component->constructor(): this.postModel', this.postModel);
    }
  }

  async ngOnInit() {
    try {

      console.log('post-comments.component->ngOnInit(): this.postModel', this.postModel);

      const params = await this.routerService.getRouteParam();
      console.log('post-comments.component->ngOnInit(): params', params);

      this.commentId = params.commentId;
      console.log('post-comments.component->ngOnInit(): this.commentId', this.commentId);

      this.hostEl = this.hostElement.nativeElement;

      this.loggedInUser = await this.userService.getLoginUser();
      console.log('post-comments.component->ngOnInit(): this.loggedInUser', this.loggedInUser);

      if (!this.postModel) {

        this.postId = params.postId;
        console.log('post-comments.component->ngOnInit(): this.postId', this.postId);

        if (!this.postId) {
          throw new Error(`PostId must be provided`);
        }

        this.isFirstLoading = true;
        this.changeDetectorRef.markForCheck();

        console.log('post-comments.component->ngOnInit(): this.postModel', this.postModel);

        this.postModel = await this.postService.getPost(this.postId);
        console.log('post-comments.component->ngOnInit(): this.postModel', this.postModel);

      }

      this.totalCount = this.postModel.stats.comments;
      console.log('post-comments.component->ngOnInit(): this.totalCount', this.totalCount);

      this.commentsPagination = new LastIdPaginationFactory(this.postModel.getComments.bind(this.postModel), this.comments, { limit: this.totalCommentsPerPage });

      this.changeDetectorRef.markForCheck();

      if (this.commentId) {

        try {
          await this.commentsPagination.getItems({ limit: this.totalCommentsBeforeCommentId, lastId: this.commentId, order: 'asc' }, true);

          const currentComment = await this.postModel.getComment(this.commentId);
          currentComment['just_created'] = true;

          this.comments.push(currentComment);
          console.log('post-comments.component->ngOnInit(): this.comments', this.comments);

          if (this.comments.length >= this.totalCommentsBeforeCommentId) {
            this.hasMorePreviousComments = true;
            console.log('post-comments.component->ngOnInit(): this.hasMorePreviousComments', this.hasMorePreviousComments);
          }

          this.changeDetectorRef.markForCheck();
        }
        catch (e) {
          console.error('post-comments.component->ngOnInit(): ERROR', e);
        }

      }

      // if (this.postModel.stats.comments > 0) {
      await this.getMoreComments('start', false, 'ngOnInit');
      /*}
       else {
       this.isLoading = false;
       this.changeDetectorRef.markForCheck();
       }*/

    }
    catch (e) {
      console.error('post-comments.component->ngOnInit(): ERROR', e);
      this.isFirstLoading = false;
      this.comments = [];
      this.changeDetectorRef.markForCheck();
      throw e;
    }
  }

  ngAfterViewInit() {
    this.mainContainerEl = this.mainContainerElement.nativeElement;
  }

  ngOnDestroy() {

    if (this.commentsPagination) {
      this.commentsPagination.destroy();
    }

    if (this.comments) {
      for (const comment of this.comments) {
        comment['just_created'] = false;
      }
    }
  }

  async getMoreComments(step, cleanCommentsList?, from?) {
    console.log('post-comments.component->getMoreComments(): from', from, step, cleanCommentsList);
    try {

      if (this.requestingMoreCommentsInterval) {
        clearInterval(this.requestingMoreCommentsInterval);
      }

      if (this.isRequestingMoreComments) {
        await utilsFactory.waitToBeTrue('post-comments.component', () => !this.isRequestingMoreComments);

        /*await new Promise(resolve => {
         this.requestingMoreCommentsInterval = setInterval(() => {
         if (!this.isRequestingMoreComments) {
         clearInterval(this.requestingMoreCommentsInterval);
         resolve();
         }
         }, 100);
         });*/
      }

      if (step === 'start') {
        this.isFirstLoading = true;
      }

      const params = {
        order: '',
        lastId: null
      };

      if (step === 'start') {
        this.isFirstLoading = true;
        params.lastId = this.commentId;
      }
      else if (step === 'get-previous') {
        this.isLoadingPrevious = true;
        params.order = 'asc';
      }
      else if (step === 'get-more') {
        this.isLoadingMore = true;
      }

      this.isRequestingMoreComments = true;
      this.changeDetectorRef.markForCheck();

      await this.commentsPagination.getItems(params, step === 'get-previous', cleanCommentsList);
      console.log('post-comments.component->getMoreComments(): this.comments', this.comments.length);
      console.log('post-comments.component->getMoreComments(): this.totalCount', this.totalCount);

      this.isFirstLoading = false;
      this.isLoadingPrevious = false;
      this.isLoadingMore = false;
      this.isRequestingMoreComments = false;

      // this.totalCount = this.commentsPagination.totalCount;

      if (step === 'get-previous') {
        console.log('post-comments.component->getMoreComments(): this.commentsPagination.lastResponseCount', this.commentsPagination.lastResponseCount, this.totalCommentsPerPage);
        this.hasMorePreviousComments = this.commentsPagination.lastResponseCount >= this.totalCommentsPerPage;
        console.log('post-comments.component->getMoreComments(): this.hasMorePreviousComments', this.hasMorePreviousComments);
      }

      this.changeDetectorRef.markForCheck();

    }
    catch (e) {
      throw e;
    }
  }

  async onSendMessage(response: { text: string, resetForm?: () => void }) {
    console.log('post-comments.component->onSendMessage(): response', response);

    try {

      if (!response || !response.text) {
        return false;
      }

      if (!this.loggedInUser) {
        return this.routerService.navigateTo(environment.loginUrl, {
          redirectPath: true
        });
      }

      this.addingNewCommenting = true;
      this.changeDetectorRef.markForCheck();

      const commentResponse = await this.postModel.addComment({ message: response.text });
      console.log('post-comments.component->onSendMessage(): commentResponse', commentResponse);

      if (this.commentId) {
        this.commentId = null;

        if (this.comments.length) {
          this.comments[0]['focus'] = true;
        }

        await this.getMoreComments('get-more', true, 'onSendMessage');
        await this.routerService.navigateTo(`${this.postModel.author.username}/p/${this.postModel.id}`);
      }
      else {
        this.comments.unshift(commentResponse);
      }

      commentResponse['just_created'] = true;

      setTimeout(() => {
        commentResponse['just_created'] = false;
        this.changeDetectorRef.markForCheck();
      }, 5000);

      response.resetForm();

      ++this.totalCount;
      console.log('post-comments.component->onSendMessage(): this.totalCount', this.totalCount);

      this.addingNewCommenting = false;
      this.changeDetectorRef.markForCheck();

    }
    catch (e) {
      this.addingNewCommenting = false;
      this.changeDetectorRef.markForCheck();
      throw e;
    }
  }

  async toggleReaction(type, comment) {
    try {

      comment['saving'] = true;
      this.changeDetectorRef.markForCheck();

      if (comment.hasReacted(type)) {
        await comment.undoReaction('like');
      }
      else {
        await comment.doReaction('like');
      }

      comment['saving'] = false;
      this.changeDetectorRef.markForCheck();

    }
    catch (e) {
      throw e;
    }
  }

  async deleteComment(comment) {
    try {
      comment['deleting'] = true;
      this.changeDetectorRef.markForCheck();

      this.comments.splice(this.comments.indexOf(comment), 1);
      await this.postModel.deleteComment(comment.id);

      --this.totalCount;
      console.log('post-comments.component->toggleReaction(): this.totalCount', this.totalCount);

      this.changeDetectorRef.markForCheck();
    }
    catch (e) {
      throw e;
    }
  }

  onKeyboardOpens() {
    if (utilsFactory.isBrowser) {
      console.log('post-comments.component->onKeyboardOpens(): window.innerHeight', window.innerHeight);
      utilsFactory.addClass(this.hostEl.parentNode, '--keyboard-is-open');
      this.mainContainerEl.style.minHeight = `${window.innerHeight}px`;
      this.mainContainerEl.style.maxHeight = `${window.innerHeight}px`;
    }
  }

  onKeyboardCloses() {
    if (utilsFactory.isBrowser) {
      console.log('post-comments.component->onKeyboardCloses(): window.innerHeight', window.innerHeight);
      utilsFactory.removeClass(this.hostEl.parentNode, '--keyboard-is-open');
      this.mainContainerEl.style.minHeight = `350px`;
      this.mainContainerEl.style.maxHeight = `70vh`;
    }
  }

  closeComments() {

    if (this.commentsModalInstance) {
      this.commentsModalInstance.dismiss();
    }

    this.onCommentsClose.emit();

  }

}
