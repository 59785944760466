import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { PostChallengeInterface } from './post-challenge.interface';
import { PostModel } from './post.model';

export class PostChallengeModel extends BaseModel implements PostChallengeInterface {

  id = null; // api
  coOwnerAccepted = null; // api
  stats = null; // api

  // model dependencies
  originalPost: PostModel = null; // api
  coOwner: UserModel = null; // api

  constructor(model?: PostChallengeInterface) {
    super(model);

    this.fill(model);

  }

  override afterFill(model) {
    this.originalPost = ModelFactoryService.instance.postFactory.build(model.originalPost);
    this.coOwner = ModelFactoryService.instance.userFactory.build(model.coOwner);
  }

}
