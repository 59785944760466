<mip-card
  *ngIf="hashtagModel"
  [mipBdColor]="bdColor"
  [mipBgColor]="bgColor"
  [mipTextColor]="textColor"
  [noBackground]="true"
  [noShadow]="true"
  class="hashtag-info"
>

  <!-- section HEADER -->
  <mip-card-header
    *ngIf="contestModel && contestModel.isActive()"
    [class.--margin-horizontal]="!bdColor && isMobile"
    [class.--rounded-border]="!bdColor"
    mipBgColor="gray"
  >

    <div class="__info-header">
      <h3 mipTextColor="success" mipTranslate="hashtag-info.active-contest.title">ACTIVE CONTEST</h3>
      <p
        [translateValues]="{contestEndDateFromNow: contestEndDateFromNow}"
        mipTranslate="hashtag-info.active-contest.time"
      >
        {{ contestEndDateFromNow }} to finish this contest
      </p>
    </div>

    <a
      [mipGoBackReferrer]="true"
      class="__program-details"
      mat-button
      mipTextColor="primary"
      mipTranslate="hashtag-info.active-contest.details"
      routerLink="program-details"
    >
      Rules
    </a>

  </mip-card-header>

  <!-- section CONTENT -->
  <mip-card-content [class.--no-horizontal-padding]="!bdColor && isDesktop">

    <div class="__info-stats">

      <a
        *ngIf="contestModel && !contestModel.isActive()"
        [mipGoBackReferrer]="true"
        class="__program-details"
        mat-button
        mipTextColor="primary"
        mipTranslate="hashtag-info.active-contest.details"
        routerLink="program-details"
      >
        Rules
      </a>

      <div [style.background-image]="'url(' + hashtagModel.coverUrl + ')'" class="__info-image mip-color--bg-gray">
        <span *ngIf="!hashtagModel.coverUrl">#</span>
      </div>

      <div class="__into-text">
        <div class="__hashtag-link">
          <a
            [mipGoBackReferrer]="true"
            class="__link --ellipsis"
            mat-button
            routerLink="/hashtag/{{hashtagModel.hashtag}}"
          >
            #{{ hashtagModel.hashtag }}
          </a>
          <button
            (click)="shareHashtag()"
            *ngIf="showShareButton"
            class="__share"
            mat-icon-button
          >
            <mip-icon icon="share" size="2x"></mip-icon>
          </button>
        </div>
        <div
          class="__text-views">{{ (showContestStats && contestModel ? contestModel.views : hashtagModel.views) | number }}
          <small mipTranslate="hashtag-info.header.views">views</small></div>
        <div
          class="__text-posts">{{ (showContestStats && contestModel ? contestModel.totalPosts : hashtagModel.postsCount) | number }}
          <small>posts</small></div>

        <div *ngIf="contestModel && contestModel.hasEnded()" class="__text-has-ended" mipTextColor="warning">
          <strong mipTranslate="hashtag-info.contest-has-finished">
            Contest has ended
          </strong>
        </div>

        <!-- section BOTTOM ACTION -->
        <mip-join-hashtag-button
          *ngIf="contestModel && contestModel.isActive()"
          [hashtagModel]="hashtagModel"
          buttonSize="sm"
        ></mip-join-hashtag-button>

      </div>
    </div>

    <div *ngIf="hashtagModel.description || (contestModel && contestModel.description)" class="__description">
      <mip-see-more
        [actionTextColor]="seeMoreTextColor"
        [text]="hashtagModel.description || (contestModel ? contestModel.description : '')"
      ></mip-see-more>
    </div>

  </mip-card-content>

  <!-- section FOOTER -->
  <mip-card-footer *ngIf="postsList.length">

    <mip-slide-posts-preview
      [baseRoute]="baseRoute"
      [enableAvatarCrowns]="enableAvatarCrowns"
      [enableSampleLabel]="enableSampleLabel"
      [enableTimestamp]="enableTimestamp"
      [orientation]="isDesktop ? 'square' : null"
      [postsList]="postsList"
      [seeMoreLink]="seeMoreLink"
      [spacing]="4"
      [tileWidth]="isDesktop ? 200 : 95"
    ></mip-slide-posts-preview>

  </mip-card-footer>

</mip-card>
