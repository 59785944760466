import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';

import { RecaptchaComponent } from './recaptcha.component';

@NgModule({
  declarations: [
    RecaptchaComponent
  ],
  exports: [
    RecaptchaComponent
  ],
  imports: [
    CommonModule,
    MipIconModule,
    MipTranslateModule
  ]
})
export class RecaptchaModule {
}
