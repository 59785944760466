import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { MediaInterface } from '@libs/gc-common/lib/api/models/media';
import { PostInterface } from '@libs/gc-common/lib/api/models/post';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

export interface BottomSheetInterface {
  title?: string;

  /* mip-icon */
  icon?: string;
  iconBold?: boolean;
  addonIcon?: string;

  /* mip-icon > mat-icon */
  matIcon?: string;
  matIconBold?: boolean;
  matIconOutlined?: boolean;

  /* mip-icon > mat-icon [addon] */
  addonMatIcon?: string;
  addonMatIconBold?: boolean;
  addonMatIconOutlined?: boolean;

  avatar?: MediaInterface;
  text?: string;
  disabled?: boolean;
  nowrap?: boolean;
  class?: string;
  textClass?: string;
  path?: string | Array<string>;
  href?: string;
  target?: string;
  onSelect?: (any) => void;
  divider?: boolean;
}

@Component({
  selector: 'mip-bottom-sheet-component',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent {

  title = null;
  position = 'bottom';
  postModel: PostInterface = null;
  optionsList: Array<BottomSheetInterface> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private matBottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) {
    this.optionsList = data.optionsList;
    this.title = data.title;

    if (this.position === 'top') {
      this.matBottomSheetRef['_overlayRef']._backdropElement.addClass('');
    }
  }

  closeModal() {
    if (this.matBottomSheetRef) {
      this.matBottomSheetRef.dismiss();
    }
  }

  onSelection($event, selected) {

    console.log('bottom-sheet.component->onSelection() selected', selected);

    if (typeof selected.onSelect === 'function') {
      selected.onSelect(selected);
    }
    else if (selected.href) {
      window.open(selected.href, '_blank'); // , selected.value.target ? 'width=700,height=500' : null
    }
    else if (selected.path) {
      console.log('bottom-sheet.component->onSelection() selected.path', selected.path);

      this.routerService.navigateTo(selected.path, {
        redirectPath: true
      });
    }

    setTimeout(() => {
      this.closeModal();
    }, 50);
  }

}
