<div class="feed-posts">

  <!-- FEED-POSTS-DETAILS -->
  <mip-feed-posts-details
    *ngIf="isDesktop && isDetailsView === true"
    [sections]="sections"
    [loading]="loading"
    [postsLimitAuthenticated]="postsLimitAuthenticated"
    [postsLimitAnonymous]="postsLimitAnonymous"
    [disableListLimit]="disableListLimit"
    [loadingMorePosts]="loading"
    (onReachedEnd)="_onReachedBottom($event)"
    (onSlideChange)="_onSlideChange($event)"
    (onToggleShare)="_onToggleShare()"
  >
  </mip-feed-posts-details>

  <!-- FEED-POSTS-DESKTOP -->
  <mip-feed-posts-desktop
    *ngIf="isDesktop && isDetailsView === false"
    [sections]="sections"
    [reachedBottomCountLimit]="reachedBottomCountLimit"
    [isModal]="isModal"
    [loading]="loading"
    [postsLimitAuthenticated]="postsLimitAuthenticated"
    [postsLimitAnonymous]="postsLimitAnonymous"
    [disableListLimit]="disableListLimit"
    (onReachedBottom)="_onReachedBottom($event)"
    (onSlideChange)="_onSlideChange($event)"
    (onPostAdFill)="_onPostAdFill($event)"
    (onPostAdHide)="_onPostAdHide($event)"
    (onPostHeaderToggle)="_onPostHeaderToggle($event)"
    (onToggleComments)="_onToggleComments()"
    (onToggleShare)="_onToggleShare()"
    (onPostAdPlayed)="_onPostAdPlayed()"
    (onPostAdEnded)="_onPostAdEnded()"
  ></mip-feed-posts-desktop>

  <!-- FEED-POSTS-MOBILE -->
  <mip-feed-posts-mobile
    *ngIf="isMobile"
    [sections]="sections"
    [postCount]="postCount"
    [reachedBottomCountLimit]="reachedBottomCountLimit"
    [isModal]="isModal"
    [loading]="loading"
    [postsLimitAuthenticated]="postsLimitAuthenticated"
    [postsLimitAnonymous]="postsLimitAnonymous"
    [disableListLimit]="disableListLimit"
    (onReachedTop)="_onReachedTop($event)"
    (onReachedBottom)="_onReachedBottom($event)"
    (onSlideChange)="_onSlideChange($event)"
    (onPostAdFill)="_onPostAdFill($event)"
    (onPostAdHide)="_onPostAdHide($event)"
    (onPostHeaderToggle)="_onPostHeaderToggle($event)"
    (onToggleComments)="_onToggleComments()"
    (onToggleShare)="_onToggleShare()"
    (onPostAdPlayed)="_onPostAdPlayed()"
    (onPostAdEnded)="_onPostAdEnded()"
  ></mip-feed-posts-mobile>

</div>
