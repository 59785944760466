import { Location } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Directive({
  selector: '[mipGoBack]'
})
export class GoBackDirective implements OnInit, OnDestroy {

  @Input() hideParent = false;
  @Input() routerLink = null;

  currentPathname = null;
  goBackReferrer = null;

  constructor(
    private location: Location,
    private routerService: RouterService,
    private hostElement: ElementRef
  ) {
    console.log('go-back.directive->constructor() hostElement', this.hostElement);
  }

  routerSubscription = null;

  ngOnInit() {
    // this.routerSubscription = this.routerService.onRouteChange().subscribe(route => {

    console.log('go-back.directive->onInit() this.hostElement.nativeElement.parentElement', this.hostElement.nativeElement.parentElement);

    this.currentPathname = this.routerService.getCurrentUrl();
    console.log('go-back.directive->onInit() this.currentPathname', this.currentPathname);

    this.goBackReferrer = this.routerService.getGoBackReferer(this.currentPathname);
    console.log('go-back.directive->onInit() this.goBackReferrer', this.currentPathname, this.goBackReferrer);

    const classNames = this.hostElement.nativeElement.className.split(' ');
    const parentClassNames = this.hostElement.nativeElement.parentElement.className.split(' ');
    console.log('go-back.directive->onInit() this.goBackReferrer', this.goBackReferrer);

    if (this.goBackReferrer) {

      const goBackEl = this.hostElement.nativeElement;
      const goBackLabelEl = goBackEl.querySelector('.__back-button-label');

      if (goBackLabelEl) {
        if (this.goBackReferrer.title) {
          goBackLabelEl.innerText = this.goBackReferrer.title;
        }
        else {
          goBackLabelEl.innerText = '';
        }
      }

      if (classNames.indexOf('--hide-button') > -1) {
        classNames.splice(classNames.indexOf('--hide-button'), 1);
      }

      if (parentClassNames.indexOf('--hide-parent') > -1) {
        parentClassNames.splice(parentClassNames.indexOf('--hide-parent'), 1);
      }

    }
    else if (this.routerLink) {

    }
    else {
      if (classNames.indexOf('--hide-button') === -1) {
        classNames.push('--hide-button');
      }

      if (parentClassNames.indexOf('--hide-parent') === -1) {
        parentClassNames.push('--hide-parent');
      }
    }

    if (this.hideParent) {
      this.hostElement.nativeElement.parentElement.className = parentClassNames.join(' ');
    }

    this.hostElement.nativeElement.className = classNames.join(' ');

  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
      // this.routerService.removeGoBackReferrer(this.currentPathname);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    console.log('go-back.directive->onClick(): this.currentPathname', this.currentPathname);
    console.log('go-back.directive->onClick(): this.goBackReferrer', this.goBackReferrer);
    // this.routerService.removeGoBackReferrer(this.currentPathname);
    // this.routerService.navigateTo(this.goBackReferrer.path, {queryParams: this.goBackReferrer.search});

    // this.routerService.goBackReferrer(this.currentPathname);

    // this.routerService.goBackReferrer();

    if (this.goBackReferrer) {
      const goBackReferrer = this.routerService.getGoBackReferer(this.goBackReferrer.path) || {};
      console.log('go-back.directive->onClick(): goBackReferrer', goBackReferrer);

      this.routerService.removeGoBackReferer(this.currentPathname);
      // await this.routerService.navigateTo(goBackReferrer.path, {queryParams: goBackReferrer.search});

      console.log('go-back.directive->onClick(): replaceState: goBackReferrer', goBackReferrer);
      this.routerService.navigateTo(this.goBackReferrer.path, {queryParams: this.goBackReferrer.search});
    }

  }

}
