import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: '[mipScrollIntoView]'
})
export class ScrollIntoViewDirective implements OnInit, OnChanges {

  @Input() mipScrollIntoView = false;
  @Input() skipInitialScroll = false;
  @Input() initialTimeout = 500;
  @Input() timeout = 500;
  @Input() from = null;

  @Input() scrollBehavior: 'auto' | 'smooth' = 'smooth';
  @Input() verticalPosition: 'center' | 'end' | 'nearest' | 'start' = 'center';
  @Input() horizontalPosition: 'center' | 'end' | 'nearest' | 'start' = 'center';

  isFirst = true;

  constructor(private hostElRef: ElementRef) {
 // console.allowNamespace('scroll-into-view.directive');
    // console.log('scroll-into-view.directive->constructor(): this.hostElRef.nativeElement', this.hostElRef.nativeElement);
  }

  ngOnInit() {
    if (this.isFirst) {
      setTimeout(() => {
        this.isFirst = false;
      }, this.initialTimeout);
    }
  }

  ngOnChanges({ mipScrollIntoView }: SimpleChanges) {
    // console.log('scroll-into-view.directive->ngOnChanges(): mipScrollIntoView', mipScrollIntoView.currentValue);

    if (utilsFactory.isBrowser && mipScrollIntoView && mipScrollIntoView.currentValue) {
      // console.log('scroll-into-view.directive->ngOnChanges(): this.mipScrollIntoView', this.from, this.isFirst ? this.initialTimeout : this.timeout, mipScrollIntoView.currentValue);

      const hostEl = this.hostElRef.nativeElement;
      setTimeout(() => {

        // console.log('scroll-into-view.directive->ngOnChanges() SCROLL INTO VIEW');

        if (this.skipInitialScroll && this.isFirst) {
          // console.log('scroll-into-view.directive->ngOnChanges() SKIPPED', this.from);

          this.isFirst = false;
          return;
        }

        const scrollIntoViewConfig: {
          behavior?: 'auto' | 'smooth';
          block?: 'center' | 'end' | 'nearest' | 'start';
          inline?: 'center' | 'end' | 'nearest' | 'start';
        } = {};

        if (this.scrollBehavior) {
          scrollIntoViewConfig.behavior = this.scrollBehavior;
        }

        if (this.verticalPosition) {
          scrollIntoViewConfig.block = this.verticalPosition;
        }

        if (this.horizontalPosition) {
          scrollIntoViewConfig.inline = this.horizontalPosition;
        }

        // console.log('scroll-into-view.directive->ngOnChanges(): scrollIntoViewConfig', this.from, scrollIntoViewConfig);

        hostEl.scrollIntoView(scrollIntoViewConfig);

        this.isFirst = false;

      }, this.isFirst ? this.initialTimeout : this.timeout);
    }
  }

}

