import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-post-header',
  templateUrl: './post-header.component.html',
  styleUrls: ['./post-header.component.scss']
})
export class PostHeaderComponent implements OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('headerElement') headerElement: ElementRef;
  @ViewChild('slugNameElement') slugNameElement: ElementRef;
  @ViewChild('textContainerElement') textContainerElement: ElementRef;
  @ViewChild('innerElement') innerElement: ElementRef;
  @ViewChild('titleElement') titleElement: ElementRef;
  @ViewChild('wrapTextElement') wrapTextElement: ElementRef;
  @ViewChild('bodyElement') bodyElement: ElementRef;
  @ViewChild('seeMoreElement') seeMoreElement: ElementRef;

  @Input() parentContainer = null;

  @Input() user: UserModel = null;
  @Input() showAvatar = false;
  @Input() avatarSize = 50;
  @Input() showUsernameAvatar = false;
  @Input() showFollowBtn = false;

  @Input() showAvatarRoundProgress = false;
  @Input() roundProgressRadius = 22;
  @Input() roundProgressStroke = 4;
  @Input() roundProgressCurrent = 100;
  @Input() roundProgressMax = 100;

  @Input() linkifyUrls = false;
  @Input() maxHeightClosed = 63;
  @Input() profileSlugName: string;
  @Input() timestamp: string;
  @Input() titleText = '';
  @Input() bodyText = '';
  @Input() textColorTheme = 'light';
  @Input() textShadow = true;
  @Input() textColor: string = null;
  @Input() bgColor = 'rgba(0, 0, 0, 0.7)';
  @Input() usernameTextColor = null;
  @Input() usernameBgColor = 'none';
  @Input() usernameBdColor: string = null;
  @Input() isModal = true;
  @Input() seeMore = true;
  @Input() position = 'top';

  @Output() onOpen = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onToggle = new EventEmitter();

  bodyTextNowrap = null;

  openText = false;
  isOpeningText = false;
  openTextBtn = false;

  headerEl = null;
  headerElPadding = 8;

  seeMoreEl = null;
  seeMoreElMarginTop = 5;

  slugNameEl = null;
  slugNameElMarginBottom = 5;

  hostEl = null;
  parentEl = null;
  textContainerEl = null;
  innerElementEl = null;
  wrapTextEl = null;
  bodyEl = null;

  enableSeeMore = false;

  headerElHeight = null; // this.maxHeightClosed + (this.headerElPadding * 2) + this.slugNameElMarginBottom;
  extraHeight = 0;
  initialHeaderElHeight = null;

  isMobile = true;
  isDesktop = false;
  // containerHasHeight = false;

  onUserAuthenticationSubscription = null;

  constructor(
    private hostElement: ElementRef,
    private mobileDetectService: MobileDetectService
  ) {
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
  }

  ngAfterViewInit() {
    if (utilsFactory.isBrowser) {

      // console.log('mip-post-header.component->ngAfterViewInit(): this.parentContainer', this.parentContainer);

      this.hostEl = this.hostElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.hostEl', this.hostEl.offsetHeight, this.hostEl);

      this.slugNameEl = this.slugNameElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.slugNameEl', this.slugNameEl.offsetHeight, this.slugNameEl);

      this.parentEl = this.parentContainer || this.hostEl.parentNode;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.parentEl', this.parentEl.offsetHeight, this.hostEl);

      this.headerEl = this.headerElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.headerEl', this.headerEl.offsetHeight, this.headerEl);

      this.textContainerEl = this.textContainerElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.bodyEl', this.bodyEl.offsetHeight, this.bodyEl);

      this.innerElementEl = this.innerElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.innerElement', this.innerElementEl.offsetHeight, this.innerElementEl);

      this.wrapTextEl = this.wrapTextElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.wrapTextEl', this.wrapTextEl.offsetHeight, this.wrapTextEl);

      this.bodyEl = this.bodyElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.bodyEl', this.bodyEl.offsetHeight, this.bodyEl);

      this.seeMoreEl = this.slugNameElement.nativeElement;
      // console.log('mip-post-header.component->ngAfterViewInit(): this.seeMoreEl', this.seeMoreEl.offsetHeight, this.seeMoreEl);

      if (this.position === 'bottom') {
        utilsFactory.addClass(this.hostEl, '--position-bottom');
      }

    }

  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log('mip-post-header.component->ngOnChanges(): changes', changes);

    // this.bodyText = 'google.com ' + this.bodyText;

    if (changes['bodyText']) {

      this.bodyTextNowrap = (this.bodyText || '').replace(/<br>|<br\/>/g, ' ');
      // console.log('mip-post-header.component->ngOnChanges(): this.bodyTextNowrap', this.bodyTextNowrap);

      if (utilsFactory.isBrowser) {

        this.headerElHeight = this.maxHeightClosed + (this.headerElPadding * 2) + this.slugNameElMarginBottom;

        await utilsFactory.waitToBeTrue('mip-post-header.component', () => this.textContainerEl);

        if (this.textContainerEl) {

          this.enableSeeMore = false;

          // console.log('mip-post-header.component->ngOnChanges(): this.slugNameEl.offsetHeight', this.slugNameEl.offsetHeight);
          // console.log('mip-post-header.component->ngOnChanges(): this.slugNameElMarginBottom', this.slugNameElMarginBottom);

          this.extraHeight = (this.headerElPadding * 2) + (this.slugNameEl.offsetHeight + 1);
          // console.log('mip-post-header.component->ngOnChanges(): this.extraHeight', this.extraHeight);

          this.initialHeaderElHeight = this.extraHeight + this.maxHeightClosed;
          // console.log('mip-post-header.component->ngOnChanges(): this.initialHeaderElHeight', this.initialHeaderElHeight);

          setTimeout(() => {
            // console.log('mip-post-header.component->ngOnChanges(): this.parentEl.offsetHeight', this.parentEl.offsetHeight);

            this.headerElHeight = this.initialHeaderElHeight;
            // console.log('mip-post-header.component->ngOnChanges(): this.headerElHeight', this.headerElHeight);

            this.hostEl.style.maxHeight = `${this.headerElHeight}px`;

            setTimeout(() => {
              // console.log('mip-post-header.component->ngOnChanges(): offsetHeight', this.wrapTextEl.offsetHeight, (this.innerElementEl.offsetHeight + 5));
              if (this.wrapTextEl.offsetHeight > (this.innerElementEl.offsetHeight + 5)) {

                this.enableSeeMore = true;

                this.extraHeight += (this.seeMoreEl.offsetHeight + this.seeMoreElMarginTop);
                // console.log('mip-post-header.component->ngOnChanges(): [enableSeeMore] this.extraHeight', this.extraHeight);

                this.initialHeaderElHeight = this.extraHeight + this.maxHeightClosed;
                // console.log('mip-post-header.component->ngOnChanges(): [enableSeeMore] this.initialHeaderElHeight', this.initialHeaderElHeight);

                this.headerElHeight = this.initialHeaderElHeight;
                // console.log('mip-post-header.component->ngOnChanges(): [enableSeeMore] this.headerElHeight', this.headerElHeight);

                this.hostEl.style.maxHeight = `${this.headerElHeight}px`;

                if (this.openText) {
                  setTimeout(() => {
                    this.openText = false;
                    this.toggleSeeMore();
                  }, 10);
                }

              }
              else {
                this.openTextBtn = true;
              }
            }, 10);

          }, 10);

        }

      }

    }

  }

  ngOnDestroy() {
    if (this.onUserAuthenticationSubscription) {
      this.onUserAuthenticationSubscription.unsubscribe();
    }
  }

  open() {

    const wrapTextElHeight = this.wrapTextEl.offsetHeight + this.extraHeight;
    // console.log('mip-post-header.component->open(): wrapTextElHeight', wrapTextElHeight);

    this.openText = true;
    this.isOpeningText = true;
    this.openTextBtn = true;

    setTimeout(() => {
      this.isOpeningText = false;
    }, 300);

    setTimeout(() => {
      utilsFactory.addClass(this.hostEl, '--open-text');

      const parentElHeight = this.parentEl.offsetHeight;
      // console.log('mip-post-header.component->open(): parentElHeight', parentElHeight);

      const parentElClient = this.parentEl.getBoundingClientRect();
      // console.log('mip-post-header.component->open(): parentEl->top', parentElClient.top);

      // console.log('mip-post-header.component->open(): this.wrapTextEl.offsetHeight', this.wrapTextEl.offsetHeight, this.extraHeight);

      // console.log('mip-post-header.component->open(): this.extraHeight', this.extraHeight);
      // console.log('mip-post-header.component->open(): this.wrapTextEl.offsetHeight', this.wrapTextEl.offsetHeight);

      // console.log('mip-post-header.component->open(): this.position', this.position);
      // console.log('mip-post-header.component->open(): wrapTextElHeight > parentElHeight', wrapTextElHeight, parentElHeight);

      if (wrapTextElHeight > parentElHeight) {

        let offsetBottom = 0;

        if (this.position === 'bottom') {

          const hostElClient = this.hostEl.getBoundingClientRect();
          // console.log('mip-post-header.component->open(): hostEl->top', hostElClient.top);

          offsetBottom = parentElHeight - (hostElClient.bottom - parentElClient.top);
          // console.log('mip-post-header.component->open(): offsetBottom', offsetBottom);

        }

        this.headerElHeight = parentElHeight - offsetBottom;
        // console.log('mip-post-header.component->open(): this.headerElHeight', this.headerElHeight);

      }
      else {
        this.headerElHeight = wrapTextElHeight;
        // console.log('mip-post-header.component->open(): this.headerElHeight', this.headerElHeight);
      }

      this.onOpen.emit(this.openText);
      this.onToggle.emit(this.openText);
    }, 50);

  }

  close() {

    this.openText = false;
    this.isOpeningText = true;

    setTimeout(() => {
      this.isOpeningText = false;
    }, 300);

    utilsFactory.removeClass(this.hostEl, '--open-text');

    this.headerElHeight = this.initialHeaderElHeight;
    // console.log('mip-post-header.component->close(): this.headerElHeight', this.headerElHeight);

    this.innerElementEl.scrollTo(0, 0);

    setTimeout(() => {
      this.openTextBtn = false;
      this.onClose.emit(this.openText);
    }, 200);

    this.onToggle.emit(this.openText);

  }

  toggleSeeMore(event?) {
    this.openText = !this.openText;

    if (this.openText) {
      this.open();
    }
    else {
      this.close();
    }

    if (event) {
      event.stopPropagation();
    }

  }

}
