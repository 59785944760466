import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MipShapeComponent } from './mip-shape.component';

@NgModule({
  declarations: [MipShapeComponent],
  exports: [
    MipShapeComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MipShapeModule {
}
