import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {
  RouterService
} from '@lib/gc-common';

@Injectable({
  providedIn: 'root'
})
export class RedirectAtProfileGuard implements CanActivate {

  constructor(
    private router: Router,
    private routerService: RouterService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    console.log('redirect-at-profile.guard->canActivate() state.url', state.url);

    if (state.url.indexOf('@') === 1) {
      return true;
    }

    console.log('redirect-at-profile.guard->canActivate() route', route);
    console.log('redirect-at-profile.guard->canActivate() state', state);
    console.log('redirect-at-profile.guard->canActivate() state.url', state.url);

    const finalUrl = state.url.replace(/^\/([A-z0-9._+~-]+)/, '/@$1');
    console.log('redirect-at-profile.guard->canActivate() state.url @', state.url.indexOf('@'));

    return this.router.parseUrl(finalUrl);

  }
}
