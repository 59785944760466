import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ShareModalService } from '@libs/gc-common/lib/features/share-modal/share-modal.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mip-event-banner',
  templateUrl: './event-banner.component.html',
  styleUrls: ['./event-banner.component.scss']
})
export class EventBannerComponent implements OnInit, OnDestroy {

  loggedInUser: UserModel = null;
  assetsPath = environment.assetsPath;
  eventName = 'GotChosen Experience';
  eventRuleName = 'GOTCHOSEN_EXPERIENCE';
  isPtLanguage = false;

  observerHelper = new ObserverHelper();

  constructor(
    private shareModalService: ShareModalService,
    private routerService: RouterService,
    private userService: UserService,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(REQUEST) private request: any
  ) {

    let browserLang = ['en'];

    if (utilsFactory.isBrowser) {
      browserLang = [window['navigator'].language];

      if (navigator.languages) {
        browserLang = [...browserLang, ...navigator.languages];
      }

    }
    else if (this.request && this.request.headers && this.request.headers['accept-language']) {
      browserLang = [];
      const acceptLanguageSplit = this.request.headers['accept-language'].split(';');
      console.log('event-banner.component->constructor(): acceptLanguageSplit', acceptLanguageSplit);

      // tslint:disable-next-line:forin
      for (const i in acceptLanguageSplit) {
        const acceptLanguageSplitSplit = acceptLanguageSplit[i].split(',');
        console.log('event-banner.component->constructor(): acceptLanguageSplitSplit', acceptLanguageSplitSplit);

        // tslint:disable-next-line:forin
        for (const x in acceptLanguageSplitSplit) {
          browserLang.push(acceptLanguageSplitSplit[x]);
        }
      }
    }

    console.log('event-banner.component->constructor(): browserLang', browserLang);

    for (const i in browserLang) {
      if (browserLang[i].indexOf('pt') > -1) {
        this.isPtLanguage = true;
        break;
      }
    }

    this.observerHelper.addSubscription(
      this.userService.onUserAuthentication().subscribe(user => {

        this.loggedInUser = user;

        if (!this.isPtLanguage) {
          const isUserLanguagePT = user.getSettings('content_language') === 'pt' || user.getSettings('application_language') === 'pt';
          console.log('event-banner.component->constructor(): isUserLanguagePT', isUserLanguagePT);

          if (isUserLanguagePT) {
            this.isPtLanguage = true;
          }
        }

      })
    );
  }

  async ngOnInit() {

  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

  shareEvent() {

    if (this.loggedInUser) {

      this.shareModalService.shareOpen({
        url: `/@${this.loggedInUser.username}/invite/${this.loggedInUser.referralCode}/experience`,
        title: 'GotChosen Experience',
        image: `${this.assetsPath}/images/event/experience/logo-bg.jpg`,
        imageLeft: this.loggedInUser.getAvatarSource()
      });

    }
    else {

      this.shareModalService.shareOpen({
        url: `/experience`,
        title: 'GotChosen Experience',
        image: `${this.assetsPath}/images/event/experience/logo-bg.jpg`
      });

    }
  }

}
