import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipFormFileUploadModule } from '@libs/gc-common/lib/components/mip-form-file-upload/mip-form-file-upload.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { TranslateModule } from '@ngx-translate/core';

import { ContactFormComponent } from './contact-form.component';
import { ContactPageComponent } from './contact-page.component';

@NgModule({
  declarations: [
    ContactFormComponent,
    ContactPageComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MipTranslateModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MipAvatarModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MipLoadingModule,
    TranslateModule,
    MipFormFileUploadModule,
    MatBottomSheetModule,
    ColorModule,
    MipIconModule
  ],
  exports: [
    ContactPageComponent
  ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {} }
  ]
})
export class ContactFormModule {
}
