import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { OnReachingBottomModule } from '@libs/gc-common/lib/directives/on-reaching-bottom/on-reaching-bottom.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app/download-app.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';

import { HashtagsListComponent } from './hashtags-list.component';

@NgModule({
  declarations: [HashtagsListComponent],
  imports: [
    CommonModule,
    MipIconModule,
    MatListModule,
    MillifyModule,
    MipLoadingModule,
    MipTranslateModule,
    MatRippleModule,
    RouterModule,
    OnReachingBottomModule,
    DownloadAppModule,
    GoBackModule
  ],
  exports: [HashtagsListComponent]
})
export class HashtagsListModule {
}
