import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment-timezone';
import {
  Observable,
  Subject
} from 'rxjs';

interface MomentOptionsInterface {
  date?: string | Date | Moment;
  time?: string;
  isEST?: boolean;
  isoWeekday?: number;
  format?: string;
  local?: boolean;
  tz?: string;
  lang?: string;
  add?: {
    amount: number,
    unit: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class MomentService {
  
  _languageObserver = new Subject();
  _languageObservable = this._languageObserver.asObservable();
  
  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe(({lang}) => {
      // console.log('moment.service->constructor(): onLangChange()', lang);
      this._languageObserver.next(lang);
    });
  }
  
  getMoment(options, lang) {
    // console.log('moment.service->getMoment(): lang', lang);
    
    let momentInstance;
    
    lang = options.lang || lang;
    const language = lang === 'pt' ? 'pt-br' : lang;
    
    if (!options.date) {
      options.date = new Date();
    }
    
    if (options.tz) {
      momentInstance = moment['tz'](options.date, options.tz)/*.locale(language)*/;
      // console.log('moment.service->getMoment(): momentInstance 1', momentInstance);
    }
    else if (options.isEST || options.local === false) {
      momentInstance = moment['tz'](options.date, 'America/New_York')/*.locale(language)*/;
      // console.log('moment.service->getMoment(): momentInstance 2', momentInstance);
    }
    else {
      momentInstance = moment(options.date)/*.locale(language)*/;
      // console.log('moment.service->getMoment(): momentInstance 3', momentInstance);
    }
    
    if (options.add && options.add.amount > -1 && options.add.unit) {
      momentInstance.add(options.add.amount, options.add.unit);
      // console.log('moment.service->getMoment(): momentInstance 4', momentInstance);
    }
    
    if (options.time) {
      
      const time = options.time.split(':');
      
      if (time[0]) {
        momentInstance.set('hour', time[0]);
      }
      if (time[1]) {
        momentInstance.set('minute', time[1]);
      }
      if (time[1]) {
        momentInstance.set('second', time[1]);
      }
      
      // console.log('moment.service->getMoment(): momentInstance 5', momentInstance);
      
    }
    
    if (options.isoWeekday > -1) {
      momentInstance.isoWeekday(options.isoWeekday);
      // console.log('moment.service->getMoment(): momentInstance 6', momentInstance);
    }
    
    if (options.format) {
      momentInstance.format(options.format);
      // console.log('moment.service->getMoment(): momentInstance 7', momentInstance);
    }
    
    if (options.local !== false) {
      momentInstance = momentInstance.local();
      // console.log('moment.service->getMoment(): momentInstance 8', momentInstance);
    }
    
    // console.log('moment.service->getMoment(): momentInstance 9 ----------------------------------------- ', momentInstance);
    
    return momentInstance;
    
  };
  
  moment(options: MomentOptionsInterface = {}): Observable<Moment> {
    
    // console.log('moment.service->moment(): options', options);
    
    return new Observable(subscriber => {
      
      // console.log('moment.service->moment(): subscriber');
      
      let momentInstance = this.getMoment(options, this.translateService.currentLang);
      subscriber.next(momentInstance);
      
      this._languageObservable.subscribe(lang => {
        
        // console.log('moment.service->moment(): subscribe(lang)', lang);
        
        momentInstance = this.getMoment(options, lang);
        subscriber.next(momentInstance);
      });
      
    });
    
  }
  
  single(options: MomentOptionsInterface = {}): Moment {
    return this.getMoment(options, this.translateService.currentLang);
  }
}
