import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LazyImgDirective } from './lazy-img.directive';

@NgModule({
  declarations: [
    LazyImgDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LazyImgDirective
  ]
})
export class LazyImgModule {
}
