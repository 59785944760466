import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PaginationInterface } from '@libs/gc-common/lib/api/services/pagination.interface';

export interface IndexPaginationInterface {
  contestId?: number;
  hashtagId?: number;
  limit?: number;
  page?: number;
}

export class IndexPaginationFactory {

  serviceFunc = null;

  totalCount = 0;
  list = null;

  lastResponseCount = 0;

  paginationSettings: PaginationInterface = {
    limit: 21
  };

  options: IndexPaginationInterface = {};

  constructor(
    serviceFunc: (any) => Promise<any>,
    list = [],
    options: IndexPaginationInterface = {}
  ) {

    // console.log('index-pagination.factory->constructor(): list', list);
    // console.log('index-pagination.factory->constructor(): options', options);

    if (!serviceFunc) {
      throw new Error(`The 'serviceFunc' must be provided`);
    }

    this.list = list || [];
    // console.log('index-pagination.factory->constructor(): list', list);

    this.options = { ...this.options, ...options };
    // console.log('index-pagination.factory->constructor(): this.options', this.options);

    if (this.options.limit) {
      this.paginationSettings.limit = this.options.limit;
    }

    this.serviceFunc = serviceFunc;
    // console.log('index-pagination.factory->constructor(): this.serviceFunc', this.serviceFunc);

  }

  destroy() {
    // console.log('index-pagination.factory->destroy(): this BEFORE', this);

    // tslint:disable-next-line:forin
    for (const i in this) {
      delete this[i];
    }

    // console.log('index-pagination.factory->destroy(): this AFTER', this);
  }

  async getItems(): Promise<any> {
    // console.log('index-pagination.factory->getItems()');

    try {

      // console.log('index-pagination.factory->getItems(): this.list.length', this.list.length, this.totalCount);

      // if (this.list.length <= this.totalCount || this.totalCount === 0) {

      // console.log('index-pagination.factory->getItems(): this.paginationSettings.nextPage', this.paginationSettings.nextPage);
      if (this.paginationSettings.nextPage) {
        this.paginationSettings.page = this.paginationSettings.nextPage;
      }

      const requestParams = { ...this.options, ...this.paginationSettings };
      // console.log('index-pagination.factory->getItems(): requestParams', requestParams);

      const response: { pagination: PaginationInterface, list: Array<PostModel> } = await this.serviceFunc(requestParams);
      // console.log('index-pagination.factory->getItems(): response', response);

      this.paginationSettings = response.pagination;
      // console.log('index-pagination.factory->getItems(): this.paginationSettings', this.paginationSettings);

      if (response.list) {
        this.lastResponseCount = response.list.length;

        for (const item of response.list) {
          if (this.list.indexOf(item) === -1) {
            this.list.push(item);
          }
        }

        this.totalCount = this.paginationSettings.totalItems || this.list.length;
        // console.log('index-pagination.factory->getItems(): this.totalCount', this.totalCount);

      }
      // console.log('index-pagination.factory->getItems(): this.list', this.list);

      // }

      // console.log('index-pagination.factory->getItems(): this.list', this.list);
      return this.list;

    }
    catch (e) {
      console.error('index-pagination.factory->getItems(): ERROR', e);
      throw e;
    }
  }

  addPost(postModel) {
    this.list.push(postModel);
    this.totalCount = this.list.length;
  }
}
