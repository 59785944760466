import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-modal',
  templateUrl: './mip-modal.component.html',
  styleUrls: ['./mip-modal.component.scss']
})
export class MipModalComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {

  @Input() fullScreen = false;
  @Input() fullScreenMobile = true;
  @Input() maxWidth: string = null;
  @Input() size = 'full';
  @Input() fullHeight = true;
  @Input() position = 'fixed';
  @Input() backdrop = true;
  @Input() backdropRouterLink = null;
  @Input() backdropRouterFragment = '';
  @Input() hasBackdropClose = false;
  @Input() pushAside = false;
  @Input() isOpen = true;
  @Input() noShadow = false;

  @Output() onClose = new EventEmitter();

  isDesktop = false;

  isContentOpen = false;
  isOpenDelay = false;
  isAnimating = false;

  modalId = utilsFactory.generateGUID('mip-modal', 'xxxxx');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private mobileDetect: MobileDetectService
  ) {
    this.isDesktop = !this.mobileDetect.isMobile();

    const classNames = this.document.body.className.split(' ');
    console.log('mip-modal.component->constructor() classNames', classNames);

    // checking if there is another modal opened. I yes, stop the process to
    // avoid remove the .--modal-opened from the <body> tag
    for (const i in classNames) {
      if (classNames[i].indexOf('mip-modal-') > -1) {
        console.log('mip-modal.component->constructor() DO NO REMOVE CLASS FROM BODY');
        return;
      }
    }

  }

  ngOnInit() {
    console.log('mip-modal.component->ngOnInit(): this.isOpen', this.isOpen);
    // this.isOpenDelay = this.isOpen;
    // this.isContentOpen = this.isOpen;
    console.log('mip-modal.component->ngOnInit(): this.position', this.position);

    if (this.isOpen) {
      this.openModal();
    }

  }

  ngOnDestroy(): void {

    console.log('mip-modal.component->ngOnDestroy()');
    this.closeModal();

    /*if (utilsFactory.isBrowser/!* && this.position === 'fixed'*!/) {
     // utilsFactory.removeClass(this.document.body, '--modal-opened');
     this.document.body.className = document.body.className.replace(/--modal-opened/g, '');
     this.document.body.className = document.body.className.replace('  ', '');
     }*/

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('mip-modal.component->ngOnChanges(): changes', changes);

    if (changes['isOpen']) {
      console.log('mip-modal.component->ngOnChanges(): this.isOpen', this.isOpen);
    }
  }

  ngAfterViewInit() {
    if (this.isOpen) {
      this.openModal();
    }
    else {
      this.closeModal(this.hasBackdropClose);
    }
  }

  openModal() {
    this.isAnimating = true;
    this.isContentOpen = true;

    console.log('mip-modal.component->openModal(): this.position', this.position, this.document.body);

    if (this.position === 'fixed') {
      utilsFactory.addClass(this.document.body, this.modalId);
      utilsFactory.addClass(this.document.body, '--modal-opened');

      if (this.document.querySelector('mat-drawer-container')) {
        utilsFactory.addClass(this.document.querySelector('mat-drawer-container'), '--modal-opened');
      }

    }

    setTimeout(() => {
      this.isOpenDelay = true;
      this.isAnimating = false;
    }, 100);

  }

  closeModal(hasBackdropClose?) {

    console.log('mip-modal.component->closeModal()');

    if (!hasBackdropClose) {
      this.isOpenDelay = false;
      this.isAnimating = true;
    }

    setTimeout(() => {
      console.log('mip-modal.component->closeModal() setTimeout()');

      if (!hasBackdropClose) {
        this.isAnimating = false;
        this.isContentOpen = false;
      }

      utilsFactory.removeClass(this.document.body, this.modalId);

      this.onClose.emit();

      const classNames = this.document.body.className.split(' ');
      console.log('mip-modal.component->closeModal() classNames', classNames);

      // checking if there is another modal opened. I yes, stop the process to
      // avoid remove the .--modal-opened from the <body> tag
      for (const i in classNames) {
        if (classNames[i].indexOf('mip-modal-') > -1) {
          console.log('mip-modal.component->closeModal() DO NO REMOVE CLASS FROM BODY');
          return;
        }
      }

      if (this.position === 'fixed') {
        utilsFactory.removeClass(this.document.body, '--modal-opened');

        if (this.document.querySelector('mat-drawer-container')) {
          utilsFactory.removeClass(this.document.querySelector('mat-drawer-container'), '--modal-opened');
        }

      }
    }, 500);

  }
}
