import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { ContestWinnerInterface } from './contest-winner.interface';
import { ContestWinnerModel } from './contest-winner.model';

export class ContestWinnerFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = ContestWinnerModel;

  constructor() {
    super();
  }

  override build(data: ContestWinnerInterface): ContestWinnerModel {
    return super.build(data);
  }

  override buildCollection(data: Array<ContestWinnerInterface>): Array<ContestWinnerModel> {
    return super.buildCollection(data);
  }

}

// export const contestFactory = new ContestFactory();
