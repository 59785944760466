<div
  class="mip-card"
  [class]="classes"
  [class.--no-shadow]="noShadow"
  [class.--no-background]="noBackground"
  [style.border]="border"
  [style.border-radius]="borderRadiusPx"
>
  <ng-content select="mip-toolbar"></ng-content>
  <ng-content select="mip-card-header"></ng-content>
  <ng-content select="mip-card-content"></ng-content>
  <ng-content select="mip-card-footer"></ng-content>
</div>
