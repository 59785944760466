import { NgModule } from '@angular/core';

import { MillifyPipe } from './millify.pipe';

@NgModule({
  declarations: [
    MillifyPipe
  ],
  imports: [
    // CommonModule
  ],
  exports: [
    MillifyPipe
  ]
})
export class MillifyModule {
}
