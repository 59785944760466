<div class="__autocomplete-overlay" *ngIf="listUsers.length || isSearching"></div>

<!--<mat-list class="__autocomplete" *ngIf="listUsers.length || isSearching" [style.maxHeight]="autocompleteMaxHeight + 'px'">

  <mat-list-item *ngIf="isSearching">
   <mip-loading  size="4x">
      <span mipTranslate="mip-textarea.loading">Loading...</span>
    </mip-loading>
  </mat-list-item>

  <mat-list-item class="__user-item" *ngFor="let user of listUsers" (click)="onSelectUser(user)">
    <mip-avatar [userModel]="user" [size]="30"></mip-avatar> &lt;!&ndash; borderColor="gray" [borderWidth]="1" &ndash;&gt;
    <div class="__header-section">
      <small class="__username">
        <mip-profile-slug-name [user]="user"></mip-profile-slug-name>
      </small>
      <small *ngIf="user.getName()">{{user.getName()}}</small>
    </div>
  </mat-list-item>

</mat-list>-->

<mip-users-list
  class="__autocomplete"
  *ngIf="listUsers.length"
  [usersList]="listUsers"
  [isLoading]="isSearching"
  [showDownloadApp]="false"
  (onSelect)="onSelectUser($event)"
></mip-users-list>

<div
  class="__emijis-modal"
  *ngIf="enableEmojis && isDesktop"
  [class.--show]="isEmojisModalOpened"
  [class.--is-mobile]="isMobile"
>

  <div class="__emijis-overlay" (click)="toggleEmojisModal($event)"></div>

  <emoji-mart
    [darkMode]="false"
    [i18n]="emojisTranslation"
    [showPreview]="false"
    (emojiSelect)="onEmojiSelect($event)"
    (clickOutside)="closeEmojisModal()"
  ></emoji-mart>

</div>

<form (ngSubmit)="onSubmit()" class="__textarea-container">

  <!-- MULTILINE TEXTAREA -->
  <textarea
    #textareaElement
    class="__textarea"
    name="textarea"
    maxlength="150"
    [rows]="rows"
    [placeholder]="placeholder"
    [(ngModel)]="text"
    [disabled]="isSaving"
    (keydown)="onTextareaKeydown($event)"
    (click)="onTextareaClick($event)"
    (focus)="onTextareaFocus($event)"
    (paste)="onPaste($event)"
    (drop)="onPaste($event)"
    (blur)="onTextareaBlur()"
    autocomplete="off"
  ></textarea>

  <button
    mat-icon-button
    class="__action __action-mentions"
    type="button"
    *ngIf="enableMentions"
    [disabled]="isSaving || !loggedInUser"
    (click)="addMentionSymbol()"
  >
    <mip-icon
      icon="at-symbol"
    ></mip-icon>
  </button>

  <button
    mat-icon-button
    class="__action __action-emojis"
    type="button"
    *ngIf="enableEmojis && isDesktop"
    [disabled]="isSaving || !loggedInUser"
    (click)="toggleEmojisModal($event)"
  >
    <mip-icon
      icon="happy-face"
      [circle]="true"
    ></mip-icon>
  </button>

  <button
    mat-icon-button
    class="__action"
    type="submit"
    [class]="colorIcon ? 'mip-color--text-' + colorIcon : ''"
    [style]="{'color': hexColor}"
    [disabled]="isSaving || !loggedInUser"
  >
    <mip-icon
      [icon]="isSaving ? 'refresh' : actionIcon"
      [bold]="isSaving ? false : actionIconBold"
      [solid]="isSaving ? false : actionIconSolid"
      [spin]="isSaving"
    ></mip-icon>
  </button>

</form>
