import {
  Inject,
  Injectable,
  Optional,
} from '@angular/core';
import {
  makeStateKey,
  TransferState
} from '@angular/platform-browser';
import { countryEnvironment } from '@libs/gc-common/environments/counties';
import { environment } from '@libs/gc-common/environments/environment';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { CookieService } from '@libs/gc-common/lib/services/cookie/cookie.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { Request } from 'express';

import {
  Observable,
  Subject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  
  // Since the languages are added asynchronous (based on country), we need to dispatch the changes none-official (ex.: to be used on side-menu.component)
  _onLangAddedObservable = new Subject();
  _onLangAddedObserver = this._onLangAddedObservable.asObservable();
  _userIpAddress = null;
  _currentLanguage = 'en';
  
  constructor(
    private translate: TranslateService,
    // private translateCacheService: TranslateCacheService,
    private userService: UserService,
    private cookieService: CookieService,
    private transferState: TransferState,
    // private resourceService: ResourceService,
    private routerService: RouterService,
    @Optional() @Inject(REQUEST) private request: Request,
    // @Inject(PLATFORM_ID) private platform: any,
    @Optional() @Inject('USER_COUNTRY') private userCountry: string
  ) {
    
    // console.log('i18n.service->constructor()');
    
    /*if (utilsFactory.isSSR) {
     
     console.log('i18n.service->constructor(): request.headers', request.headers);
     
     const xForwardedFor = request.headers['x-forwarded-for'].toString();
     console.log('i18n.service->constructor(): xForwardedFor', xForwardedFor);
     
     this._userIpAddress = xForwardedFor.split(',')[0].trim();
     console.log('i18n.service->constructor(): this._userIpAddress', this._userIpAddress);
     
     }*/
    
  }
  
  async init() {
    try {
      /*if (isPlatformBrowser(this.platform)) {
       this.translateCacheService.init();
       }*/
      
      const { queryParams } = await this.routerService.getRouteObject();
      // console.log('i18n.service->init(): queryParams', queryParams);
      
      let language = null;
      
      const defaultCountry = environment.defaultCountry;
      
      let defaultLanguage = environment.defaultLanguage || 'en';
      let languages = environment.globalLanguages;
      
      if (!this.userCountry) {
        const USER_COUNTRY = makeStateKey<string>('USER_COUNTRY');
        this.userCountry = this.transferState.get(USER_COUNTRY, defaultCountry);
      }
      
      // console.log('i18n.service->init(): this.userCountry', this.userCountry);
      // console.log('i18n.service->init(): countryEnvironment', countryEnvironment);
      
      if (this.userCountry && this.userCountry in countryEnvironment) {
        // console.log('i18n.service->init(): countryEnvironment[this.userCountry]', countryEnvironment[this.userCountry]);
        
        const countryEnv = countryEnvironment[this.userCountry];
        
        if (countryEnv && countryEnv.globalLanguages) {
          defaultLanguage = countryEnv.defaultLanguage || 'en';
          languages = countryEnv.globalLanguages;
        }
        
      }
      
      // console.log('i18n.service->init(): defaultLanguage', defaultLanguage);
      // console.log('i18n.service->init(): languages 1', languages);
      
      // this.cookieService.removeCookie('lang');
      
      try {
        
        const loggedInUser = await this.userService.getLoginUser(false);
        // console.info('i18n.service->init(): loggedInUser', loggedInUser);
        
        language = loggedInUser.getLanguages('application_language');
        // console.info('i18n.service->init(): USER language', language);
        
      }
      catch (e) {
        // console.info('i18n.service->init(): NO USER CREDENTIALS');
      }
      
      // console.info('i18n.service->init(): languages 2', language, languages);
      
      if (!language) {
        const acceptLanguage = this.getLangFromAcceptLanguage(languages);
        // console.info('i18n.service->init(): acceptLanguage', acceptLanguage);
        
        const browserLang = this.translate.getBrowserLang();
        // console.info('i18n.service->init(): browserLang', browserLang);
        
        language = acceptLanguage || browserLang || defaultLanguage;
        // console.info('i18n.service->init(): language 1', language);
      }
      
      language = languages.indexOf(language) > -1 ? language : defaultLanguage;
      // console.info('i18n.service->init(): language 2', language);
      
      if (queryParams.lang && languages.indexOf(queryParams.lang) > -1) {
        language = queryParams.lang;
        // console.info('i18n.service->init(): queryParams.lang', language);
      }
      
      this._currentLanguage = language;
      // console.info('i18n.service->init(): this._currentLanguage', this._currentLanguage);
      
      this.cookieService.setCookie('lang', language);
      
      setTimeout(async () => {
        
        this.translate.addLangs(languages);
        this.translate.setDefaultLang(language);
        
        // console.info('i18n.service->init() setTimeout(): language', language);
        this.translate.use(language);
        
        if (language && language !== 'en') {
          await this.loadMomentLocale(language);
        }
        
        // console.log('i18n.service->init(): DONE', languages);
        
        // Since the languages are added asynchronous (based on country), we need to dispatch the changes none-official (ex.: to be used on side-menu.component)
        this._onLangAddedObservable.next(this._currentLanguage);
        
      }, 100);
      
    }
    catch (e) {
      console.error('i18n.service->init(): ERROR', e);
      throw e;
    }
    
  }
  
  getUserCountry() {
    return this.userCountry;
  }
  
  onLangAdded(): Observable<any> {
    // console.log('i18n.service->onLangAdded()');
    
    setTimeout(() => {
      this._onLangAddedObservable.next(this._currentLanguage);
    }, 10);
    
    return this._onLangAddedObserver;
    
  }
  
  getLangFromAcceptLanguage(languages) {
    
    try {
      
      let language = this.translate.getBrowserLang();
      // console.log('i18n.service->getLangFromAcceptLanguage(): [getBrowserLang] language', language);
      // console.log('i18n.service->getLangFromAcceptLanguage(): window.navigator.language', window.navigator.language);
      
      if (this.request && this.request.headers['accept-language']) {
        language = this.request.headers['accept-language']; // pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7
        // console.log('i18n.service->getLangFromAcceptLanguage(): [accept-language] language', language);
      }
      
      const splitAcceptLanguage = language.split(',');
      // console.log('i18n.service->getLangFromAcceptLanguage(): splitAcceptLanguage', splitAcceptLanguage);
      
      // tslint:disable-next-line:forin
      for (const i in splitAcceptLanguage) {
        
        // tslint:disable-next-line:forin
        for (const x in languages) {
          // console.log('i18n.service->getLangFromAcceptLanguage(): splitAcceptLanguage[i]', splitAcceptLanguage[i], languages[x]);
          
          if (splitAcceptLanguage[i].indexOf(languages[x]) > -1) {
            // console.log('i18n.service->getLangFromAcceptLanguage(): languages[x]', languages[x]);
            return languages[x];
          }
        }
      }
      
      return null;
      
    }
    catch (e) {
      console.error(e);
      return null;
    }
  }
  
  getCurrentLanguage() {
    return this._currentLanguage;
  }
  
  async loadMomentLocale(language) {
    const locale: string = (language === 'pt' ? 'pt-br' : language);
    // console.log('i18n.service->loadMomentLocale(): moment/locale', 'moment/locale/' + locale);
    await import('moment/locale/' + locale);
  }
  
}
