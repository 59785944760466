import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareModalModule } from '@libs/gc-common/lib/features/share-modal/share-modal.module';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

import { ShareModalComponent } from './share-modal.component';

@Injectable({
	providedIn: ShareModalModule
})
export class ShareModalService {
	
	isMobile = false;
	
	constructor(
		private dialog: MatDialog,
		private routerService: RouterService,
		private mobileDetectService: MobileDetectService
	) {
		this.isMobile = mobileDetectService.isMobile();
	}
	
	shareOpen(options: { url: string, text?: string, title?: string, image?: string, imageLeft?: string }) {
		
		console.log('share-modal.service->shareOpen() options', options);
		
		if (!options.url || typeof options.url !== 'string') {
			throw new Error(`The 'options.url' must be provided.`);
		}
		
		console.log('post-interaction.component->ngOnInit() options.url', options.url);
		
		if (this.isMobile && 'share' in window.navigator) {
			window.navigator['share']({
				url: options.url
				// text: options.text,
				// title: options.title
			});
		}
		else {
			
			let appHostName = '';
			console.log('post-interaction.component->ngOnInit() options.url', options.url);
			
			if (options.url.indexOf('http') === -1) {
				appHostName = this.routerService.getHostName();
			}
			
			console.log('post-interaction.component->ngOnInit() appHostName', appHostName);
			
			this.dialog.open(ShareModalComponent, {
				width: '300px',
				data: {
					shareUrl: appHostName + options.url,
					image: options.image,
					imageLeft: options.imageLeft
				}
			});
		}
		
	}
	
}
