import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectLoginUrlGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // Logs debugging information if the environment is not production
    if (!environment.production) {
      console.log('redirect-login-url.guard->canActivate(): route', route);
      console.log('redirect-login-url.guard->canActivate(): state', state);
    }

    // Manipulates the current URL to remove the '/dashboard' and '/collab' segments
    let newPath = state.url.replace(/^\/login/, '');
    console.log('redirect-login-url.guard->canActivate(): newPath', newPath);

    const url = environment.loginUrl + newPath;
    console.log('redirect-login-url.guard->canActivate(): url', url);

    // Redirects the user to the new URL using document.location.href
    document.location = url;

    return false; // Stops Angular's navigation cycle
    
  }
}
