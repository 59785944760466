import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { ContestModel } from '@libs/gc-common/lib/api/models/contest';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PaginationInterface } from '@libs/gc-common/lib/api/services/pagination.interface';
import {
  GetPostsParamsInterface,
  PostsService
} from '@libs/gc-common/lib/api/services/posts/posts.service';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { HashtagInterface } from './hashtag.interface';

import * as randomMC from 'random-material-color';

export class HashtagModel extends BaseModel implements HashtagInterface {

  id = null; // api
  postsCount = null; // api
  hashtag = null; // api
  language = null; // api
  mediaCover = null; // api
  mimeTypeMediaCover = null; // api
  coverUrl = null; // api
  mimeTypeCover = null; // api
  views = 0; // api
  description = null; // api
  type = null; // api
  contest: ContestModel = null; // api
  posts = []; // api

  randomBgColor = randomMC.getColor({ shades: ['400', '900'] }); // local

  constructor(model?: HashtagInterface) {
    super(model);

    this.fill(model);
  }

  override afterFill(model: HashtagInterface) {
    if (model.contest) {
      this.contest = ModelFactoryService.instance.contestFactory.build(model.contest);
      this.contest.hashtag = this;
    }

    if (model.posts && model.posts.length) {
      console.log('contest.model->afterFill(): model.posts', model.posts);

      this.posts = [];
      const posts = ModelFactoryService.instance.postFactory.buildCollection(model.posts);
      this.addPosts(posts);

      if (this.contest) {
        this.contest.addPosts(posts);
      }

    }
  }

  addPost(post: PostModel) {
    const isOnTheList = !!this.posts.filter(item => item === post).length;

    if (!isOnTheList) {
      this.posts.push(post);
    }
  }

  addPosts(posts: Array<PostModel>) {
    for (const post of posts) {
      this.addPost(post);
    }
  }

  async getPosts(params?: GetPostsParamsInterface): Promise<{ pagination: PaginationInterface, list: Array<PostModel> }> {
    try {
      console.log('hashtag.model->getPosts(): PostsService.instance', PostsService.instance);

      params = { ...params, hashtagId: this.id };
      console.log('hashtag.model->getPosts(): params', params);

      const response = await PostsService.instance.getHashtagPosts(params);
      console.log('hashtag.model->getPosts(): response', response);

      return response;

    }
    catch (e) {
      throw e;
    }
  }

}
