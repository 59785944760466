<div
  class="mip-modal"
  [class.--position-relative]="position === 'relative'"
  [class.--has-backdrop]="backdrop"
  [class.--push-aside]="pushAside"
  [class.--is-open]="isOpenDelay"
  [class.--is-animating]="isAnimating"
  [class.--no-shadow]="noShadow"
>

  <a
    class="__modal-shadow"
    [routerLink]="backdropRouterLink"
    [fragment]="backdropRouterFragment"
    queryParamsHandling="merge"
    *ngIf="hasBackdropClose && backdropRouterLink"
  ></a>

  <div
    class="__backdrop"
    *ngIf="backdrop"
    (click)="hasBackdropClose ? closeModal(hasBackdropClose) : null"
  >
  </div>

  <div
    class="mip-modal-inner"
    [class]="'--size-' + size"
    [class.--is-desktop]="isDesktop"
    [style]="{'max-width': maxWidth + ' !important'}"
    [class.--no-full-screen]="fullScreen === false"
    [class.--no-full-screen-mobile]="fullScreenMobile === false"
    [class.--full-height]="fullHeight === true"
    *ngIf="isContentOpen"
  >
    <ng-content></ng-content>
  </div>

</div>
