import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: '[mipOnReachingBottom]'
})
export class OnReachingBottomDirective implements AfterViewInit, OnDestroy, OnChanges {

  @Input() mipOnReachingBottom = 0;
  @Input() bottomBounce = 600;

  @Output() onReachingBottom = new EventEmitter();

  @Input() scrollableParentSelector: string = null;

  containerEl = null;
  lastItemsCount = 0;
  hasCalledOnReachingBottom = false;
  hasBondOnScroll = false;

  lastChildElement = null;

  scrollableParent = null;

  constructor(
    private hostElement: ElementRef
  ) {
    this.containerEl = this.hostElement.nativeElement;
    // console.log('on-reaching-bottom.directive->constructor(): this.hostElement', this.hostElement);
  }

  ngAfterViewInit() {
    // console.log('on-reaching-bottom.directive->ngOnChanges(): this.containerEl', this.containerEl.offsetHeight, this.containerEl);

    /*const interval = setInterval(() => {
     // console.log('on-reaching-bottom.directive->ngOnChanges(): this.containerEl', this.containerEl.offsetHeight, this.containerEl);
     if (this.containerEl.offsetHeight) {

     this.scrollableParent = utilsFactory.getCloserScrollableParent(this.containerEl, false, 'on-reaching-bottom.directive');
     // console.log('on-reaching-bottom.directive->ngAfterViewInit(): this.scrollableParent', this.containerEl.offsetHeight, this.scrollableParent);

     clearInterval(interval);
     }
     }, 50);*/
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('on-reaching-bottom.directive->ngOnChanges(): this.lastItemsCount', this.lastItemsCount, this.mipOnReachingBottom, this.containerEl);

    if (changes['mipOnReachingBottom']) {
      // console.log('on-reaching-bottom.directive->ngOnChanges(): this.mipOnReachingBottom', this.mipOnReachingBottom);

      if (utilsFactory.isBrowser) {
        setTimeout(() => {

          let scrollableParent = null;

          if (this.scrollableParentSelector) {
            scrollableParent = document.querySelector(this.scrollableParentSelector);
          }
          else {
            scrollableParent = utilsFactory.getCloserScrollableParent(this.containerEl, false);
          }

          // console.log('on-reaching-bottom.directive->ngOnChanges(): setTimeout(): scrollableParent', this.containerEl.offsetHeight, scrollableParent);

          if (scrollableParent !== this.scrollableParent) {
            this.scrollableParent = scrollableParent;
            this.bindOnScroll();
          }

        }, 200);
      }

    }

    if (utilsFactory.isBrowser && this.lastItemsCount !== this.mipOnReachingBottom) {

      this.hasCalledOnReachingBottom = false;

      /*if (this.hasBondOnScroll === false) {
       this.bindOnScroll();
       }*/

    }

    this.lastItemsCount = this.mipOnReachingBottom;

  }

  ngOnDestroy() {
  }

  async bindOnScroll() {
    if (utilsFactory.isBrowser) {

      this.hasBondOnScroll = true;

      if (!this.scrollableParent) {
        // console.log('on-reaching-bottom.directive->bindOnScroll(): this.scrollableParent: waitToBeTrue() !!this.scrollableParent', this.scrollableParent);
        await utilsFactory.waitToBeTrue('on-reaching-bottom.directive', () => !!this.scrollableParent);
      }

      // console.log('on-reaching-bottom.directive->bindOnScroll(): this.scrollableParent', this.scrollableParent);

      if (this.scrollableParent) {
        // console.log('on-reaching-bottom.directive->bindOnScroll(): this.scrollableParent', this.scrollableParent);

        this.scrollableParent.addEventListener('scroll', event => {
          // console.log('on-reaching-bottom.directive->bindOnScroll(): this.hasCalledOnReachingBottom', this.hasCalledOnReachingBottom);

          if (this.hasCalledOnReachingBottom === false) {

            let bouncing = null;

            if (this.scrollableParentSelector) {
              bouncing = this.scrollableParent.scrollHeight - (this.scrollableParent.scrollTop + this.scrollableParent.offsetHeight);
            }
            else {

              const clientRect = this.containerEl.getBoundingClientRect();
              // console.log('on-reaching-bottom.directive->bindOnScroll(): clientRect', clientRect);

              if (clientRect.bottom === 0) {
                return;
              }

              bouncing = (clientRect.bottom - window.innerHeight);
              // console.log('on-reaching-bottom.directive->bindOnScroll(): bouncing', bouncing, this.bottomBounce);

            }

            if (bouncing !== null && bouncing < this.bottomBounce) {
              // console.log('on-reaching-bottom.directive->bindOnScroll(): REACHED BOTTOM');
              this.hasCalledOnReachingBottom = true;
              this.onReachingBottom.emit();
            }

          }

        });
      }

    }
  }

}
