import {
	Component,
	Input,
	OnChanges
} from '@angular/core';

@Component({
	selector: 'mip-download-app-see-more',
	templateUrl: './download-app-see-more.component.html',
	styleUrls: ['./download-app-see-more.component.scss']
})
export class DownloadAppSeeMoreComponent implements OnChanges {
	
	@Input() label = '';
	
	finalLabel = 'posts';
	
	constructor() {
	}
	
	ngOnChanges() {
		if (this.label) {
			this.finalLabel = this.label;
		}
	}
	
}
