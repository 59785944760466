import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { AdNativeComponent } from '@libs/gc-common/lib/features/advertisement/ad-native/ad-native.component';
import { AdvertisementService } from '@libs/gc-common/lib/features/advertisement/advertisement.service';
import { FeedPostSectionInterface } from '@libs/gc-common/lib/features/feed-posts/feed-post-section.interface';
import { MomentService } from '@libs/gc-common/lib/services/moment/moment.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-feed-posts-details',
  templateUrl: './feed-posts-details.component.html',
  styleUrls: ['./feed-posts-details.component.scss']
})
export class FeedPostsDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('nativeAdvertisement') nativeAdvertisement: AdNativeComponent;

  @Input() sections: Array<FeedPostSectionInterface> = [];
  @Input() loading = null;
  @Input() postsLimitAuthenticated = null;
  @Input() postsLimitAnonymous = null;
  @Input() disableListLimit = null;
  @Input() loadingMorePosts = false;

  @Output() onReachedEnd = new EventEmitter();
  @Output() onSlideChange = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();

  countSlidedPosts = 0;

  loadingNextPost = false;

  createdAt = null;
  showTimestamp = false;

  currentSection: FeedPostSectionInterface = null;
  assetsPath = environment.assetsPath;
  disableFeedDetailsAd = null;

  nativeAdHasFill = false;

  thereAreNoSectionsError = false;

  showLeftColumn = false;

  onUserAuthenticationSubscription = null;
  onRouteChangeSubscription = null;

  constructor(
    private advertisementService: AdvertisementService,
    private routerService: RouterService,
    private userService: UserService,
    private momentService: MomentService,
    private activatedRoute: ActivatedRoute
  ) {

    this.disableFeedDetailsAd = this.advertisementService.isAdDisabled('disableFeedDetailsAd');
    console.log('feed-posts-details.component->constructor(): this.disableFeedDetailsAd', this.disableFeedDetailsAd);

    this.onUserAuthenticationSubscription = this.userService.onUserAuthentication().subscribe(user => {
      console.log('feed-posts-details.component->constructor(): user', user);

      this.showTimestamp = user.isModerator;
    });

    this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe(({ params }) => {
      console.log('feed-posts-details.component->constructor(): params', params);

      let currentSection = null;

      if (this.sections.length > 0) {

        if (params.postId) {
          currentSection = this.getSectionByPostId(params.postId);
          console.log('feed-posts-details.component->constructor(): currentSection', currentSection);
        }

        this.currentSection = currentSection || this.sections[0];
        console.log('feed-posts-details.component->constructor(): this.currentSection', this.currentSection);

        const createdAt = this.momentService.single({
          date: this.currentSection.model.createdAt,
          isEST: true,
          local: false
        });

        this.createdAt = createdAt.format('lll');
        console.log('feed-posts-details.component->ngOnInit(): this.createdAt', this.createdAt);

        this.countSlidedPosts++;

      }
      else {
        this.thereAreNoSectionsError = true;
      }

    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {

    if (this.onUserAuthenticationSubscription) {
      this.onUserAuthenticationSubscription.unsubscribe();
    }

    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }

  }

  onNativeAdFill() {
    this.nativeAdHasFill = true;
  }

  /**
   * Method to return a section model by its ID
   */
  getSectionByPostId(postId: string) {
    console.log('feed-posts-mobile.component->getSectionByPostId(): postId', postId);
    for (const section of this.sections) {
      console.log('feed-posts-mobile.component->getSectionByPostId(): section.model.id', section.model.id);
      // tslint:disable-next-line:radix
      if (section.model.id === parseInt(postId)) {
        return section;
      }
    }
  }

  async goPrevPost() {
    console.log('feed-posts-details.component->goPrevPost()');

    if (this.currentSection && this.currentSection.prevSection) {

      this.loadingNextPost = true;

      this.currentSection = this.currentSection.prevSection;
      console.log('feed-posts-details.component->goPrevPost(): this.currentSection', this.currentSection);

      await this.routerService.navigateTo([this.currentSection.model.id], {
        relativeTo: this.activatedRoute
      });

      if (this.nativeAdvertisement) {
        this.nativeAdvertisement.refreshAd();
      }

      setTimeout(() => {
        this.loadingNextPost = false;
      }, 1);

    }
  }

  async goNextPost() {
    console.log('feed-posts-details.component->goNextPost()');

    if (this.currentSection) {

      if (this.currentSection.nextSection) {

        this.loadingNextPost = true;

        this.currentSection = this.currentSection.nextSection;
        console.log('feed-posts-details.component->goNextPost(): this.currentSection', this.currentSection);

        await this.routerService.navigateTo([this.currentSection.model.id], {
          relativeTo: this.activatedRoute
        });

        if (this.nativeAdvertisement) {
          this.nativeAdvertisement.refreshAd();
        }

        setTimeout(() => {
          this.loadingNextPost = false;
        }, 1);

        console.log('feed-posts-details.component->goNextPost(): nextSection', this.currentSection.nextSection);
        if (!this.currentSection.nextSection) {
          this.onReachedEnd.emit();
        }

      }

    }

  }

  onCommentsClose() {
    console.log('feed-posts-details.component->onCommentsClose()');
    this.showLeftColumn = !this.showLeftColumn;
  }

  _onVideoPlay() {

  }

  _onVideoPause() {

  }

  _onVideoDetach() {

  }

  _onPostAdFill() {

  }

  _onPostAdHide() {

  }

  _onPostAdPlayed() {

  }

  _onPostAdEnded() {

  }

}
