import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { UserModel } from '@libs/gc-common/lib/api/models/user/user.model';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';

@Component({
	selector: 'mip-top-menu-bar',
	templateUrl: './top-menu-bar.component.html',
	styleUrls: ['./top-menu-bar.component.scss']
})
export class TopMenuBarComponent implements OnInit, OnDestroy {
	
	@Input() enableShadow = false;
	@Input() enableSearch = true;
	
	isFixed = false;
	loggedInUser: UserModel = null;
	assetsPath = environment.assetsPath;
	loginUrl = environment.loginUrl;
	
	observerHelper = new ObserverHelper();
	
	constructor(
		private userService: UserService
	) {
	}
	
	ngOnInit() {
		
		this.observerHelper.addSubscription(
			this.userService.onUserAuthentication().subscribe(user => {
				// console.log('top-menu-bar.component->ngOnInit(): user', user);
				this.loggedInUser = user;
			})
		);
		
		if (utilsFactory.isBrowser) {
			
			this.isFixed = document.querySelector('html').scrollTop > 10;
			
			window.addEventListener('scroll', event => {
				this.isFixed = document.querySelector('html').scrollTop > 10;
			});
			
		}
		
	}
	
	ngOnDestroy() {
		this.observerHelper.unsubscribeAll();
	}
	
}
