import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { ContestInterface } from '@libs/gc-common/lib/api/models/contest';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { ContestWinnerInterface } from './contest-winner.interface';

export class ContestWinnerModel extends BaseModel implements ContestInterface {

  id = null; // local (post.id + user.id)

  position = null; // api
  validated = null; // api
  views = null; // api
  post = null; // api
  user = null; // api

  constructor(model?: ContestWinnerInterface) {
    super(model);
    this.fill(model);
  }

  override beforeFill(model) {

    model.id = `${model.post.id}-${model.user.id}`;

    switch (model.position) {
      case 1:
        model.post.contest_role = 'winner';
        break;
      case 2:
        model.post.contest_role = 'second';
        break;
      case 3:
        model.post.contest_role = 'joker';
    }

  }

  override afterFill(model) {
    this.post = ModelFactoryService.instance.postFactory.build(model.post);
    this.user = ModelFactoryService.instance.userFactory.build(model.user);
  }

}
