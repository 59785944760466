import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { UserSettingsModel } from './user-settings.model';

export class UserSettingsFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = UserSettingsModel;

  constructor() {
    super();
  }

  override build(data: object): UserSettingsModel {
    return super.build(data);
  }

  override buildCollection(data: Array<object>): Array<UserSettingsModel> {
    return super.buildCollection(data);
  }

}

// export const userSettingsFactory = new UserSettingsFactory();
