import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PostViewService {

  floatingLabel = {
    text: 'Single',
    bgColor: '#FFF',
    textColor: '#333'
  };

  constructor() {
  }
}
