import { AudioModel } from '@libs/gc-common/lib/api/models/audio';
import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';

import { MediaDimensionModel } from './media-dimension.model';
import { MediaSongModel } from './media-song';
import { MediaInterface } from './media.interface';

export class MediaModel extends BaseModel implements MediaInterface {

  id = null; // api
  source = null; // api
  mimeType = null; // api
  mediaType = null; // api
  updatedAt = null; // api
  createdAt = null; // api

  audio: AudioModel = null; // api
  song: MediaSongModel = null; // api

  dimensions: {
    high?: MediaDimensionModel,
    medium?: MediaDimensionModel
  } = null; // api

  constructor(model?: MediaInterface) {
    super(model);

    this.fill(model);
  }

  override beforeFill(model?: MediaInterface): void {
    // console.log('post.model->beforeFill() model', model);

    if (model.audio) {
      model.audio = ModelFactoryService.instance.audioFactory.build(model.audio);
    }

    if (model.dimensions) {

      this.dimensions = {};

      // console.log('post.model->beforeFill() model.dimensions', model.dimensions);

      if (model.dimensions.high) {
        this.dimensions.high = new MediaDimensionModel(model.dimensions.high);
      }

      if (model.dimensions.medium) {
        this.dimensions.medium = new MediaDimensionModel(model.dimensions.medium);
      }

      // console.log('post.model->beforeFill() this.dimensions', this.dimensions);

    }

    // console.log('post.model->beforeFill() model', model);

  }
}
