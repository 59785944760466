import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { PostInterface } from './post.interface';
import { PostModel } from './post.model';

export class PostFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override identifier = 'id';
  override model = PostModel;

  allowedThemes = ['single', 'challenge', 'duet'];

  constructor() {
    super();
  }

  override build(data: PostInterface): PostModel {
    // resetting all posts as single for now.
    // NOT SUPPORTING duet AND  challenge posts
    data.theme = 'single';
    return super.build(data);
  }

  override buildCollection(data: Array<PostInterface>): Array<PostModel> {

    const collection = [];

    if (data) {
      for (const item of data) {
				if (
					item.medias &&
					(
						!Array.isArray(item.medias) ||
						(Array.isArray(item.medias) && item.medias.length)
					) && (
						!item.theme || this.allowedThemes.indexOf(item.theme) > -1
					)
				) {
          // resetting all posts as single for now.
          // NOT SUPPORTING duet AND  challenge posts
          item.theme = 'single';
          collection.push(item);
        }
      }
    }

    return super.buildCollection(collection);

  }

}

// export const postFactory = new PostFactory();
