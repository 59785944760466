import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: '[mipScrollFix]'
})
export class ScrollFixTopDirective implements AfterViewInit, OnDestroy {

  @Input() mipScrollFix = 0;
  @Input() marginTop = 0;
  @Input() zIndex = 10;

  @Input() mipScrollBottomFix = 0;
  @Input() marginBottom = 0;

  @Input() fixBorder = 'top';

  @Input() hideOnUnstick = false;
  @Input() nameSpace = '';

  @Output() onFixed = new EventEmitter();
  @Output() onUnfixed = new EventEmitter();
  @Output() onHide = new EventEmitter();

  initialStyles = { position: null };

  hostEl = null;
  fixableEl = null;

  hasStick = false;
  hasHidden = false;
  hasUnstick = false;

  constructor(
    private host: ElementRef
  ) {

  }

  ngAfterViewInit() {
    if (utilsFactory.isBrowser) {
      // console.log(`${this.nameSpace} scroll-fix-top.directive->ngAfterViewInit()`);

      if (['top', 'bottom'].indexOf(this.fixBorder) === -1) {
        this.fixBorder = 'top';
        console.warn(`${this.nameSpace} scroll-fix-top.directive->ngAfterViewInit(): ERROR`, new Error(`The "fixBorder" must be "top" or "bottom". Default value: "top".`));
      }

      // console.log(`${this.nameSpace} scroll-fix-top.directive->ngAfterViewInit(): this.fixBorder`, this.fixBorder);

      this.hostEl = this.host.nativeElement;
      // console.log(`${this.nameSpace} scroll-fix-top.directive->ngAfterViewInit(): this.hostEl`, this.hostEl);

      const scrollableParent = utilsFactory.getCloserScrollableParent(this.hostEl, false);
      // console.log(`${this.nameSpace} scroll-fix-top.directive->ngAfterViewInit(): scrollableParent`, scrollableParent);

      this.bindScroll(scrollableParent);

      let limitTime = 0;

      if (utilsFactory.isBrowser) {
        const parentInterval = setInterval(() => {

          const newScrollableParent = utilsFactory.getCloserScrollableParent(this.hostEl, false);
          // console.log('scroll-fix-top.directive->ngAfterViewInit(): newScrollableParent', newScrollableParent);

          if (newScrollableParent !== scrollableParent) {
            this.bindScroll(newScrollableParent);
            clearInterval(parentInterval);
          }
          else if (limitTime > 5000) {
            clearInterval(parentInterval);
          }

          limitTime += 250;

        }, 250);
      }

    }
  }

  ngOnDestroy() {
  }

  bindScroll(scrollableParent) {
    this.fixableEl = this.hostEl.querySelector('.mip-scroll-fix-element');
    // console.log(`${this.nameSpace} scroll-fix-top.directive->bindScroll(): this.fixableEl`, this.fixableEl);

    if (this.fixableEl) {

      if (this.hideOnUnstick) {
        this.hide(this.fixBorder);
      }

      if (scrollableParent) {
        scrollableParent.addEventListener('scroll', event => {
          // console.log(`${this.nameSpace} scroll-fix-top.directive->bindScroll(): this.fixableEl`, this.fixableEl);

          const clientRect = this.hostEl.getBoundingClientRect();
          // console.log(`${this.nameSpace} scroll-fix-top.directive->bindScroll(): clientRect.top`, clientRect.top, this.mipScrollFix);

          const parentHeight = scrollableParent === window ? scrollableParent.innerHeight : scrollableParent.offsetHeight;
          // console.log(`${this.nameSpace} scroll-fix-top.directive->bindScroll(): parentHeight`, clientRect.height, parentHeight);

          if (this.fixBorder === 'bottom') {
            this.checkIfStickyToBottom(clientRect, (clientRect.height < parentHeight));
          }
          else {
            // console.log(`${this.nameSpace} scroll-fix-top.directive->bindScroll(): clientRect.height < parentHeight`, clientRect.height, parentHeight, (clientRect.height < parentHeight));
            // BUG FIXED: set 'justStick' as true to fix bug with floating item not considering the margin provided
            this.checkIfStickyToTop(clientRect, false); // (clientRect.height < parentHeight));
          }

        });
      }
    }
  }

  checkIfStickyToTop({ top }, justStick = false) {
    // console.log(`${this.nameSpace} scroll-fix-top.directive->checkIfStickyToTop(): justStick`, justStick);

    if (top < this.mipScrollFix) {
      this.stick((justStick ? '' : 'top'), this.marginTop);
    }
    else if (this.hideOnUnstick) {
      this.hide((justStick ? '' : 'top'));
    }
    else if (this.fixableEl.style.position === 'fixed') {
      this.unstick((justStick ? '' : 'top'));
    }
  }

  checkIfStickyToBottom({ bottom }, justStick = false) {
    // console.log(`${this.nameSpace} scroll-fix-top.directive->checkIfStickyToBottom(): bottom`, bottom < window.innerHeight);

    if (bottom < window.innerHeight) {
      this.stick((justStick ? '' : 'bottom'), this.marginBottom);
    }
    else if (this.hideOnUnstick) {
      this.hide((justStick ? '' : 'bottom'));
    }
    else if (this.fixableEl.style.position === 'fixed') {
      this.unstick((justStick ? '' : 'bottom'));
    }
  }

  stick(placement: string, margin: number) {

    // console.log(`${this.nameSpace} scroll-fix-top.directive->stick():`, placement);

    if (this.hasStick === false) {

      this.hostEl.style.height = `${this.hostEl.offsetHeight}px`;
      this.hostEl.style.zIndex = this.zIndex;

      this.fixableEl.style.position = 'fixed';
      if (placement) {
        this.fixableEl.style[placement] = `${margin}px`;
      }
      this.fixableEl.style.width = `${this.hostEl.offsetWidth}px`;
      this.fixableEl.style.opacity = `1`;
      this.fixableEl.style.zIndex = this.zIndex;

      this.hasStick = true;
      this.hasUnstick = false;
      this.hasHidden = false;

      this.onFixed.emit();

    }

  }

  unstick(placement: string) {
    if (this.hasUnstick === false) {

      // console.log(`${this.nameSpace} scroll-fix-top.directive->unstick():`, this.fixableEl);

      if (placement) {
        this.fixableEl.style[placement] = 'auto';
      }
      this.fixableEl.style.position = this.initialStyles.position;
      this.fixableEl.style.opacity = `1`;
      this.fixableEl.style.transition = 'none';

      this.hasUnstick = true;
      this.hasStick = false;

      this.onUnfixed.emit();
    }
  }

  hide(placement: string) {
    if (this.hasHidden === false) {

      // console.log(`${this.nameSpace} scroll-fix-top.directive->hide():`, this.fixableEl);

      this.fixableEl.style.position = `fixed`;
      if (placement) {
        this.fixableEl.style[placement] = `-${this.fixableEl.offsetHeight}px`;
      }
      this.fixableEl.style.width = `${this.hostEl.offsetWidth}px`;
      this.fixableEl.style.opacity = `0`;
      this.fixableEl.style.transition = 'all 0.3s ease-in-out';

      this.hasHidden = true;
      this.hasStick = false;

      setTimeout(() => {
        this.hostEl.style.zIndex = '-1';
        this.onHide.emit();
      }, 300);

    }

  }

}
