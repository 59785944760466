<mat-nav-list
  (onReachingBottom)="_onReachingBottom()"
  [mipOnReachingBottom]="hashtagsList.length"
  class="hashtags-list"
>
  <mat-divider *ngIf="topDivider"></mat-divider>

  <div *ngIf="title" class="__title" mat-subheader>
    {{ title }}
  </div>

  <a
    *ngFor="let hashtag of hashtagsList"
    [mipGoBackReferrer]="true"
    [routerLink]="['/', 'hashtag', hashtag.hashtag]"
    mat-list-item
    matRipple
  >

    <div class="__item-inner">

      <div
        [style.background-color]="hashtag.randomBgColor"
        class="__icon"
      >#
      </div>

      <div class="__hashtag-info">
        <strong>#{{ hashtag.hashtag }}</strong>
        <small>
          {{ hashtag.views | number }} <span mipTranslate="hashtags-list.views">views</span>
        </small>
      </div>

    </div>

  </a>

  <mat-list-item
    *ngIf="!isLoading && hashtagsList.length  === 0"
    class="__no-data"
  >
    <span mipTranslate="hashtags-list.no-hashtags">No hashtags found!</span>
  </mat-list-item>

  <mat-divider *ngIf="bottomDivider"></mat-divider>

</mat-nav-list>

<div
  *ngIf="isLoading"
  class="__loading"
>
  <mip-loading></mip-loading>
</div>

<mip-download-app-see-more
  *ngIf="showDownloadApp && (hashtagsList.length >= listLimit || hasMoreItems === false)"
  label="hashtags"
></mip-download-app-see-more>

