import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';

@Component({
  selector: 'mip-profile-following-mini-button',
  templateUrl: './profile-following-mini-button.component.html',
  styleUrls: ['./profile-following-mini-button.component.scss']
})
export class ProfileFollowingMiniButtonComponent implements OnInit {

  @Input() userModel: UserModel = null;

  isAuthenticated = this.userService.isAuthenticated();

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
  }

}
