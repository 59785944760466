import { Injectable } from '@angular/core';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { IndexPaginationFactory } from '@libs/gc-common/lib/factories/index-pagination.factory';
import { LastIdPaginationFactory } from '@libs/gc-common/lib/factories/last-id-pagination.factory';

export interface PostFeedInterfaceLastId {
  namespace: string;
  destroy: () => void;
  postsList: Array<PostModel>;
  pagination: LastIdPaginationFactory;
}

export interface PostFeedInterfaceIndex {
  namespace: string;
  destroy: () => void;
  postsList: Array<PostModel>;
  pagination: IndexPaginationFactory;
}

@Injectable({
  providedIn: 'root'
})
export class PostFeedService {

  feedStorage = {};

  registerFeedLastIdPagination(namespace: string, getPosts: () => any, paginationOptions?: object, reset = false): PostFeedInterfaceLastId {
    // console.log('post-feed.service->registerFeedLastIdPagination(): namespace', namespace, paginationOptions);

    try {

      if (!namespace && typeof namespace !== 'string') {
        throw new Error(`'feedName' must be a string`);
      }

      const feedStorage: PostFeedInterfaceLastId = this.getFeedControl(namespace, paginationOptions);

      if (feedStorage && reset) {
        feedStorage.postsList.splice(0, 5000);

        if (feedStorage.pagination) {
          feedStorage.pagination.destroy();
          feedStorage.pagination = null;
        }

        // console.log('post-feed.service->registerFeedLastIdPagination(): RESET', namespace, feedStorage);
      }

      if (!feedStorage.pagination) {
        feedStorage.pagination = new LastIdPaginationFactory(getPosts, feedStorage.postsList, paginationOptions);
      }

      // console.log('post-feed.service->registerFeedLastIdPagination(): feedStorage', feedStorage);

      return feedStorage;

    }
    catch (e) {
      console.error('post-feed.service->registerFeedLastIdPagination(): ERROR', e);
      throw e;
    }
  }

  registerFeedIndexPagination(namespace: string, getPosts: () => any, paginationOptions?: object, reset = false): PostFeedInterfaceIndex {
    // console.log('post-feed.service->registerFeedIndexPagination(): namespace', namespace, paginationOptions);
    try {

      if (!namespace && typeof namespace !== 'string') {
        throw new Error(`'namespace' must be a string`);
      }

      const feedStorage: PostFeedInterfaceIndex = this.getFeedControl(namespace, paginationOptions);

      if (feedStorage && reset) {
        feedStorage.postsList.splice(0, 1000);

        if (feedStorage.pagination) {
          feedStorage.pagination.destroy();
          feedStorage.pagination = null;
        }

        // console.log('post-feed.service->registerFeedIndexPagination(): RESET', namespace, feedStorage);
      }

      if (!feedStorage.pagination) {
        feedStorage.pagination = new IndexPaginationFactory(getPosts, feedStorage.postsList, paginationOptions);
      }

      // console.log('post-feed.service->registerFeedIndexPagination(): feedStorage', feedStorage);

      return feedStorage;

    }
    catch (e) {
      console.error('post-feed.service->registerFeedIndexPagination(): ERROR', e);
      throw e;
    }
  }

  getFeedControl(namespace: string, paginationOptions?: object) {

    // console.log('post-feed.service->getFeedControl(): namespace', namespace, paginationOptions);

    try {

      if (!namespace && typeof namespace !== 'string') {
        throw new Error(`'feedName' must be a string`);
      }

      if (paginationOptions && paginationOptions['limit']) {
        namespace = `${namespace}-${paginationOptions['limit']}`;
      }

      if (!this.feedStorage[namespace]) {
        // console.log('post-feed.service->getFeedControl(): CREATING namespace', namespace);
        this.feedStorage[namespace] = {
          namespace,
          postsList: [],
          pagination: null,
          destroy: () => {
            this.destroy(namespace);
          }
        };
      }

      // console.log('post-feed.service->getFeedControl(): namespace', namespace, this.feedStorage[namespace]);
      return this.feedStorage[namespace];

    }
    catch (e) {
      throw e;
    }
  }

  destroy(namespace, limit?: number) {
    try {

      // console.log('post-feed.service->destroy(): namespace', namespace, limit);

      if (limit) {
        namespace = `${namespace}-${limit}`;
      }

      if (this.feedStorage[namespace]) {

        if (this.feedStorage[namespace].pagination) {
          this.feedStorage[namespace].pagination.destroy();
        }

        delete this.feedStorage[namespace];
      }

    }
    catch (e) {
      throw e;
    }
  }

}
