<div class="download-app-buttons" [class]="'download-app-buttons--orientation-' + orientation">

  <!-- IS: IPHONE or ANDROID -->
  <div
    class="download-app-buttons__actions --is-phone"
    [class.--align-center]="align === 'center'"
    [class.--align-left]="align === 'left'"
    [class.--align-right]="align === 'right'"
    *ngIf="isIphone || isAndroid"
  >

    <a
      class="__action"
      href="itms-apps://apps.apple.com/app/gotchosen/id1536151089"
      *ngIf="isIphone"
    >
      <!--<span mipTranslate="login.welcome-page.download-app">Download App</span>-->
      <img src="{{assetsPath}}/images/apple-store-badge.svg" [height]="buttonHeight">
    </a>

    <a
      class="__action"
      href="market://details?id=com.gotchosen.influencers"
      *ngIf="isAndroid"
    >
      <!--<span mipTranslate="login.welcome-page.download-app">Download App</span>-->
      <img src="{{assetsPath}}/images/google-play-badge.svg" [height]="buttonHeight">
    </a>
  </div>

  <!-- IS-NOT: IPHONE or ANDROID -->
  <div
    class="download-app-buttons__actions"
    [class.--align-center]="align === 'center'"
    [class.--align-left]="align === 'left'"
    [class.--align-right]="align === 'right'"
    *ngIf="!isIphone && !isAndroid"
  >
    <a
      class="__action"
      target="_blank"
      href="https://apps.apple.com/app/gotchosen/id1536151089"
    >
      <img src="{{assetsPath}}/images/apple-store-badge.svg" [height]="buttonHeight">
    </a>

    <a
      class="__action"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.gotchosen.influencers"
    >
      <img src="{{assetsPath}}/images/google-play-badge.svg" [height]="buttonHeight">
    </a>
  </div>

</div>
