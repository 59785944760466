// import { REQUEST } from '@nguniversal/express-engine/tokens';
import * as atobPolyfill from 'atob';
import * as btoaPolyfill from 'btoa';
import {
  parse,
  stringify
} from 'flatted';

import moment from 'moment';
import { Subject } from 'rxjs';

import slugify from 'slugify';

interface VerticalViewportInterface {
  top?: number;
  bottom?: number;
  startTopBorderBounce?: number;
  topBorderBounce?: number;
  bottomBorderBounce?: number;
}

interface VerticalViewportResponseInterface {
  aboveTopEdge: boolean;
  belowBottomEdge: boolean;
  fullyOutViewport: boolean;
  fullyIntoViewport: boolean;
  intoViewport: boolean;
  topOrBottomIntoTopBounce: boolean;
  topOrBottomIntoBottomBounce: boolean;
  topBorder: {
    intoTopBounce: boolean;
    intoBottomBounce: boolean;
    aboveTopEdge: boolean;
    belowTopEdge: boolean;
    aboveBottomEdge: boolean;
    belowBottomEdge: boolean;
  };
  bottomBorder: {
    intoTopBounce: boolean;
    intoBottomBounce: boolean;
    aboveTopEdge: boolean;
    belowTopEdge: boolean;
    aboveBottomEdge: boolean;
    belowBottomEdge: boolean;
  };
}

class UtilsFactory {
  
  // _request: REQUEST;
  _tabFocusObservable = new Subject();
  _tabFocusObserver = this._tabFocusObservable.asObservable();
  
  _userAgent = null;
  
  isBrowser = false;
  isSSR = false;
  isSafari = false;
  hasStartWatchForActiveTab = false;
  isTabActive = true;
  
  ignoreWaitToBeTrue = false;
  
  constructor() {
    try {
      this.isSSR = !!process.env;
    }
    catch (e) {
      this.isBrowser = true;
    }
    
    // console.log('UtilsFactory->constructor() starting window.onfocus/onblur', this._tabFocusObservable);
    
    if (this.isBrowser && !this.hasStartWatchForActiveTab) {
      
      this.hasStartWatchForActiveTab = true;
      
      window.addEventListener('focus', () => {
        this.isTabActive = true;
        this._tabFocusObservable.next(true);
      }, false);
      window.addEventListener('blur', () => {
        this.isTabActive = false;
        this._tabFocusObservable.next(false);
      }, false);
      
    }
    
    /*if (this._request) {
     this._userAgent = this._request.get('User-Agent');
     }
     else {
     this._userAgent = window.navigator.userAgent;
     }
     
     if (this._userAgent.toLowerCase().indexOf('safari') !== -1) {
     this.isSafari = this._userAgent.toLowerCase().indexOf('chrome') <= -1;
     }
     else {
     this.isSafari = false;
     }*/
    
  }
  
  /**
   * Method to inject a script lib into <head> tag
   */
  async injectTag(url: string, type?: string, persistResponse?) {
    return new Promise((resolve, reject) => {
      
      if (!type) {
        type = 'script';
      }
      
      if (['script'].indexOf(type) === -1) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`The type '${type}' is not valid.`);
        throw new Error(`The type '${type}' is not valid.`);
      }
      
      if (!url || typeof url !== 'string') {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`The URL '${url}' is not valid.`);
        throw new Error(`The URL '${url}' is not valid.`);
      }
      
      let script = null;
      let persistTimit = 0;
      const scriptId = this.btoa(url);
      
      // Debug.log('InjectTag', scriptId, document.getElementById(scriptId))
      
      if (!document.getElementById(scriptId)) {
        script = document.createElement('script');
        script.id = scriptId;
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
      }
      
      if (typeof persistResponse === 'function') {
        const interval = setInterval(() => {
          
          const persistValidation = persistResponse();
          
          if (persistValidation) {
            clearInterval(interval);
            resolve(true);
          }
          else if (persistTimit >= 5000) {
            clearInterval(interval);
            reject(new Error(`The injectTag() has timed out for ${url}`));
          }
          
          persistTimit += 10;
          
        }, 10);
      }
      else if (script && !script.onload) {
        script.onload = () => {
          resolve(true);
        };
      }
      else {
        resolve(true);
      }
      
    });
  }
  
  /**
   * Method to load a JS lib
   */
  async loadScript(url: string, persistence?: () => boolean, config?: { crossOrigin?: string, async?: boolean, defer?: boolean }): Promise<any> {
    // console.log('utils.factory->loadScript()');
    return new Promise(async (resolve, reject) => {
      
      try {
        
        const id = window.btoa(url).split('').slice(0, 40).join('');
        
        if (document.getElementById(id)) {
          // console.log('utils.factory->loadScript(): SCRIPT ALREADY INJECTED');
          resolve(true);
        }
        else {
          const script = document.createElement('script');
          
          script.src = url;
          script.id = id;
          
          if (config) {
            
            script.async = !!config.async;
            script.defer = !!config.defer;
            
            if (config.crossOrigin) {
              script.crossOrigin = config.crossOrigin;
            }
          }
          
          script.nonce = 'LXEcieXb';
          
          if (persistence && typeof persistence === 'function') {
            // console.log('utils.factory->loadScript(): persistence', persistence);
            document.body.appendChild(script);
            
            let countInterval = 0;
            
            await new Promise((resolvePersistence, rejectPersistence) => {
              const persistenceInterval = setInterval(() => {
                if (persistence()) {
                  clearInterval(persistenceInterval);
                  resolvePersistence(true);
                }
                else if (countInterval > 5000) {
                  clearInterval(persistenceInterval);
                  rejectPersistence(`'${url}' has timed out!`);
                }
                countInterval += 100;
              }, 100);
            });
            
            resolve(true);
            
          }
          else {
            script.onload = resolve;
            document.body.appendChild(script);
          }
        }
        
      }
      catch (e) {
        reject(e);
      }
      
    });
  }
  
  getCloserScrollableParent(element, returnDocIfWin = false, bounce = 5) {
    try {
      
      if (this.isBrowser) {
        
        if (!element) {
          throw new Error(`Element must be provided. ${element} was provided instead.`);
        }
        
        // console.log(`utils.factory->getCloserScrollableParent(): element`, element);
        
        let scrollableParent = element;
        let whileLimiter = 0;
        
        const isScrollable = (parent) => {
          
          // console.log(`utils.factory->getCloserScrollableParent(): isScrollable(): parent`, whileLimiter, parent);
          // console.log(`utils.factory->getCloserScrollableParent(): isScrollable(): parent->client`, whileLimiter, { clientHeight: parent.clientHeight, scrollHeight: parent.scrollHeight });
          // console.log(`utils.factory->getCloserScrollableParent(): isScrollable(): parent->client`, whileLimiter, (parent.clientHeight > 0), (parent.scrollHeight > parent.clientHeight));
          
          const isScrollableEl = parent ? (parent.clientHeight > 0 && parent.scrollHeight > (parent.clientHeight + bounce)) : null;
          /*// console.log(`utils.factory->getCloserScrollableParent(): isScrollable(): parent->isScrollableEl`, {
           whileLimiter,
           isScrollableEl,
           scrollHeight: parent.scrollHeight,
           clientHeight: parent.clientHeight,
           offsetHeight: element.offsetHeight
           });
           // console.log(`utils.factory->getCloserScrollableParent(): isScrollable(): -------------------------------------`);*/
          return isScrollableEl;
        };
        
        while (scrollableParent && isScrollable(scrollableParent) === false && whileLimiter < 100 && scrollableParent !== document.querySelector('html')) {
          if (scrollableParent.parentNode) {
            scrollableParent = scrollableParent.parentNode;
            whileLimiter += 1;
          }
          else {
            break;
          }
        }
        
        // console.log(`utils.factory->getCloserScrollableParent(): scrollableParent`, scrollableParent);
        
        // console.log(`utils.factory->getCloserScrollableParent(): element`, element);
        // console.log(`utils.factory->getCloserScrollableParent(): scrollableParent`, scrollableParent);
        // console.log(`utils.factory->getCloserScrollableParent(): ---------------------------`);
        
        if (element !== scrollableParent) {
          
          if (
            scrollableParent === document.querySelector('html') ||
            scrollableParent === document.querySelector('body')
          ) {
            scrollableParent = returnDocIfWin ? document : window;
          }
          
          // console.log(`utils.factory->getCloserScrollableParent(): scrollableParent`, scrollableParent);
          
          return scrollableParent;
        }
        
        return null;
        
      }
      
    }
    catch (e) {
      throw e;
    }
  }
  
  validateUrl(url) {
    return url ? /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(url) : null;
  }
  
  getCookieByName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  
  async isWebView() {
    return new Promise(resolve => {
      if (this.isBrowser) {
        // console.log('utils.factory->isWebView(): window.gcApp_isWithinWebView', typeof window['gcApp_isWithinWebView'], window['gcApp_isWithinWebView']);
        resolve(!!window['gcApp_isWithinWebView']);
      }
      else {
        resolve(false);
      }
    });
  }
  
  atob(value): string {
    if (this.isBrowser) {
      return window.atob(value);
    }
    else {
      return atobPolyfill(value);
    }
  }
  
  btoa(value: string): string {
    if (this.isBrowser) {
      return window.btoa(value);
    }
    else {
      return btoaPolyfill(value);
    }
  }
  
  jsonStringifier(value: object): string {
    
    if (Array.isArray(value)) {
      
      const stringifyValue = [];
      
      for (const item of value) {
        stringifyValue.push(item);
      }
      
      return stringify(stringifyValue);
      
    }
    
    return stringify(value);
  }
  
  jsonParse(value: string): any {
    return parse(value);
  }
  
  /**
   * Method to generate a GUID (string)
   */
  generateGUID(prefix = 'GC', format = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx', size = 16): string {
    let d = new Date().getTime();
    
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    
    const newGuid = format.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * size) % size | 0;
      d = Math.floor(d / size);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(size);
    });
    
    return `${prefix}-${newGuid}`;
  }
  
  onTabChangingActiveState() {
    // console.log('UtilsFactory->onTabChangingActiveState()');
    return this._tabFocusObserver;
  }
  
  diffDates(d1: string | Date, d2: string | Date) {
    return {
      inSeconds: moment(d1).diff(d2, 'seconds'),
      inMinutes: moment(d1).diff(d2, 'minutes'),
      inHours: moment(d1).diff(d2, 'hours'),
      inDays: moment(d1).diff(d2, 'days'),
      inWeeks: moment(d1).diff(d2, 'weeks'),
      inMonths: moment(d1).diff(d2, 'months'),
      inYears: moment(d1).diff(d2, 'years')
    };
  }
  
  hasDateExpired(expirationDate: string | Date, diffDate?): boolean {
    // console.log('utils.factory->hasDateExpired(): expirationDate', expirationDate);
    try {
      
      const expirationDateMoment = moment(expirationDate);
      const today = moment(diffDate);
      const diffDates = expirationDateMoment.diff(today, 'minutes');
      
      // console.log('utils.factory->hasDateExpired(): diffDays', diffDates);
      
      return diffDates < 0;
      
    }
    catch (e) {
      throw e;
    }
  }
  
  randomBetweenMinMax(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  /**
   * Method to parse a string when json is expected
   */
  tryParseJSON(jsonString) {
    try {
      try {
        
        const obj = JSON.parse(jsonString);
        
        if (obj && typeof obj === 'object') {
          return obj;
        }
      }
      catch (e) {
        return false; // null is an object, so we use a boolean for simplicity
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to parse the value and return the value type
   */
  parseValue(value: string) {
    
    // Debug.log('UtilsFactory->parseValue(): ', value)
    
    try {
      if (typeof value === 'string') {
        
        // making some standard values
        if (value === 'false') {
          return false;
        }
        else if (value === 'true') {
          return true;
        }
        else if (value === 'null') {
          return null;
        }
        else if (value.indexOf('[') === 0) {
          value = this.tryParseJSON(value);
          if (typeof value === 'object') {
            return value;
          }
          else {
            return [];
          }
        }
        else if (value.indexOf('{') === 0) {
          value = this.tryParseJSON(value);
          if (typeof value === 'object') {
            return value;
          }
          else {
            return {};
          }
        }
        else if (!isNaN(Number(value))) {
          return parseInt(value, 10);
        }
        
      }
      
      return value;
    }
    catch (e) {
      throw e;
    }
    
  }
  
  noticeError(error, params?: object | Array<any>) {
    if (this.isBrowser && window['newrelic']) {
      
      const sensitiveData = ['password'/*, 'token'*/];
      
      const checkForSensitiveData = (obj, level) => {
        
        // in case recursive enclosure, it won't check
        // deer as 10 level
        if (level > 10) {
          return;
        }
        
        // console.log('checkForSensitiveData(): obj', level, obj);
        
        if (typeof obj === 'object') {
          for (const i in obj) {
            if (Array.isArray(obj[i]) || typeof obj[i] === 'object') {
              // console.log('checkForSensitiveData(): IF', level, i, obj[i]);
              checkForSensitiveData(obj[i], level + 1);
            }
            else if (sensitiveData.indexOf(i) > -1) {
              // console.log('UtilsFactory->noticeError(): level', level, i, obj[i]);
              obj[i] = '##########';
            }
          }
        }
        
      };
      
      let customAttributes;
      
      if (params && typeof params === 'object') {
        
        if (Array.isArray(params)) {
          customAttributes = [...params];
        }
        else {
          customAttributes = { ...params };
        }
        
        // clearing sensitive data before report
        // error to New Relic
        checkForSensitiveData(params, 0);
        
      }
      
      // console.log('UtilsFactory->noticeError(): customAttributes', customAttributes);
      window['newrelic'].noticeError(error, customAttributes);
      
    }
  }
  
  serializeObject(obj: object): string {
    try {
      // console.log('utils.factory->serializeObject(): obj', obj);
      
      const serializedParams = [];
      
      for (const key of Object.keys(obj)) {
        // console.log('utils.factory->serializeObject(): key', obj[key]);
        if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
          serializedParams.push([key, obj[key]].join('='));
        }
      }
      
      // console.log('utils.factory->serializeObject(): serializedParams', serializedParams);
      // console.log('utils.factory->serializeObject(): --------------------------');
      return serializedParams.join('&');
    }
    catch (e) {
      throw e;
    }
  }
  
  parseObjectValues(obj: object): object {
    
    // tslint:disable-next-line:forin
    for (const i in obj) {
      if (typeof obj[i] === 'string') {
        obj[i] = this.parseValue(obj[i]);
      }
    }
    
    return obj;
    
  }
  
  deserializeParams(stringParams): any {
    try {
      
      const paramsObject = {};
      
      if (stringParams) {
        
        stringParams = stringParams.indexOf('?') > -1 ? stringParams.split('?')[1] : stringParams;
        // console.log('utils.factory->deserializeParams(): stringParams', stringParams);
        
        if (stringParams) {
          
          // spliting the params itself
          const url = stringParams.split('&');
          // console.log('utils.factory->deserializeParams(): url', url);
          
          // looping through params
          // tslint:disable-next-line:forin
          for (const i in url) {
            
            // breaking key=value
            const param = url[i].split('=');
            // console.log('utils.factory->deserializeParams(): param', param);
            
            // parse value to return the right type:
            paramsObject[param[0]] = this.parseValue(param[1]);
            
          }
          
        }
        
      }
      
      // console.log('utils.factory->deserializeParams(): paramsObject', paramsObject);
      return paramsObject;
      
    }
    catch (e) {
      throw e;
    }
  }
  
  randomNumber(from: number, to: number) {
    return Math.floor(Math.random() * to) + from;
  }
  
  randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  
  isEmailValid(email: string): boolean {
    // eslint-disable-next-line no-control-regex
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g.test(email);
  }
  
  addClass(element, className: Array<string> | string) {
    try {
      const classNames = element.className.split(' ');
      
      if (Array.isArray(className)) {
        for (const i in className) {
          if (classNames.indexOf(className[i]) === -1) {
            classNames.push(className[i]);
          }
        }
      }
      else {
        if (classNames.indexOf(className) === -1) {
          classNames.push(className);
        }
      }
      
      element.className = classNames.join(' ');
      // console.log('utils.factory->addClass():element.className ', element.className);
    }
    catch (e) {
      throw e;
    }
  }
  
  removeClass(element, className: Array<string> | string) {
    try {
      const classNames = element.className.split(' ');
      // console.log('utils.factory->removeClass(): classNames', className, classNames);
      
      if (Array.isArray(className)) {
        for (const i in className) {
          if (classNames.indexOf(className[i]) > -1) {
            classNames.splice(classNames.indexOf(className[i]), 1);
          }
        }
      }
      else {
        // console.log('utils.factory->removeClass(): classNames', className, classNames, classNames.indexOf(className));
        if (classNames.indexOf(className) > -1) {
          classNames.splice(classNames.indexOf(className), 1);
        }
      }
      
      element.className = classNames.join(' ');
      // console.log('utils.factory->removeClass():element.className ', className, element.className.split(' '));
      
    }
    catch (e) {
      throw e;
    }
  }
  
  slugifyString(value: string): string {
    return slugify(value);
  }
  
  async convertImageToBase64(imageFile) {
    try {
      if (imageFile) {
        const reader = new FileReader();
        
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result);
          };
          reader.readAsDataURL(imageFile);
        });
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to check if an element is within the viewport
   */
  isElementIntoVerticalViewport(el, parent, prop?: { viewport?: VerticalViewportInterface, virtualViewport?: VerticalViewportInterface }) {
    
    try {
      
      if (utilsFactory.isBrowser) {
        
        const innerHeight = parent === window ? parent.innerHeight : parent.offsetHeight;
        // console.log('utils.factory->isElementIntoViewport()', { innerHeight });
        
        const { top, bottom, height } = el.getBoundingClientRect();
        // console.log('utils.factory->isElementIntoViewport()', { top, bottom });
        
        const getViewportBounding = (bounceProp) => {
          
          const realTop = top; //  - (bounceProp.marginTop || 0);
          const realBottom = bottom; // - (bounceProp.marginTop || 0);
          
          const topBounce = (bounceProp.top || 0);
          const bottomBounce = (bounceProp.bottom || 0);
          
          const startTopBorderBounce = bounceProp.startTopBorderBounce;
          
          const isTopBorderAboveTopEdge = realTop < topBounce;
          // console.log('utils.factory->isElementIntoViewport()', { isTopBorderAboveTopEdge });
          
          const isTopBorderBelowTopEdge = realTop >= (topBounce ? (topBounce * (-1)) : 0);
          // console.log('utils.factory->isElementIntoViewport()', { isTopBorderBelowTopEdge });
          
          const isTopBorderAboveBottomEdge = realTop <= (innerHeight + bottomBounce);
          // console.log('utils.factory->isElementIntoViewport()', { isTopBorderAboveBottomEdge });
          
          const isTopBorderBelowBottomEdge = realTop > (innerHeight + bottomBounce);
          // console.log('utils.factory->isElementIntoViewport()', { isTopBorderBelowBottomEdge });
          
          const isBottomBorderAboveTopEdge = realBottom < bottomBounce;
          // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderAboveTopEdge });
          
          const isBottomBorderBelowTopEdge = realBottom >= topBounce;
          // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderAboveTopEdge });
          
          const isBottomBorderAboveBottomEdge = realBottom <= (innerHeight + bottomBounce);
          // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderAboveBottomEdge });
          
          const isBottomBorderBelowBottomEdge = realBottom > (innerHeight + bottomBounce);
          // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderAboveBottomEdge });
          
          let isTopBorderIntoTopBounce = null;
          let isBottomBorderIntoTopBounce = null;
          
          let isBottomBorderIntoBottomBounce = null;
          let isTopBorderIntoBottomBounce = null;
          
          if (typeof bounceProp === 'object' && bounceProp.topBorderBounce) {
            isTopBorderIntoTopBounce = (realTop >= 0 && realTop >= startTopBorderBounce && realTop <= (bounceProp.topBorderBounce + startTopBorderBounce));
            // console.log('utils.factory->isElementIntoViewport()', { isTopBorderIntoTopBounce });
            
            isBottomBorderIntoTopBounce = (realBottom >= 0 && realBottom >= startTopBorderBounce && realBottom <= (bounceProp.topBorderBounce + startTopBorderBounce));
            // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderIntoTopBounce });
          }
          
          const bottomPosition = innerHeight - realBottom;
          if (typeof bounceProp === 'object' && bounceProp.bottomBorderBounce) {
            isBottomBorderIntoBottomBounce = (bottomPosition >= 0 && bottomPosition <= bounceProp.bottomBorderBounce);
            // console.log('utils.factory->isElementIntoViewport()', { isBottomBorderIntoBottomBounce });
            
            isTopBorderIntoBottomBounce = realTop >= (innerHeight - bounceProp.bottomBorderBounce) && top < innerHeight;
            // console.log('utils.factory->isElementIntoViewport()', { isTopBorderIntoBottomBounce });
          }
          
          return {
            props: {
              innerHeight,
              realTop,
              realBottom,
              topBounce,
              bottomBounce,
              bounceProp
            },
            aboveTopEdge: isBottomBorderAboveTopEdge,
            belowBottomEdge: isTopBorderBelowBottomEdge,
            fullyOutViewport: isBottomBorderAboveTopEdge || isTopBorderBelowBottomEdge,
            fullyIntoViewport: isTopBorderBelowTopEdge && isBottomBorderAboveBottomEdge,
            intoViewport: (isTopBorderBelowTopEdge && isTopBorderAboveBottomEdge) || (isBottomBorderAboveBottomEdge && isBottomBorderBelowTopEdge),
            topOrBottomIntoTopBounce: isTopBorderIntoTopBounce || isBottomBorderIntoTopBounce,
            topOrBottomIntoBottomBounce: isTopBorderIntoBottomBounce || isBottomBorderIntoBottomBounce,
            topBorder: {
              intoTopBounce: isTopBorderIntoTopBounce,
              intoBottomBounce: isTopBorderIntoBottomBounce,
              aboveTopEdge: isTopBorderAboveTopEdge,
              belowTopEdge: isTopBorderBelowTopEdge,
              aboveBottomEdge: isTopBorderAboveBottomEdge,
              belowBottomEdge: isTopBorderBelowBottomEdge
            },
            bottomBorder: {
              intoTopBounce: isBottomBorderIntoTopBounce,
              intoBottomBounce: isBottomBorderIntoBottomBounce,
              aboveTopEdge: isBottomBorderAboveTopEdge,
              belowTopEdge: isBottomBorderBelowTopEdge,
              aboveBottomEdge: isBottomBorderAboveBottomEdge,
              belowBottomEdge: isBottomBorderBelowBottomEdge
            }
          };
          
        };
        
        const response: { viewport?: VerticalViewportResponseInterface, virtualViewport?: VerticalViewportResponseInterface } = {
          viewport: getViewportBounding(prop.viewport || {})
        };
        
        if (prop.virtualViewport) {
          response.virtualViewport = getViewportBounding(prop.virtualViewport || {});
        }
        
        return response;
        
      }
      
      return false;
      
    }
    catch (e) {
      throw e;
    }
    
  }
  
  async delayTime(time = 10) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }
  
  async waitToBeTrue(caller: string, val: () => boolean, timeout = 30000, intervalTime = 100, disableTimerLimiter = false) {
    try {
      // console.log(`[${caller.toUpperCase()}] utils.factory->waitToBeTrue()`);
      
      if (typeof val !== 'function') {
        throw new Error(`You must provide a validation function like: waitToBeTrue(() => {variable_to_be_validated})`);
      }
      
      return await new Promise((resolve, reject) => {
        // console.log(`[${caller.toUpperCase()}] utils.factory->waitToBeTrue(): val()`, val());
        
        if (val()) {
          resolve(true);
          return;
        }
        
        let timerLimiter = 0;
        
        const interval = setInterval(() => {
          // console.log('[${caller.toUpperCase()}] utils.factory->waitToBeTrue(): val', val(), val);
          
          if (this.ignoreWaitToBeTrue) {
            clearInterval(interval);
            reject(new Error(`[${caller.toUpperCase()}] waitToBeTrue() is been ignored due to possible error!`));
          }
          else if (val()) {
            clearInterval(interval);
            resolve(true);
          }
          else if (disableTimerLimiter === false && timerLimiter > timeout) {
            clearInterval(interval);
            reject(new Error(`[${caller.toUpperCase()}] waitToBeTrue() has timed out!`));
          }
          
          timerLimiter += intervalTime;
          
        }, intervalTime);
        
      });
    }
    catch (e) {
      throw e;
    }
  }
  
}

export const utilsFactory = new UtilsFactory();
