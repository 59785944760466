import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class IsAnonymousGuard implements CanActivate, CanActivateChild {

	constructor(
		private userService: UserService,
		private router: Router,
		private routerService: RouterService,
		private snackBar: MatSnackBar,
		private translate: TranslateService
	) {
	}

	async canActivate(
		route: ActivatedRouteSnapshot,
		{ url }: RouterStateSnapshot
	) {
		try {
			console.log('is-anonymous.guard->canActivate() url', url);
			console.log('is-anonymous.guard->canActivate() route', route.params);

			this.routerService.setActiveRoute(route);

			const { data, params } = await this.routerService.getRouteObject();
			console.log('is-anonymous.guard->canActivate() data', data);
			console.log('is-anonymous.guard->canActivate() params', params);

			if (!this.userService.isAuthenticated()) {
				console.log('is-anonymous.guard->canActivate() return TRUE');
				return true;
			}
			else {

				this.translate.get('user-already-logged-in').subscribe(async translation => {
					this.snackBar.open(translation, 'Ok', {
						panelClass: 'mip-color--bg-info',
						horizontalPosition: 'end',
						duration: 5000
					});
				});

				// redirect to profile
				if (url.indexOf('/invite/') > -1 && params.userId) {
					return this.router.parseUrl(`${params.userId.indexOf('@') > -1 ? params.userId : '@' + params.userId}`);
				}
				else {
					// return this.router.parseUrl(data.baseUrl ? data.baseUrl : '/trending');
					document.location = environment.networkUrl + (`/${data.baseUrl ? data.baseUrl : '/trending'}`).replace('//', '/');
					return false;
				}

				return true;

			}
		}
		catch (e) {
			console.error('is-anonymous.guard->canActivate() ERROR', e);
			// return this.router.parseUrl('/trending');
		}
	}

	async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log('is-anonymous.guard->canActivateChild()');
		return this.canActivate(route, state);
	}
}
