import { environment } from '@libs/gc-common/environments/environment';
import { BaseModel } from '@libs/gc-common/lib/api/models/base.model';

import { AvatarInterface } from './avatar.interface';

export class AvatarModel extends BaseModel implements AvatarInterface {

  id = null; // api
  mimeType = null; // api
  source = null; // api
  updatedAt = null; // api
  createdAt = null; // api
  height = null; // api
  width = null; // api

  constructor(model?: AvatarInterface) {
    super(model);
    this.fill(model);
  }

  override beforeFill(model) {

    // console.log('avatar.model->beforeFill(): BEFORE model.avatarUrl', model.avatarUrl);

    if (environment.replaceMediaUrl && environment.replaceMediaUrl.replace) {
      // console.log('avatar.model->beforeFill(): environment.replaceMediaUrl', environment.replaceMediaUrl);

      if (model.avatarUrl) {
        model.avatarUrl = model.avatarUrl.replace(
          environment.replaceMediaUrl.replace,
          environment.replaceMediaUrl.with
        );
      }

    }

    // console.log('avatar.model->beforeFill(): AFTER model.avatarUrl', model.avatarUrl);

  }

}
