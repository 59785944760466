import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import {
  Observable,
  Subject
} from 'rxjs';

import { BaseModelFactoryInterface } from './base.model.factory.interface';

export class BaseModelFactory implements BaseModelFactoryInterface {

  _collectionObservable = utilsFactory.isBrowser ? new Subject() : null;
  _collectionObserver = utilsFactory.isBrowser ? this._collectionObservable.asObservable() : null;

  _triggersRepository = {};

  storage = {};
  identifier = null;
  model = null;

  triggerOnCollectionChangedTimeout = null;

  constructor() {
    // console.log('base.model.factory->constructor() this.storage', this.storage, this.model);

    /*setTimeout(() => {
     // console.log('base.model.factory->constructor() this', this);

     if (!this.model) {
     throw new Error(`You must specify the Model Constructor within the new model factory`);
     }
     }, 1);*/
  }

  getIdentifier(model) {

    let identifier = null;

    if (typeof this.identifier === 'string') {
      identifier = model[this.identifier];
    }
    else if (Array.isArray(this.identifier)) {

      identifier = '';

      // tslint:disable-next-line:forin
      for (const i in this.identifier) {
        if (this.identifier[i] in model && model[this.identifier[i]]) {
          identifier += (identifier ? '-' : '') + model[this.identifier[i]];
        }
        else {
          throw new Error(`'${this.identifier[i]}' does not exist within model or it is undefined`);
        }
      }

    }

    return identifier;
  }

  getModel(filter: string) {
    // console.log('base.model.factory->getModel() filter', this.model, filter, !!this.storage[filter]);
    return this.storage[filter];
  }

  storeModel(model: object) {

    const identifier = this.getIdentifier(model);
    // console.log('base.model.factory->storeModel() identifier', identifier);

    if (identifier in this.storage) {
      return this.storage[identifier];
    }
    else {

      // if (utilsFactory.isBrowser) {
      this.storage[identifier] = model;
      // }

      // console.log('base.model.factory->storeModel() storage', Object.keys(this.storage).length);
      return model;
    }
  }

  removeModel(filter: any) {
    if (this.storage[filter]) {
      delete this.storage[filter];
      this.triggerOnCollectionChanged();
    }
  }

  createModel(data: object) {

    // console.log(('base.model.factory->createModel() data', data);
    // console.log(('base.model.factory->createModel() this.identifier', this.identifier);
    // console.log(('base.model.factory->createModel() this.model', this.model);
    // console.log(('base.model.factory->createModel() this.storage', this.storage);

    let model = null;

    const identifier = this.getIdentifier(data);
    // console.log(('base.model.factory->createModel() identifier', identifier);

    if (identifier) {
      model = this.getModel(identifier);
    }

    // console.log(('base.model.factory->createModel() this.model', this.model);
    // console.log(('base.model.factory->createModel() model', model);
    // console.log(('base.model.factory->createModel() data', data);

    if (model) {
      model.fill(data);
    }
    else {
      model = new this.model(data);

      if (identifier) {
        this.storeModel(model);
      }
    }

    // console.log(('base.model.factory->createModel() model', model);

    return model;

  }

  build(data: object): any {

    let model = null;

    if (data && Object.keys(data).length) {
      model = this.createModel(data);
    }

    this.triggerOnCollectionChanged();

    return model;

  }

  buildCollection(data: Array<object>): Array<any> {

    const collection = [];

    if (Array.isArray(data) && data.length) {
      data.forEach(item => {
        // console.log(('base.model.factory->createModel() item', item, this.model);
        collection.push(this.createModel(item));
      });
    }

    this.triggerOnCollectionChanged();

    return collection;

  }

  onCollectionChanged() {
    if (this._collectionObserver) {
      return this._collectionObserver;
    }
    else {
      return new Observable();
    }
  }

  triggerOnCollectionChanged() {

    if (this._collectionObservable) {

      if (this.triggerOnCollectionChangedTimeout) {
        clearTimeout(this.triggerOnCollectionChangedTimeout);
      }

      this.triggerOnCollectionChangedTimeout = setTimeout(() => {
        this._collectionObservable.next(this);
      }, 50);

    }

  }

  onCollectionChange(namespace?: string) {
    try {
      // console.log(('base.model.factory->onCollectionChange(): namespace', namespace, this._triggersRepository);

      if (namespace) {
        if (!this._triggersRepository[namespace]) {

          this._triggersRepository[namespace] = {
            observable: null,
            observer: null
          };

          this._triggersRepository[namespace].observable = new Observable(observer => {
            this._triggersRepository[namespace].observer = observer;
          });
        }

        // console.log(('base.model.factory->onChange(): namespace', namespace, this._triggersRepository[namespace]);

        return this._triggersRepository[namespace].observable;

      }
      else {
        throw new Error(`'namespace' must be provided!`);
      }
    }
    catch (e) {
      console.error('base.model.factory->onCollectionChange(): ERROR', e);
      throw e;
    }
  }

  triggerOnChange(namespace?: string, excludeAfterRun = false) {
    try {
      if (namespace) {
        // console.log(('base.model.factory->triggerOnChange(): namespace', namespace, excludeAfterRun, this._triggersRepository);

        if (this._triggersRepository[namespace]) {
          this._triggersRepository[namespace].observer.next(this);

          if (excludeAfterRun) {
            delete this._triggersRepository[namespace];
          }
        }
        else {
          throw new Error(`There is no observer registered with namespace '${namespace}'!`);
        }
      }
      else {
        throw new Error(`'namespace' must be provided!`);
      }
    }
    catch (e) {
      console.error('base.model.factory->triggerOnChange(): ERROR', e);
      throw e;
    }
  }

}
