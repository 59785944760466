import { MediaModel } from '@libs/gc-common/lib/api/models/media';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import HLS from 'hls.js';
import { Subject } from 'rxjs';
import { utilsFactory } from './utils.factory';

export interface AttachVideoInterface {
  preservePlayer?: boolean;
  videoContainer: HTMLDivElement;
  postModel: PostModel;
  looping?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  controls?: boolean;
  poster?: boolean;
  videoQuality?: string;

  onLoaded?: (any?) => void;
  onLoadStart?: (any?) => void;
  onEnded?: (any?) => void;
  onPlay?: (any?) => void;
  onPause?: (any?) => void;
  onDetach?: (any?) => void;
  onMute?: (any?) => void;
  onUnmute?: (any?) => void;
  onError?: (any?) => void;

}

export class VideoPlayerFactory {

  _playerObservable = new Subject();
  observer = this._playerObservable.asObservable();

  // local vars
  videoContainer = null;
  videoElement = null;
  looping = true;
  autoplay = false;
  timelineInterval = null;
  onPlayInterval = null;
  postModel: PostModel = null;
  postMediaModel: MediaModel = null;
  controls = false;
  muted = true;
  isPlaying = false;

  hasCalledOnLoad = false;
  hasCalledOnLoaded = false;

  hls = null;

  // local methods
  onLoaded: (any?) => void = null;
  onLoadStart: (any?) => void = null;
  onEnded: (any?) => void = null;
  onPlay: (any?) => void = null;
  onPause: (any?) => void = null;
  onDetach: (any?) => void = null;
  onMute: (any?) => void = null;
  onUnmute: (any?) => void = null;
  onError: (any?) => void = null;

  constructor() {

    if (utilsFactory.isBrowser) {

      this.videoElement = document.createElement('video');
      this.videoElement.className = 'video-player-element';
      this.videoElement.controls = this.controls;
      this.videoElement.loop = this.looping;
      this.videoElement.autoplay = this.autoplay;
      this.videoElement.muted = this.muted;
      // this.videoElement.style['object-fit'] = 'cover';
      this.videoElement.setAttribute('playsinline', '');
      this.videoElement.setAttribute('preload', 'metadata');
      // console.log('video-player.factory->initVideoPlayer(): this.videoElement', this.videoElement);

      // videos self events
      this.videoElement.onloadstart = this.onVideoLoadStart.bind(this);
      // this.videoElement.onwaiting = this.onVideoLoadStart.bind(this);
      this.videoElement.oncanplay = this.onVideoLoaded.bind(this);

      // videos interactions events
      this.videoElement.onplay = this.onVideoPlayed.bind(this);
      this.videoElement.onpause = this.onVideoPaused.bind(this);
      this.videoElement.onended = this.onVideoEnded.bind(this);

    }

  }

  /**
   * Method to attach the video player into a provided container
   */
  attachVideo(options: AttachVideoInterface) {
    // console.log('video-player.factory->attachVideo(): options', options);

    try {

      if (!options.videoContainer) {
        throw new Error(`You must provide a video container: HTMLDivElement`);
      }

      if (!options.postModel) {
        throw new Error(`You must provide the 'PostModel'`);
      }

      if (!options.postModel.getCover()) {
        throw new Error(`The 'MediaModel' has no 'source' url`);
      }

      if (!options.postModel.medias) {
        throw new Error(`The 'PostModel' has no 'MediaModel' url`);
      }

      if (!this.videoElement) {
        return;
      }

      this.detachVideo();
      this.onVideoPaused();

      this.videoContainer = options.videoContainer;
      // console.log('video-player.factory->attachVideo(): this.videoContainer', this.videoContainer);

      this.onLoaded = options.onLoaded;
      this.onLoadStart = options.onLoadStart;
      this.onEnded = options.onEnded;
      this.onPlay = options.onPlay;
      this.onPause = options.onPause;
      this.onDetach = options.onDetach;
      this.onMute = options.onMute;
      this.onUnmute = options.onUnmute;
      this.onError = options.onError;

      this.postModel = options.postModel;
      // console.log('video-player.factory->attachVideo(): this.postModel', this.postModel);

      this.postMediaModel = this.postModel.getVideoMedia();
      // console.log('video-player.factory->attachVideo(): this.postMediaModel', this.postMediaModel);

      // console.log('video-player.factory->attachVideo(): options.poster', options.poster);

      if (options.poster === true) {
        this.videoElement.poster = this.postModel.getCover();
        // console.log('video-player.factory->attachVideo(): options.poster: this.videoElement.poster', this.videoElement.poster);
      }

      this.videoElement.controls = options.controls;
      // console.log('video-player.factory->attachVideo(): this.videoElement.controls', this.videoElement.controls);

      if (this.videoElement.canPlayType(this.postMediaModel.mimeType)) {
        // console.log('video-player.factory->attachVideo(): VIDEO TYPE', this.postMediaModel.mimeType);
        this.videoElement.src = this.postMediaModel.source;
      }
      else if (HLS.isSupported()) {
        // console.log('video-player.factory->attachVideo(): VIDEO HLS', this.postMediaModel.mimeType);
        this.hls = new HLS();
        this.hls.loadSource(options.postModel.getVideoSource(options.videoQuality));
        this.hls.attachMedia(this.videoElement);
      }

      if (options.muted === false) {
        this.unmuteVideo();
      }
      else if (options.muted === true) {
        this.muteVideo();
      }

      if (options.autoplay === false) {
        this.pauseVideo();
      }
      else if (options.autoplay === true) {
        this.playVideo();
      }

      this.videoContainer.setAttribute('is-attached', 'true');
      this.videoContainer.appendChild(this.videoElement);

    }
    catch (e) {
      console.error('video-player.factory->attachVideo(): ERROR', e);
      throw e;
    }

  }

  /**
   * Remove the video from the provided container
   */
  detachVideo(keepAutoplaying = false) {
    // console.log('video-player.factory->detachVideo(): keepAutoplaying', keepAutoplaying);

    try {

      if (this.hls) {
        this.hls.destroy();
      }

      if (this.onPlayInterval) {
        clearInterval(this.onPlayInterval);
      }

      this.isPlaying = keepAutoplaying;

      if (this.postModel) {
        this.postModel.isPlaying = false;
      }

      if (this.videoContainer) {
        // console.log('video-player.factory->detachVideo(): this.videoContainer', this.videoContainer);
        this.videoContainer.innerHTML = '';
        this.videoContainer.removeAttribute('is-attached');
        this.videoContainer = null;
      }

      if (this.onDetach) {
        this.onDetach();
      }

      this.hasCalledOnLoad = false;
      this.hasCalledOnLoaded = false;
      this.postModel = null;

      this.onLoaded = null;
      this.onLoadStart = null;
      this.onEnded = null;
      this.onPlay = null;
      this.onPause = null;
      this.onDetach = null;
      this.onMute = null;
      this.onUnmute = null;
      this.onError = null;

    }
    catch (e) {
      console.error('video-player.factory->detachVideo(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method to play the video
   */
  playVideo() {

    // console.log('video-player.factory->playVideo(): this.videoContainer', this.videoContainer);
    // console.log('video-player.factory->playVideo(): this.videoElement', this.videoElement);

    try {

      if (this.videoContainer) {
        // console.log('video-player.factory->playVideo(): is-attached');

        this.looping = true;
        this.autoplay = true;

        // this.videoPlayer.pause();
        this.videoElement.loop = this.looping;
        this.videoElement.autoplay = this.autoplay;
        this.videoElement.play();

        // console.log('video-player.factory->playVideo(): this.videoElement.muted', this.videoElement.muted);

        let hasTriggeredPlayed = false;

        clearInterval(this.onPlayInterval);

        this.onPlayInterval = setInterval(() => {
          // console.log('video-player.factory->playVideo(): onPlayInterval', this.videoElement.currentTime);

          if (this.videoElement.currentTime > 0 && hasTriggeredPlayed === false) {
            this.onVideoPlayed();
            hasTriggeredPlayed = true;
          }

        }, 100);

      }
    }
    catch (e) {
      // console.log('video-player.factory->playVideo(): ERROR', e);

      throw e;
    }

  }

  /**
   * Method to pause the video element
   */
  pauseVideo(pauseWithAutoplay = false) {
    // console.log('video-player.factory->pauseVideo()');

    try {

      if (this.videoContainer) {

        this.videoElement.pause();

        this.autoplay = !!pauseWithAutoplay;
        this.videoElement.autoplay = this.autoplay;

      }

      this.onVideoPaused();

    }
    catch (e) {
      throw e;
    }

  }

  onVideoError() {
    // console.log('video-player.factory->onVideoError()');

    if (this.onVideoError) {
      this.onVideoError();
    }

  }

  onVideoPlayed() {
    // console.log('video-player.factory->onVideoPlayed()');

    clearInterval(this.timelineInterval);

    if (!this.postModel) {
      // console.log('video-player.factory->onVideoPlayed(): Not a PostModel');
      return false;
    }

    // console.log('video-player.factory->onVideoPlayed(): setInterval');
    this.timelineInterval = setInterval(() => {
      // console.log('video-player.factory->onVideoPlayed(): this.timelineInterval', this.timelineInterval);

      const currentTime = this.videoElement.currentTime;
      // console.log('video-player.factory->onVideoPlayed(): currentTime', currentTime);

      this.isPlaying = true;

      if (this.postModel) {

        this.postModel.isPlaying = true;

        if (this.postModel.duration !== null) {
          this.postModel.currentTime = currentTime;
        }

      }
      else {
        // clearInterval(this.timelineInterval);
      }

    }, 100);

    // console.log('video-player.factory->onVideoPlayed(): this.postModel', this.postModel);

    this.isPlaying = true;

    if (this.postModel) {
      this.postModel.isPlaying = true;
      this.postModel.currentTime = this.videoElement.currentTime;
    }

    if (this.onPlay) {
      this.onPlay();
    }

    this._playerObservable.next('played');

  }

  onVideoPaused() {

    // console.log('video-player.factory->onVideoPaused()');

    clearInterval(this.timelineInterval);

    // this.autoplay = false;
    // this.videoElement.autoplay = this.autoplay;

    this.isPlaying = false;

    if (this.postModel) {
      this.postModel.isPlaying = false;
      this.postModel.currentTime = this.videoElement.currentTime;
    }

    // console.log('video-player.factory->onVideoPaused(): this.onPause', this.onPause);
    if (this.onPause) {
      this.onPause();
    }

    this._playerObservable.next('paused');

  }

  onVideoEnded() {
    // console.log('video-player.factory->onVideoEnded()');

    clearInterval(this.timelineInterval);

    this.isPlaying = false;
    this.hasCalledOnLoad = false;
    this.hasCalledOnLoaded = false;

    if (this.postModel) {
      this.postModel.isPlaying = false;
    }

    if (this.onEnded) {
      this.onEnded();
    }

    this._playerObservable.next('ended');

  }

  onVideoLoadStart() {
    // console.log('video-player.factory->onVideoLoadStart()');

    if (this.postModel) {
      this.postModel.isPlaying = false;
    }

    if (this.onLoadStart) {
      this.onLoadStart();
    }

    this._playerObservable.next('loading');

  }

  onVideoLoaded() {
    // console.log('video-player.factory->onVideoLoaded()');

    if (this.postModel) {
      this.postModel.currentTime = this.videoElement.currentTime;
      this.postModel.duration = this.videoElement.duration;
    }

    setTimeout(() => {

      if (this.onLoaded) {
        this.onLoaded();
      }

      this._playerObservable.next('loaded');

    }, 100);

    return false;

  }

  toggleMute() {
    // console.log('video-player.factory->toggleMute(): this.muted', this.muted);

    if (this.muted) {
      this.unmuteVideo();
    }
    else {
      this.muteVideo();
    }
  }

  muteVideo() {
    // console.log('video-player.factory->muteVideo(): this.onMute', this.onMute);

    if (this.videoElement) {
      this.videoElement.muted = true;
    }

    this.muted = true;

    if (this.onMute) {
      this.onMute();
    }

  }

  unmuteVideo() {
    // console.log('video-player.factory->unmuteVideo(): this.videoElement', this.videoElement);

    if (this.videoElement) {
      this.videoElement.muted = false;
    }

    this.muted = false;

    if (this.onUnmute) {
      this.onUnmute();
    }
  }

  setCurrentVideoTime(currentTime: number) {

    if (this.videoElement) {
      this.videoElement.currentTime = currentTime;
    }

  }

}

export const videoPlayerFactory = new VideoPlayerFactory();
