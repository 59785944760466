import {
  Component,
  OnInit
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from '@libs/gc-common/environments/environment';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-download-app-modal',
  templateUrl: './download-app-modal.component.html',
  styleUrls: ['./download-app-modal.component.scss']
})
export class DownloadAppModalComponent implements OnInit {

  isIphone = false;
  isAndroid = false;
  isMobile = false;
  isDesktop = false;

  assetsPath = environment.assetsPath;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DownloadAppModalComponent>,
    private mobileDetect: MobileDetectService
  ) {
  }

  ngOnInit(): void {

    this.isIphone = this.mobileDetect.isIphone();
    this.isAndroid = this.mobileDetect.isAndroid();
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !(this.isIphone || this.isAndroid || this.isMobile);

    console.log('composer.component->ngOnInit() mobileDetect: this.isIphone', this.isIphone);
    console.log('composer.component->ngOnInit() mobileDetect: this.isAndroid', this.isAndroid);
    console.log('composer.component->ngOnInit() mobileDetect: this.isMobile', this.isMobile);
    console.log('composer.component->ngOnInit() mobileDetect: this.isDesktop', this.isDesktop);
  }

  closeDialog() {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }

}
