import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';

@Component({
  selector: 'mip-mip-profile-slug-name-badges',
  templateUrl: './mip-profile-slug-name-badges.component.html',
  styleUrls: ['./mip-profile-slug-name-badges.component.scss']
})
export class MipProfileSlugNameBadgesComponent implements OnInit {

  @Input() user: UserModel = null;
  @Input() badgeSize = '3x';

  eventIconsSize = '2x';

  constructor() {
    // console.log('mip-profile-slug-name-badges.component->constructor(): this.badgeSize', parseInt(this.badgeSize, 10));

    this.eventIconsSize = (parseInt(this.badgeSize, 10) - 1) + 'x';
    // console.log('mip-profile-slug-name-badges.component->constructor(): this.eventIconsSize', this.eventIconsSize);
  }

  ngOnInit(): void {
  }

  showTooltip(Tooltip, event) {
    event.stopPropagation();
    Tooltip.show();
    return false;
  }

}
