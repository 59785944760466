<div class="recaptcha">

  <mip-icon pngIcon="recaptcha" size="9x"></mip-icon>

  <div class="recaptcha__inner" mipTranslate="recaptcha.message">
    This site is protected by <strong>reCAPTCHA</strong> and the Google
    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
  </div>

</div>
