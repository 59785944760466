<form
  class="form"
  *ngIf="formConfigData"
  [formGroup]="form"
  [class.form--is-mobile]="isMobile"
  [class.--is-disabled]="form.disabled"
  (submit)="onSubmit()"
  (change)="hasFormChanged = true"
>

  <div class="form__header">
    <span style="margin-right: 5px" mipTranslate="features.contact-form.title">Form:</span> <strong>{{ (formConfigData?.mipTranslate || formConfigData?.formName) | translate }}</strong>

    <button
      class="form__close"
      type="button"
      mat-button
      *ngIf="isWithinWebView === false"
      [disabled]="form.disabled"
      (click)="close()"
    >
      <mip-icon [matIcon]="isMobile ? 'arrow_back_ios_new' : 'close'"></mip-icon>
    </button>

  </div>

  <div class="form__fields">

    <div class="field" *ngFor="let row of formFields" [class.field--group]="row.group">

      <div class="field__inner" *ngFor="let field of row.group" [style.max-width]="field.maxWidth || '100%'">

        <div *ngIf="field.type !== 'file'">
          <ng-container *ngTemplateOutlet="fieldTpl; context: { field: field}"></ng-container>
        </div>

        <mip-form-file-upload
          *ngIf="resetFiles === false && field.type === 'file'"
          (onFilesChange)="onFilesChange($event)"
        ></mip-form-file-upload>

      </div>

      <div class="field__inner" *ngIf="row.field" [style.max-width]="row.field.maxWidth || '100%'">

        <div *ngIf="row.field.type !== 'file'">
          <ng-container *ngTemplateOutlet="fieldTpl; context: { field: row.field}"></ng-container>
        </div>

        <mip-form-file-upload
          *ngIf="resetFiles === false && row.field.type === 'file'"
          (onFilesChange)="onFilesChange($event)"
        ></mip-form-file-upload>

      </div>

    </div>

  </div>

  <div class="form__footer">

    <div class="form__message" *ngIf="errorTitle || errorMessage">
      <mat-icon class="gc-icon--size-6x">warning</mat-icon>
      <div>
        <h5 *ngIf="errorTitle"><strong>{{ errorTitle }}</strong></h5>
        <div *ngIf="errorMessage">{{ errorMessage }}</div>
      </div>
    </div>

    <div class="form__message form__message--success" *ngIf="success">
      <mat-icon class="gc-icon--size-6x">thumb_up</mat-icon>
      <div mipTranslate="features.contact-form.message.success">
        The message was sent successfully! Thanks for your contact.
      </div>
    </div>

    <div class="actions">
      <button
        class="actions__action"
        type="button"
        mat-button
        *ngIf="isWithinWebView === false"
        [disabled]="form.disabled"
        (click)="close()"
      >
        <span *ngIf="hasFormChanged" mipTranslate="features.contact-form.action.cancel">CANCEL</span>
        <span *ngIf="!hasFormChanged" mipTranslate="features.contact-form.action.close">CLOSE</span>
      </button>

      <button
        class="actions__action actions__action--submit"
        type="submit"
        mat-button
        mipBgColor="black"
        [disabled]="form.disabled"
      >
        <mip-loading *ngIf="sending" size="4x"></mip-loading>
        <span *ngIf="sending" mipTranslate="features.contact-form.action.sending">SENDING...</span>
        <span *ngIf="!sending" mipTranslate="features.contact-form.action.send">SEND</span>
      </button>
    </div>
  </div>

</form>

<ng-template #fieldTpl let-field="field">

  <div [formGroup]="form">

    <mat-form-field
      appearance="fill"
      [class.--is-disabled]="field.disabled || (field.dependencyControl ? (!field.dependencyControl.value || field.loading) : false)"
    >

      <!-- LABEL -->
      <mat-label>{{ 'features.contact-form.field.' + field.id | translate }}</mat-label>

      <!-- ICON -->
      <mat-icon
        matIconPrefix
        [svgIcon]="field.svgIcon"
        *ngIf="!field.loading && !field.image && (field.icon || field.svgIcon)"
      >
        {{ field.icon }}
      </mat-icon>

      <div class="field__avatar" matIconPrefix *ngIf="!field.loading && field.image">
        <mip-avatar [source]="field.image"></mip-avatar>
      </div>

      <div class="field__avatar" matIconPrefix *ngIf="field.loading">
        <mip-loading
          class="field__loading"
          [class.field__loading--show]="field.loading"
          size="4x"
        ></mip-loading>
      </div>

      <!-- FIELD.TYPE -->
      <input
        matInput
        [type]="field.type || 'text'"
        [formControl]="field.formControl"
        [placeholder]="field.placeholder"
        [readonly]="field.disabled || (field.dependencyControl ? (!field.dependencyControl.value || field.loading) : false)"
        [required]="formConfigData.requiredFields ? formConfigData.requiredFields[field.id] !== false : true"
        *ngIf="field.type !== 'textarea' && field.type !== 'autocomplete' && field.type !== 'select' && field.type !== 'file'"
        (change)="field.onChange ? field.onChange(field) : null"
      >

      <!-- TEXTAREA -->
      <textarea
        matInput
        [rows]="3"
        [formControl]="field.formControl"
        [required]="formConfigData.requiredFields ? formConfigData.requiredFields[field.id] !== false : true"
        *ngIf="field.type === 'textarea'"
        (change)="field.onChange ? field.onChange(field) : null"
      ></textarea>

      <!-- AUTOCOMPLETE -->
      <input
        matInput
        type="text"
        [formControl]="field.formControl"
        [matAutocomplete]="autoDropdown"
        [placeholder]="field.placeholder"
        [readOnly]="field.readOnly"
        [required]="formConfigData.requiredFields ? formConfigData.requiredFields[field.id] !== false : true"
        (change)="field.onChange ? field.onChange(field) : null"
        (keyup)="field.onKeyUp ? field.onKeyUp({field, $event}) : null"
        *ngIf="field.type === 'autocomplete'"
      >
      <mat-autocomplete
        class="mat-autocomplete-mobile"
        #autoDropdown="matAutocomplete"
        [displayWith]="getOptionText"
        (optionSelected)="field.onChange ? field.onChange(field) : null"
      >
        <mat-option
          *ngFor="let option of field.options"
          [value]="option"
        >
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>

      <!-- SELECT -->
      <mat-select
        *ngIf="field.type === 'select'"
        [formControl]="field.formControl"
        [placeholder]="field.placeholder"
        [required]="formConfigData.requiredFields ? formConfigData.requiredFields[field.id] !== false : true"
        (selectionChange)="field.onChange ? field.onChange(field) : null"
      >
        <mat-option *ngFor="let option of field.options" [value]="option">
          {{ option.label }}
        </mat-option>
      </mat-select>

      <div matTextSuffix *ngIf="field.type === 'autocomplete'">
        <mat-icon>arrow_drop_down</mat-icon>
      </div>

      <!-- ERRORS -->
      <mat-error>
        <div *ngIf="field.formControl.getError('required')">
          <span [innerHTML]="'features.contact-form.error.required' | translate: { label: field.label }"></span>
        </div>

        <div *ngIf="field.formControl.getError('invalid') || field.formControl.getError('pattern')">
          <span [innerHTML]="'features.contact-form.error.invalid' | translate: { label: field.label, placeholder: field.placeholder || '' }"></span>
        </div>

        <div *ngIf="field.formControl.getError('not-found')">
          <span [innerHTML]="'features.contact-form.error.not-found' | translate: { value: field.formControl.value }"></span>
        </div>
      </mat-error>

    </mat-form-field>
  </div>

</ng-template>
