<div class="video-controls" #slider>

  <div
    class="__timer"
    *ngIf="controlsActions.indexOf('timer') > -1"
    [style.order]="controlsActions.indexOf('timer')"
  >
    {{timer}}
  </div>

  <!--<mat-slider
    class="__timeline"
    *ngIf="controlsActions.indexOf('timeline') > -1"
    [style.order]="controlsActions.indexOf('timeline')"
    step="0.5"
    [min]="0"
    [max]="duration"
    [(ngModel)]="sliderCurrentPosition"
    (change)="onSliderChange()"
  ></mat-slider>-->

  <mat-slider
    class="__timeline"
    *ngIf="controlsActions.indexOf('timeline') > -1"
    [min]="0"
    [max]="duration"
    [step]="0.5"
    (change)="onSliderChange($event)"
    color="accent"
  >
    <input matSliderThumb [value]="sliderCurrentPosition">
  </mat-slider>

  <button
    class="__action"
    *ngIf="controlsActions.indexOf('play') > -1"
    [style.order]="controlsActions.indexOf('play')"
    (click)="onPlayChange.emit()"
    mat-icon-button
  >
    <mip-icon
      [icon]="isPlaying ? 'pause' : 'play'"
      [solid]="true"
    ></mip-icon>
  </button>

  <button
    class="__action"
    *ngIf="controlsActions.indexOf('mute') > -1"
    [style.order]="controlsActions.indexOf('mute')"
    (click)="onMuteChange.emit()"
    mat-icon-button
  >
    <mip-icon
      [icon]="isMuted ? 'volume-off' : 'volume-on'"
      [solid]="true"
    ></mip-icon>
  </button>

</div>
