import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollFixTopDirective } from './scroll-fix-top.directive';
import { ScrollFixComponent } from './scroll-fix.component';

@NgModule({
  declarations: [ScrollFixComponent, ScrollFixTopDirective],
  imports: [
    CommonModule
  ],
  exports: [ScrollFixComponent, ScrollFixTopDirective]
})
export class ScrollFixModule {
}
