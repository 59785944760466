import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { AudioModel } from '@libs/gc-common/lib/api/models/audio';
import { MediaSongModel } from '@libs/gc-common/lib/api/models/media';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-post-audio-text',
  templateUrl: './post-audio-text.component.html',
  styleUrls: ['./post-audio-text.component.scss']
})
export class PostAudioTextComponent implements OnInit {

  @Input() audio: AudioModel;
  @Input() song: MediaSongModel;
  @Input() theme = 'light';

  audioText = '';

  isMobile = false;
  isDesktop = false;

  constructor(
    private mobileDetect: MobileDetectService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    // console.log('post-audio-text.component->ngOnInit(): this.isMobile', this.isMobile);

    this.isDesktop = !this.mobileDetect.isMobile();
    // console.log('post-audio-text.component->ngOnInit(): this.isDesktop', this.isDesktop);
  }

  ngOnInit() {
    // console.log('post-audio-text.component->ngOnInit(): this.audio', this.audio);
    // console.log('post-audio-text.component->ngOnInit(): this.song', this.song);

    if (this.audio) {
      this.audioText = `${this.audio.description} <strong>@${this.audio.owner.username}</strong>`;
      // console.log('post-audio-text.component->ngOnInit(): this.audioText', this.audioText);
    }
    else if (this.song) {
      this.audioText = `Song not available!`;
    }

    // console.log('post-audio-text.component->ngOnInit(): this.audioText', this.audioText);

  }

  /*get audioText() {
   return `${this.audio.description} @${this.audio.owner.username}`;
   }*/

}
