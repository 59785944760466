<mat-nav-list
  (onReachingBottom)="_onReachingBottom()"
  [bottomBounce]="bottomBounce"
  [mipOnReachingBottom]="usersList.length"
  class="users-list"
>

  <mat-divider *ngIf="topDivider"></mat-divider>

  <div *ngIf="title" class="__title" mat-subheader>
    {{ title }}
  </div>

  <a
    *ngFor="let user of usersList; let $index = index;"
    mat-list-item
  >

    <div (click)="onClick(user, $event)" class="__item-inner">

      <a
        [mipGoBackReferrer]="true"
        [routerLink]="enableOnSelect ? [] : ['/@' + user.username]"
        class="__item-link"
        matRipple
      >

        <mip-avatar
          [size]="avatarSize"
          [userModel]="user"
          class="__avatar"
        ></mip-avatar>

        <div class="__user-info">
          <strong *ngIf="user.getName()">{{ user.getName() }}</strong>
          <mip-profile-slug-name [user]="user"></mip-profile-slug-name>
        </div>

      </a>

      <div class="__actions">
        <mip-profile-following-button
          *ngIf="showFollowBottom"
          [profile]="user"
        ></mip-profile-following-button>
      </div>

    </div>

  </a>

  <mat-list-item
    *ngIf="!isLoading && usersList.length  === 0"
    class="__no-data"
  >
    <span mipTranslate="accounts-list.no-accounts">No accounts found!</span>
  </mat-list-item>

  <mat-divider *ngIf="bottomDivider"></mat-divider>

</mat-nav-list>

<div
  *ngIf="isLoading"
  class="__loading"
>
  <mip-loading></mip-loading>
</div>

<!--  || hasMoreItems === false -->
<mip-download-app-see-more
  *ngIf="showDownloadApp && (usersList.length >= listLimit)"
  label="{{'accounts-list.see-more.accounts' | translate}}"
></mip-download-app-see-more>

