import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationStart,
  NavigationEnd,
  Router,
} from '@angular/router';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { CookieService } from '@libs/gc-common/lib/services/cookie/cookie.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {
  filter,
  Observable,
  Subject,
} from 'rxjs';

export interface NavigateToInterface {
  queryParams?;
  relativeTo?;
  fragment?;
  redirectPath?: boolean | string;
  allowRemoveGoBack?: boolean;
  forceReload?: boolean;
  skipLocationChange?: boolean;
  queryParamsHandling?: 'merge' | 'preserve';
  data?: object | Array<any>;
}

export interface RegisterGoBackParamsInterface {
  href?: string;
  path?: string;
  queryParams?: object;
  redirectUrl?: string;
  canRemove?: boolean;
  title?: string;
  allowRemoveGoBack?: boolean;
  fragment?: string;
}

export interface GoBackRefererInterface {
  path?: string;
  search?: object;
  fragment?: string;
  title?: string;
  canRemove?: boolean;
  created_at?: number;
}

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  
  _onNavigationEndedObserver = new Subject();
  _onNavigationEndedObservable = this._onNavigationEndedObserver.asObservable();
  
  // _appVersion = environment.appVersion;
  
  _goBackLocalKeyBase = `mip-go-back-referer`;
  _goBackLocalKey = this._goBackLocalKeyBase; // -${this._appVersion}
  _goBackReferrers: { [key: string]: GoBackRefererInterface } = {};
  _goBackStorageExpirationTime = 3600000;
  
  activatedRouteObject = null;
  hasChangedToErrorPage = false;
  
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    @Optional() @Inject(REQUEST) private request: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // console.log('router.service->constructor(): this.activatedRoute', this.activatedRoute);
    // this.setActiveRoute(this.activatedRoute);
    
    let onNavigationEndedTimeout = null;
    
    this.router.events.pipe(
      filter(
        event => event instanceof ActivationStart || event instanceof NavigationEnd
      )
    ).subscribe(event => {
      // console.log('router.service->constructor(): subscribe', event['url'], event instanceof NavigationEnd, event);
      
      if (event instanceof NavigationEnd) {
        // console.log('router.service->constructor(): NavigationEnd', event['url'], event instanceof NavigationEnd, event);
        
        this.setActiveRoute(this.activatedRoute, 'router.events.subscribe');
        
        if (onNavigationEndedTimeout) {
          clearTimeout(onNavigationEndedTimeout);
        }
        
        if (utilsFactory.isBrowser) {
          onNavigationEndedTimeout = setTimeout(() => {
            this._onNavigationEndedObserver.next(event);
          }, 300);
        }
      }
      
      /*const {config} = this.router;
       // console.log('router.service->matchRouteToUrl(): config', config);
       
       if (config && config.length) {
       this.matchRouteToUrl(config);
       }*/
      
      /*else if (event instanceof NavigationError) {
       // console.log('router.service->constructor(): NavigationError', event);
       
       if (event.url !== '/null') {
       // utilsFactory.ignoreWaitToBeTrue = true;
       }
       }*/
      
    });
    
    if (utilsFactory.isBrowser) {
      
      for (const [key] of Object.entries(window.localStorage)) {
        if (key.indexOf(this._goBackLocalKeyBase) > -1) {
          // if (key.indexOf(this._appVersion) === -1) {
          // console.log('router.service->constructor(): REMOVE old goBack data', key);
          window.localStorage.removeItem(key);
          // }
        }
      }
      
      // const goBackReferrers = JSON.parse(window.localStorage.getItem(this._goBackLocalKey));
      const goBackReferrers = this.cookieService.getCookie(this._goBackLocalKey);
      // console.log('router.service->constructor(): goBackReferrers', this._goBackLocalKey, goBackReferrers);
      
      if (goBackReferrers) {
        
        this._goBackReferrers = {};
        
        // tslint:disable-next-line:forin
        for (const i in goBackReferrers) {
          // console.log('router.service->constructor(): Date.now() - goBackReferrers[i].created_at', Date.now() - goBackReferrers[i].created_at, this._goBackStorageExpirationTime);
          
          if ((Date.now() - goBackReferrers[i].created_at) < this._goBackStorageExpirationTime) {
            this._goBackReferrers[i] = goBackReferrers[i];
          }
        }
        
        // console.log('router.service->constructor(): this._goBackReferrers', this._goBackReferrers);
        
      }
    }
  }
  
  /**
   * Method to return the current route pathname (equivalent to window.location.pathname)
   */
  getCurrentPathName() {
    try {
      return this.getCurrentUrl().split('?')[0];
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the current route URL (equivalent to window.location.pathname + window.location.search)
   */
  getCurrentUrl() {
    try {
      const url = decodeURIComponent(this.router.url);
      // console.log(`router.service->getCurrentUrl() url`, url);
      
      return url;
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the current route pathname (equivalent to window.location.pathname)
   */
  getHostName() {
    try {
      
      // console.log('router.service->getHostName()');
      
      if (isPlatformServer(this.platformId)) {
        // console.log('router.service->getHostName()', `${this.request.protocol}:${environment.appBaseHrefUseValue}`);
        return `${this.request.protocol}:${environment.appBaseHrefUseValue}`;
      }
      else if (utilsFactory.isBrowser) {
        // console.log('router.service->getHostName()', window.location['origin']);
        return `${window.location['origin']}`;
      }
      
      return null;
      
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the highest parent route of a given route
   */
  async _getTheHighestParentRoute() {
    try {
      // console.log('router.service->_getTheHighestParentRoute(): this.activatedRouteObject', !!this.activatedRouteObject);
      
      await utilsFactory.waitToBeTrue('router.service', () => !!this.activatedRouteObject);
      
      let currentChild: any = this.activatedRouteObject;
      // console.log('router.service->_getTheHighestParentRoute(): currentChild', currentChild);
      
      let countWhile = 0;
      
      // getting the highest parent route so we can go deep into all route chain
      while (currentChild && currentChild.parent && countWhile < 100) {
        // console.log('router.service->_getTheHighestParentRoute(): currentChild WHILE', countWhile, currentChild);
        countWhile++;
        currentChild = currentChild.parent;
      }
      
      // console.log('router.service->_getTheHighestParentRoute(): currentChild ----------------------- ');
      
      return currentChild || null;
      
    }
    catch (e) {
      throw e;
    }
  }
  
  _getRouteParams(route: ActivatedRouteSnapshot) {
    if (route.children.length === 0) {
      return route.params;
    }
    
    const combinedChildParams = route.children.reduce(
      (prev, childRoute) => ({ ...prev, ...this._getRouteParams(childRoute) }),
      {}
    );
    return {
      ...route.params,
      ...combinedChildParams
    };
  }
  
  /**
   * Method to return the route "data|queryParams|params" recursively
   */
  async _getNextedRouteParam(paramType, paramName?, from?) {
    try {
      
      const returnParams = {};
      let countWhile = 0;
      
      if (!paramType) {
        throw new Error(`'paramType' must be provided`);
      }
      
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] paramName`, paramName);
      
      let currentChild: any = await this._getTheHighestParentRoute();
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] currentChild BEFORE`, currentChild);
      
      while (currentChild && countWhile < 100) {
        // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] currentChild CURRENT`, countWhile, currentChild);
        
        if (
          currentChild[paramType] ||
          (
            currentChild.snapshot &&
            currentChild.snapshot[paramType]
          )
        ) {
          
          let snapshot = {};
          
          if (currentChild.snapshot && currentChild.snapshot[paramType]) {
            snapshot = currentChild.snapshot[paramType];
          }
          else {
            snapshot = currentChild[paramType];
          }
          
          // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] snapshot`, snapshot);
          
          if (Array.isArray(snapshot) || typeof snapshot === 'object') {
            
            // tslint:disable-next-line:forin
            for (const i in snapshot) {
              returnParams[i] = snapshot[i];
            }
            
          }
          else {
            returnParams[paramType] = snapshot;
          }
        }
        
        // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] firstChild`, currentChild.firstChild);
        
        currentChild = currentChild.firstChild;
        countWhile++;
      }
      
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] returnParams`, returnParams);
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] --------------------------------------------------------------------`);
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] --------------------------------------------------------------------`);
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] --------------------------------------------------------------------`);
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] --------------------------------------------------------------------`);
      // console.log(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}] --------------------------------------------------------------------`);
      
      if (paramName) {
        return paramName in returnParams ? returnParams[paramName] : null;
      }
      else {
        return returnParams;
      }
      
    }
    catch (e) {
      console.error(`router.service->getRouteDataParam() [${paramType}${from ? '/' + from : ''}]: ERROR`, e);
      throw e;
    }
  }
  
  onNavigationEnded() {
    return this._onNavigationEndedObservable;
  }
  
  /**
   * This method is returning a Observable every time because we need to
   * the ability to unsubscribe within the component
   */
  onRouteChange(): Observable<{ url: string, fragment: any, params: any, queryParams: any, data: any }> {
    return new Observable(observer => {
      
      this.getRouteObject().then(routeObj => {
        observer.next(routeObj);
      });
      
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          
          // console.log(`router.service->onRouteChange() event`, event);
          
          this.getRouteObject().then(routeObj => {
            observer.next(routeObj);
          });
        }
      });
      
    });
  }
  
  /**
   * Method to get a GoBack referer
   */
  getGoBackReferer(routerLinkPrimary?: string, routerLinkSecondary?: string | boolean): GoBackRefererInterface {
    try {
      
      if (!routerLinkPrimary) {
        routerLinkPrimary = this.getCurrentUrl();
      }
      
      routerLinkPrimary = routerLinkPrimary.split('?')[0];
      // console.log(`router.service->getGoBackReferer(): routerLinkPrimary`, routerLinkPrimary);
      
      const decodedRouterLinkPrimary = routerLinkPrimary ? decodeURIComponent(routerLinkPrimary) : null;
      // console.log('router.service->getGoBackReferer(): decodedRouterLinkPrimary', decodedRouterLinkPrimary);
      
      const decodedRouterLinkSecondary = typeof routerLinkSecondary === 'string' ? decodeURIComponent(routerLinkSecondary) : null;
      // console.log('router.service->getGoBackReferer(): decodedRouterLinkSecondary', decodedRouterLinkSecondary);
      
      let goBackReferer = this._goBackReferrers[decodedRouterLinkPrimary] || null;
      // console.log('router.service->getGoBackReferer(): goBackReferer', goBackReferer);
      
      if (!goBackReferer && routerLinkSecondary) {
        
        // tslint:disable-next-line:forin
        for (const path in this._goBackReferrers) {
          if (path.indexOf(decodedRouterLinkSecondary) === 0 || path.indexOf(decodedRouterLinkPrimary) === 0) {
            // console.log('router.service->getGoBackReferer(): this._goBackReferrers[path]', this._goBackReferrers[path]);
            goBackReferer = this._goBackReferrers[path];
            break;
          }
        }
        
      }
      
      // console.log('router.service->getGoBackReferer(): goBackReferer', goBackReferer);
      return goBackReferer;
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to get a GoBack referer final URL
   */
  getGoBackRefererUrl(routerLinkPrimary?: string, routerLinkSecondary?: string | boolean): string {
    try {
      // console.log(`router.service->getGoBackRefererUrl(): routerLinkPrimary, routerLinkSecondary`, routerLinkPrimary, routerLinkSecondary);
      // console.log(`router.service->getGoBackRefererUrl(): this._goBackReferrers`, this._goBackReferrers);
      
      const goBackReferer = this.getGoBackReferer(routerLinkPrimary, routerLinkSecondary);
      // console.log(`router.service->getGoBackRefererUrl(): goBackReferer`, goBackReferer);
      
      let finalUrl = goBackReferer.path;
      
      if (goBackReferer.search) {
        finalUrl += `/?${utilsFactory.serializeObject(goBackReferer.search)}`;
      }
      
      // console.log(`router.service->getGoBackRefererUrl(): finalUrl`, finalUrl);
      
      return finalUrl;
      
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to register a new GoBack URL referer
   */
  registerGoBackReferer(routerLink: RegisterGoBackParamsInterface, goBackReferer?: string) {
    try {
      if (utilsFactory.isBrowser) {
        
        // console.log('router.service->registerGoBackReferer(): routerLink', routerLink);
        
        const searchParams = routerLink.queryParams || {};
        // console.log('router.service->registerGoBackReferer(): searchParams 1', searchParams);
        
        let redirectPathName = routerLink.redirectUrl || window.location['pathname'];
        // console.log('router.service->registerGoBackReferer(): redirectPathName', redirectPathName);
        
        let redirectSearch = window.location['search'];
        // console.log('router.service->registerGoBackReferer(): redirectSearch 1', redirectSearch);
        
        if (redirectPathName.indexOf('?') > -1) {
          const searchSplit = redirectPathName.split('?');
          // console.log('router.service->registerGoBackReferer(): searchSplit', searchSplit);
          redirectPathName = searchSplit[0];
          redirectSearch = searchSplit[1];
        }
        
        if (routerLink.path.indexOf('http') > -1) {
          redirectPathName = redirectPathName.indexOf('http') === -1 ? environment.networkUrl + redirectPathName : redirectPathName;
          // console.log('router.service->registerGoBackReferer(): routerLink.path', routerLink.path, redirectPathName);
        }
        
        // console.log('router.service->registerGoBackReferer(): redirectPath', redirectPathName);
        // console.log('router.service->registerGoBackReferer(): redirectSearch 2', redirectSearch);
        
        if (redirectSearch) {
          
          if (redirectSearch.indexOf('?') > -1) {
            redirectSearch = redirectSearch.split('?')[1];
            // console.log(`router.service->registerGoBackReferer(): redirectSearch`, redirectSearch);
          }
          
          const parseSearch = redirectSearch.split('&');
          // console.log(`router.service->registerGoBackReferer(): parseSearch`, parseSearch);
          
          // tslint:disable-next-line:forin
          for (const i in parseSearch) {
            const parsedParams = parseSearch[i].split('=');
            // console.log(`router.service->registerGoBackReferer(): parsedParams`, i, parsedParams);
            
            if (searchParams[parsedParams[0]]) {
              if (typeof searchParams[parsedParams[0]] === 'string') {
                searchParams[parsedParams[0]] = [searchParams[parsedParams[0]], parsedParams[1]];
              }
              else {
                searchParams[parsedParams[0]].push(parsedParams[1]);
              }
            }
            else {
              searchParams[parsedParams[0]] = parsedParams[1];
            }
          }
          
          // console.log(`router.service->registerGoBackReferer(): searchParams 2`, searchParams);
          
        }
        
        if (routerLink.path && routerLink.path !== redirectPathName) {
          
          this._goBackReferrers[routerLink.path] = {
            path: redirectPathName,
            search: searchParams,
            fragment: routerLink.fragment || null,
            title: routerLink.title || null,
            canRemove: routerLink.allowRemoveGoBack,
            created_at: Date.now()
          };
          
          // console.log('router.service->registerGoBackReferer(): this._goBackReferrers[routerLink.path]', this._goBackReferrers[routerLink.path]);
          // window.localStorage.setItem(this._goBackLocalKey, JSON.stringify(this._goBackReferrers));
          this.cookieService.setCookie(this._goBackLocalKey, this._goBackReferrers, 365);
          
        }
        // }
      }
    }
    catch (e) {
      console.error('router.service->registerGoBackReferer(): ERROR', e);
      throw e;
    }
  }
  
  /**
   * Method to remove a route reference from
   */
  removeGoBackReferer(routerLink: string, force = false) {
    try {
      // console.log(`router.service->removeGoBackReferer()`, routerLink, this._goBackReferrers);
      
      if (this._goBackReferrers[routerLink] && (force || this._goBackReferrers[routerLink].canRemove)) {
        delete this._goBackReferrers[routerLink];
        // console.log('router.service->removeGoBackReferer()', routerLink, this._goBackReferrers);
        
        if (utilsFactory.isBrowser) {
          // window.localStorage.setItem(this._goBackLocalKey, JSON.stringify(this._goBackReferrers));
          this.cookieService.setCookie(this._goBackLocalKey, this._goBackReferrers, 365);
        }
      }
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to remove a route by the referer route
   */
  removeGoBackByReferer(refererLink: string, force = false) {
    try {
      // console.log(`router.service->removeGoBackByReferer()`, refererLink, this._goBackReferrers);
      
      for (const i in this._goBackReferrers) {
        // console.log('router.service->removeGoBackByReferer()', i, refererLink, this._goBackReferrers[i]);
        if (this._goBackReferrers[i].path === refererLink) {
          this.removeGoBackReferer(i, force);
          break;
        }
      }
      
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return a Previously "GoBack" route
   */
  goPreviouslyRoute() {
    
    const currentGoBackUrl = this.getCurrentUrl();
    // console.info('router.service->goPreviouslyRoute(): currentGoBackUrl', currentGoBackUrl);
    
    const backPathReferer = this.getGoBackReferer(currentGoBackUrl);
    // console.info('router.service->goPreviouslyRoute(): backPathReferer', backPathReferer);
    
    return backPathReferer;
    
  }
  
  /**
   * Method to set the this.activatedRouteObject with the current active route
   */
  setActiveRoute(activatedRoute, from?) {
    // console.log(`router.service->setActiveRoute() activatedRoute ${from}`, activatedRoute);
    
    // if (!this.activatedRouteObject) {
    // console.log(`router.service->setActiveRoute() activatedRoute`, activatedRoute);
    this.activatedRouteObject = activatedRoute;
    // }
  }
  
  standardizeUrl(path) {
    try {
      // console.log('router.service->standardizeUrl(): path', path);
      
      let finalPath: any = (Array.isArray(path) ? path.join('/') : path).replace('//', '/');
      // console.log('router.service->standardizeUrl(): finalPath', finalPath);
      
      if (finalPath.indexOf('../') === 0) {
        
        const parsedPath = finalPath.split('/');
        // console.log('router.service->standardizeUrl(): [..] parsedPath', parsedPath);
        
        const parsedCurrentPathName = this.getCurrentPathName().split('/');
        // console.log('router.service->standardizeUrl(): [..] parsedCurrentPathName', parsedCurrentPathName);
        
        for (const i in parsedPath) {
          if (parsedPath[i] === '..') {
            parsedCurrentPathName.splice((parsedCurrentPathName.length - 1), 1);
            // console.log('router.service->standardizeUrl(): [..] parsedCurrentPathName', parsedCurrentPathName);
          }
        }
        
        for (const i in parsedPath) {
          if (parsedPath[i] === '..') {
            parsedPath.splice(parseInt(i, 10), 1);
          }
        }
        
        finalPath = `${parsedCurrentPathName.join('/')}/${parsedPath}`;
        // console.log('router.service->standardizeUrl(): [..] finalPath 2', finalPath);
        
      }
        
        // else if (path.indexOf('./') === 0) {
      // change because the ['.'] was not enter the ELSE IF, so the finalPath was ending up like '.'
      else if (finalPath.indexOf('./') === 0) {
        
        const currentPathName = this.getCurrentPathName();
        // console.log('router.service->standardizeUrl(): [.] currentPathName', currentPathName);
        
        finalPath = (finalPath.replace('./', currentPathName + '/')).replace('//', '/');
        // console.log('router.service->standardizeUrl(): [.] finalPath 1', finalPath);
        
        finalPath = finalPath.split('/');
        // console.log('router.service->standardizeUrl(): [.] finalPath 2', finalPath);
        
        if (finalPath[finalPath.length - 1] === '') {
          finalPath.splice((finalPath.length - 1), 1);
          // console.log('router.service->standardizeUrl(): [.] finalPath 3', finalPath);
        }
        
        finalPath = finalPath.join('/');
        // console.log('router.service->standardizeUrl(): [.] finalPath 4', finalPath);
        
      }
      
      // console.log('router.service->standardizeUrl(): finalPath 5', finalPath);
      
      return finalPath;
      
    }
    catch (e) {
      console.error('router.service->standardizeUrl(): ERROR', e);
      throw e;
    }
  }
  
  getRouteConfigByPath(url) {
    try {
      // console.log('router.service->getCurrentRoute(): ################################-');
      // console.log('router.service->getCurrentRoute(): ################################-');
      // console.log('router.service->getCurrentRoute(): url', url);
      
      const finalUrl = this.standardizeUrl(url);
      // console.log('router.service->getCurrentRoute(): finalUrl', finalUrl);
      
      const listRoutesByPath = {};
      
      const getRecursiveRoute = (config, path = '', prefix = '>') => {
        
        for (const route of config) {
          let localPath = path;
          
          if (route.path) {
            localPath += '/' + route.path;
            
            const parsedParams = [...route.path.matchAll(/(:[a-zA-Z0-9.\-_]*)\/?/g)].map(item => {
              
              const key = item[1];
              const index = localPath.indexOf(item[1]);
              
              return {
                key,
                index,
                start: index,
                end: index + key.length
              };
            });
            // console.log('router.service->getCurrentRoute(): parsedParams', prefix, parsedParams);
            
            listRoutesByPath[localPath] = {
              route,
              path: route.path,
              fullPath: localPath,
              params: parsedParams.length ? parsedParams : null
            };
          }
          
          const children = route['_loadedRoutes'] || route['children'];
          // console.log('router.service->getCurrentRoute(): children', prefix, children);
          
          // console.log('router.service->getCurrentRoute(): route', prefix, localPath, (children ? children.length : null));
          
          if (children && children.length > 0) {
            getRecursiveRoute(children, localPath);
          }
          
        }
      };
      
      getRecursiveRoute(this.router.config);
      // console.log('router.service->getCurrentRoute(): listRoutesByPath', listRoutesByPath);
      
      return listRoutesByPath[finalUrl] || null;
      
    }
    catch (e) {
      console.error('router.service->getCurrentRoute(): ERROR', e);
      throw e;
    }
  }
  
  /**
   * Method to go back to the referer
   */
  async goBackReferer(currentPathname?: string | { path, search, title, canRemove }) {
    // console.log('router.service->goBackReferer() currentPathname', currentPathname);
    
    let pathName = null;
    
    if (typeof currentPathname === 'object' && currentPathname.path) {
      pathName = currentPathname.path;
    }
    else if (typeof currentPathname === 'string') {
      pathName = currentPathname || this.getCurrentUrl();
    }
    
    // console.log('router.service->goBackReferer() pathName', pathName);
    
    const goBackReferer = this.getGoBackReferer(pathName);
    // console.log('router.service->goBackReferer() goBackReferer', goBackReferer);
    
    if (goBackReferer && typeof goBackReferer === 'object') {
      this.removeGoBackReferer(pathName);
      await this.navigateTo(goBackReferer.path, { queryParams: goBackReferer.search });
    }
    
  }
  
  /**
   * Method to return the route built object
   */
  async getRouteObject(from?: string): Promise<{ url: string, fragment: any, params: any, queryParams: any, data: any }> {
    try {
      
      const promises = [
        this.getRouteFragments(from),
        this.getRouteParam(null, from),
        this.getRouteQueryParam(null, from),
        this.getRouteDataParam(null, from)
      ];
      
      const responses = await Promise.all(promises);
      // console.log('router.service->getRouteObject(): responses', responses[2]);
      
      const { fragment } = responses[0];
      
      let url = this.getCurrentUrl();
      
      if (url.indexOf('#') > -1) {
        url = url.split('#')[0];
      }
      
      // console.log('router.service->getRouteObject(): url', url);
      
      const params = {
        url,
        fragment: fragment || null,
        params: responses[1],
        queryParams: responses[2],
        data: responses[3]
      };
      // console.log('router.service->getRouteObject(): params', params);
      
      return params;
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the route params recursively
   */
  async getRouteFragments(from?): Promise<any> {
    try {
      return this._getNextedRouteParam('fragment', null, from);
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the route params recursively
   */
  async getRouteParam(paramName?, from?): Promise<any> {
    try {
      return this._getNextedRouteParam('params', paramName, from);
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the route queryParams recursively
   */
  async getRouteQueryParam(paramName?, from?): Promise<any> {
    try {
      return this._getNextedRouteParam('queryParams', paramName, from);
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to return the route dataParams recursively
   */
  async getRouteDataParam(paramName?, from?): Promise<any> {
    try {
      // console.log('router.service->getRouteDataParam(): paramName', paramName);
      return await this._getNextedRouteParam('data', paramName, from);
    }
    catch (e) {
      throw e;
    }
  }
  
  /**
   * Method to navigate to ANY route
   */
  async navigateTo(path: Array<string> | string, params: NavigateToInterface = {}, skipLocationChange = false) {
    try {
      
      // console.log('router.service->navigateTo(): path', path, params);
      
      let finalPath: any = path;
      // console.log('router.service->navigateTo(): finalPath [FINAL]', finalPath);
      
      if (finalPath.indexOf('http') === -1) {
        finalPath = this.standardizeUrl(path);
      }
      
      if (params.redirectPath) {
        this.registerGoBackReferer({
          path: finalPath,
          redirectUrl: typeof params.redirectPath === 'string' ? params.redirectPath : this.getCurrentPathName(),
          allowRemoveGoBack: params.allowRemoveGoBack
        });
      }
      
      // removing the last '/' if present (to avoid errors)
      finalPath = finalPath.replace(/\/$/, '');
      
      if (finalPath.indexOf('http') > -1) {
        document.location = finalPath;
      }
      else {
        
        params.queryParams = params.queryParams || await this.getRouteQueryParam();
        // console.log('router.service->navigateTo(): params', finalPath, params);
        
        if (skipLocationChange) {
          params.skipLocationChange = true;
        }
        
        if (params.forceReload) {
          // console.log('router.service->navigateTo(): this.router.navigateByUrl');
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([finalPath], params);
          });
        }
        else {
          // console.log('router.service->navigateTo(): this.router.navigate', finalPath, params);
          await this.router.navigate(finalPath.split('/'), params);
        }
        
      }
      
    }
    catch (e) {
      console.error('router.service->navigateTo(): ERROR', e);
      throw e;
    }
  }
  
  /**
   * Method to navigate to CHILD route
   */
  async navigateToChild(path: Array<string> | string, params?: object, options?: NavigateToInterface) {
    try {
      // console.log('router.service->navigateToChild(): path', path);
      
      if (!path || (Array.isArray(path) && path.length === 0)) {
        path = this.getCurrentPathName();
      }
      
      path = (Array.isArray(path) ? path : [path]);
      // console.log('router.service->navigateToChild(): path', path);
      
      const routeConfig = {
        queryParams: params || await this.getRouteQueryParam(),
        relativeTo: this.activatedRoute,
        ...options
      };
      // console.log('router.service->navigateToChild(): routeConfig', routeConfig);
      
      await this.navigateTo(path, routeConfig);
    }
    catch (e) {
      throw e;
    }
  }
  
}
