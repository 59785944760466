import {
  NgForOf,
  NgIf,
  NgTemplateOutlet
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipLoadingModule } from '@libs/gc-common/lib/components/mip-loading/mip-loading.module';
import { MipModalModule } from '@libs/gc-common/lib/components/mip-modal/mip-modal.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { MipProgressBarModule } from '@libs/gc-common/lib/components/mip-progress-bar/mip-progress-bar.module';
import { MipTextareaModule } from '@libs/gc-common/lib/components/mip-textarea/mip-textarea.module';
import { MipVideoControlsModule } from '@libs/gc-common/lib/components/mip-video-controls/mip-video-controls.module';
import { BottomSheetModule } from '@libs/gc-common/lib/directives/bottom-sheet/bottom-sheet.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { LinkifyModule } from '@libs/gc-common/lib/directives/linkify/linkify.module';
import { OnReachingBottomModule } from '@libs/gc-common/lib/directives/on-reaching-bottom/on-reaching-bottom.module';
import { ScrollTopModule } from '@libs/gc-common/lib/directives/scroll-top/scroll-top.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { AdvertisementModule } from '@libs/gc-common/lib/features/advertisement/advertisement.module';
import { ProfileFollowingButtonModule } from '@libs/gc-common/lib/features/profile-following-button/profile-following-button.module';
import { ShareModalModule } from '@libs/gc-common/lib/features/share-modal/share-modal.module';
import { MillifyModule } from '@libs/gc-common/lib/pipes/millify/millify.module';
import { SafeHtmlModule } from '@libs/gc-common/lib/pipes/safe-html/safe-html.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ClickOutsideModule } from 'ng4-click-outside';
import { MomentModule } from 'ngx-moment';
import { SwiperModule } from 'swiper/angular';

import { PostAudioTextComponent } from './post-audio-text/post-audio-text.component';
import { PostCommentsComponent } from './post-comments/post-comments.component';
import { PostHeaderComponent } from './post-header/post-header.component';
import { PostInteractionComponent } from './post-interaction/post-interaction.component';
import { SingleThemeComponent } from './post-themes/single-theme/single-theme.component';
import { StaticThemeComponent } from './post-themes/static-theme/static-theme.component';
import { PostVideoComponent } from './post-video/post-video.component';
import { PostViewComponent } from './post-view.component';

@NgModule({
  declarations: [
    PostAudioTextComponent,
    PostCommentsComponent,
    PostHeaderComponent,
    PostInteractionComponent,
    SingleThemeComponent,
    StaticThemeComponent,
    PostVideoComponent,
    PostViewComponent
  ],
  imports: [
    MipIconModule,
    MatButtonModule,
    RouterModule,
    SwiperModule,
    MatBottomSheetModule,
    MipProfileSlugNameModule,
    MipAvatarModule,
    RoundProgressModule,
    MipProgressBarModule,
    MatIconModule,
    BottomSheetModule,
    MipModalModule,
    GoBackModule,
    AdvertisementModule,
    ShareModalModule,
    MipTranslateModule,
    MipLoadingModule,
    MomentModule,
    OnReachingBottomModule,
    TranslateModule,
    MatAutocompleteModule,
    MipTextareaModule,
    ScrollTopModule,
    LinkifyModule,
    MillifyModule,
    MatProgressBarModule,
    SafeHtmlModule,
    ColorModule,
    ProfileFollowingButtonModule,
    ClickOutsideModule,
    MatSliderModule,
    FormsModule,
    MipVideoControlsModule,
    NgIf,
    NgForOf,
    NgTemplateOutlet
  ],
  exports: [
    PostViewComponent,
    SingleThemeComponent,
    PostInteractionComponent,
    PostCommentsComponent,
    StaticThemeComponent,
    PostAudioTextComponent,
    PostVideoComponent,
    PostHeaderComponent
  ]
})
export class PostViewModule {
}
