<div
  class="__left-line"
  [mipBgColor]="mipColor"
  [style.background-color]="lineColor"
  [style.height]="bdHeight + 'px'"
  [class]="lineClasses"
></div>
<div
  class="__label"
  [mipBgColor]="mipColor"
  [style.background-color]="labelColor"
  [class]="labelClasses"
  [style.color]="textColor"
>
  <ng-content></ng-content>
</div>
<div
  class="__right-line"
  [mipBgColor]="mipColor"
  [style.background-color]="lineColor"
  [style.height]="bdHeight + 'px'"
  [class]="lineClasses"
></div>







