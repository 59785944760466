import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';

import { MipAlertFooterComponent } from './mip-alert-footer/mip-alert-footer.component';
import { MipAlertComponent } from './mip-alert.component';

@NgModule({
	declarations: [
		MipAlertComponent,
		MipAlertFooterComponent
	],
	imports: [
		CommonModule,
		ColorModule
	],
	exports: [
		MipAlertComponent,
		MipAlertFooterComponent
	]
})
export class MipAlertModule {
}
