import {
  Pipe,
  PipeTransform
} from '@angular/core';
import Millify from 'millify';

@Pipe({
  name: 'millify'
})
export class MillifyPipe implements PipeTransform {

  transform(value: number, precision = 1, minValue?): string {
    return (minValue && value < minValue) ? value.toString() : Millify(value, { precision });
  }

}
