import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { MipProfileSlugNameModule } from '@libs/gc-common/lib/components/mip-profile-slug-name/mip-profile-slug-name.module';
import { MipSectionArrowModule } from '@libs/gc-common/lib/components/mip-section-arrow/mip-section-arrow.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { ShareModalModule } from '@libs/gc-common/lib/features/share-modal/share-modal.module';

import { EventBannerComponent } from './event-banner.component';

@NgModule({
  declarations: [EventBannerComponent],
  exports: [EventBannerComponent],
  imports: [
    CommonModule,
    MipSectionArrowModule,
    MatButtonModule,
    MipIconModule,
    ColorModule,
    MipProfileSlugNameModule,
    RouterModule,
    ShareModalModule
  ]
})
export class EventBannerModule {
}
