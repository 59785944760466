<div
  class="video-ad"
  [class.--is-desktop]="isDesktop"
  [class.--is-mobile]="isMobile"
  *ngIf="hasAdBeenDestroyed === false"
>

  <video
    #adVideoPlayer
    class="__ad-video"
    [src]="videoSrc"
    [controls]="false"
    [muted]="isMuted"
    [autoplay]="autoplay"
    preload="metadata"
    playsinline
  ></video>

  <button
    class="__volume-button"
    [class.--is-muted]="isMuted"
    (click)="toggleMuteAd()"
  >
    <mip-icon
      [icon]="isMuted ? 'volume-off' : 'volume-on'"
      [solid]="true"
    ></mip-icon>
  </button>

  <div
    #adContainer
    class="__ad-content-container"
  ></div>

</div>
