import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { PostsService } from '@libs/gc-common/lib/api/services/posts/posts.service';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { PostVideoComponent } from '@libs/gc-common/lib/features/post-view/post-video/post-video.component';
import { PostViewService } from '@libs/gc-common/lib/features/post-view/post-view.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { MomentService } from '@libs/gc-common/lib/services/moment/moment.service';

@Component({
  selector: 'mip-single-theme',
  templateUrl: './single-theme.component.html',
  styleUrls: ['./single-theme.component.scss']
})
export class SingleThemeComponent implements OnInit, OnChanges {

  @ViewChild('videoPlayer') videoPlayer: PostVideoComponent;

  @Input() postModel: PostModel = null;
  @Input() isIntoViewport = false;
  @Input() isIntoTopBounceLimit = false;
  @Input() isModal = false;
  @Input() postVideoAdType: string = null;
  @Input() isPostRollAdEnable = false;
  @Input() isPreRollAdEnable = false;
  @Input() isAdEnable = false;
  @Input() postInteraction = true;
  @Input() swiperNavigation = false;
  @Input() isFirstSlide = false;
  @Input() isLastSlide = false;

  @Input() isMobile = null;
  @Input() isDesktop = null;

  @Output() onPostHeaderToggle = new EventEmitter();
  @Output() onToggleComments = new EventEmitter();
  @Output() onToggleShare = new EventEmitter();
  @Output() onPostAdFill = new EventEmitter();
  @Output() onPostAdHide = new EventEmitter();
  @Output() onPostAdPlayed = new EventEmitter();
  @Output() onPostAdEnded = new EventEmitter();

  assetsPath = environment.assetsPath;

  bufferValue = 0;

  createdAt = null;
  showTimestamp = false;

  isAdPlaying = false;
  isVideoPlaying = false;
  isDescriptionOpened = false;

  constructor(
    private postViewService: PostViewService,
    private mobileDetect: MobileDetectService,
    private userService: UserService,
    private momentService: MomentService,
    private postsService: PostsService
  ) {
    this.userService.onUserAuthentication().subscribe(user => {
      // console.log('single-player.component->constructor(): user', user);

      this.showTimestamp = user.isModerator;
    });
  }

  /**
   * Method to be triggered within component creation
   */
  ngOnInit() {
    // console.log('single-player.component->ngOnInit()');

    if (typeof this.isMobile !== 'boolean') {
      this.isMobile = this.mobileDetect.isMobile();
    }

    if (typeof this.isDesktop !== 'boolean') {
      this.isDesktop = !this.mobileDetect.isMobile();
    }

    // console.log('single-player.component->constructor()');

    const createdAt = this.momentService.single({
      date: this.postModel.createdAt,
      isEST: true,
      local: false
    });

    this.createdAt = createdAt.format('lll');
    // console.log('single-player.component->ngOnInit(): this.createdAt', this.createdAt);

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('post-view.component->ngOnChanges(): changes', this.postModel.id, changes);

    if (changes['isIntoViewport']) {
      if (this.isIntoViewport) {
        // console.log('post-view.component->ngOnChanges(): this.isIntoViewport', this.isIntoViewport);
        this.postModel.setAsViews();
      }
    }
  }

  _onToggleComments() {
    // console.log('post-view.component->_onToggleComments()');
    this.videoPlayer.pauseVideo();
    this.onToggleComments.emit();
  }

  _onToggleShare() {
    // console.log('post-view.component->_onToggleShare()');
    this.videoPlayer.pauseVideo();
    this.onToggleShare.emit();
  }

  _onPostHeaderToggle(isOpen) {
    // console.log('post-view.component->_onPostHeaderToggle(): isOpen', isOpen);

    this.isDescriptionOpened = isOpen;
    this.onPostHeaderToggle.emit(isOpen);

    if (isOpen) {
      this.videoPlayer.pauseVideo();
    }
  }

  /**
   * Video events methods
   */

  _onVideoPlay() {
    this.isVideoPlaying = true;
  }

  _onVideoPause() {
    this.isVideoPlaying = false;
  }

  _onVideoEnded() {
    console.log(`single-theme.component->_onVideoEnded()`);
  }

  _onVideoDetach() {
    this.isVideoPlaying = false;
  }

  /**
   * AD events methods
   */

  _onPostAdFill() {
    this.onPostAdFill.emit();
  }

  _onPostAdHide() {
    this.onPostAdHide.emit();
  }

  _onPostAdPlayed() {
    this.isAdPlaying = true;
    this.onPostAdPlayed.emit();
  }

  _onPostAdEnded() {
    this.isAdPlaying = false;
    this.onPostAdEnded.emit();
  }

}
