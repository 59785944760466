import { Injectable } from '@angular/core';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

import { SearchInterface } from './search.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  static instance: SearchService;

  paginationControl = {};

  constructor(
    private resourceService: ResourceService,
    private modelFactory: ModelFactoryService
  ) {
    SearchService.instance = this;
  }

  /**
   * Method to get the Trending posts
   */
  async getSearch(params?: { term }): Promise<{ matching, hashtags, users: Array<UserModel> }> {
    try {

      if (!params || !params.term) {
        throw new Error(`The "term" property must be provided.`);
      }

      console.log('search.service->getTrendingPosts(): params', params);

      const response: { matching: { user, hashtag }, hashtags, users } = await this.resourceService.get('searchApi', {
        resource: 'search',
        path: `/search`,
        // fromCache: false,
        params
      });

      const matching: { user?, hashtag? } = {};
      const users = [];
      const hashtags = [];

      console.log('search.service->getTrendingPosts(): response', response);

      if (response.matching.user) {
        matching.user = this.modelFactory.userFactory.build(response.matching.user);
      }

      if (response.matching.hashtag) {
        matching.hashtag = this.modelFactory.hashtagFactory.build(response.matching.hashtag);
      }

      if (response.users.length) {
        response.users.forEach(item => {
          if (users.length < 10) {
            users.push(this.modelFactory.userFactory.build(item));
          }
        });
      }

      if (response.hashtags.length) {
        response.hashtags.forEach(item => {
          if (hashtags.length < 10) {
            hashtags.push(this.modelFactory.hashtagFactory.build(item));
          }
        });
      }

      console.log('search.service->getSearch(): users', users);
      console.log('search.service->getSearch(): hashtags', hashtags);

      return {
        users,
        hashtags,
        matching
      };

    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the accounts search list
   */
  async getAccountsSearch(params?: { term: string, cursor?: string, limit?: number }, fromCache = true): Promise<SearchInterface> {
    try {
      console.log('search.service->getAccountsSearch(): params', params);

      if (!params || !params.term) {
        throw new Error(`The "term" property must be provided.`);
      }

      const response = await this.resourceService.get('searchApi', {
        resource: 'search',
        path: `/user/search`,
        fromCache,
        params
      });

      return {
        params: utilsFactory.deserializeParams(response.nextPage),
        users: this.modelFactory.userFactory.buildCollection(response.items)
      };
    }
    catch (e) {
      throw e;
    }
  }

  /**
   * Method to get the hashtags search list
   */
  async getHashtagsSearch(params?: { term: string, cursor?: string, limit?: number }): Promise<SearchInterface> {

    try {
      console.log('search.service->getHashtagsSearch(): params', params);

      if (!params || !params.term) {
        throw new Error(`The "term" property must be provided.`);
      }

      const response = await this.resourceService.get('searchApi', {
        resource: 'search',
        path: `/hashtag/search`,
        params
      });

      return {
        params: utilsFactory.deserializeParams(response.nextPage),
        hashtags: this.modelFactory.hashtagFactory.buildCollection(response.items)
      };
    }
    catch (e) {
      throw e;
    }
  }

}
