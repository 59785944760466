import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MipAvatarModule } from '@libs/gc-common/lib/components/mip-avatar/mip-avatar.module';
import { MipDividerModule } from '@libs/gc-common/lib/components/mip-divider/mip-divider.module';
import { MipIconModule } from '@libs/gc-common/lib/components/mip-icon/mip-icon.module';
import { ColorModule } from '@libs/gc-common/lib/directives/color/color.module';
import { GoBackModule } from '@libs/gc-common/lib/directives/go-back/go-back.module';
import { MipTranslateModule } from '@libs/gc-common/lib/directives/translate/mip-translate.module';
import { AdvertisementModule } from '@libs/gc-common/lib/features/advertisement/advertisement.module';
import { ContactFormModule } from '@libs/gc-common/lib/features/contact-form';
import { DownloadAppModule } from '@libs/gc-common/lib/features/download-app';
import { EventBannerModule } from '@libs/gc-common/lib/features/event-banner';

import { SideMenuComponent } from './side-menu.component';

@NgModule({
  declarations: [SideMenuComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MipTranslateModule,
    RouterModule,
    MipAvatarModule,
    MipIconModule,
    AdvertisementModule,
    ColorModule,
    MipDividerModule,
    DownloadAppModule,
    EventBannerModule,
    GoBackModule,
    ContactFormModule
  ],
  exports: [SideMenuComponent]
})
export class SideMenuModule {
}
