import { BaseModelFactory } from '@libs/gc-common/lib/api/models/base.model.factory';
import { BaseModelFactoryInterface } from '@libs/gc-common/lib/api/models/base.model.factory.interface';

import { TopTenRankingInterface } from './top-ten-ranking.interface';
import { TopTenRankingModel } from './top-ten-ranking.model';

export class TopTenRankingFactory extends BaseModelFactory implements BaseModelFactoryInterface {

  override model = TopTenRankingModel;

  constructor() {
    super();
  }

  override build(data: TopTenRankingInterface): TopTenRankingModel {
    return super.build(data);
  }

  override buildCollection(data: Array<TopTenRankingInterface>): Array<TopTenRankingModel> {
    return super.buildCollection(data);
  }

}

// export const topTenRankingFactory = new TopTenRankingFactory();
